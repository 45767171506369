// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TopStepper_TopStepper__3LQFz {}\n\n.TopStepper_root__138Ny{\n  padding: 0;\n}\n.TopStepper_ReactStepper__2r154 {\n  padding: 0px !important;\n  background-color: transparent !important;\n  flex-wrap: wrap;\n  margin-bottom: 15px;\n}\n.TopStepper_ReactStep__1BN3o {\n  padding: 0px !important;\n  width: auto !important;\n  margin: 0px 15px 15px 0px;\n  display: inline-flex;\n  flex-wrap: wrap;\n  flex: none !important;\n}\n\n.TopStepper_ReactStep__1BN3o button:disabled{\n  border:1px solid #707070 !important;\n  background-color: #fff;\n  color: #707070;\n  font-weight: normal !important;\n  opacity: 0.65;\n}", ""]);
// Exports
exports.locals = {
	"TopStepper": "TopStepper_TopStepper__3LQFz",
	"root": "TopStepper_root__138Ny",
	"ReactStepper": "TopStepper_ReactStepper__2r154",
	"ReactStep": "TopStepper_ReactStep__1BN3o"
};
module.exports = exports;
