import React, { useState, useEffect, useCallback, useMemo } from "react";
import styles from "./Policy.module.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
import { TextField } from "@material-ui/core";
import Input from "../../../components/UI/Form/Input/Input";
import { GET_CATEGORY } from "../../../graphql/queries/Category";
import { GET_POLICY } from "../../../graphql/queries/Policy";
import {
  CREATE_POLICY,
  UPDATE_POLICY,
  DELETE_POLICY,
} from "../../../graphql/mutations/Policy";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import Autocomplete from "../../../components/UI/Form/Autocomplete/Autocomplete";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import {
  useQuery,
  useMutation,
  ApolloError,
  useLazyQuery,
} from "@apollo/client";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Grid } from "@material-ui/core";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AutoCompleteDropDown from "../../../components/UI/Form/Autocomplete/Autocomplete";
import logout from "../../../containers/Auth/Logout/Logout";
export const Policy: React.FC = () => {
  //Autocomplete list
  const [getCategoryList, setCategoryList] = useState([]);
  //Dialog Box
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);

  //add/edit data
  const [name, setName] = useState<String>("");
  const [description, setDescription] = useState<String>("");
  const [category, setCategory] = useState({ id: null });
  const [newData, setNewData] = useState();
  const [editDataId, setEditDataId] = useState<Number | null>();

  const title = "Listing of Policy";

  //validation and error handelling
  const [isError, setIsError] = useState<any>({
    name: "",
    // description: "",
    category: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [filterName, setFilterName] = useState("");
  const [filterDescription, setFilterDescription] = useState("");
  const [filterCategory, setFilterCategory] = useState<any>();
  const [reset, setReset] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<object>();
  const [orderBy, setOrderBy] = useState<String>();
  const [sortOrder, setSortOrder] = useState<String | null>("asc");

  //queries
  const [createPolicy] = useMutation(CREATE_POLICY);
  const [updatePolicy] = useMutation(UPDATE_POLICY);
  const [deletePolicy] = useMutation(DELETE_POLICY);

  //table
  const columns = [
    {
      title: "Name",
      field: "name",
      defaultSort: orderBy?.includes("name") ? sortOrder : "asc",
    },
    // { title: "Description", field: "description" },
    {
      title: "Category",
      field: "category",
      defaultSort: orderBy?.includes("category") ? sortOrder : "",
    },
  ];
  //filter query condition declaration
  const {
    data: dataPD,
    error: errorPD,
    loading: loadingPD,
    refetch: refetchPD,
  } = useQuery(GET_POLICY, {
    variables:
      !orderBy || orderBy === ""
        ? { where: filters, sort: "name:asc" }
        : { where: filters, sort: orderBy },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    // skip: filterUseQueryCondition(),
  });

  //query for category list
  const {
    data: dataPC,
    error: errorPC,
    loading: loadingPC,
    refetch: refetchPC,
  } = useQuery(GET_CATEGORY);

  const checkValidation = () => {
    if (showDialogBox === true) {
      if (
        isError.name !== "" ||
        isError.category !== "" ||
        // isError.description !== "" ||
        !name ||
        // !description ||
        category.id === null
      ) {
        return true;
      }
      return false;
    }
    return false;
  };
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (dataPD) {
      let temp: any = {};
      temp = convertTableData(dataPD.policies);
      setNewData(temp);
      console.log("dataPD", temp);
    }
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [dataPD, filters, formState]);

  useEffect(() => {
    setSubmitDisabled(checkValidation);
  }, [name, category, submitDisabled]);

  if (loadingPC || loading) return <Loading />;
  // if (errorPC) {
  //   return (
  //     <div className="error">
  //       Error!
  //       {/* Error! ${error.message} */}
  //     </div>
  //   );
  // }
  if (getCategoryList.length === 0) {
    setCategoryList(dataPC.categories);
  }

  if (loadingPD) return <Loading />;
  if (errorPD) {
    return (
      <div className="error">
        Error!
        {logout()}
      </div>
    );
  }

  function convertTableData(data: any) {
    let arr: any = [];
    for (let i in data) {
      let tempArr: any = {};
      tempArr["id"] = data[i].id;
      tempArr["name"] = data[i].name;
      tempArr["description"] = data[i].description;
      tempArr["category"] = data[i].category ? data[i].category.name : null;
      arr.push(tempArr);
    }
    return arr;
  }

  const getCategory = {
    options: getCategoryList,
    getOptionLabel: (option: { name: String }) => option.name,
  };

  const nameFilter = (event: any) => {
    setFilterName(event.target.value);
  };
  const descriptionFilter = (event: any) => {
    setFilterDescription(event.target.value);
  };
  const categoryFilter = (event: any, newValue: any) => {
    setReset(false);
    setFilterCategory(newValue);
  };

  const handleClickOpen = (rowData: any) => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
    setIsError((isError: any) => ({
      ...isError,
      name: "",
      // description: "",
      category: "",
    }));
    setName("");
    setDescription("");
    setCategory({ id: null });
    setShowDialogBox(true);
    if (rowData.id) {
      setEditDataId(rowData.id);
      let obj = dataPC.categories;
      if (rowData.category) {
        for (let i in obj) {
          if (Object.values(obj[i]).includes(rowData.category)) {
            setCategory(obj[i]);
          }
        }
      }
      setName(rowData.name);
      setDescription(rowData.description);
    }
  };

  const deleteTableRow = (rowData: any) => {
    setLoading(true);
    deletePolicy({
      variables: {
        id: rowData.id,
      },
    })
      .then((userRes) => {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: true,
          isFailed: false,
          errMessage: "",
        }));
        refetchPD();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: err.message,
        }));
      });
  };

  const handleSearch = () => {
    let searchData: any = {};
    if (filterName) {
      searchData["name_contains"] = filterName.toString();
    }
    if (filterDescription) {
      searchData["description_contains"] = filterDescription.toString();
    }
    if (filterCategory) {
      searchData["category"] = filterCategory.id;
    }
    setFilters(searchData);
  };

  const resetForm = () => {
    setFilters({});
    setReset(true);
    setFilterName("");
    setFilterDescription("");
    setFilterCategory({});
    setOrderBy("name");
    refetchPD();
  };

  const handleSubmitDialogBox = () => {
    if (editDataId) {
      updatePolicy({
        variables: {
          id: editDataId,
          name: name,
          description: description,
          category: category ? category.id : null,
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: true,
            isDelete: false,
            isFailed: false,
            errMessage: "",
          }));
          setShowDialogBox(false);
          setEditDataId(null);
          refetchPD();
        })
        .catch((err) => {
          console.log("error", err.message);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
          setEditDataId(null);
        });
    } else {
      createPolicy({
        variables: {
          name: name,
          description: description,
          category: category ? category.id : null,
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: "",
          }));
          setShowDialogBox(false);
          refetchPD();
        })
        .catch((err) => {
          console.log("error", err);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
        });
    }
  };

  // const orderFunc = (orderedColumnId: any, orderDirection: any) => {
  //   let orderByColumn;
  //   let orderBy = "";
  //   if (orderedColumnId >= 0) {
  //     if (columns[orderedColumnId]["field"] === "name") {
  //       orderByColumn = "name";
  //     }
  //     if (columns[orderedColumnId]["field"] === "description") {
  //       orderByColumn = "description";
  //     }
  //     if (columns[orderedColumnId]["field"] === "category") {
  //       orderByColumn = "category";
  //     }
  //   }
  //   orderBy = orderByColumn + ":" + orderDirection;
  //   console.log("orderBy", orderBy);
  //   setSortOrder(orderDirection);
  //   setOrderBy(orderBy);
  // };

  const closeDialogBox = () => {
    setShowDialogBox(false);
    setEditDataId(null);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    let value = event.target.value;
    let isErrName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      name: isErrName,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
    let value = event.target.value;
    let isErr = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      // description: isErr,
    }));
    setSubmitDisabled(checkValidation);
  };

  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <main className={styles.layout}> */}
      <Typography component="h5" variant="h1">
        Policy
      </Typography>
      {!showDialogBox ? (
        <Grid className={styles.TableWrap}>
          <div className={styles.FilterAddWrap}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
            >
              <AddCircleIcon className={styles.EditIcon}/>
              &nbsp; Policy
            </Button>
          </div>
          <Grid className={styles.FilterWrap}>
            <div className={styles.FilterInput}>
              <Input
                label="Name"
                name="filterName"
                id="combo-box-demo"
                value={filterName}
                onChange={nameFilter}
                onKeyDown={handleKeyDown}
              />
            </div>
            {/* <div className={styles.FilterInput}>
              <Input
                label="Description"
                name="filterDescription"
                id="combo-box-demo1"
                value={filterDescription}
                onChange={descriptionFilter}
              />
            </div> */}
            <div className={styles.FilterInput}>
              <AutoCompleteDropDown
                {...getCategory}
                id="categoryFilter"
                value={reset ? null : filterCategory ? filterCategory : null}
                onChange={categoryFilter}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    id="categoryFilter"
                    label="Category"
                    onKeyDown={handleKeyDown}
                  />
                )}
              />
            </div>
            <div className={styles.FilterSearchButton}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
            <div className={styles.FilterResetButton}>
              <Button color="secondary" variant="contained" onClick={resetForm}>
                reset
              </Button>
            </div>
          </Grid>
          <Paper className={styles.paper}>
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Policy {SUCCESS}
              </Alert>
            ) : null}
            {formState.isUpdate ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Policy {UPDATE}
              </Alert>
            ) : null}

            {formState.isDelete ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Policy {DELETE}
              </Alert>
            ) : null}
            <MaterialTable
              title={title}
              columns={columns}
              data={newData}
              actions={[
                {
                  icon: () => (
                    <img
                      className={styles.EditIcon}
                      src={process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"}
                      alt="edit icon"
                    />
                  ),
                  tooltip: "Edit",
                  onClick: (event: any, rowData: any) => {
                    handleClickOpen(rowData);
                  },
                },
                {
                  icon: () => (
                    <img
                      className={styles.EditIcon}
                      src={process.env.PUBLIC_URL + "/icons/svg-icon/delete.svg"}
                      alt="delete icon"
                    />
                  ),
                  tooltip: "Delete",
                  onClick: (event: any, rowData: any) => {
                    deleteTableRow(rowData);
                  },
                },
              ]}
              // editable={{
              //   onRowDelete: (oldData: any) =>
              //     new Promise((resolve: any) => {
              //       resolve();
              //       deleteTableRow(oldData);
              //     }),
              // }}
              options={{
                thirdSortClick: false,
                actionsColumnIndex: -1,
                paging: true,
                sorting: true,
                search: false,
                filter: true,
                pageSize: 25,
                draggable: false,
                pageSizeOptions: [25, 50, 75, 100],
              }}
              // onOrderChange={(orderedColumnId: any, orderDirection: any) => {
              //   orderFunc(orderedColumnId, orderDirection);
              // }}
            />
          </Paper>
        </Grid>
      ) : (
        <AddEditForm
          handleOk={handleSubmitDialogBox}
          disabled={submitDisabled}
          handleCancel={closeDialogBox}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {formState.isFailed ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {FAILED}
                  {formState.errMessage}
                </Alert>
              ) : null}
            </Grid>
            <Grid item  xs={12} md={6}>
              <Input
                type="text"
                label="Policy"
                value={name}
                onChange={handleNameChange}
                error={isError.name}
                helperText={isError.name}
                required
              >
                Policy
              </Input>
            </Grid>
            <Grid item  xs={12} md={6}>
              <AutoCompleteDropDown
                {...getCategory}
                id="category"
                value={category}
                onChange={(event: any, newValue: any) => {
                  setCategory(newValue);
                  let isErrorCat = newValue === null ? "Required" : "";
                  setIsError((isError: any) => ({
                    ...isError,
                    category: isErrorCat,
                  }));
                  setSubmitDisabled(checkValidation);
                }}
                renderInput={(
                  params:
                    | (JSX.IntrinsicAttributes &
                        import("@material-ui/core").StandardTextFieldProps)
                    | (JSX.IntrinsicAttributes &
                        import("@material-ui/core").FilledTextFieldProps)
                    | (JSX.IntrinsicAttributes &
                        import("@material-ui/core").OutlinedTextFieldProps)
                ) => (
                  <Input
                    {...params}
                    required
                    id="category"
                    label="Category"
                    error={isError.category}
                    helperText={isError.category}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <Input
                type="text"
                label="Descripton"
                value={description}
                onChange={handleDescriptionChange}
                error={isError.description}
                helperText={isError.description}
              >
                Description
              </Input>
            </Grid> */}
          </Grid>
        </AddEditForm>
      )}

      {/* </main> */}
    </React.Fragment>
  );
};

export default Policy;
