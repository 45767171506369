import { gql } from "@apollo/client";

export const CREATE_PIICATEGORY = gql`
  mutation CreatePiiCategory($name: String!) {
    createPiiCategory(
      input: {
        data: { name: $name }
      }
    ) {
      piiCategory {
        id
        name
      }
    }
  }
`;

export const UPDATE_PIICATEGORY = gql`
  mutation UpdatePiiCategory(
    $id: ID!
    $name: String!
  ) {
    updatePiiCategory(
      input: {
        where: { id: $id }
        data: { name: $name }
      }
    ) {
      piiCategory{
        id
        name
      }
    }
  }
`;

export const DELETE_PIICATEGORY = gql`
  mutation DeletePiiCategory($id: ID!) {
    deletePiiCategory(input: { where: { id: $id } }) {
      piiCategory {
        id
        name
      }
    }
  }
`;
