// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomerForm_CustomerForm__2jtC2 {}\n\n.CustomerForm_FormHelperText__y61hv {\n  color: #e53935 !important;\n}\n\n.CustomerForm_customerEmpnum__2WfmM {\n  width: 165px;\n}\n", ""]);
// Exports
exports.locals = {
	"CustomerForm": "CustomerForm_CustomerForm__2jtC2",
	"FormHelperText": "CustomerForm_FormHelperText__y61hv",
	"customerEmpnum": "CustomerForm_customerEmpnum__2WfmM"
};
module.exports = exports;
