import { gql } from "@apollo/client";

export const GET_INDIVIDUAL = gql `
  query getIndividual($where: JSON, $sort: String) {
    individuals(where: $where, sort: $sort) {
      id
      first_name
      last_name
      contact_id {
        id
        email
        name
        phone
        created_at
        contact_type
      }
      partner_id {
        id
        name
        contact_type
      }
    }
  }
`
export const GET_INDIVIDUAL_COUNT = gql `
query individualCount {
  individualsConnection {
    aggregate {
      totalCount
    }
  }
}
`