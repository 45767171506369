import { gql } from '@apollo/client';

// // Custom Resolver
// export const CREATE_COMPLIANCE_VERSION = gql`
//   query($id: Int!) {     # ID of Contact_type = 'Individual' (Logged in user's Contact's Id)
//     createComplianceVersion(id: $id) {
//       message
//     }
//   }
// `;


// Default APIs
export const GET_COMPLIANCE_VERSION = gql`
  query($where: JSON, $sort: String) {
    complianceVersions(where: $where, sort: $sort) {
      created_at
      updated_at
      contact_id {
        id
        created_at
        updated_at
        name
        email
        phone
        contact_type
        user_id {
          id
        }
        # compliance_versions {id}
      }
      compliances {
        id
        created_at
        updated_at
        contact_id {
          id
        }
        address_id {
          id
        }
        allow_download
        compliance_version {
          id
        }
      }
    }
  }
`;

// {
//   "where": {"contact_id": "164"},
//   "sort": "created_at:DESC"
// }