import React, { useState, useEffect } from "react";
import styles from "./Compliance.module.css";
import { GET_COMPLIANCE_VERSION } from "../../../graphql/queries/ComplianceVersion";
import { GET_COMPLIANCE_INFO } from "../../../graphql/queries/Compliance";
import { GET_COMPLIANCEINFO } from "../../../graphql/queries/ComplianceInfo";

import { useLazyQuery } from "@apollo/client";
import Loading from "../../../components/UI/Layout/Loading/Loading";

import Grid from "@material-ui/core/Grid";
import { Select, FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import MainOfficeCompliance from "../MainOfficeCompliance/MainOfficeCompliance";
import OtherOfficeCompliance from "../OtherOfficeCompliance/OtherOfficeCompliance";
import AlertBox from "../../../components/UI/AlertBox/AlertBox";
import * as msgConstant from "../../../common/MessageConstants";
import * as routeConstants from "../../../common/RouteConstants";
import { useApolloClient } from "@apollo/client";
import stepper from "../../../common/stepperMenu.json";
import { setStepper } from "../../../common/SetStepper";
import logout from "../../../containers/Auth/Logout/Logout";
import TopStepper from "../../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import Typography from "@material-ui/core/Typography";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import { useHistory } from "react-router-dom";
import * as routeConstant from "../../../common/RouteConstants";

export const Compliance: React.FC = (props: any) => {
  const client = useApolloClient();
  let stepperObject = stepper;
  const [orgContactId, setOrgContactId] = useState<any>();
  const [showBackDrop, setShowBackdrop] = useState(true);
  const [mainOffice, setMainOffice] = useState<any>("");
  const [otherOffice, setOtherOffice] = useState<any>("");
  const [param, setParam] = useState<any>();
  const [preMainOffice, setPreMainOffice] = useState<any>([]);
  const [preOtherOffice, setPreOtherOffice] = useState<any>([]);
  const [verValue, setVerValue] = useState(0);
  const [version, setVersion] = useState<any>([]);
  const [active, setActive] = useState("main");
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [dialogBoxMsg, setDialogBoxMsg] = useState("");
  const [pathName, setPathName] = useState("");
  const [closeButtonPath, setCloseButtonPath] = useState("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [stepperObj, setStepperObj] = useState<any>({path: ""});
  const history = useHistory();

  const checkAllowDownload = (mainOffice: any, version: any) => {
    if (mainOffice.length > 0 && verValue === 0) {
      if (mainOffice[0].allowDownload === false) {
        setShowDialogBox(true);
        setDialogBoxMsg(msgConstant.COMPLIANCE_NOTIFICATION);
        setPathName(routeConstants.REVIEW);
      }
    }
    if (mainOffice === "" && version.length === 0) {
      setShowDialogBox(true);
      setDialogBoxMsg(msgConstant.NO_COMPLIANCE_NOTIFICATION);
      setPathName(routeConstants.REVIEW);
      setCloseButtonPath(routeConstants.COMPANY_INFO);
    }
  };

  const [
    getComplianceInfo,
    { data: dataOrgUser, error: errorOrgUser, loading: loadingOrgUser },
  ] = useLazyQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      getComplianceVersion({
        variables: {
          where: { contact_id: dataOrgUser.complianceInfos[0].contact_id.id },
          sort: "created_at:DESC",
        },
      });
    },
  });

  const [
    getComplianceVersion,
    { data: compVersionData, loading: compVersionLoading },
  ] = useLazyQuery(GET_COMPLIANCE_VERSION, {
    onCompleted: () => {
      if (compVersionData.complianceVersions.hasOwnProperty(0)) {
        getComplianceInformation(
          compVersionData.complianceVersions[0].compliances
        );
      }
      setVersion(compVersionData.complianceVersions);
      if (compVersionData.complianceVersions.hasOwnProperty(1)) {
        getPreComplianceInfo(compVersionData.complianceVersions[1].compliances);
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    getCompliance,
    { data: compData, loading: compLoading },
  ] = useLazyQuery(GET_COMPLIANCE_INFO, {
    onCompleted: () => {
      setMainOffice(compData.getComplianceInfo.data.mainoffice);
      setOtherOffice(compData.getComplianceInfo.data.otheroffice);
      setShowBackdrop(false)
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    getPrevCompliance,
    { data: prvCompData, loading: prvCompLoading },
  ] = useLazyQuery(GET_COMPLIANCE_INFO, {
    onCompleted: () => {
      setPreMainOffice(prvCompData.getComplianceInfo.data.mainoffice);
      setPreOtherOffice(prvCompData.getComplianceInfo.data.otheroffice);
      setShowBackdrop(false)
    },
    fetchPolicy: "cache-and-network",
  });

  const getComplianceInformation = (data: any) => {
    getCompliance({
      variables: {
        input: data,
      },
    });
  };

  const getPreComplianceInfo = (data: any) => {
    getPrevCompliance({
      variables: {
        input: data,
      },
    });
  };

  useEffect(() => {
    setParam(props.location.state);
    if (
      props.location.state &&
      props.location.state.hasOwnProperty("complianceInfo")
    ) {
      getComplianceInfo({
        variables: {
          where: {
            client_id: props.location.state.clientInfo.clientId,
            contact_id: props.location.state.complianceInfo.contactId,
            id: props.location.state.complianceInfo.id
          },
        },
      });
    }
    setStepper(
      client,
      stepperObject.workplan.name,
      stepperObject.workplan.value
    );
  }, []);

  if (errorOrgUser) {
    let error = { message: "Error" };
    return (
      <div className="error">
        Error!
        {logout()}
      </div>
    );
  }
  // if ( showBackDrop ) return <SimpleBackdrop/>;

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }

  if (compVersionData) {
  }
  if (compData) {
  }
  if (prvCompData) {
  }

  const handleChange = (event: any) => {
    let index = event.target.value;
    setVerValue(index);
    getComplianceInformation(version[index].compliances);

    if (version.hasOwnProperty(index + 1)) {
      getPreComplianceInfo(version[index + 1].compliances);
    }
  };

  const onMenuClick = (value: any) => {
    setActive(value);
  };

  const handleClose = () => {
    setShowDialogBox(false);
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
    navigateTo();
  }

  const navigateTo = () => {
    history.push(routeConstant.COMPLIANCE_LIST, param);
  }

  return (
    <React.Fragment>
      <Typography component="h5" variant="h1">
        {props.location.state
          ? "CyberCompliance for " + props.location.state.clientInfo.name
          : null}
      </Typography>
      {props.location.state ? (props.location.state.from === "ComplianceList" ? null : 
        <TopStepper
          handleSave={callingFromTopStepper}
          obj={stepperObj}
        />
      ) : null}
      { enableBackdrop() ? <SimpleBackdrop /> : null}
      <div className={styles.TabButtons}>
        <div className={styles.ComplainceDiv}>
          {/* <Grid item xs={12} className={styles.ComplianceGrid}>
            Your Compliance Version:
              <FormControl variant="outlined" className={styles.formControl}>
              <Select className={styles.ComplianceVersion}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={verValue}
                onChange={handleChange}
              >
                {version.map((ver: any, index: any) =>
                  <MenuItem
                    key={index}
                    value={index}
                  > Compliance V-{version.length - index}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid> */}
          {/* <div className={styles.TabOptions}>
            <Button className={active === 'main' ? styles.TabOptionsActive : styles.TabOptionsInactive} onClick={() => onMenuClick('main')}>Main Office</Button>
            <Button className={active === 'other' ? styles.TabOptionsActive : styles.TabOptionsInactive} onClick={() => onMenuClick('other')}>Other Office</Button>
          </div> */}
        </div>
        {active === "main" ? (
          <MainOfficeCompliance
            key={mainOffice}
            data={{ new: mainOffice, other: otherOffice, pre: preMainOffice }}
            param={param}
          ></MainOfficeCompliance>
        ) : (
          <OtherOfficeCompliance
            key={otherOffice}
            data={{ new: otherOffice, pre: preOtherOffice }}
            param={param}
          ></OtherOfficeCompliance>
        )}
      </div>
      {/* <AlertBox
        open={showDialogBox}
        dialogBoxMsg={dialogBoxMsg}
        pathName={pathName}
        closeButtonPath={closeButtonPath}
        buttonName="Review and Build Compliance"
        handleClose={handleClose}
      >
      </AlertBox> */}
    </React.Fragment>
  );
};

export default Compliance;
