import React, { useState, useEffect } from "react";
// import styles from "./PartnerUserForm.module.css";
import Grid from "@material-ui/core/Grid";
import { Typography, FormHelperText } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
    FAILED,
    SUCCESS,
    ALERT_MESSAGE_TIMER,
    DELETE,
  } from "../../../common/MessageConstants";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "../../../components/UI/Form/Input/Input";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useHistory } from "react-router-dom";
import { GET_USER_DETAILS } from "../../../graphql/queries/PG";
import { useMutation, FetchResult, useLazyQuery,useQuery } from "@apollo/client";
import { GET_INDIVIDUAL } from "../../../graphql/queries/Individual";
import { PG_DELETE_INDIVIDUAL } from "../../../graphql/mutations/Individual";
import { PG_DELETE_CONTACT } from "../../../graphql/mutations/Contacts";
import { PG_DELETE_USER } from "../../../graphql/mutations/User";
import { DELETE_DATA } from "../../../graphql/mutations/Delete";

export const DeletePartnerUser: React.FC = (props: any) => {

  const history = useHistory();
  const [pgID, setPgID] = useState("");
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [pgDeleteIndividual] = useMutation(PG_DELETE_INDIVIDUAL);
  const [pgDeleteContact] = useMutation(PG_DELETE_CONTACT);
  const [pgDeleteUser] = useMutation(PG_DELETE_USER);
  const [partnerUserDelete] = useMutation(DELETE_DATA);

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "pgID") {
      setPgID(event.target.value);
    }
  };
  
  const [getPgDetails, { data: pg_UserData, loading: pgUserLoading }] = useLazyQuery(
    GET_USER_DETAILS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data : any) => {
        if(data != null && data.individualsConnection.values !== 0 && data.individualsConnection.values[0] ) {
          getIndividual({
            variables: {
              where: { partner_id_null: false, contact_id: pg_UserData.individualsConnection.values[0].contact_id.id },
              sort: "created_at:desc",
            },
          });

          console.log("data",data);
        } else {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: " Entry Not Found",
          }));
        }
      },
      onError: () => {
        // setShowBackdrop(false);
        // history.push(routeConstant.ADMIN_DASHBOARD);
      },
    }
  );

  const [getIndividual, { data: iData, loading: iLoading }] = useLazyQuery(
    GET_INDIVIDUAL,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data : any) => {
        if(data != null ) {
          DeleteIndividual(data.individuals[0].id)
        }
      },
      onError: () => {
        // setShowBackdrop(false);
        // history.push(routeConstant.ADMIN_DASHBOARD);
      },
    }
  );

  const DeleteIndividual = (id : any) => {
    pgDeleteIndividual({
      variables: {
        id: id
      },
    }) .then((res: any) => {
      deleteContact(res.data.deleteIndividual.individual.contact_id.id)
      // setCreateFlag(true);
    })
    .catch ((err) => {
  })

  }
  const deleteContact = (id : any) => {
    pgDeleteContact({
      variables: {
        id: id
      },
    }) .then((res: any) => {
      deleteUser(res.data.deleteContact.contact.user_id.id)
      // setCreateFlag(true);
    })
    .catch ((err) => {
  })
  }

  const deleteUser = (id : any) => {
    pgDeleteUser({
      variables: {
        id: id
      },
    }) .then((res: any) => {
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: true,
        isFailed: false,
        errMessage: " Deleted Successfully ",
      }));
      setPgID("");
      // console.log("res",res.data.deleteContact.contact.user_id.id);
      // deleteUser(res.data.deleteContact.contact.user_id.id)
      // setCreateFlag(true);
    })
    .catch ((err) => {
  })
  }

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  }

  const handleSubmit = () => {
    // getPgDetails({
    //   variables: {
    //     pg_user_id: JSON.parse(pgID)
    //   },
    // });
    // DeletePartnerUser()
  }

  const DeletePartnerUser=()=>{
    partnerUserDelete({
      variables:{
        id: parseInt(pgID),
        type:"PartnerUser"
      }
    }).then((deletePartnerRes:any)=>{
      console.log("deletePartnerRes",deletePartnerRes)
    })
  };
  const backToList = () => {
    history.push("/pg-action");
}

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Typography component="h5" variant="h1">
          <div>Delete Partner User</div>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {FAILED}
                {formState.errMessage}
              </Alert>
            ) : null}
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                User<strong>{formState.errMessage}</strong>
                {SUCCESS}
              </Alert>
            ) : null}
             {formState.isDelete ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                User<strong>{formState.errMessage}</strong>
                {DELETE}
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="PG User ID"
              name="pgID"
              value={pgID}
              onChange={handleChange}
            //   error={isError.pgID}
            //   helperText={isError.pgID}
            >
              PG Id
            </Input>
          </Grid>
          </Grid>
          
        
          <Grid item xs={12} md={6} >
          <Button
            // className={styles.ContinueButton}

            onClick={handleSubmit}
            variant="contained"
            color="primary"
            // type="submit"
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={6}>
        <Button
          variant={"contained"}
          onClick={backToList}
          color="secondary"
          data-testid="cancel-button"
        >
          {"Cancel"}
        </Button>
        </Grid>

       
      </React.Fragment>
    </div>
  );
};
export default DeletePartnerUser;
