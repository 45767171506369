import React, { useEffect, useState } from "react";
import styles from "./Client.module.css";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Button } from "../../components/UI/Form/Button/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Alert from "../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "../../components/UI/Table/MaterialTable";
import { useLazyQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../graphql/queries/Organization";
import DescriptionIcon from "@material-ui/icons/Description";
import * as routeConstant from "../../common/RouteConstants";
import { useHistory } from "react-router-dom";
import logout from "../../containers/Auth/Logout/Logout";
import { GET_INDIVIDUAL } from "../../graphql/queries/Individual";
import { GET_PARTNER_SUBSCRIPTION } from "../../graphql/queries/PartnerSubscription";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { GET_CLIENT_SUBSCRIPTION } from "../../graphql/queries/ClientSubscription";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { setActiveFormStep } from "../../services/Data";
import {
  SUCCESS,
  UPDATE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../common/MessageConstants";
import moment from "moment";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import Cookies from 'js-cookie';
import { GET_CONTACT } from "../../graphql/queries/Contact";
import { GET_COMPLIANCEINFO } from "../../graphql/queries/ComplianceInfo";

export const Client: React.FC = (props: any) => {
  const history = useHistory();
  const [showBackDrop, setShowBackdrop] = useState(true);
  const [newData, setNewData] = useState<any>([]);
  const [subscriptionList, setSubscriptionList] = useState<any>();
  const [selectedApp, setSelectedApp] = useState<any>();
  const user = Cookies.get('cc_user') || ""
  const application = Cookies.get('cc_app') || ""
  const contact = Cookies.get('cc_contact') || ""

  //table
  const column = [
    { title: "Company Name", field: "name" },
    // { title: "Email", field: "email" },
    { title: "Complaince Name", field: "compliance_name" },
    { title: "Last Report Generated on", field: "last_report_created_at" },
    // { title: "Phone", field: "phone" },
    // { title: "Created on", field: "createdon" },
  ];

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [getIndividual, { data: iData, error: iError, loading: iLoading }] = useLazyQuery(
    GET_INDIVIDUAL,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        getPartnerSubs({
          variables: {
            where: { contact_id: iData.individuals[0].partner_id.id },
          },
        });
        getIndividualpartner({
          variables: {
            where: { partner_id: iData.individuals[0].partner_id.id },
          },
        });
      },
      onError: () => {
        history.push(routeConstant.CLIENT)
      }
    }
  );

  const [getIndividualpartner, { data: ipData, loading: ipLoading }] = useLazyQuery(
    GET_INDIVIDUAL,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data.individuals.length !== 0) {
          getOrganization({
            variables: {
              where: { subtype: "Client", partner_id: data.individuals[0].partner_id.id },
            },
          });
        }
        // let contactIdArray = data.individuals.map((val: any) => val.contact_id.id);
        // if (contactIdArray.length > 0) {
        //   getOrganization({
        //     variables: {
        //       where: { subtype: "Client", partner_id: contactIdArray },
        //     },
        //   });
        // }
      },
      onError: () => {
        history.push(routeConstant.CLIENT)
      }
    }
  );

  const [getClientSubs, { data: dataSubs, loading: loadSubs }] = useLazyQuery(
    GET_CLIENT_SUBSCRIPTION,
    {
      onCompleted: (data: any) => {
        let contact_id_array = dataOrg.organizations.map((val: any) => val.contact_id.id);
        getComplianceInfo({
          variables: {
            where: {
              client_id: contact_id_array,
            },
            sort: "updated_at:DESC"
          },
        });        

      },
      fetchPolicy: "cache-and-network",
    },
  );

  const [getPartnerSubs, { data: dataPSubs, loading: loadPSubs }] = useLazyQuery(
    GET_PARTNER_SUBSCRIPTION,
    {
      onCompleted: (data: any) => {
        setSubscriptionList(data.partnerSubscriptions[0]);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    getComplianceInfo,
    { data: dataOrgUser, error: errorOrgUser, loading: loadingOrgUser },
  ] = useLazyQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: any) => {
      setShowBackdrop(false);
    },
  });

  const createTableDataObject = (data: any) => {
    let arr: any = [];
    let contactIdArray : any = []
    data.map((element: any, i: any) => {
      let obj: any = {};
      obj["createdon"] = moment(element.contact_id.created_at).format(
        "MM/DD/YYYY hh:mm a"
      );
      obj["email"] = !element.contact_id.email ? "-" : element.contact_id.email;
      obj["name"] = element.contact_id.name + " " + (element.contact_id.last_name ? element.contact_id.last_name : "");
      obj["first_name"] = element.contact_id.name;
      obj["last_name"] = element.contact_id.last_name;
      obj["phone"] = !element.contact_id.phone ? "-" : element.contact_id.phone;
      obj["clientId"] = element.contact_id.id;
      obj["partnerId"] = element.partner_id.id;
      obj["clientOrgId"] = element.id;
      obj["partnerSubscriptions"] = subscriptionList;

      if (dataSubs) {
        for (let j in dataSubs.clientSubscriptions) {
          if (element.contact_id.id === dataSubs.clientSubscriptions[j].contact_id.id) {
            obj["clientSubsId"] = dataSubs.clientSubscriptions[j].id;
            obj["cc_subs"] = subscriptionList.cc_subscription ? dataSubs.clientSubscriptions[j].cc_subscription : false;
            // obj["ra_subs"] = subscriptionList.ra_subscription ? dataSubs.clientSubscriptions[j].ra_subscription : false;
          }
        }
      }
      if (dataOrgUser) {
        for (let i in dataOrgUser.complianceInfos) {
          if (element.contact_id.id === dataOrgUser.complianceInfos[i].client_id.id) {
            if (!contactIdArray.includes(dataOrgUser.complianceInfos[i].client_id.id)) {
              contactIdArray.push(dataOrgUser.complianceInfos[i].client_id.id)
              obj["compliance_name"] = dataOrgUser.complianceInfos[i].name
              obj["last_report_created_at"] = moment(dataOrgUser.complianceInfos[i].updated_at).format('MM/DD/YYYY hh:mm a');
            }
          }
        }
      }
      if (obj.cc_subs) {
        arr.push(obj);
      }
    });
    setNewData(arr);
  };

  const [getOrganization, { data: dataOrg, loading: loadingOrg }] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: "cache-and-network",
    onError: () => {
      logout();
    },
    onCompleted: () => {
      let contact_id_array = dataOrg.organizations.map((val: any) => val.contact_id.id);
      getClientSubs({
        variables: {
          where: { contact_id_in: contact_id_array },
        },
      });

    },
  });

  useEffect(() => {
    let userdata = JSON.parse(user)
    localStorage.setItem("cc_user", JSON.stringify(userdata));
    getContact({
      variables: {
        id: userdata.user.id,
      },
    });
  }, [user]);

  useEffect(() => {
    if (dataOrg && dataOrgUser) {
      createTableDataObject(dataOrg.organizations)
    }
  }, [dataOrg, dataSubs,dataOrgUser]);

  const [getContact, { data: dataCo, loading: loadingCO }] = useLazyQuery(GET_CONTACT, {
    fetchPolicy: "cache-and-network",
    onCompleted(data: any) {
      if (data.contacts.length !== 0) {
        localStorage.setItem("cc_contact", JSON.stringify(data.contacts[0]));
      }
    },
    onError(err: any) { }
  });

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleClickOpen = (rowData: any) => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
    if (rowData.hasOwnProperty("clientId")) {
      history.push(routeConstant.CLIENT_FORM_EDIT + rowData.clientId, rowData);
    } else {
      history.push(routeConstant.CLIENT_FORM_ADD);
    }
  };

  useEffect(() => {
    setSelectedApp(application)
    if (props.location.state && props.location.state != null && props.location.state.formState) {
      setFormState(props.location.state.formState);
    }
    let contactdata = JSON.parse(contact)
    getIndividual({
      variables: {
        where: { partner_id_null: false, contact_id: contactdata.id },
      },
    });
  }, [])

  if (iError) {
    return (
      <div className="error">
        {logout()}
      </div>
    )
  }

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }

  const onRowClick = async (event: any, rowData: any, oldData: any, val: any) => {
    let data: any = {
      clientInfo: rowData,
    };
    if (val === "CC") {
      history.push(routeConstant.COMPLIANCE_LIST, data);
    }
    if (val === "Edit") {
      handleClickOpen(rowData);
    }
    if(val === "NewCompliance") {
      // let val: any = {
      //   clientInfo: props.location.state.clientInfo,
      // };
      setActiveFormStep(0);
      history.push(routeConstant.COMPANY_INFO, data);
      // setOpenEdit(true);
    }
  };
if(showBackDrop) return <SimpleBackdrop/>

  return (
    <React.Fragment>
      <CssBaseline />

      {selectedApp === "CC360" ?
        <Typography component="h5" variant="h1">
         Clients
        </Typography> : null}
      {/* { enableBackdrop() ? <SimpleBackdrop /> : null} */}
      <Grid>
        {/* <Grid container>
          <Grid item xs={12} sm={9} className={styles.FilterWrap}>
          </Grid>
          <Grid item xs={12} sm={3} className={styles.FilterAddWrap}>
            <div className={styles.FilterInput}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClickOpen}
              >
                <AddCircleIcon className={styles.EditIcon} />
                &nbsp; Client
              </Button>
            </div>
          </Grid>
        </Grid> */}
        <Paper className={styles.paper}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Client<strong>{formState.errMessage}</strong>
              {SUCCESS}
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Client<strong>{formState.errMessage}</strong>
              {UPDATE}
            </Alert>
          ) : null}
          <div className={styles.ScrollTable}>
            {newData.length !== 0 ?
              <MaterialTable
                columns={column}
                data={newData}
                actions={[
                  (rowData: any) =>
                    rowData
                      ? {
                        icon: () =>
                          !rowData.cc_subs ? (
                            <VisibilityIcon style={{ fill: "grey" }} />
                          ) : (
                            <VisibilityIcon />
                          ),
                        tooltip: "Cyber Compliance",
                        disabled: !rowData.cc_subs,
                        hidden: selectedApp === "CC360" ? false : true,
                        onClick: (event: any, rowData: any, oldData: any) => {
                          onRowClick(event, rowData, oldData, "CC");
                        },
                      }
                      : null,
                      {
                        icon: () => <AddCircleIcon />,
                        tooltip: "Create Compliance",
                        onClick: (event: any, rowData: any, oldData: any) => {
                          onRowClick(event, rowData, oldData, "NewCompliance");
                        },
                      },
                ]}
                options={{
                  headerStyle: {
                    backgroundColor: "#EFF6FD",
                    color: "#002F60",
                  },
                  actionsColumnIndex: -1,
                  paging: true,
                  sorting: true,
                  search: false,
                  filter: true,
                  draggable: false,
                  pageSize: 25,
                  pageSizeOptions: [25, 50, 75, 100], // rows selection options
                }}
              />
              : (!enableBackdrop() ?
                (<Typography component="h5" variant="h3">
                  You don't have any client subscribed for CyberCompliance360
                </Typography>)
                : null)
            }
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default Client;
