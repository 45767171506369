import React, { useState, useEffect } from "react";
// import styles from "./PartnerUserForm.module.css";
import Grid from "@material-ui/core/Grid";
import { Typography, FormHelperText } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
    FAILED,
    SUCCESS,
    ALERT_MESSAGE_TIMER,
    DELETE,
  } from "../../../common/MessageConstants";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "../../../components/UI/Form/Input/Input";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useHistory } from "react-router-dom";
import { useMutation, FetchResult, useLazyQuery,useQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../../graphql/queries/Organization";
import { DELETE_DATA } from "../../../graphql/mutations/Delete";

export const DeleteClient: React.FC = (props: any) => {

  const history = useHistory();
  const [pgClientID, setPgClientID] = useState("");
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  let PartnerID: any

  const [getOrganization, { data: Org }] = useLazyQuery(
    GET_ORGANIZATION,
    {
      onCompleted: (data: any) => {
        if (data.organizations[0] !== undefined) {
          PartnerID = data.organizations[0].contact_id.id
        }
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const [clientDelete] = useMutation(DELETE_DATA);
  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "pgClientID") {
      setPgClientID(event.target.value);
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  }

  const handleSubmit = () => {
    // callOrganizationForDelete()
    // DeleteClient()
  }

  const DeleteClient=()=>{
    clientDelete({
      variables:{
        id: parseInt(pgClientID),
        type:"Client"
      }
    }).then((deletePartnerRes:any)=>{
      console.log("deletePartnerRes",deletePartnerRes)
    })
  };
  const backToList = () => {
    history.push("/pg-action");
}
const callOrganizationForDelete = () => {
  getOrganization({
    variables: {
      where: {
        subtype: "Client",
        pg_id: pgClientID
      }
    },
  });
}

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Typography component="h5" variant="h1">
          <div>Delete Client</div>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {FAILED}
                {formState.errMessage}
              </Alert>
            ) : null}
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                User<strong>{formState.errMessage}</strong>
                {SUCCESS}
              </Alert>
            ) : null}
             {formState.isDelete ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                User<strong>{formState.errMessage}</strong>
                {DELETE}
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="PG Client ID"
              name="pgClientID"
              value={pgClientID}
              onChange={handleChange}
            >
              PG Client ID
            </Input>
          </Grid>
          </Grid>
          <Grid item xs={12} md={6} >
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={6}>
        <Button
          variant={"contained"}
          onClick={backToList}
          color="secondary"
          data-testid="cancel-button"
        >
          {"Cancel"}
        </Button>
        </Grid>

       
      </React.Fragment>
    </div>
  );
};
export default DeleteClient;
