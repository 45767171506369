import { gql } from "@apollo/client";

export const SET_COMPLIANCE = gql`
  mutation SetCompliance($input: JSON!) {
    setCompliance(context: $input) {
      address_id {location_type}
      allow_download
    }
  }
`

export const UPDATE_COMPLIANCE = gql`
  mutation updateCompliance(
    $id: ID!
    $contact_id: ID!
    $law: [ID!]
    $rule: [ID!]
    $policy: [ID!]
    $workplan: [ID!]
    $address: ID!
    $allowdownload: Boolean!
    $compliance_version: ID
  ) {
    updateCompliance(
      input: {
        where: { id: $id }
        data: {
          contact_id: $contact_id
          laws: $law
          rules: $rule
          policies: $policy
          workplans: $workplan
          address_id: $address
          allow_download: $allowdownload
          workplan_document: []
          compliance_version: $compliance_version
        }
      }
    ) {
      compliance {
        id
        contact_id {
          id
          email
        }
        allow_download
        created_at
        updated_at
        address_id {
          id
        }
        rules {
          id
        }
        policies {
          id
        }
        workplans {
          id
        }
      }
    }
  }
`