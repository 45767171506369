// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*.layout {\n  width: 80%;\n}*/\n\n.CompanyProfile_paper__3z6Sq {\n  margin-bottom: 20px;\n  padding: 28px 25px 17px 25px;\n  background-color: #FFF;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);\n  border-radius: 20px !important;\n  border: 1px solid #0051A8;\n}\n\n.CompanyProfile_FilterAddWrap__7Hrkr {\n  float: right;\n  margin-top: 20px;\n  text-align: right;\n}\n\n.CompanyProfile_FilterInput__3337F {\n  /* width: 250px; */\n  margin-right: 10px;\n  margin-bottom: 10px;\n  text-align: right;\n}\n\n.CompanyProfile_prevBtn__2Mfz_ {\n  display: inline-block !important;\n}\n\n.CompanyProfile_cancelButton__TVtMa {\n  margin-left: 10px !important;\n  display: inline-block !important;\n}", ""]);
// Exports
exports.locals = {
	"paper": "CompanyProfile_paper__3z6Sq",
	"FilterAddWrap": "CompanyProfile_FilterAddWrap__7Hrkr",
	"FilterInput": "CompanyProfile_FilterInput__3337F",
	"prevBtn": "CompanyProfile_prevBtn__2Mfz_",
	"cancelButton": "CompanyProfile_cancelButton__TVtMa"
};
module.exports = exports;
