import { gql } from "@apollo/client";

export const CREATE_RULE = gql`
mutation CreateRule(
  $name: String!
  $policy_description: String
  $workplan_description: String
  $category: ID!
  $laws: [ID!]
  $policy_content:String
  $workplan_content:String
  $rule_no: String
  $target: ENUM_RULE_TARGET
) {
  createRule(
    input: {
      data: {
        name: $name
        policy_description: $policy_description
        workplan_description: $workplan_description
        category: $category
        laws: $laws
        policy_content:$policy_content
        workplan_content:$workplan_content
        rule_no: $rule_no
        Target: $target
      }
    }
  ) {
    rule {
      id
      name
      policy_description
      workplan_description
      category {
        id
      }
      laws {
        id
        name
      }
      policy_content
      workplan_content
      rule_no
      Target
    }
  }
}
`;

export const UPDATE_RULE = gql`
  mutation UpdateRule(
    $id: ID!
    $name: String!
    $policy_description: String
    $workplan_description: String
    $category: ID!
    $laws: [ID!]
    $policy_content:String
    $workplan_content:String
    $rule_no: String
    $target: ENUM_RULE_TARGET
  ) {
    updateRule(
      input: {
        where: { id: $id }
        data: {
          name: $name
          policy_description: $policy_description
          workplan_description: $workplan_description
          category: $category
          laws: $laws
          policy_content:$policy_content
          workplan_content:$workplan_content
          rule_no: $rule_no
          Target: $target
        }
      }
    ) {
      rule {
        id
        name
        policy_description
        workplan_description
        category {
          id
        }
        laws {
          id
          name
        }
        policy_content
        workplan_content
        rule_no
        Target
      }
    }
  }
`;

export const DELETE_RULE = gql`
  mutation DeleteRule($id: ID!) {
    deleteRule(input: { where: { id: $id } }) {
      rule {
        id
        name
        policy_description
        workplan_description
        category {
          id
        }
        laws {
          id
          name
        }
        policy_content
        workplan_content
        rule_no
        Target
      }
    }
  }
`;
