import React, { useState, useEffect } from "react";
// import styles from "./PartnerUserForm.module.css";
import Grid from "@material-ui/core/Grid";
import { Typography, FormHelperText } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
    FAILED,
    SUCCESS,
    ALERT_MESSAGE_TIMER,
    DELETE,
  } from "../../../common/MessageConstants";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "../../../components/UI/Form/Input/Input";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useHistory } from "react-router-dom";
import { GET_USER_DETAILS } from "../../../graphql/queries/PG";
import { useMutation, FetchResult, useLazyQuery,useQuery } from "@apollo/client";
import { GET_INDIVIDUAL } from "../../../graphql/queries/Individual";
import { PG_DELETE_INDIVIDUAL } from "../../../graphql/mutations/Individual";
import { PG_DELETE_CONTACT } from "../../../graphql/mutations/Contacts";
import { PG_DELETE_USER } from "../../../graphql/mutations/User";
import { GET_ORGANIZATION } from "../../../graphql/queries/Organization";
import DeleteInfo from "../../DeleteInfo/DeleteInfo";
import moment from "moment";
import { GET_PARTNER_SUBSCRIPTION } from "../../../graphql/queries/PartnerSubscription";
import { GET_CLIENT_SUBSCRIPTION } from "../../../graphql/queries/ClientSubscription";
import { GET_COMPLIANCEINFO } from "../../../graphql/queries/ComplianceInfo";
import { GET_COMPLIANCE } from "../../../graphql/queries/Compliance";
import { DELETE_DATA } from "../../../graphql/mutations/Delete";

export const DeletePartner: React.FC = (props: any) => {
  const [element, setElement] = useState<any>();
  const history = useHistory();
  const [pgID, setPgID] = useState("");
  const [newData, setNewData] = useState<any>([]);
  const [clientId, setClientId] = useState<any>("");
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [PartnerId, setPartnerId] = useState("");
  const [pgDeleteIndividual] = useMutation(PG_DELETE_INDIVIDUAL);
  const [pgDeleteContact] = useMutation(PG_DELETE_CONTACT);
  const [pgDeleteUser] = useMutation(PG_DELETE_USER);
  const [partnerDelete] = useMutation(DELETE_DATA);
  const [subscriptionList, setSubscriptionList] = useState<any>();

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  useEffect(()=>{
    getOrganization({
      variables: {
        where: { subtype: "Partner" ,
        pg_id : props.location.state ? props.location.state.pgpartnerId : pgID
        // sort: "created_at:desc"
      }},
    });
    
  },[]);

  useEffect(() => {
    if(pgID !== "")
    getOrganization({
      variables: {
        where: { subtype: "Partner" ,
        pg_id : pgID
      }},
    });
  }, [pgID]);

  const {
    data: compInfo,
    error: errorCI,
    loading: loadingCI,
    refetch: refetchCompInfo,
  } = useQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        client_id: clientId,
      },
    },
    onCompleted: () => {
      // setShowBackdrop(false)
      if (compInfo.complianceInfos.length !== 0) {
        let contact_id_array = compInfo.complianceInfos.map(
          (val: any) => val.contact_id.id
        );
        getcomplianceforClient({
          variables: {
            where: {
              contact_id_in: contact_id_array,
            },
          },
        });
      }
    },
    onError:()=>{
      // setShowBackdrop(false)
      // history.push(routeConstant.CLIENT)
    }
  });


  const [
    getcomplianceforClient,
    { data: clientCompData, error: dataError, loading: clientCompLoading },
  ] = useLazyQuery(GET_COMPLIANCE, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      // setShowBackdrop(false)
    }, 
    onError:()=>{
      // setShowBackdrop(false)
      // history.push(routeConstant.CLIENT)
    }
  });

  const [getOrganizationforClient, { data: ClientOrg}] = useLazyQuery(
    GET_ORGANIZATION,
    { 
      onCompleted: (data: any) => {
        if(data.organizations[0] !== undefined){
          console.log("data.organizations[0]",data.organizations[0])
          let contact_id_array = data.organizations.map((val: any) => val.contact_id.id);
          setClientId(data.organizations[0].contact_id.id)
          // getClientSubs({
          //   variables: {
          //     where: { contact_id_in: contact_id_array },
          //   },
          // });
          DeleteComplianceData()
          createTableDataObject(data.organizations)
        }
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [getOrganization, { data: Org}] = useLazyQuery(
    GET_ORGANIZATION,
    { 
      onCompleted: (data: any) => {
        if(data.organizations[0] !== undefined){
          console.log("data.organizations[0]",data.organizations[0])
          let contact_id_array = data.organizations.map((val: any) => val.contact_id.id);
          setPartnerId(data.organizations[0].contact_id.id)
          // getClientSubs({
          //   variables: {
          //     where: { contact_id_in: contact_id_array },
          //   },
          // });
          createTableDataObject(data.organizations)
        }
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [getClientSubs, { data: dataSubs, loading: loadSubs }] = useLazyQuery(
    GET_CLIENT_SUBSCRIPTION,
    {
      onCompleted: (data: any) => {
        // setShowBackdrop(false);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const createTableDataObject = (data: any) => {
    let arr: any = [];
    data.map((element: any, i: any) => {
      let obj: any = {};
      obj["createdon"] = moment(element.contact_id.created_at).format(
        "MM/DD/YYYY hh:mm a"
      );
      obj["email"] = !element.contact_id.email ? "-" : element.contact_id.email;
      obj["name"] = element.contact_id.name + " " + (element.contact_id.last_name ? element.contact_id.last_name : "");
      obj["first_name"] = element.contact_id.name;
      obj["last_name"] = element.contact_id.last_name;
      obj["phone"] = !element.contact_id.phone ? "-" : element.contact_id.phone;
      obj["clientId"] = element.contact_id.id;
      obj["partnerId"] = element.partner_id ? element.partner_id.id : "";
      obj["clientOrgId"] = element.id;
      obj["partnerSubscriptions"] = subscriptionList;

      if (dataSubs) {
        for (let j in dataSubs.clientSubscriptions) {
          if (element.contact_id.id === dataSubs.clientSubscriptions[j].contact_id.id) {
            obj["clientSubsId"] = dataSubs.clientSubscriptions[j].id;
            obj["cc_subs"] = subscriptionList.cc_subscription ? dataSubs.clientSubscriptions[j].cc_subscription : false;
            obj["ra_subs"] = subscriptionList.ra_subscription ? dataSubs.clientSubscriptions[j].ra_subscription : false;
          }
        }
      }
      arr.push(obj);
    });
    setNewData(arr);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "pgID") {
      setPgID(event.target.value);
    }
  };
  
  const [getPgDetails, { data: pg_UserData, loading: pgUserLoading }] = useLazyQuery(
    GET_USER_DETAILS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data : any) => {
        if(data != null && data.individualsConnection.values !== 0 && data.individualsConnection.values[0] ) {
          getIndividual({
            variables: {
              where: { partner_id_null: false, contact_id: pg_UserData.individualsConnection.values[0].contact_id.id },
              sort: "created_at:desc",
            },
          });

          console.log("data",data);
        } else {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: " Entry Not Found",
          }));
        }
      },
      onError: () => {
        // setShowBackdrop(false);
        // history.push(routeConstant.ADMIN_DASHBOARD);
      },
    }
  );

  const [getIndividual, { data: iData, loading: iLoading }] = useLazyQuery(
    GET_INDIVIDUAL,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data : any) => {
        if(data != null && data.individuals[0] !== undefined) {
          let contactIdArray = data.individuals.map((val: any) => val.contact_id.id);
          getOrganizationforClient({
            variables: {
              where: { subtype: "Client", partner_id: contactIdArray },
            },
          });
          // DeleteIndividual(data.individuals[0].id)
        }
      },
      onError: () => {
        // setShowBackdrop(false);
        // history.push(routeConstant.ADMIN_DASHBOARD);
      },
    }
  );

  const DeleteIndividual = (id : any) => {
  //   pgDeleteIndividual({
  //     variables: {
  //       id: id
  //     },
  //   }) .then((res: any) => {
  //     deleteContact(res.data.deleteIndividual.individual.contact_id.id)
  //     // setCreateFlag(true);
  //   })
  //   .catch ((err) => {
  // })
    console.log("HEllll",id)
  }
  const deleteContact = (id : any) => {
    pgDeleteContact({
      variables: {
        id: id
      },
    }) .then((res: any) => {
      deleteUser(res.data.deleteContact.contact.user_id.id)
      // setCreateFlag(true);
    })
    .catch ((err) => {
  })
  }

  const deleteUser = (id : any) => {
    pgDeleteUser({
      variables: {
        id: id
      },
    }) .then((res: any) => {
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: true,
        isFailed: false,
        errMessage: " Deleted Successfully ",
      }));
      setPgID("");
      // console.log("res",res.data.deleteContact.contact.user_id.id);
      // deleteUser(res.data.deleteContact.contact.user_id.id)
      // setCreateFlag(true);
    })
    .catch ((err) => {
  })
  }

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  }

  const handleSubmit = () => {
    // if(PartnerId!==""){
      // DeletePartner()
  // }
    
  }

  const DeletePartner=()=>{
    partnerDelete({
      variables:{
        id: parseInt(pgID),
        type:"Partner"
      }
    }).then((deletePartnerRes:any)=>{
      console.log("deletePartnerRes",deletePartnerRes)
    })
  };

  const DeleteComplianceData=()=>{
    console.log("DeleteComplianceData")
    // let obj = {
    //   from: "Client",
    //   complianceInfo: newData[0],
    //   isCompleted: false
    // }
    let obj = {
      from: "ComplianceList",
      complianceInfo: {contactId:clientId},
      isCompleted: false
    }
    console.log("obj",obj)
    setElement(
      <DeleteInfo
        obj={obj}
        callDeleteMethod={updateDeleteFlag}
      />
    );
  }

  
  const updateDeleteFlag = (event: any) => {
    // setProcessComplete(!event.isCompleted);
    if (event.isCompleted) {
      console.log("EVENT IS COMPLETED")
      setElement(null)
      // setCreateFlag(true);
      // setShowBackdrop(false)
      // refetchCompInfo()
    }
  }

  const backToList = () => {
    history.push("/pg-action");
  }

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Typography component="h5" variant="h1">
          <div>Delete Partner</div>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {FAILED}
                {formState.errMessage}
              </Alert>
            ) : null}
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                User<strong>{formState.errMessage}</strong>
                {SUCCESS}
              </Alert>
            ) : null}
             {formState.isDelete ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                User<strong>{formState.errMessage}</strong>
                {DELETE}
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="PG Id"
              name="pgID"
              value={pgID}
              onChange={handleChange}
            //   error={isError.pgID}
            //   helperText={isError.pgID}
            >
              PG Id
            </Input>
          </Grid>
          </Grid>
          
        
          <Grid item xs={12} md={6} >
          <Button
            // className={styles.ContinueButton}

            onClick={handleSubmit}
            variant="contained"
            color="primary"
            // type="submit"
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={6}>
        <Button
          variant={"contained"}
          onClick={backToList}
          color="secondary"
          data-testid="cancel-button"
        >
          {"Cancel"}
        </Button>
        </Grid>
        {element ? element : null}
       
      </React.Fragment>
    </div>
  );
};
export default DeletePartner;
