import React, { useState, useEffect } from "react";
import styles from "./Law.module.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
// import { TextField } from "@material-ui/core";
import Input from "../../../components/UI/Form/Input/Input";
import { GET_STATES } from "../../../graphql/queries/States";
import { GET_ORGANIZATIONTYPE } from "../../../graphql/queries/OrganizationType";
import { GET_LAW } from "../../../graphql/queries/Law";
import { GET_APPLICABLE_TO } from "../../../graphql/queries/ApplicableTo";
import { GET_PIIDATA } from "../../../graphql/queries/PiiData";
import { GET_BESTPRACTICE } from "../../../graphql/queries/BestPractice";
import { uploadFiles } from "../../../graphql/mutations/Upload";
import {
  CREATE_LAW,
  UPDATE_LAW,
  DELETE_LAW,
} from "../../../graphql/mutations/Law";
import Loading from "../../../components/UI/Layout/Loading/Loading";
// import Autocomplete from "../../../components/UI/Form/Autocomplete/Autocomplete";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import {
  useQuery,
  useMutation,
  ApolloError,
  useLazyQuery,
} from "@apollo/client";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AutoCompleteDropDown from "../../../components/UI/Form/Autocomplete/Autocomplete";
import * as dropDownConstants from "../../../common/dropDownConstants";
import CancelIcon from "@material-ui/icons/Cancel";
import logout from "../../../containers/Auth/Logout/Logout";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
interface filterMultiselect {
  id: number;
  name: string;
}

export const Law: React.FC = () => {
  //Autocomplete list
  const [getStateList, setStateList] = useState([]);
  const [getCompanyTypeList, setCompanyTypeList] = useState([]);
  const [getAllCompanyList, setAllCompanyList] = useState([]);
  const [getCompanySubTypeList, setCompanySubTypeList] = useState([]);
  const [getLawList, setLawList] = useState([]);
  const [getAppToList, setAppToList] = useState([]);
  const [getPiiDataList, setPiiDataList] = useState<any>([]);
  const [getBestPracticeList, setBestPracticeList] = useState([]);
  const getJurisdictionList = dropDownConstants.getJurisdictionList;
  const [uploadedImages, setUploadedImages] = useState<any>();
  const [uploadDocId, setUploadDocId] = useState<any>();

  //Dialog Box
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);

  //add/edit data
  const [name, setName] = useState<String>("");
  const [jurisdiction, setJurisdiction] = useState<any>();
  const [state, setState] = useState<any>();
  const [companyType, setCompanyType] = useState<any>();
  const [companySubType, setCompanySubType] = useState<any>();
  const [showSubType, setShowSubType] = useState<boolean>(true);
  const [parentLaw, setParentLaw] = useState<any>();
  const [appTo, setAppTo] = useState<any>();
  const [piiData, setPiiData] = useState<any>();
  const [bestPractice, setBestPractice] = useState<any>();
  const [fileName, setFileName] = useState<any>();
  const [url, setUrl] = useState<String>("");
  const [newData, setNewData] = useState();
  const [editDataId, setEditDataId] = useState<Number | null>();
  const [fileChanged, setFileChanged] = useState<boolean>(false);

  const title = "Listing of Laws";

  //validation and error handelling
  const [isError, setIsError] = useState<any>({
    name: "",
    jurisdiction: "",
    parentLaw: "",
    comapnyType: "",
    appTO: "",
    piiData: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [filterName, setFilterName] = useState("");
  const [filterJurisdiction, setFilterJurisdiction] = useState<any | null>();
  const [filterState, setFilterState] = useState<any | null>();
  const [filterPiiData, setFilterPiiData] = useState<any | null>();
  const [filterCompanyType, setFilterCompanyType] = useState<any | null>();
  const [reset, setReset] = useState<boolean>(false);
  const [filters, setFilters] = useState<object>();
  const [orderBy, setOrderBy] = useState<String>();
  const [sortOrder, setSortOrder] = useState<String | null>("asc");
  const [editorState, setEditorState] = useState<any>();

  //queries
  const [uploadFile] = useMutation(uploadFiles);
  const [createLaw] = useMutation(CREATE_LAW);
  const [updateLaw] = useMutation(UPDATE_LAW);
  const [deleteLaw] = useMutation(DELETE_LAW);

  //table
  const columns = [
    {
      title: "Name",
      field: "name",
      defaultSort: orderBy?.includes("name") ? sortOrder : "asc",
    },
    {
      title: "State",
      field: "state",
      defaultSort: orderBy?.includes("state")  ? sortOrder : "",
      // where: { email_contains: "@strapi.io" }
    },
    {
      title: "Jurisdiction",
      field: "jurisdiction",
      defaultSort:orderBy?.includes("jurisdiction") ? sortOrder : "",
      // disableSorting : true
    },
    {
      title: "Company Type",
      field: "company_type",
      sorting: false 
    },
    {
      title: "PII Data",
      field: "pii_data",
      sorting: false 
    },
  ];
  //filter query condition declaration
  const {
    data: dataLaw,
    error: errorLaw,
    loading: loadingLaw,
    refetch: refetchLaw,
  } = useQuery(GET_LAW, {
    variables:
      !orderBy || orderBy === ""
        ? { where: filters, sort: "name:asc" }
        : { where: filters, sort: orderBy },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    // skip: filterUseQueryCondition(),
  });

  //query for category list
  const {
    data: dataState,
    error: errorState,
    loading: loadingState,
    refetch: refetchState,
  } = useQuery(GET_STATES);

  //query for company type
  const {
    data: dataCompanyType,
    error: errorCompanyType,
    loading: loadingCompanyType,
    refetch: refetchComapnyType,
  } = useQuery(GET_ORGANIZATIONTYPE);

  //query for applicableTo list
  const {
    data: dataAppTo,
    error: errorAppTo,
    loading: loadingAppTo,
    refetch: refetchAppTo,
  } = useQuery(GET_APPLICABLE_TO);

  //query for pii data list
  const {
    data: dataPiiData,
    error: errorPiiData,
    loading: loadingPiiData,
    refetch: refetchPiiData,
  } = useQuery(GET_PIIDATA);

  //query for best practices list
  const {
    data: dataBestPractice,
    error: errorBestPractice,
    loading: loadingBestPractice,
    refetch: refetchBestPractice,
  } = useQuery(GET_BESTPRACTICE);

  const checkValidation = () => {
    if (showDialogBox === true) {
      if (
        isError.name !== "" ||
        isError.jurisdiction !== "" ||
        isError.comapnyType !== "" ||
        isError.appTO !== "" ||
        isError.piiData !== "" ||
        !name ||
        jurisdiction === null ||
        appTo.length === 0 ||
        piiData.length === 0 ||
        companyType.length === 0
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (dataLaw) {
      let temp: any = {};
      temp = convertTableData(dataLaw.laws);
      setNewData(temp);
    }
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [dataLaw, filters, formState]);

  useEffect(() => {
    setSubmitDisabled(checkValidation);
  }, [
    name,
    jurisdiction,
    companyType,
    parentLaw,
    appTo,
    piiData,
    submitDisabled,
  ]);

  if (
    loadingState ||
    loadingCompanyType ||
    loadingAppTo ||
    loadingPiiData ||
    loadingBestPractice ||
    loadingLaw
  )
    return <Loading />;
  if (errorState) {
    return <div className="error">State Error!</div>;
  }
  if (getStateList.length === 0) {
    setStateList(dataState.states);
  }

  if (errorCompanyType) {
    return <div className="error">Company type Error!</div>;
  }
  if (getCompanyTypeList.length === 0 && dataCompanyType) {
    let dataObj = dataCompanyType.organizationTypes;
    let companyTypeList: any = [];
    for (let i in dataObj) {
      if (dataObj[i].sub_type === null) {
        companyTypeList.push(dataObj[i]);
      }
    }
    setCompanyTypeList(companyTypeList);
  }

  // if (getCompanySubTypeList.length === 0 && dataCompanyType) {
  //   let dataObj = dataCompanyType.organizationTypes;
  //   let companySubTypeList: any = [];
  //   for (let i in dataObj) {
  //     if (dataObj[i].sub_type !== null) {
  //       companySubTypeList.push(dataObj[i]);
  //     }
  //   }
  //   setCompanySubTypeList(companySubTypeList);
  // }

  if (errorAppTo) {
    return <div className="error">App To Error!</div>;
  }
  if (getAppToList.length === 0) {
    setAppToList(dataAppTo.applicableTos);
  }

  if (errorPiiData) {
    return <div className="error">Pii Data Error!</div>;
  }
  if (getPiiDataList.length === 0) {
    setPiiDataList(dataPiiData.piiData);
  }

  if (errorBestPractice) {
    return <div className="error">Best Parctice Error!</div>;
  }
  if (getBestPracticeList.length === 0) {
    if (dataBestPractice.bestPractices.length > 0) {
      setBestPracticeList(dataBestPractice.bestPractices);
    }
  }

  if (getAllCompanyList.length === 0) {
    setAllCompanyList(dataCompanyType.organizationTypes);
  }

  if (errorLaw) {
    {
      logout();
    }
    return <div className="error">Law Error!</div>;
  }

  function convertTableData(data: any) {
    let arr: any = [];
    for (let i in data) {
      let tempArr: any = {};
      let compTypeArr: any = [];
      let piiDataArr: any = [];
      let appToArr: any = [];
      let bestPracticesArr: any = [];
      tempArr["id"] = data[i].id;
      tempArr["name"] = data[i].name;
      tempArr["jurisdiction"] = data[i].jurisdiction
        ? data[i].jurisdiction
        : null;
      tempArr["state"] = data[i].state ? data[i].state.name : null;
      tempArr["url"] = data[i].url;
      tempArr["category"] = data[i].category ? data[i].category.name : null;
      tempArr["company_type"] = [];
      for (let j in data[i].company_type) {
        compTypeArr.push(data[i].company_type[j].name);
      }
      tempArr["company_type"] = compTypeArr.join(", ");
      tempArr["parent_law"] = data[i].parent_law
        ? data[i].parent_law.name
        : null;
      tempArr["pii_data"] = [];
      for (let j in data[i].pii_data) {
        piiDataArr.push(data[i].pii_data[j].name);
      }
      tempArr["pii_data"] = piiDataArr.join(", ");
      tempArr["applicable_to"] = [];
      for (let j in data[i].applicable_to) {
        appToArr.push(data[i].applicable_to[j].type);
      }
      tempArr["applicable_to"] = appToArr.join(", ");
      if (data[i].best_practices) {
        tempArr["best_practices"] = [];
        for (let j in data[i].best_practices) {
          bestPracticesArr.push(data[i].best_practices[j].Name);
        }
        tempArr["best_practices"] = bestPracticesArr.join(", ");
      }
      tempArr["law_document"] = data[i].law_document[0];
      arr.push(tempArr);
    }
    return arr;
  }

  const getState = {
    options: getStateList,
    getOptionLabel: (option: { name: String }) => option.name,
  };
  const getJurisdiction = {
    options: getJurisdictionList,
    getOptionLabel: (option: { label: String }) => option.label,
  };
  const getCompanyType = {
    options: getCompanyTypeList,
    getOptionLabel: (option: { name: String }) => option.name,
  };
  const getCompanySubType = {
    options: getCompanySubTypeList,
    getOptionLabel: (option: { name: String }) => option.name,
  };
  const getLaw = {
    options: getLawList,
    getOptionLabel: (option: { name: String }) => option.name,
  };
  const getAppTo = {
    options: getAppToList,
    getOptionLabel: (option: { type: String }) => option.type,
  };
  const getPiiData = {
    options: getPiiDataList,
    getOptionLabel: (option: { name: String }) => option.name,
  };
  const getBestPractice = {
    options: getBestPracticeList,
    getOptionLabel: (option: { Name: String }) => option.Name,
  };
  const getAllCompany = {
    options: getAllCompanyList,
    getOptionLabel: (option: { name: String }) => option.name,
  };

  const nameFilter = (event: any) => {
    setFilterName(event.target.value);
  };

  const jurisdictionFilter = (event: any, newValue: any) => {
    setReset(false);
    setFilterJurisdiction(newValue);
  };

  const stateFilter = (event: any, newValue: any) => {
    setReset(false);
    setFilterState(newValue);
  };
  const piiDataFilter = (event: any, newValue: any) => {
    setReset(false);
    let piiDataArr = [];
    for (let i in newValue) {
      piiDataArr.push(newValue[i]);
    }
    setFilterPiiData(piiDataArr);
  };
  const companyTypeFilter = (event: any, newValue: any) => {
    setReset(false);
    let companyTypeArr = [];
    for (let i in newValue) {
      companyTypeArr.push(newValue[i]);
    }
    setFilterCompanyType(companyTypeArr);
  };

  const handleClickOpen = (rowData: any) => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
    setIsError((isError: any) => ({
      ...isError,
      name: "",
      jurisdiction: "",
      companyType: "",
      appTo: "",
      piiData: "",
    }));
    setName("");
    setJurisdiction({});
    setState({});
    setCompanyType([]);
    setCompanySubType([]);
    setParentLaw([]);
    setAppTo([]);
    setPiiData([]);
    setBestPractice([]);
    setUrl("");
    setFileName("");
    setShowSubType(false);
    setShowDialogBox(true);
    if (rowData.id) {
      setEditDataId(rowData.id);
      //for pre-filled jurisdiction
      let jurisdictionObj = getJurisdictionList;
      if (rowData.jurisdiction) {
        for (let i in jurisdictionObj) {
          if (jurisdictionObj[i].label === rowData.jurisdiction) {
            setJurisdiction(jurisdictionObj[i]);
          }
        }
      }

      //for pre-filled state
      let obj = dataState.states;
      if (rowData.state) {
        for (let i in obj) {
          if (Object.values(obj[i]).includes(rowData.state)) {
            setState(obj[i]);
          }
        }
      }
      //for pre-filled company-type
      let compObj = dataCompanyType.organizationTypes;
      let rowComp = rowData.company_type.split(", ");
      let companyTypeArr = [];
      if (rowComp) {
        for (let i in rowComp) {
          for (let j in compObj) {
            if (
              Object.values(compObj[j]).includes(rowComp[i]) &&
              compObj[j].sub_type === null
            ) {
              companyTypeArr.push(compObj[j]);
            }
          }
        }
        setCompanyType(companyTypeArr);
        let subTypeArrList: any = [];
        if (companyTypeArr) {
          for (let k in companyTypeArr) {
            for (let l in compObj) {
              if (
                compObj[l].sub_type &&
                compObj[l].sub_type.name === companyTypeArr[k].name
              ) {
                setShowSubType(true);
              }
              if (
                compObj[l].sub_type !== null &&
                companyTypeArr[k].name === compObj[l].sub_type.name
              ) {
                subTypeArrList.push(compObj[l]);
              }
            }
          }
          setCompanySubTypeList(subTypeArrList);
        }
      }
      //for pre-filled company-sub-type
      // let compSubObj = dataCompanyType.organizationTypes;
      // let rowCompSubType = rowData.company_type.split(", ");
      // if (rowCompSubType) {
      //   let companySubTypeArr = [];
      //   for (let i in rowCompSubType) {
      //     for (let j in compSubObj) {
      //       if (
      //         Object.values(compSubObj[j]).includes(rowCompSubType[i]) &&
      //         compSubObj[j].sub_type !== null
      //       ) {
      //         companySubTypeArr.push(compSubObj[j]);
      //       }
      //     }
      //   }
      //   setCompanySubType(companySubTypeArr);
      // }

      //for pre-filled applicable to
      let appToObj = dataAppTo.applicableTos;
      let rowAppTo = rowData.applicable_to.split(", ");
      if (rowAppTo) {
        let appToArr = [];
        for (let i in rowAppTo) {
          for (let j in appToObj) {
            if (Object.values(appToObj[j]).includes(rowAppTo[i])) {
              appToArr.push(appToObj[j]);
            }
          }
        }
        setAppTo(appToArr);
      }

      //for pre-filled pii data
      let piiDataObj = dataPiiData.piiData;
      let rowPiiData = rowData.pii_data.split(", ");
      if (rowPiiData) {
        let piiDataArr = [];
        for (let i in rowPiiData) {
          for (let j in piiDataObj) {
            if (Object.values(piiDataObj[j]).includes(rowPiiData[i])) {
              piiDataArr.push(piiDataObj[j]);
            }
          }
        }
        setPiiData(piiDataArr);
      }

      //for pre-filled best practices
      let bestPracticeObj = dataBestPractice.bestPractices;
      let rowBestPractice = rowData.best_practices.split(", ");
      if (bestPracticeObj && rowBestPractice) {
        let bestPracticeArr = [];
        for (let i in rowBestPractice) {
          for (let j in bestPracticeObj) {
            if (
              Object.values(bestPracticeObj[j]).includes(rowBestPractice[i])
            ) {
              bestPracticeArr.push(bestPracticeObj[j]);
            }
          }
        }
        setBestPractice(bestPracticeArr);
      }
      setFileName(rowData.law_document);
      setName(rowData.name);
      setUrl(rowData.url);
    }
  };

  const deleteTableRow = (rowData: any) => {
    deleteLaw({
      variables: {
        id: rowData.id,
      },
    })
      .then((userRes) => {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: true,
          isFailed: false,
          errMessage: "",
        }));
        refetchLaw();
      })
      .catch((err) => {
        console.log("error", err);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: err.message,
        }));
      });
  };

  const handleSearch = () => {
    let searchData: any = {};
    if (filterName) {
      searchData["name_contains"] = filterName.toString();
    }
    if (
      filterJurisdiction !== undefined &&
      filterJurisdiction !== null &&
      Object.keys(filterJurisdiction).length !== 0
    ) {
      // console.log("filterCategory issue");
      searchData["jurisdiction"] = filterJurisdiction.label;
    }
    if (
      filterState !== undefined &&
      filterState !== null &&
      Object.keys(filterState).length !== 0 &&
      filterState !== null
    ) {
      // console.log("filterCategory issue");
      searchData["state"] = filterState.id;
    }

    if (
      filterPiiData !== undefined &&
      filterPiiData !== null &&
      filterPiiData.length > 0
    ) {
      let piiDataArr = [];
      for (let i in filterPiiData) {
        piiDataArr.push(filterPiiData[i].id);
      }
      searchData["pii_data_in"] = piiDataArr;
    }

    if (
      filterCompanyType !== undefined &&
      filterCompanyType !== null &&
      filterCompanyType.length > 0
    ) {
      let companyTypeArr = [];
      for (let i in filterCompanyType) {
        companyTypeArr.push(filterCompanyType[i].id);
      }
      searchData["company_type_in"] = companyTypeArr;
    }

    console.log("searchData", searchData);
    setFilters(searchData);
  };

  const resetForm = () => {
    setFilters({});
    setReset(true);
    setFilterName("");
    setFilterJurisdiction({});
    setFilterState({});
    setFilterPiiData([]);
    setFilterCompanyType([]);
    setOrderBy("name");
    refetchLaw();
  };

  //code for
  const onChangeHandler = (event: any) => {
    setFileChanged(true);
    // console.log("files onChange handler", event.target.files[0]);
    setFileName(event.target.files[0]);
  };

  // const handleFileUpload = () => {
  //   var formData = new FormData();                   // Create an object of formData
  //   formData.append('file', fileName);
  //   console.log("formData >", formData.get('files'));
  //   uploadFile({
  //     variables: {
  //       refId: '12345',
  //       ref: 'law',
  //       field: 'cover',
  //       source: 'base64',
  //       file: formData
  //     }
  //   })
  //     .then((userRes) => {
  //       // handleSubmitDialogBox();
  //       console.log("res", userRes);
  //     })
  //     .catch((err) => {
  //       console.log("error -> ", err.message);
  //     });
  // };

  const handleSubmitDialogBox = () => {
    let docID: any = null;
    let docUpdateID: any = null;

    // console.log("---handlesubmit >");
    // let piiDataArr = [];
    // let appToArr = [];
    // let compArr = [];
    // let compSubArr = [];
    // let companyTypeArr = [];
    // let bestPractices = [];
    // for (let i in piiData) {
    //   piiDataArr.push(piiData[i].id);
    // }
    // for (let i in appTo) {
    //   appToArr.push(appTo[i].id);
    // }
    // for (let i in companyType) {
    //   compArr.push(companyType[i].id);
    // }
    // for (let i in companySubType) {
    //   compSubArr.push(companySubType[i].id);
    // }
    // for (let i in bestPractice) {
    //   bestPractices.push(bestPractice[i].id);
    // }

    // companyTypeArr = [...compArr, ...compSubArr];
    if (editDataId) {
      console.log("FILECHANGED", fileChanged);
      //Added Files
      // if(fileChanged && fileName) {
      //   uploadFile({
      //     variables: {
      //       files: fileName,
      //     },
      //   })
      //   .then((userRes) => {
      //     docUpdateID = userRes.data.upload.id;
      //     let piiDataArr = [];
      //     let appToArr = [];
      //     let compArr = [];
      //     let compSubArr = [];
      //     let companyTypeArr = [];
      //     let bestPractices = [];
      //     for (let i in piiData) {
      //       piiDataArr.push(piiData[i].id);
      //     }
      //     for (let i in appTo) {
      //       appToArr.push(appTo[i].id);
      //     }
      //     for (let i in companyType) {
      //       compArr.push(companyType[i].id);
      //     }
      //     for (let i in companySubType) {
      //       compSubArr.push(companySubType[i].id);
      //     }
      //     for (let i in bestPractice) {
      //       bestPractices.push(bestPractice[i].id);
      //     }
      //     companyTypeArr = [...compArr, ...compSubArr];
      //     updateLaw({
      //       variables: {
      //         id: editDataId,
      //         name: name,
      //         jurisdiction: jurisdiction.id,
      //         state: state ? state.id : null,
      //         url: url,
      //         pii_data: piiDataArr,
      //         applicable_to: appToArr,
      //         company_type: companyTypeArr,
      //         best_practices: bestPractices,
      //         law_document: docUpdateID,

      //       },
      //     })
      //       .then((userRes) => {
      //         setFormState((formState) => ({
      //           ...formState,
      //           isSuccess: false,
      //           isUpdate: true,
      //           isDelete: false,
      //           isFailed: false,
      //           errMessage: "",
      //         }));
      //         setShowDialogBox(false);
      //         setEditDataId(null);
      //         refetchLaw();
      //       })
      //       .catch((err) => {
      //         console.log("error", err.message);
      //         let error = err.message;
      //         if (
      //           error.includes("duplicate key value violates unique constraint")
      //         ) {
      //           error = " Name already present.";
      //         } else {
      //           error = err.message;
      //         }
      //         setFormState((formState) => ({
      //           ...formState,
      //           isSuccess: false,
      //           isUpdate: false,
      //           isDelete: false,
      //           isFailed: true,
      //           errMessage: error,
      //         }));
      //         setEditDataId(null);
      //       });

      //   })
      //   .catch((err) => {
      //     let error = "";
      //     console.log("ERROR", err);
      //     {
      //       error = "Upload Failed";
      //     }
      //     setFormState((formState) => ({
      //       ...formState,
      //       isSuccess: false,
      //       isUpdate: false,
      //       isDelete: false,
      //       isFailed: true,
      //       errMessage: error,
      //     }));
      //   });
      // } else {
      let piiDataArr = [];
      let appToArr = [];
      let compArr = [];
      let compSubArr = [];
      let companyTypeArr = [];
      let bestPractices = [];
      for (let i in piiData) {
        piiDataArr.push(piiData[i].id);
      }
      for (let i in appTo) {
        appToArr.push(appTo[i].id);
      }
      for (let i in companyType) {
        compArr.push(companyType[i].id);
      }
      for (let i in companySubType) {
        compSubArr.push(companySubType[i].id);
      }
      for (let i in bestPractice) {
        bestPractices.push(bestPractice[i].id);
      }
      companyTypeArr = [...compArr, ...compSubArr];
      updateLaw({
        variables: {
          id: editDataId,
          name: name,
          jurisdiction: jurisdiction.id,
          state: state ? state.id : null,
          url: url,
          pii_data: piiDataArr,
          applicable_to: appToArr,
          company_type: companyTypeArr,
          best_practices: bestPractices,
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: true,
            isDelete: false,
            isFailed: false,
            errMessage: "",
          }));
          setShowDialogBox(false);
          setEditDataId(null);
          setFileChanged(false);
          refetchLaw();
        })
        .catch((err) => {
          console.log("error", err.message);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
          setEditDataId(null);
        });
      // }
    } else {
      uploadFile({
        variables: {
          files: fileName,
        },
      })
        .then((userRes) => {
          let piiDataArr = [];
          let appToArr = [];
          let compArr = [];
          let compSubArr = [];
          let companyTypeArr = [];
          let bestPractices = [];
          for (let i in piiData) {
            piiDataArr.push(piiData[i].id);
          }
          for (let i in appTo) {
            appToArr.push(appTo[i].id);
          }
          for (let i in companyType) {
            compArr.push(companyType[i].id);
          }
          for (let i in companySubType) {
            compSubArr.push(companySubType[i].id);
          }
          for (let i in bestPractice) {
            bestPractices.push(bestPractice[i].id);
          }
          companyTypeArr = [...compArr, ...compSubArr];
          docID = userRes.data.upload.id;
          createLaw({
            variables: {
              name: name,
              jurisdiction: jurisdiction.id,
              state: state ? state.id : null,
              url: url,
              pii_data: piiDataArr,
              applicable_to: appToArr,
              company_type: companyTypeArr,
              best_practices: bestPractices.length > 0 ? bestPractices : null,
              law_document: docID,
            },
          })
            .then((userRes) => {
              setFormState((formState) => ({
                ...formState,
                isSuccess: true,
                isUpdate: false,
                isDelete: false,
                isFailed: false,
                errMessage: "",
              }));
              setShowDialogBox(false);
              refetchLaw();
            })
            .catch((err) => {
              console.log("error", err);
              let error = err.message;
              if (
                error.includes("duplicate key value violates unique constraint")
              ) {
                error = " Name already present.";
              } else {
                error = err.message;
              }
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: error,
              }));
            });
        })
        .catch((err) => {
          let error = "";
          console.log("ERROR", err);
          {
            error = "Upload Failed";
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
        });
    }
  };

  // const orderFunc = (orderedColumnId: any, orderDirection: any) => {
  //   let orderByColumn;
  //   let orderBy = "";
  //   if (orderedColumnId >= 0) {
  //     if (columns[orderedColumnId]["field"] === "name") {
  //       orderByColumn = "name";
  //     }
  //     if (columns[orderedColumnId]["field"] === "state") {
  //       orderByColumn = "state";
  //     }

  //     if (columns[orderedColumnId]["field"] === "applicable_to") {
  //       orderByColumn = "applicable_to";
  //     }
  //   }
  //   if(orderByColumn != null) {
  //   orderBy = orderByColumn + ":" + orderDirection;
  //   setSortOrder(orderDirection);
  //   setOrderBy(orderBy);
  //   }
  // };

  const closeDialogBox = () => {
    setFileChanged(false);
    setShowDialogBox(false);
    setEditDataId(null);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    let value = event.target.value;
    let isErrName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      name: isErrName,
    }));
    setSubmitDisabled(checkValidation);
  };
  // const handleJurisdictionChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setJurisdiction(event.target.value);
  //   let value = event.target.value;
  //   let isErrJurisdiction = value.length <= 0 ? "Required" : "";
  //   setIsError((isError: any) => ({
  //     ...isError,
  //     jurisdiction: isErrJurisdiction,
  //   }));
  //   setSubmitDisabled(checkValidation);
  // };
  const handleCompanyTypeChange = (event: any, newValue: any) => {
    setShowSubType(false);
    setCompanySubTypeList([]);
    setCompanySubType([]);
    let compArr = [];
    for (let i in newValue) {
      compArr.push(newValue[i]);
    }
    setCompanyType(compArr);
    let isErrorComp = newValue.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      companyType: isErrorComp,
    }));
    setSubmitDisabled(checkValidation);

    // if (compArr.length > 0) {
    //   let dataObj = dataCompanyType.organizationTypes;
    //   let subTypeArr: any = [];
    //   for (let i in compArr) {
    //     for (let j in dataObj) {
    //       if (
    //         dataObj[j].sub_type &&
    //         dataObj[j].sub_type.name === compArr[i].name
    //       ) {
    //         setShowSubType(true);
    //       }
    //       if (
    //         dataObj[j].sub_type !== null &&
    //         dataObj[j].sub_type.name === compArr[i].name
    //       ) {
    //         subTypeArr.push(dataObj[j]);
    //       }
    //     }
    //   }
    //   setCompanySubTypeList(subTypeArr);
    // }
  };

  // const handleCompanySubTypeChange = (event: any, newValue: any) => {
  //   let compSubTypeArr = [];
  //   for (let i in newValue) {
  //     compSubTypeArr.push(newValue[i]);
  //   }
  //   setCompanySubType(compSubTypeArr);
  // };

  const handleAppToChange = (event: any, newValue: any) => {
    let appToArr = [];
    for (let i in newValue) {
      appToArr.push(newValue[i]);
    }
    setAppTo(appToArr);
    let isErrorAppTo = newValue.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      appTo: isErrorAppTo,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handlePiiDataChange = (event: any, newValue: any) => {
    let piiDataArr = [];
    for (let i in newValue) {
      piiDataArr.push(newValue[i]);
    }
    setPiiData(piiDataArr);
    let isErrorPiiData = newValue.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      piiData: isErrorPiiData,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handleBestPracticeChange = (event: any, newValue: any) => {
    let bestPracticeArr = [];
    for (let i in newValue) {
      bestPracticeArr.push(newValue[i]);
    }
    setBestPractice(bestPracticeArr);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const removeUploadFile = () => {
    setFileChanged(true);
    setFileName("");
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const uploadImageCallBack = (file: any) => {
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };
    let image = [];
    image.push(imageObject);
    setUploadedImages({ uploadedImages: image });

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Law
      </Typography>
      {!showDialogBox ? (
        <Grid>
          <div className={styles.FilterAddWrap}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
            >
              <AddCircleIcon className={styles.EditIcon}/>
              &nbsp; Law
            </Button>
          </div>
          <Grid className={styles.FilterWrap}>
            <div className={styles.FilterInput}>
              <Input
                label="Name"
                name="filterName"
                id="combo-box-demo"
                value={filterName}
                onChange={nameFilter}
              />
            </div>
            <div className={styles.FilterInput}>
              <AutoCompleteDropDown
                {...getJurisdiction}
                id="jurisdictionFilter"
                value={
                  reset ? null : filterJurisdiction ? filterJurisdiction : null
                }
                onChange={jurisdictionFilter}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    id="jurisdictionFilter"
                    label="Jurisdiction"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className={styles.FilterInput}>
              <AutoCompleteDropDown
                {...getState}
                id="stateFilter"
                value={reset ? null : filterState ? filterState : null}
                onChange={stateFilter}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    id="stateFilter"
                    label="State"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className={styles.FilterInput}>
              <AutoCompleteDropDown
                {...getAllCompany}
                id="companyTypeFilter"
                value={reset ? [] : filterCompanyType}
                multiple
                onChange={companyTypeFilter}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    id="companyTypeFilter"
                    label="Company Type"
                  />
                )}
              />
            </div>
            <div className={styles.FilterInput}>
              <AutoCompleteDropDown
                {...getPiiData}
                id="piiDataFilter"
                value={reset ? [] : filterPiiData}
                multiple
                onChange={piiDataFilter}
                renderInput={(params: any) => (
                  <Input {...params} id="piiDataFilter" label="PII Data" />
                )}
              />
            </div>
            <div className={styles.FilterSearchButton}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
            <div className={styles.FilterResetButton}>
              <Button color="secondary" variant="contained" onClick={resetForm}>
                reset
              </Button>
            </div>
          </Grid>
          <Paper className={styles.paper}>
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Law {SUCCESS}
              </Alert>
            ) : null}
            {formState.isUpdate ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Law {UPDATE}
              </Alert>
            ) : null}

            {formState.isDelete ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Law {DELETE}
              </Alert>
            ) : null}
            <MaterialTable
              title={title}
              columns={columns}
              data={newData}
              actions={[
                {
                  icon: () => (
                    <img
                      className={styles.EditIcon}
                      src={process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"}
                      alt="edit icon"
                    />
                  ),
                  tooltip: "Edit",
                  onClick: (event: any, rowData: any) => {
                    handleClickOpen(rowData);
                  },
                },
                {
                  icon: () => (
                    <img
                      className={styles.EditIcon}
                      src={process.env.PUBLIC_URL + "/icons/svg-icon/delete.svg"}
                      alt="delete icon"
                    />
                  ),
                  tooltip: "Delete",
                  onClick: (event: any, rowData: any) => {
                    deleteTableRow(rowData);
                  },
                },
              ]}
              // editable={{
              //   onRowDelete: (oldData: any) =>
              //     new Promise((resolve: any) => {
              //       resolve();
              //       deleteTableRow(oldData);
              //     }),
              // }}
              options={{
                thirdSortClick: false,
                actionsColumnIndex: -1,
                paging: true,
                sorting: true,
                search: false,
                filter: true,
                pageSize: 25,
                pageSizeOptions: [25, 50, 75, 100],
                draggable: false,
              }}
              // onOrderChange={(orderedColumnId: any, orderDirection: any) => {
              //   orderFunc(orderedColumnId, orderDirection);
              // }}
            />
          </Paper>
        </Grid>
      ) : (
        <AddEditForm
          handleOk={handleSubmitDialogBox}
          // handleOk={handleFileUpload}
          disabled={submitDisabled}
          handleCancel={closeDialogBox}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {formState.isFailed ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {FAILED}
                  {formState.errMessage}
                </Alert>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Input
                type="text"
                label="Law"
                required
                value={name}
                onChange={handleNameChange}
                error={isError.name}
                helperText={isError.name}
              >
                Law
              </Input>
            </Grid>
            <Grid item xs={6}>
              <AutoCompleteDropDown
                {...getJurisdiction}
                id="jurisdiction"
                value={jurisdiction}
                onChange={(event: any, newValue: any) => {
                  setJurisdiction(newValue);
                  let value = newValue;
                  let isErrJurisdiction = value === null ? "Required" : "";
                  setIsError((isError: any) => ({
                    ...isError,
                    jurisdiction: isErrJurisdiction,
                  }));
                  setSubmitDisabled(checkValidation);
                }}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    required
                    id="jurisdiction"
                    label="Jurisdiction"
                    error={isError.jurisdiction}
                    helperText={isError.jurisdiction}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <AutoCompleteDropDown
                {...getState}
                id="state"
                value={state}
                onChange={(event: any, newValue: any) => {
                  setState(newValue);
                }}
                renderInput={(params: any) => (
                  <Input {...params} id="state" label="State" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <AutoCompleteDropDown
                {...getCompanyType}
                limitTags={2}
                id="companyType"
                name="companyType"
                value={companyType}
                multiple
                onChange={handleCompanyTypeChange}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    required
                    id="companyType"
                    label="Company Type"
                    error={isError.companyType}
                    helperText={isError.companyType}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={6}>
              {showSubType ? (
                <AutoCompleteDropDown
                  {...getCompanySubType}
                  id="companySubType"
                  name="companySubType"
                  value={companySubType}
                  multiple
                  onChange={handleCompanySubTypeChange}
                  renderInput={(params: any) => (
                    <Input
                      {...params}
                      required
                      id="companySubType"
                      label="Company Sub Type"
                    />
                  )}
                />
              ) : null}
            </Grid> */}
            <Grid item xs={6}>
              <AutoCompleteDropDown
                {...getAppTo}
                limitTags={2}
                id="applicableTo"
                name="applicableTo"
                value={appTo}
                multiple
                onChange={handleAppToChange}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    required
                    id="applicableTo"
                    label="Applicable To"
                    error={isError.appTo}
                    helperText={isError.appTo}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <AutoCompleteDropDown
                {...getPiiData}
                limitTags={2}
                id="piiData"
                name="piiData"
                value={piiData}
                multiple
                onChange={handlePiiDataChange}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    required
                    id="piiData"
                    label="PII Data"
                    error={isError.piiData}
                    helperText={isError.piiData}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <AutoCompleteDropDown
                {...getBestPractice}
                id="bestPractice"
                name="betPractice"
                value={bestPractice}
                multiple
                onChange={handleBestPracticeChange}
                renderInput={(params: any) => (
                  <Input {...params} id="bestPractice" label="Best Practices" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <label className={styles.lawDocument}>Law Document:</label>
              <input
                required
                id="fileUpload"
                type="file"
                accept=".pdf"
                name="file"
                className={styles.fileInput}
                onChange={onChangeHandler}
              />
              {fileName ? (
                <div className={styles.uploadLabelWrap}>
                  Uploaded File :
                  <div className={styles.uploadedLabel}>
                    {fileName.name}&nbsp;&nbsp;
                  </div>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={removeUploadFile}
                    className={styles.CloseButton}
                  >
                    <CancelIcon />
                  </Button>
                </div>
              ) : (
                <label htmlFor="fileUpload" className={styles.fileUploadLable}>
                  Upload File
                </label>
              )}
            </Grid>
            <Grid item xs={6}>
              <Input
                type="text"
                label="Url"
                value={url}
                onChange={handleUrlChange}
              >
                URL
              </Input>
            </Grid>
            {/* <Grid item xs={6}>
            <div className="container-fluid">
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditorState}
                toolbar={{
                  options: ["image"],
                  image: { uploadCallback: uploadImageCallBack },

                }}
              />           
              </div>
            </Grid> */}
          </Grid>
        </AddEditForm>
      )}
    </React.Fragment>
  );
};

export default Law;
