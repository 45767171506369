// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AlertBox_AlertboxPaper__1PRw9 {\n  border-radius: 24px !important;\n  margin-top: 66px !important;\n  /* WIDTH:50%; */\n}\n\n.AlertBox_ScrollPaper__337F- {\n  align-items: unset !important;\n}\n.AlertBox_Alertbox__1IjVn {\n  max-width: 80%;\n  min-width: 50% !important;\n  margin: 0 auto;\n  max-height: 550px;\n  height: unset !important;\n}\n\n.AlertBox_AlertActions__3QNzC {\n  padding-left: 24px !important;\n  padding-bottom: 24px !important;\n}\n\n.AlertBox_AlertTitle__3UIO1 {\n  color: #073f24;\n  padding-top: 24px !important;\n  text-align: center;\n  padding-bottom: 8px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"AlertboxPaper": "AlertBox_AlertboxPaper__1PRw9",
	"ScrollPaper": "AlertBox_ScrollPaper__337F-",
	"Alertbox": "AlertBox_Alertbox__1IjVn",
	"AlertActions": "AlertBox_AlertActions__3QNzC",
	"AlertTitle": "AlertBox_AlertTitle__3UIO1"
};
module.exports = exports;
