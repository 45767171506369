// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Layout_Main__3p0Fq {\n  width: calc(100% - 246px);\n  padding: 35px;\n}\n\n.Layout_mainContainer__3SArF {\n  display: flex;\n  background-color: #EFF6FD;\n  box-shadow: inset 18px 0px 25px -35px rgba(0, 0, 0, 0.45);\n  min-height: calc(100vh - 101px);\n  position: relative;\n}\n\n@media (max-width: 599.95px) {\n  .Layout_mainContainer__3SArF nav>div {\n    display: block !important;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Main": "Layout_Main__3p0Fq",
	"mainContainer": "Layout_mainContainer__3SArF"
};
module.exports = exports;
