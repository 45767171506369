import React from "react";
import Cookies from 'js-cookie';

export interface LoginProps {}
export const Logout: any = () => {
  localStorage.clear();
  Cookies.remove('cc_user', { path: '', domain: process.env.REACT_APP_DOMAIN });
  Cookies.remove('cc_session', { path: '', domain: process.env.REACT_APP_DOMAIN });
  Cookies.remove('cc_contact', { path: '', domain: process.env.REACT_APP_DOMAIN });
  Cookies.remove('cc_app', { path: '', domain: process.env.REACT_APP_DOMAIN });
  return window.location.replace("/pg-action");
};
export default Logout;
