import { gql } from "@apollo/client";



export const GET_ORGANIZATIONTYPE = gql`
query ($where:JSON,$sort:String) {
  organizationTypes(where:$where,sort:$sort) {
    id
    name
    sub_type {
      id
      name
    }
    is_active
  }
}
`;