import { gql } from '@apollo/client';

export const IS_OTHER_OFFICE = gql`
  query otherOffice ($orgId: ID!) {
    addresses (where: {location_type: "Other", contact_id: $orgId }) {
		address_line_1
	    address_line_2
	    id
	    city
	    state {
	      id
	      name
	    }
	    zip
	    location_type
	  }
  }
`;

export const GET_ADDRESS = gql`
	query getaddress($where: JSON) {
		addresses(where: $where) {
			address_line_1
			address_line_2
			id
			city
			state {
				id
				name
			}
			zip
			location_type
		}
	}
`;

// export const GET_ADDRESS = gql`
// 	query getaddress ($orgId: ID!,
// 		$location_type: String) {
// 	  addresses(where: {location_type: $location_type, contact_id: $orgId }) {
// 	    address_line_1
// 	    address_line_2
// 	    id
// 	    city
// 	    state {
// 	      id
// 	      name
// 	    }
// 	    zip
// 	    location_type
// 	  }
// 	}
// `;
