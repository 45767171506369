// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* .Header {\n  width: 93%;\n  padding-left: 5%;\n  padding-top: 1%;\n} */\n\n.Header_root__1whHG {\n  display: flex;\n  border-radius: 0px !important;\n}\n\n/* .details {\n  display: flex;\n}\n.content {\n  color: #9288f2;\n  display: flex;\n  padding: 5px !important;\n} */\n\n.Header_leftContent__3Lg_V {\n  display: inline-flex;\n  align-items: center;\n  font-size: 16px !important;\n}\n\n.Header_rightContent__2c2WE {\n  display: inline-flex;\n  align-items: right;\n}\n\n.Header_siteInfo__1f7iM {\n  justify-content: flex-start;\n  display: inline-flex;\n  align-items: center;\n  width: 220px;\n  border-right: 1px solid #c1c1c1;\n  flex-basis: unset !important;\n  padding: 11px 5px;\n  height: 50px;\n}\n\n.Header_userInfo__39RWa {\n  justify-content: flex-end;\n  display: inline-flex;\n  align-items: center;\n  min-width: calc(100% - 220px);\n  padding: 5px 25px;\n  background-color: #0051a8;\n  box-shadow: 0px 5px 5px #e4e4e4;\n  color: #fff;\n  height:46px;\n}\n\n.Header_userNameLabel__3furc {\n  font-size: 14px;\n  color: #FFFFFF;\n  font-weight: 700;\n  font-family: 'Roboto', sans-serif !important;\n}\n\n.Header_userIcon__3K43d {\n  margin-left: 10px;\n}\n\n.Header_logout__2Ek5A {\n  cursor:pointer\n}\n\n@media (max-width: 599.95px) {\n  .Header_userInfo__39RWa {\n    height: 49px;\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 900;\n    width: 100%;\n}\n}\n\n.Header_RALogoImg__n1j1d {\n\tdisplay: inline-block;\n\twidth: 246px;\n\tbackground-color: #fff !important;\n\tpadding: 5px 15px;\n\ttext-align: center;\n\tfont-size: 140%;\n\tcolor: #002F60 !important;\n\tbox-shadow: 10px 0 7px -10px rgba(0, 0, 0, 0.45);\n\tvertical-align: top;\n\theight: 46px;\n\tz-index: 100;\n\tposition: absolute;\n\ttop: 0px;\n\tline-height: 22px;\n}\n\n.Header_RALogoImg__n1j1d img {\n  width: 40px;\n  height: auto;\n}", ""]);
// Exports
exports.locals = {
	"root": "Header_root__1whHG",
	"leftContent": "Header_leftContent__3Lg_V",
	"rightContent": "Header_rightContent__2c2WE",
	"siteInfo": "Header_siteInfo__1f7iM",
	"userInfo": "Header_userInfo__39RWa",
	"userNameLabel": "Header_userNameLabel__3furc",
	"userIcon": "Header_userIcon__3K43d",
	"logout": "Header_logout__2Ek5A",
	"RALogoImg": "Header_RALogoImg__n1j1d"
};
module.exports = exports;
