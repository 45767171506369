// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Footer_Footer__1Mt8E {\n  text-align: right;\n  padding: 20px 25px 20px 10px;\n  box-shadow: 0px 0px 11px #e4e4e4 !important;\n  margin-top: 1px;\n  font-size: 12px;\n  line-height: 1.2;\n  vertical-align: middle;\n  padding-left: 5px;\n  color: #707070;\n  font-family: 'Roboto', sans-serif !important;\n  background-color: #eff6fd;\n}", ""]);
// Exports
exports.locals = {
	"Footer": "Footer_Footer__1Mt8E"
};
module.exports = exports;
