import React, { useState, useEffect, useCallback, useMemo } from "react";
import { BrowserRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router'
import Typography from "@material-ui/core/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import Grid from "@material-ui/core/Grid";
import * as routeConstant from '../../../common/RouteConstants';
import styles from "./Views.module.css";
export const Views: React.FC = (props: any) => {
// const Views = (props: any) => {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const [param, setParam] = useState<any>();
  const handleBack = () => {
    setRedirect(true)
  };
  useEffect(() => {
    setParam(props.location.state.props);
  }, []);
  if (redirect) {
    history.push(routeConstant.COMPLIANCE, param);
  }
  
  let data: any;
  let key: any;
  let title: any;
  if (props.location.state) {
    data = props.location.state.oldData;
    key = props.location.state.key;
    title = props.location.state.title
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} className={styles.expandButtonDiv}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBack}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Typography component="h1" variant="h1" gutterBottom>
        {props.location && key === 'policies' ?
          <div>
            <h1>{title} </h1>
          Description:  {data.map((policydata: any, i: any) => (
              <div>{policydata}<br></br><br></br></div>
            ))}<br></br>
            <br></br>
          </div> : null}
        {props.location && key === 'workplans' ?
          <div>
            <br></br>
            {/* Name: {data}<br></br> */}
            <h1>{title} </h1>
            <br></br>
          Description: {data.map((workplandata: any, i: any) => (
              <div>{workplandata}<br></br><br></br></div>
            ))}<br></br>
            <br></br>
            {/* Published On: {data.published_on}<br></br> */}
            <br></br>
          </div> : null}
      </Typography>
    </div>
  )
};
export default Views;
