import React from "react";
import { AlignmentType, PageBreak, Document, HeadingLevel, Packer, Paragraph, TabStopPosition, TabStopType, TextRun, UnderlineType } from "docx";

export const DocumentCreator: any = (documentData: any) => {
  const createtempContentList = (result: any[]): Paragraph[] => {
    let replaced = result[0].replaceAll("*", "#");
    var result: any[] = replaced.match(/(?<=#\s*).*?(?=\s*#)/gs);
    var filtered = result.filter(function (el) {
      return el != "";
    });
    return filtered.map(
      (result) =>
        new Paragraph({
          text: result.includes("\n") ? result : undefined,
          heading: result.includes("\n") ? undefined : HeadingLevel.HEADING_3,
          spacing: {
            before: 200,
          },
          children: [
            new TextRun({
              text: "                                                                                                                                                                                                                      ",
            }),
            new TextRun({
              text: result.includes("\n") ? undefined : result,
              bold: true,
            }),
          ],
        })
    );
  };
  var overView: any[] = documentData.template_description[0].match(
    /[^*]*$/gs
  );

  const createPolicyContentList = (contents: any[]): Paragraph[] => {
    return contents.map(
      (contents) =>
        new Paragraph({
          spacing: {
            before: 400,
          },
          children: [
            new TextRun({
              text: contents.includes("\n")
                ? contents.replaceAll("*", "").split("\n")[0]
                : undefined,
              bold: true,
            }),
            new TextRun({
              text:
                "                                                                                                                                                                                                                      ",
            }),
            new TextRun({
              text: contents.includes("\n")
                ? contents.substr(contents.indexOf("\n") + 1)
                : undefined,
            }),
          ],
        })
    );
  };

  const createRulesList = (contents: any[]): Paragraph[] => {
    return contents.map(
      (contents) =>
        new Paragraph({
          text: " " + contents.replaceAll("*", "").split("\n")[0],
          bullet: {
            level: 0,
          },
        })
    );
  };

  const document = new Document();
  if (documentData) {
    document.addSection({
      children: [
        new Paragraph({
          text: documentData.title,
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
          thematicBreak: true,
        }),
        ...createtempContentList(documentData.template_description),
        new Paragraph({
          text: " " + overView[0],
          spacing: {
            before: 100,
            after: 100
          },
        }),
        ...createRulesList(documentData.data),
        new Paragraph({
          text: "",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
        }),
        ...createPolicyContentList(documentData.data),
      ],
    });
  }

  return document;
}

export default DocumentCreator;
