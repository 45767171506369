import React, { useState, useEffect } from "react";
import styles from "./PartnerUser.module.css";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../../../components/UI/Form/Button/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Alert from "../../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "../../../../components/UI/Table/MaterialTable";
import { Link } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_ROLE,
  GET_ROLE_BASED_USER,
} from "../../../../graphql/queries/User";
import { CompanyUser } from "../../../../common/Roles";
import { GET_ORGANIZATION } from "../../../../graphql/queries/Organization";
import { GET_CONTACT_INFO } from "../../../../graphql/queries/Contact";
import { GET_INDIVIDUAL } from "../../../../graphql/queries/Individual";
import logout from "../../../../containers/Auth/Logout/Logout";
import { useHistory } from "react-router-dom";
import * as routeConstant from "../../../../common/RouteConstants";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import {
  SUCCESS,
  UPDATE,
  ALERT_MESSAGE_TIMER,
} from "../../../../common/MessageConstants";

export const PartnerUser: React.FC = (propsData: any) => {
  const history = useHistory();;
  const [partnerList, setPartnerList] = useState("");
  const [newData, setNewData] = useState([]);
  const [param, setParam] = useState<any>();
  const contact = JSON.parse(localStorage.getItem("cc_contact") || "{}");
  const user = JSON.parse(localStorage.getItem("cc_user") || "{}");
  const [showBackDrop, setShowBackdrop] = useState(true);
  let partnerUserdata: any;
  if (propsData.location.state !== null) {
    partnerUserdata = propsData.location.state
      ? propsData.location.state.propsData
      : null;
  }

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  // useEffect(()=>{
  //   setShowBackdrop(false)
  // },[])

  //table
  const column = [
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "phone" },
  ];

  const { data: partnerLoggedIn, loading: loadRoleuser } = useQuery(
    GET_ROLE_BASED_USER,
    {
      variables: {
        where: { id: contact.user_id.id },
      },
      onCompleted: (data) => {},
      onError:()=>{
        setShowBackdrop(false)
        history.push(routeConstant.CLIENT)
      }
    }
  );

  const { data: dataOrg, error: errorOrg, loading: loadingOrg } = useQuery(
    GET_ORGANIZATION,
    {
      variables: {
        where: { subtype: "Partner" },
      },
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        let contact_id_array = dataOrg.organizations.map(
          (val: any) => val.contact_id.id
        );
        getContactInfo({
          variables: {
            where: { id_in: contact_id_array },
          },
        });
      },
      onError:()=>{
        setShowBackdrop(false)
        history.push(routeConstant.CLIENT)
      }
    }
  );

  const [
    getContactInfo,
    { data: contactData, loading: contactLoading },
  ] = useLazyQuery(GET_CONTACT_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      if (partnerList.length === 0) {
        setPartnerList(contactData.contacts);
      }
    },
    onError:()=>{
      setShowBackdrop(false)
      history.push(routeConstant.CLIENT)
    }
  });

  const [getRole, { data: userData, loading: userLoading }] = useLazyQuery(
    GET_ROLE,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [getIndividual, { data: iData, loading: iLoading }] = useLazyQuery(
    GET_INDIVIDUAL,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (
          partnerUserdata !== undefined &&
          partnerLoggedIn &&
          partnerLoggedIn.users[0].role.name === "Administrator"
        ) {
          createTableDataObject(iData.individuals);
        }
        if (
          partnerUserdata === undefined ||
          (partnerUserdata === null && iData.individuals[0] !== undefined)
        ) {
          getIndividualpartner({
            variables: {
              where: { partner_id: iData.individuals[0].partner_id.id },
            },
          });
        }
      },onError:()=>{
        setShowBackdrop(false)
        history.push(routeConstant.CLIENT)
      }
    }
  );

  const [
    getIndividualpartner,
    { data: ipData, loading: ipLoading },
  ] = useLazyQuery(GET_INDIVIDUAL, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setShowBackdrop(false)
      createTableDataObject(ipData.individuals);
    },
    onError:()=>{
      setShowBackdrop(false)
      history.push(routeConstant.CLIENT)
    }
  });

  const createTableDataObject = (data: any) => {
    let arr: any = [];
    data.map((element: any, index: any) => {
      let obj: any = {};
      obj["id"] = element.contact_id.id;
      obj["email"] = element.contact_id.email;
      obj["name"] = element.contact_id.name;
      obj["phone"] = !element.contact_id.phone ? "-" : element.contact_id.phone;
      obj["first_name"] = element.first_name;
      obj["last_name"] = element.last_name;
      obj["partner_id"] = element.id;
      arr.push(obj);
    });
    setNewData(arr);
    setShowBackdrop(false)
  };

  useEffect(() => {
    setParam(propsData.location.state);
    if (partnerUserdata) {
      getIndividual({
        variables: {
          where: { partner_id: partnerUserdata.id },
        },
      });
      if (partnerUserdata.formState) {
        setFormState(partnerUserdata.formState);
      }
    }

    //from partner user
    if (
      propsData.location.state === null ||
      propsData.location.state === undefined ||
      propsData.location.state.from && propsData.location.state.from === "partner-user"
    ) {
      getIndividual({
        variables: {
          where: { partner_id_null: false, contact_id: contact.id },
          sort: "created_at:desc",
        },
      });
      if( propsData.location.state != undefined &&  propsData.location.state != null && propsData.location.state.formstate) {
        setFormState(propsData.location.state.formstate);
      }
    }
    getRole();
  }, []);

  let UserRole: any;
  useEffect(() => {
    if (userData) {
      userData.roles.map((val: any) => {
        if (val.name === CompanyUser) UserRole = parseInt(val.id);
      });
    }
  }, [userData]);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  // if (showBackDrop) return <SimpleBackdrop/>;
  if (errorOrg) {
    let error = { message: "Error" };
    return (
      <div className="error">
        Error!
        {logout()}
      </div>
    );
  }

  const handleClickOpen = () => {
    param.propsData.from = "admin-partner-user";
    history.push(routeConstant.PARTNER_USER_FORM_ADD, param);
  };

  const handleClickEdit = (rowData: any, event: any) => {
    if (
      user && user.user && user.user.role &&
      user.user.role.name === "Company User"
    ) {
      history.push(routeConstant.PARTNER_USER_FORM_EDIT + rowData.id);
    }
    if (
      user && user.user && user.user.role &&
      user.user.role.name  === "Administrator"
    ) {
      param.propsData.from = "admin-partner-user";
      history.push(routeConstant.PARTNER_USER_FORM_EDIT + rowData.id, param);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid>
        <Grid container>
          <Grid item xs={12} md={8} className={styles.FilterWrap}></Grid>
          
          <Grid item xs={12} md={4} className={styles.backToListButton}>
            {propsData.location.state != null &&
            partnerLoggedIn &&
            partnerLoggedIn.users[0].role.name === "Administrator" ? (
              <div className={styles.ButtonGroup1}>
                <div className={styles.FilterInputgotolist}>
                  <Button
                    className={styles.BackToButton}
                    variant={"contained"}
                    onClick={() => {
                      history.push(routeConstant.ADD_PARTNER);
                    }}
                    color="secondary"
                    data-testid="cancel-button"
                  >
                    <img
	                  src={
	                    process.env.PUBLIC_URL + "/icons/svg-icon/back-list.svg"
	                  } alt="user icon"
	                  />
                    &nbsp; Back to List
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleClickOpen}
                  >
                    <AddCircleIcon className={styles.EditIcon}/>
                    &nbsp; User
                  </Button>
                </div>
              </div>
            ) : null}
            {showBackDrop ? <SimpleBackdrop/> : null}
            {user && user.user && user.user.role &&
            user.user.role.name === "Company User" ? (
              <div className={styles.ButtonGroup2}>
                <div className={styles.FilterInput}>
                  <Link to={routeConstant.PARTNER_USER_FORM_ADD}>
                    <Button color="primary" variant="contained">
                      <AddCircleIcon className={styles.EditIcon}/>
                      User
                    </Button>
                  </Link>
                </div>
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Paper className={styles.paper}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              User <strong>{formState.errMessage}</strong> {SUCCESS}
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              User <strong>{formState.errMessage}</strong> {UPDATE}
            </Alert>
          ) : null}
          <div className={styles.ScrollTable}>
            <MaterialTable
              columns={column}
              data={newData}
              actions={[
                {
                  icon: () => <img className={styles.EditIcon}
                  src={
                    process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
                  }
                  alt="edit icon"
                />,
                  tooltip: "Edit",
                  onClick: (event: any, rowData: any) => {
                    handleClickEdit(rowData, event);
                  },
                },
              ]}
              options={{
                headerStyle: {
                  backgroundColor: "#EFF6FD",
                  color: "#002F60",
                },
                actionsColumnIndex: -1,
                paging: true,
                sorting: true,
                search: false,
                filter: true,
                draggable: false,
                pageSize: 25,
                pageSizeOptions: [25, 50, 75, 100],
              }}
            />
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default PartnerUser;
