import React, { useState, useEffect } from 'react';
import styles from './OtherOfficeCompliance.module.css';
import ComplianceReport from "../ComplianceReport/ComplianceReport"
// import Button from '@material-ui/core/Button';
import { Button } from "../../../components/UI/Form/Button/Button"
import { AlignmentType, Document, HeadingLevel, Paragraph, TabStopPosition, TabStopType, TextRun } from "docx";
import { DocumentCreator } from "../../../components/GenerateDocument/GenerateDoc";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { keys } from '@material-ui/core/styles/createBreakpoints';

export interface ComplianceProps {
  data?: any,
  param?: any
}

export const OtherOfficeCompliance: React.SFC<ComplianceProps> = ({ data, param }: ComplianceProps) => {
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [expansionPanel, setExpansionPanel] = useState<any>([]);
  const [otherOfficeState, setOtherOfficeState] = useState<any>([]);
  const [preExpansionPanel, setPreExpansionPanel] = useState<any>([]);
  const [loading, setLoading] = useState<any>();
  const JSZip = require("jszip");
  let zip = new JSZip();
  useEffect(() => {
    otherOfficeObj();
  }, [data]);

  const policyDescription = (rule: any, category: any) => {
    let policyDes: any[] = [];
    rule.map((element: any) => {
      if (category && element.category.id === category.id) {
        policyDes.push(element.policy_content);
      }
    });
    return policyDes;
  }

  const workplanDescription = (rule: any, category: any) => {
    let workplanDes: any[] = [];
    rule.map((element: any) => {
      if (category && element.category.id === category.id) {
        workplanDes.push(element.workplan_description);
      }
    });
    return workplanDes;
  }

  const templateDescription = (rule: any, category: any) => {
    let templateDes: any[] = [];
    rule.map((element: any) => {
      if (category && element.category.id === category.id) {
        templateDes.push(element.category.template_description);
      }
    });
    return templateDes;
  }

  const WorkplanDocumentCreator: any = (documentData: any) => {
    const document = new Document();
    document.addSection({
      children: [

        new Paragraph({
          text: "Workplan",
          heading: HeadingLevel.TITLE,
          alignment: AlignmentType.CENTER,
          thematicBreak: true,
        }),

        new Paragraph({
          text: documentData.title,
          heading: HeadingLevel.TITLE,
          thematicBreak: true,
        }),

        new Paragraph({
          text: JSON.stringify(documentData.data),
          alignment: AlignmentType.CENTER,
        }),

      ],
    });

    return document;
  }

  const PolicyDocumentCreator: any = (documentData: any) => {

    const createBullet = (text: string): Paragraph => {
      return new Paragraph({
        text: text,
        // bullet: {
        //   level: 0,
        // },
      });
    };

    const createSubHeading = (text: string): Paragraph => {
      return new Paragraph({
        text: text,
        heading: HeadingLevel.HEADING_2,
      });
    };

    const createPolicyContentList = (contents: any[]): Paragraph[] => {
      return contents.map(
        (contents) =>
          new Paragraph({
            text: contents,
            bullet: {
              level: 0,
            },
          }),
      );
    };

    const document = new Document();
    if (documentData) {

      document.addSection({
        children: [

          new Paragraph({
            text: documentData.title,
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,
            thematicBreak: true,
          }),

          // new Paragraph({
          //   text: JSON.stringify(documentData.data),
          //   alignment: AlignmentType.CENTER,
          // }),

          createBullet(documentData.template_description ? documentData.template_description[0] : ""),
          ...createPolicyContentList(documentData.data),
        ],
      });
    }

    return document;
  }

  const handleLawOpen = (newData: any) => {
    // //Discuss about getting the backend url dynamically
    // if (rowData.law_document && rowData.law_document[0]) {
    //   window.open(DOCURL + rowData.law_document[0].url.slice(1))
    // }
  };

  let workplanData: any = [];
  let Contents: any;
  let workplanDocTitle: any;
  const GenerateWorkplanDoc = () => {
    if (workplanData.length > 0) {
      Contents = { "data": workplanData, "title": workplanDocTitle }
      const workplandocumentCreator = WorkplanDocumentCreator(Contents);
      Packer.toBlob(workplandocumentCreator).then(blob => {
        var timeStamp = Math.floor(Date.now() / 1000);
        // zip.folder("Other Office/Workplan").file(workplanDocTitle + "_" + ".docx", blob, { base64: true })
      });
    }
  }
  const downloadOtherOffice = async (office: any, index: any) => {
    return new Promise(async (resolve) => {
      setLoading(true);
      zip.remove("Other Office");
      workplanData = []
      Object.keys(office).map((key, index) => (
        office[key]['description'].map((element: any) => {
          if (key === 'workplans') {
            workplanData.push(workplanDescription(office['rules'].description, element.category))
            workplanDocTitle = office['workplans'].description[0].name
          }
          if (key === 'policies') {
            Contents = { "data": policyDescription(office['rules'].description, element.category), "title": element.name, "template_description": templateDescription(office['rules'].description, element.category) }
            const policydocumentCreator = PolicyDocumentCreator(Contents);
            Packer.toBlob(policydocumentCreator).then(blob => {
              var timeStamp = Math.floor(Date.now() / 1000);
              zip.folder("Other Office/Policy").file(element.name + "_" + ".docx", blob, { base64: true })
            });
          }
        })
      ))
      // Adding Law for All Offices / Other office in Zip
      if (office.laws.description) {
        for (let j in office.laws.description) {
          if (office.laws.description[j].law_document && office.laws.description[j].law_document[0] && office.laws.description[j].law_document[0].url) {
            let lawUrl = office.laws.description[j].law_document[0].url.slice(1)
            let DocUrl = serverUrl + lawUrl;
            let promise = await fetch(DocUrl,
              {
                method: "GET",
              }).then(response => response.blob()).then(response => {
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                var timeStamp = Math.floor(Date.now() / 1000);
                let fileName = office.laws.description[j].law_document[0].name;
                let fileType = '.pdf'
                let docFileName = fileName + timeStamp + fileType
                a.href = url;
                a.download = docFileName;
                zip.folder("Other Office/Laws").file(docFileName + "_" + fileType, response)
              });
          }
        }
      }
      GenerateWorkplanDoc()
      if (Contents) {
        Packer.toBlob(WorkplanDocumentCreator(Contents), PolicyDocumentCreator(Contents)).then(blob => {
          zip.generateAsync({ type: "blob" })
            .then(function (content: any) {
              setLoading(false);
              saveAs(content, "Other_Office_Compliance.zip");
            });
        });
      }
    });
  };


  const otherOfficeObj = () => {
    let otherOfficeArray: any[] = [];
    let otherOfficeState: any[] = [];
    if (data.new.length !== 0) {
      data.new.map((office: any, index: any) => {
        let dataObj = {
          // "parentLaws": {
          //   "title": "Laws applicable to the company",
          //   "description": office.parentLaws,
          //   "icon": "PermIdentityIcon",
          //   "link": "company-Info"
          // },
          "laws": {
            "title": "Laws applicable to the company",
            "description": office.laws,
            "icon": "PermIdentityIcon",
            "link": "company-Info"
          },
          "rules": {
            "title": "Rules applicable to the company",
            "description": office.rules,
            "icon": "HomeWorkIcon",
            "link": "company-address"
          },
          "policies": {
            "title": "Policies based on the laws",
            "description": office.policies,
            "icon": "HomeWorkIcon",
            "link": "company-address"
          },
          "workplans": {
            "title": "Workplan",
            "description": office.workplans,
            "icon": "HomeWorkIcon",
            "link": "company-address"
          }
        }
        otherOfficeArray.push(dataObj)
        otherOfficeState.push(office.state)
      });
    }
    setExpansionPanel(otherOfficeArray)
    setOtherOfficeState(otherOfficeState)

    if (data.pre.length !== 0) {
      let preOtherOfficeArray: any[] = [];
      data.pre.map((office: any, index: any) => {
        let preDataObj = {
          // "parentLaws": {
          //   "title": "Laws applicable to the company",
          //   "description": office.parentLaws,
          //   "icon": "PermIdentityIcon",
          //   "link": "company-Info"
          // },
          "laws": {
            "title": "Laws applicable to the company",
            "description": office.laws,
            "icon": "PermIdentityIcon",
            "link": "company-Info"
          },
          "rules": {
            "title": "Rules applicable to the company",
            "description": office.rules,
            "icon": "HomeWorkIcon",
            "link": "company-address"
          },
          "policies": {
            "title": "Policies based on the laws",
            "description": office.policies,
            "icon": "HomeWorkIcon",
            "link": "company-address"
          },
          "workplans": {
            "title": "Workplan",
            "description": office.workplans,
            "icon": "HomeWorkIcon",
            "link": "company-address"
          }
        }
        preOtherOfficeArray.push(preDataObj)
      });
      setPreExpansionPanel(preOtherOfficeArray)
    }
  }

  return (
    <div className={styles.TabContainer}>
      {/* <h3>Other Office</h3> */}
      <div>
        {data.new.length > 0 ? expansionPanel.map((office: any, index: any) => (
          <div>
            <div>
              <h4 className={styles.OfficeH4} ><label>Office in </label>{otherOfficeState[index].name}</h4>
            </div>
            <div key={index}>
              <ComplianceReport key={index}
                reportData={{ new: office, pre: preExpansionPanel.hasOwnProperty(index) ? preExpansionPanel[index] : [], param: param }}>
              </ComplianceReport>
            </div>
            <div className={styles.ComplianceButtons}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => { downloadOtherOffice(office, index) }}
                disabled={loading}
              >
                {loading && <span>Please Wait</span>}
                {!loading && 'Download'}
              </Button>
            </div>
          </div>
        )) : <label>You don't have any Other Office.</label>}
      </div>
    </div>
  )
};

export default OtherOfficeCompliance;
