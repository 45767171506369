import React, { useState, useEffect } from "react";
import styles from "./MainOffice.module.css";
import Office from "../Office/Office";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import * as routeConstant from "../../common/RouteConstants";
import { useHistory } from "react-router-dom";
import { GET_ADDRESS } from "../../graphql/queries/Address";
import {
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
} from "../../graphql/mutations/Address";
import {
  CREATE_ORG_LOC,
  UPDATE_ORG_LOC,
  DELETE_ORG_LOC,
} from "../../graphql/mutations/OrganizationLocation";
import { GET_EMPLOYEE_DATA } from "../../graphql/queries/OrganizationLocation";
import { GET_COMPLIANCEINFO } from "../../graphql/queries/ComplianceInfo";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_COMPLIANCE } from "../../graphql/queries/Compliance";
import { UPDATE_COMPLIANCE } from "../../graphql/mutations/Compliance";
import {
  getCompliance,
  setCompliance,
  returnArray,
  setOtherOffice,
  setActiveFormStep,
} from "../../services/Data";
import { Button } from "../../components/UI/Form/Button/Button";
import { useApolloClient } from "@apollo/client";
import { setStepper } from "../../common/SetStepper";
import stepper from "../../common/stepperMenu.json";
import Switch from "../../components/UI/Form/Switch/Switch";
import logout from "../../containers/Auth/Logout/Logout";
import {
  FormControl,
  FormLabel,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import TopStepper from "../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import { DialogBox } from "../../components/UI/DialogBox/DialogBox";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import { GET_REVIEW_INFO } from "../../graphql/queries/Review";

export const MainOffice: React.FC = (props: any) => {
  const client = useApolloClient();
  let stepperObject = stepper;
  const [showBackDrop, setShowBackdrop] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [param, setParam] = useState<any>();
  const [otherOfficeOrgLocation, setOtherOfficeOrgLocation] = useState<any>();
  const [piiFieldError, setpiiFieldError] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [stepperObj, setStepperObj] = useState<any>({path: ""});

  // Query and Mutation
  const [createAddress] = useMutation(CREATE_ADDRESS);
  const [updateAddress] = useMutation(UPDATE_ADDRESS);
  const [createEmployee] = useMutation(CREATE_ORG_LOC);
  const [updateEmployee] = useMutation(UPDATE_ORG_LOC);
  const [updateComplianceData] = useMutation(UPDATE_COMPLIANCE);
  const [deleteEmployee] = useMutation(DELETE_ORG_LOC);
  const [deleteAddress] = useMutation(DELETE_ADDRESS,{
    onCompleted: () => {
      setHaveOtherOffice(false);
      setOtherOffice(false);
      setOpenDialogBox(false);
  } 
  });
  const [
    getComplianceInfo,
    { data: compInfo, error: compInfoErr, loading: compInfoLoad },
  ] = useLazyQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setOrgContactId(compInfo.complianceInfos[0].contact_id.id);
      getaddress({
        variables: {
          where: {
            location_type: "Main",
            contact_id: compInfo.complianceInfos[0].contact_id.id,
          },
        },
      });
      getOtheraddress({
        variables: {
          where: { contact_id: compInfo.complianceInfos[0].contact_id.id },
        },
      });
      getOrgLocation({
        variables: {
          where: {
            address_id: { location_type: "Main" },
            contact_id: compInfo.complianceInfos[0].contact_id.id,
          },
        },
      });
      getOtherOrgLocation({
        variables: {
          where: {
            address_id: { location_type: "Other" },
            contact_id: compInfo.complianceInfos[0].contact_id.id,
          },
        },
      });
    },
    onError:()=>{
      setShowBackdrop(false)
      handleCancel()
    }
  });

  const [
    getComplianceData,
    { data: complianceData, loading: complianceLoading },
  ] = useLazyQuery(GET_COMPLIANCE, {
    onCompleted: (data: any) => {
      setCompliance(complianceData.compliances);
    },
    fetchPolicy: "cache-and-network",
    onError:()=>{
      setShowBackdrop(false)
      handleCancel()
    }
  });

  const fetchCompliance = (val: any) => {
    getComplianceData({
      variables: {
        where: { contact_id: val },
      },
    });
  };

  const [getaddress, { data: dataAD, loading: loadingAD }] = useLazyQuery(
    GET_ADDRESS,
    {
      fetchPolicy: "cache-and-network",
      onError:()=>{
        setShowBackdrop(false)
        handleCancel()
      }
    }
  );
  const [
    getOtheraddress,
    { data: dataOTAD, loading: loadingOTAD },
  ] = useLazyQuery(GET_ADDRESS, {
    fetchPolicy: "cache-and-network",
    onError:()=>{
      setShowBackdrop(false)
      handleCancel()
    }
  });
  const [getOrgLocation, { data: dataOL, loading: loadingOL }] = useLazyQuery(
    GET_EMPLOYEE_DATA,
    {
      fetchPolicy: "cache-and-network",
      onError:()=>{
        setShowBackdrop(false)
        handleCancel()
      }
    }
  );
  const [getOtherOrgLocation, { data: otherOfficeData, loading: loadingOtherOfficeData}] = useLazyQuery(
    GET_EMPLOYEE_DATA,
    {
      onCompleted: () => {
        setShowBackdrop(false)
        setOtherOfficeOrgLocation(otherOfficeData);
      },
      fetchPolicy: "cache-and-network",
      onError:()=>{
        setShowBackdrop(false)
        handleCancel()
      }
    }
  );

  const [ getReview, { data: reviewData, error: error } ] = useLazyQuery(GET_REVIEW_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: any) => {
      setShowBackdrop(true)
      if (data.review.companyprofile.data.length > 0) {
        setActiveFormStep(0);
      }
      if (data.review.mainOffice.data.length > 0) {
        setActiveFormStep(1);
      }
      if (data.review.otherOffice.data.length > 0) {
        setActiveFormStep(2);
      }
      if (data.review.customer.data.length > 0) {
        setActiveFormStep(4);
      }
    },
    onError:()=>{
      setShowBackdrop(false)
      handleCancel()
    }
  });

  const history = useHistory();
  const [orgContactId, setOrgContactId] = useState<any>();
  const [haveOtherOffice, setHaveOtherOffice] = useState(true);
  const [otherOfficeDeleted, setOtherOfficeDeleted] = useState(false);

  const [dataObj, setDataObj] = useState([
    {
      address: {
        id: null,
        address1: "",
        address2: "",
        state: "",
        city: "",
        zip: "",
        isError: {
          address1: false,
          address2: false,
          state: false,
          city: false,
          zip: false,
        },
      },
      employee: {
        id: null,
        noOfEmployee: "",
        doYouCollectPiiData: false,
        piiArray: [],
        isError: {
          noOfEmployee: false,
        },
      },
    },
  ]);

  useEffect(() => {
    setParam(props.location.state);
    if (
      props.location.state &&
      props.location.state.hasOwnProperty("complianceInfo")
    ) {
      getReview({
        variables: { ID: parseInt(props.location.state.complianceInfo.contactId) }
      });
      getComplianceInfo({
        variables: {
          where: {
            client_id: props.location.state.clientInfo.clientId,
            contact_id: props.location.state.complianceInfo.contactId,
            id: props.location.state.complianceInfo.id,
          },
        },
      });
    }
    setStepper(
      client,
      stepperObject.mainOffice.name,
      stepperObject.mainOffice.value
    );
  }, []);

  const restoredata = () => {
    let office: any = [];
    if (dataAD && dataOL && dataOTAD) {
      // check if there is other office available or not
      let val = dataOTAD.addresses.filter(
        (item: any) => item.location_type === "Other"
      );
      if (val.length !== 0) {
        setOtherOffice(true);
        setHaveOtherOffice(true);
      } else {
        setOtherOffice(false);
        setHaveOtherOffice(false);
      }
      if (
        dataAD.addresses !== undefined &&
        dataOL.organizationLocations[0] &&
        dataAD.addresses[0] !== undefined &&
        dataOL.organizationLocations !== undefined
      ) {
        let piiArray: any = [];
        for (let i in dataOL.organizationLocations[0].pii_data_field) {
          piiArray.push(
            parseInt(dataOL.organizationLocations[0].pii_data_field[i].id)
          );
        }
        office.push({
          address: {
            id: dataAD.addresses[0].id,
            address1: dataAD.addresses[0].address_line_1,
            address2: dataAD.addresses[0].address_line_2,
            city: dataAD.addresses[0].city,
            state: dataAD.addresses[0].state,
            zip: dataAD.addresses[0].zip,
            isError: {},
          },
          employee: {
            id: dataOL.organizationLocations[0].id,
            noOfEmployee: dataOL.organizationLocations[0].no_of_employees,
            doYouCollectPiiData:
              dataOL.organizationLocations[0].collect_pii_information,
            piiArray: piiArray,
            isError: {},
          },
        });
      }
      if (office.length > 0) {
        setDataObj(office);
        fetchCompliance(orgContactId);
      }
    }
  };

  useEffect(() => {
    restoredata();
    if (dataAD !== undefined && dataOL !== undefined) {
      if (dataAD.addresses.length >= 0) {
        setSubmitDisabled(false);
      }
      if (dataAD.addresses.length === 0) {
        setSubmitDisabled(true);
      }
    }
  }, [dataAD, dataOL]);


  if (compInfoErr) {
    let error = compInfoErr ? compInfoErr : { message: "Error" };
    return (
      <div className="error">
        Error!
        {logout()}
      </div>
    );
  }

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }

  let updatedObj = {};
  const verifyChange = () => {
    let comp = getCompliance();
    updatedObj = {};
    comp.map((compliance: any) => {
      if (compliance.allow_download === true) {
        updatedObj = {
          id: parseInt(compliance.id),
          contact_id: parseInt(compliance.contact_id.id),
          law: returnArray(compliance.laws),
          rule: returnArray(compliance.rules),
          policy: returnArray(compliance.policies),
          workplan: returnArray(compliance.workplans),
          address: parseInt(compliance.address_id.id),
          allowdownload: false,
          compliance_version: compliance.compliance_version
            ? parseInt(compliance.compliance_version.id)
            : compliance.compliance_version,
        };
        updateCompliance(updatedObj);
      }
    });
  };

  const updateCompliance = (val: any) => {
    updateComplianceData({
      variables: val,
    })
      .then((UpdateOrgRes) => {})
      .catch((error) => {});
  };

  let foundError = true;
  let piiFieldErrors = false;
  const officeHandleChange = (event: any) => {
    setDataObj(event);
    if (event[0].employee.doYouCollectPiiData && event[0].employee.piiArray.length === 0) {
      piiFieldErrors = true;
      setSubmitDisabled(true);
      setpiiFieldError(true);
    }
    if (event[0].employee.doYouCollectPiiData && event[0].employee.piiArray.length !== 0) {
      piiFieldErrors = false;
      setSubmitDisabled(false);
      setpiiFieldError(false);
    }
    if (event[0].employee.doYouCollectPiiData === false && event[0].employee.piiArray.length === 0) {
      setSubmitDisabled(false);
      setpiiFieldError(false);
    }
    let addressErrors = Object.values(event[0].address.isError);
    let employeeErrors = Object.values(event[0].employee.isError);
    let allErrors = addressErrors.concat(employeeErrors);
    let collectPiiData = event[0].employee.doYouCollectPiiData
    let piiarray = event[0].employee.piiArray
    foundError = allErrors.includes(true);
    
    if (foundError || (collectPiiData && piiarray.length === 0)) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  };

  const handleSubmit = () => {
    if(dataOTAD) {
      let val = dataOTAD.addresses.filter((item: any) => item.location_type === "Other");
      if(val.length > 0 && !haveOtherOffice ) {
        if(!openDialogBox) {
          setOpenDialogBox(true);
        }
      } else {
        // setShowLoading(true)
        setShowBackdrop(true)
        postAddress();
      }
    } else {
      // setShowLoading(true)
      setShowBackdrop(true)
      postAddress();
    }
  };

  let address: any;
  let employee: any;

  if (dataObj !== undefined) {
    address = dataObj[0].address;
    employee = dataObj[0].employee;
  }

  const postAddress = () => {
    if (orgContactId !== undefined && dataObj[0].address.id === null && address !== undefined && !submitDisabled) {
      setSubmitDisabled(true);
      createAddress({
        variables: {
          contact_id: orgContactId, // contact id of client
          address1: address.address1,
          address2: address.address2,
          locationType: "Main",
          city: address.city,
          state: address.state.id,
          zip: address.zip,
        },
      })
        .then((res) => {
          postEmployee(res);
        })
        .catch((Error) => {
          // setShowLoading(false);
          setShowBackdrop(false);
          setSubmitDisabled(false);
        });
    } else {
      handleNext();
    }
    if (
      orgContactId !== undefined &&
      address !== undefined &&
      address.id !== null &&
      !submitDisabled
    ) {
    // if(!submitDisabled && orgContactId && address.id) {
      setSubmitDisabled(true);
      updateAddress({
        variables: {
          id: address.id,
          contact_id: orgContactId, // contact id of client
          address1: address.address1,
          address2: address.address2,
          locationType: "Main",
          city: address.city,
          state: address.state.id,
          zip: address.zip,
        },
      })
        .then((res) => {
          postEmployee(res);
        })
        .catch((Error) => {
          // setShowLoading(false);
          setShowBackdrop(false);
          setSubmitDisabled(false);
        });
    } else {
      handleNext();
    }
  };

  const postEmployee = (val: any) => {
    let doYouCollectePiiInfo = false;
    if (dataObj[0].employee.piiArray.length > 0) {
      doYouCollectePiiInfo = true;
    }
    let addressId: any;
    if (val.data.createAddress !== undefined) {
      addressId = val.data.createAddress.address.id;
    } else {
      addressId = val.data.updateAddress.address.id;
    }

    if (orgContactId !== undefined && dataObj[0].employee.id === null && !submitDisabled) {
      setShowBackdrop(true);
      setSubmitDisabled(true);
      createEmployee({
        variables: {
          contactId: orgContactId, // contact id of client
          addressId: addressId,
          noOfEmp: parseInt(dataObj[0].employee.noOfEmployee),
          collectePiiInfo: doYouCollectePiiInfo,
          pii_data_field: dataObj[0].employee.piiArray,
        },
      })
        .then((res) => {
          handleNext();
        })
        .catch((err) => {
          // setShowLoading(false);
          setShowBackdrop(false);
          setSubmitDisabled(false);
        });
    }
    if (orgContactId !== undefined && dataObj[0].employee.id !== null && !submitDisabled) {
      setShowBackdrop(true);
      setSubmitDisabled(true);
      updateEmployee({
        variables: {
          id: dataObj[0].employee.id,
          contactId: orgContactId, // contact id of client
          addressId: addressId,
          noOfEmp: parseInt(dataObj[0].employee.noOfEmployee),
          collectePiiInfo: doYouCollectePiiInfo,
          pii_data_field: dataObj[0].employee.piiArray,
        },
      })
        .then((res) => {
          verifyChange();
          handleNext();
        })
        .catch((err) => {
          setShowBackdrop(false);
          setSubmitDisabled(false);
        });
    }
  };

  const handleBack = () => {
    setStepper(
      client,
      stepperObject.companyProfile.name,
      stepperObject.companyProfile.value
    );
    history.push(routeConstant.COMPANY_INFO, param);
  };

  const handleNext = () => {
    if (haveOtherOffice && !submitDisabled) {
      if (stepperObj.path && stepperObj.path !== "") {
        history.push(stepperObj.path, param);
      } else {
        setStepper(
          client,
          stepperObject.otherOffice.name,
          stepperObject.otherOffice.value
        );
        history.push(routeConstant.OTHER_OFFICE, param);
      }
    }
    if (!haveOtherOffice && !submitDisabled) {
      if (stepperObj.path && stepperObj.path !== "") {
        history.push(stepperObj.path, param);
      } else {
        setStepper(
          client,
          stepperObject.customer.name,
          stepperObject.customer.value
        );
        history.push(routeConstant.CUSTOMER, param);
      }
    }
  };

  const handleOtherOffice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHaveOtherOffice(event.target.checked);
    setOtherOffice(event.target.checked);
  };

  const handleCancel = () => {
    let val = {
      clientInfo: param.clientInfo
    }
    history.push(routeConstant.COMPLIANCE_LIST, val);
  };

  const closeDialogBox = () => {
    setOpenDialogBox(false);
    setHaveOtherOffice(true);
    setOtherOffice(true);
  };
  
  const handleSubmitDialogBox = () => {
    if (otherOfficeOrgLocation) {
      for (let i in otherOfficeOrgLocation.organizationLocations) {
        deleteEmployee({
          variables: {
            id: otherOfficeOrgLocation.organizationLocations[i].id,
          },
        })
          .then((res) => {
            deleteOtherOfficeAddresss();
          })
          .catch((err) => {});
      }
    }
  };
  
  function deleteOtherOfficeAddresss() {
    let val = dataOTAD.addresses.filter(
      (item: any) => item.location_type === "Other"
    );
    for (let i in val) {
      deleteAddress({
        variables: {
          id: val[i].id,
        },
      }).then((res) => {
        setHaveOtherOffice(false);
        setOtherOffice(false);
        setOpenDialogBox(false);
        setOtherOfficeDeleted(true);
        postAddress();
      });
    }
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
    handleSubmit();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {props.location.state
          ? "CyberCompliance for " + props.location.state.clientInfo.name
          : null}
      </Typography>
      <TopStepper
        navigate={!submitDisabled}
        param={param}
        handleSave={callingFromTopStepper}
        obj={stepperObj}
      />
        <Grid container>
        { enableBackdrop() ? <SimpleBackdrop/>:  null}
          <DialogBox
            open={openDialogBox}
            handleOk={handleSubmitDialogBox}
            handleCancel={closeDialogBox}
            buttonOk={"Yes"}
            buttonCancel={"No"}
            classes={{
              root: styles.MainOfficeDialogRoot,
              container: styles.MainOfficeDialogboxContainer,
              paper: styles.MainOfficeDialogboxPaper,
              scrollPaper: styles.MainOfficeScrollPaper,
            }}
            >
            <div className = {styles.DialogBoxTitle}>
              <Typography component="h1" variant="h1">
              Warning
              </Typography>
            </div>
            <div className = {styles.DialogBoxContext}>
              <p>
                Would you really want to delete other office details that already
                exist?
              </p>
            </div>
          </DialogBox>
          <Grid item xs={12} md={6} lg={4}>
            <Paper className={styles.paper}>
              <Office
                name="Main Office"
                key={0}
                index={0}
                data={dataObj}
                handleChange={officeHandleChange}
              />
              <Grid item xs={12} className={styles.OtherOfficeWrap}>
                {piiFieldError ? (
                  <div className={styles.errormsg}>
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Please Select PII Fields.
                    </FormHelperText>
                  </div>
                ) : null}
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Do you have other offices?
                  </FormLabel>
                  <div className={styles.pgswitch}>
                    <Switch
                      className={
                        haveOtherOffice
                          ? styles.PiiDataSwitch
                          : styles.PiiDataSwitchCheck
                      }
                      id="toggleSwitch"
                      checked={haveOtherOffice}
                      onChange={handleOtherOffice}
                      name="office"
                      {...props}
                    ></Switch>
                    <span className={styles.PiiDataSwitchLabels}>
                      <span className={styles.PiiDataSwitchNo}>NO</span>
                      <span className={styles.PiiDataSwitchYes}>YES</span>
                    </span>
                  </div>
                </FormControl>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button variant="contained" color="primary" onClick={handleBack}>
                {"Previous"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={submitDisabled}
              >
                {"Next"}
              </Button>
              <div className={styles.cancelButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCancel}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      
    </React.Fragment>
  );
};

export default MainOffice;
