import { gql } from "@apollo/client";

export const GET_APPLICABLE_TO = gql`
  query($where: JSON, $sort: String) {
    applicableTos(where: $where, sort: $sort) {
      id
      type
    }
  }
`;
