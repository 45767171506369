import { gql } from "@apollo/client";

export const CREATE_PIIDATA = gql`
  mutation CreatePiiData($name: String!, $category: ID! , $is_active: Boolean!) {
    createPiiDatum(
      input: {
        data: { name: $name, category: $category, is_active: $is_active }
      }
    ) {
      piiDatum {
        id
        name
        category {
          id
        }
        is_active
      }
    }
  }
`;

export const UPDATE_PIIDATA = gql`
  mutation UpdatePiiData(
    $id: ID!
    $name: String!
    $category: ID! 
    $is_active: Boolean!
  ) {
    updatePiiDatum(
      input: {
        where: { id: $id }
        data: { name: $name, category: $category, is_active: $is_active }
      }
    ) {
      piiDatum {
        id
        name
        category {
          id
        }
        is_active
      }
    }
  }
`;

export const DELETE_PIIDATA = gql`
  mutation DeletePiiData($id: ID!) {
    deletePiiDatum(input: { where: { id: $id } }) {
      piiDatum {
        id
        name
        category {
          id
        }
        is_active
      }
    }
  }
`;
