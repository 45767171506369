import * as routeConstants from "../common/RouteConstants";

export const sideDrawerMenus = [
  {
    title: "Dashboard",
    path: routeConstants.DASHBOARD,
    icon: "dashboard",
    //   <img
    //   src={process.env.PUBLIC_URL + "/icons/svg-icon/dashboard.svg"}

    //   alt="user icon"
    // />,

    pathList: [routeConstants.DASHBOARD],
  },
  {
    title: "Clients",
    path: routeConstants.CLIENT,
    icon: "users",
    pathList: [
      routeConstants.COMPLIANCE_LIST,
      routeConstants.CLIENT,
      routeConstants.COMPANY_INFO,
      routeConstants.MAIN_OFFICE,
      routeConstants.OTHER_OFFICE,
      routeConstants.CUSTOMER,
      routeConstants.REVIEW,
      routeConstants.COMPLIANCE,
      routeConstants.TASK_DETAILS,
      routeConstants.TARGET,
      routeConstants.REPORT_STATUS,
      routeConstants.RA_REPORT_LISTING,
    ],
  },
  {
    title: "Users",
    path: routeConstants.PARTNER_USER,
    icon: "partners",
    pathList: [
      routeConstants.PARTNER_USER,
      routeConstants.PARTNER_USER_FORM_EDIT,
      routeConstants.PARTNER_USER_FORM,
    ],
  },
  // {
  //   title: "Help",
  //   path: "",
  //   icon: "helpmanual",
  //   pathList: [""],
  // },
];

export const sideDrawerAdminMenus = [
  {
    title: "Dashboard",
    path: routeConstants.ADMIN_DASHBOARD,
    icon: "dashboard",
    pathList: [routeConstants.ADMIN_DASHBOARD],
  },
  {
    title: "Partners",
    path: routeConstants.ADD_PARTNER,
    icon: "partners",
    pathList: [routeConstants.ADD_PARTNER],
  },
  {
    title: "Admin",
    type: "admin",
    path: routeConstants.TEMPLATE,
    icon: "users",
    pathList: [
      routeConstants.TEMPLATE,
      routeConstants.LAW,
      routeConstants.STATE,
      routeConstants.ORGANIZATION_TYPE,
      routeConstants.RULE,
      routeConstants.POLICY,
      routeConstants.PIIDATA,
      routeConstants.PIICATEGORY,
    ],
    children: [
      {
        title: "Cyber Compliance",
        type: "cc",
        path: routeConstants.TEMPLATE,
        icon: "documents",
        pathList: [
          routeConstants.TEMPLATE,
          routeConstants.LAW,
          routeConstants.STATE,
          routeConstants.ORGANIZATION_TYPE,
          routeConstants.RULE,
          routeConstants.POLICY,
          routeConstants.PIIDATA,
          routeConstants.PIICATEGORY,
        ],
        children: [
          {
            title: "State",
            path: routeConstants.STATE,
            icon: "resource",
            pathList: [routeConstants.STATE],
          },
          {
            title: "Organization Type",
            path: routeConstants.ORGANIZATION_TYPE,
            icon: "resource",
            pathList: [routeConstants.ORGANIZATION_TYPE],
          },
          {
            title: "PII Category",
            path: routeConstants.PIICATEGORY,
            icon: "resource",
            pathList: [routeConstants.PIICATEGORY],
          },
          {
            title: "PII Data",
            path: routeConstants.PIIDATA,
            icon: "resource",
            pathList: [routeConstants.PIIDATA],
          },
          {
            title: "Template",
            path: routeConstants.TEMPLATE,
            icon: "resource",
            pathList: [routeConstants.TEMPLATE],
          },
          {
            title: "Policy",
            path: routeConstants.POLICY,
            icon: "resource",
            pathList: [routeConstants.POLICY],
          },
          {
            title: "Law",
            path: routeConstants.LAW,
            icon: "resource",
            pathList: [routeConstants.LAW],
          },

          {
            title: "Rule",
            path: routeConstants.RULE,
            icon: "resource",
            pathList: [routeConstants.RULE],
          },
        ],
      },
    ],
  },
  {
    title: "Help",
    path: "",
    icon: "prospecting",
    pathList: [""],
  },
];

export const topStepperMenu = [
  {
    title: "Company Info",
    path: routeConstants.COMPANY_INFO,
    icon: "tag",
  },
  {
    title: "Main Office",
    path: routeConstants.MAIN_OFFICE,
    icon: "speedSend",
  },
  {
    title: "Other Office",
    path: routeConstants.OTHER_OFFICE,
    icon: "speedSend1",
  },
  {
    title: "Customer",
    path: routeConstants.CUSTOMER,
    icon: "automation",
  },
  {
    title: "Review",
    path: routeConstants.REVIEW,
    icon: "template",
  },
  {
    title: "Summary",
    path: routeConstants.COMPLIANCE,
    icon: "template2",
  },
  // {
  //   title: "Logout",
  //   path: routeConstants.LOGOUT_URL,
  //   icon: "template1",
  // },
];