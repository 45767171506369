import { gql } from "@apollo/client";
// GEt user Details from PG id
export const GET_USER_DETAILS = gql`
query individualsConnection($pg_user_id: Int) {
    individualsConnection(where: {pg_user_id: $pg_user_id}) {
      values {
        id
        pg_user_id
        partner_id {
          id
          name
          contact_type
          email
        }
        contact_id {
          id
          name
          contact_type
        }
      }
    }
  }
  
`;
