// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Office_pqr__3rHOY {\n  border: 1px solid #0051a8;\n  border-radius: 10px;\n  margin-top: 10px !important;\n  margin-bottom: 10px !important;\n}\n\n/* h1 {\n  color: #002F60 !important;\n  padding: 0;\n  text-align: left;\n  font-family: 'Roboto', sans-serif !important;\n  font-size: 24px !important;\n} */\n\n.Office_Empnum__2d5iB {\n  width: 60%;\n}\n\n.Office_FormHelperText__2EVQE {\n  color: #e53935 !important;\n}\n\n.Office_errormsg__38aBx {\n  display: inline-block !important;\n  width: 100% !important;\n}", ""]);
// Exports
exports.locals = {
	"pqr": "Office_pqr__3rHOY",
	"Empnum": "Office_Empnum__2d5iB",
	"FormHelperText": "Office_FormHelperText__2EVQE",
	"errormsg": "Office_errormsg__38aBx"
};
module.exports = exports;
