// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Dropdown_Dropdown__1GWNM {\n  display: flex;\n}\n\n.Dropdown_Label__3bQFT {\n  width: 50%;\n  align-self: center;\n  font-weight: bold;\n}\n\n.Dropdown_InputBorder__3OToF {\n  border-radius: 12px !important;\n  border-width: 2px !important;\n}\n\n.Dropdown_ReactDropdown__3iort:focus, .Dropdown_ReactDropdown__3iort:hover fieldset, .Dropdown_ReactDropdown__3iort fieldset {\n  border-color: #0051A8 !important;\n  border-width: 1px !important;\n  border-radius: 10px !important;\n  background-color: transparent !important;\n}\n\n.Dropdown_ReactDropdown__3iort {\n  background-color: #fff;\n  border-radius: 12px !important;\n}\n\n.Dropdown_ReactDropdown__3iort>div {\n  background-color: transparent !important;\n  padding: 5px 7px !important;\n  line-height: 1.1876em;\n  color: #707070 !important;\n  font-size: 14px;\n  transform: translate(0px, 11px) scale(1);\n  height: 37px;\n}\n\n.Dropdown_Dropdown__1GWNM label {\n  color: #707070 !important;\n  font-size: 14px;\n  transform: translate(14px, 17px) scale(1)\n}\n\n.Dropdown_ReactDropdown__3iort fieldset legend {\n  color: #707070 !important;\n  font-size: 14px;\n}", ""]);
// Exports
exports.locals = {
	"Dropdown": "Dropdown_Dropdown__1GWNM",
	"Label": "Dropdown_Label__3bQFT",
	"InputBorder": "Dropdown_InputBorder__3OToF",
	"ReactDropdown": "Dropdown_ReactDropdown__3iort"
};
module.exports = exports;
