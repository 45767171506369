import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
  query getCustomer($where: JSON) {
    customers(where: $where) {
      id
      state {
        id
        name
      }
      no_of_clients
      collect_pii_information
      pii_data_fields {
        id
        name
      }
      contact_id {
        id
        name
        contact_type
      }
    }
  }
`;

// export const GET_CUSTOMER = gql`
//   query getCustomer($contact_id: ID!) {
//     customers(where: { contact_id: $contact_id }) {
//       id
//       state {
//         id
//         name
//       }
//       no_of_clients
//       collect_pii_information
//       pii_data_fields {
//         id
//         name
//       }
//       contact_id {
//         id
//         name
//         contact_type
//       }
//     }
//   }
// `;