import React, { useState, useEffect } from "react";
import styles from "./PgAction.module.css";
import { USER_LOGIN } from "../../../graphql/mutations/User";
import { Button } from "../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../common/RouteConstants";
import { Typography } from "@material-ui/core";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_ROLE } from "../../../graphql/queries/User";
import { CompanyUser } from "../../../common/Roles";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { GET_CONTACT } from "../../../graphql/queries/Contact";
import { GET_INDIVIDUAL } from "../../../graphql/queries/Individual";
import { GET_PARTNER_SUBSCRIPTION } from "../../../graphql/queries/PartnerSubscription";
import Paper from "@material-ui/core/Paper";

// Pop-up box
import FormControl from "@material-ui/core/FormControl";
import Input from "../../../components/UI/Form/Input/Input";
import { validate } from "graphql";
import Cookies from 'js-cookie';

export const PgAction: React.FC = (props: any) => {
  // const [userRole, setUserRole] = useState();
  const history = useHistory();
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);

  // Graphql
  const [login, { data }] = useMutation(USER_LOGIN);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [ccSubscription, setCCSubscription] = useState<any>(false);
  const [raSubscription, setRASubscription] = useState<any>(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [loginResponse, setLoginResponse] = useState<any>({});
  const [contactResponse, setContactResponse] = useState<any>({});
  const [app, setApp] = useState<any>();
  const [userRes, setUserRes] = useState<any>();

  useEffect(() => {
    // getRole();
  }, []);

  const PartnerAdd=()=>{
    // onLogin()
    history.push("/pg-partner-form/add")
  };

  const PartnerUpdate=()=>{
    // onLogin()
    history.push("/pg-partner-form/edit/")
  };

  const PartnerDelete=()=>{
    // onLogin()
    history.push("/pg-delete-partner")
  };

  const AddPartnerUser = () => {
    history.push("/pg-partner-user-form");
  };
  
  const updatePartnerUser = () => {
    history.push("/pg-partner-user-form/edit/");
  };
  const deletePartnerUser = () => {
    history.push("/pg-delete-partner-user");
  }


  // Client
  const addClient = () => {
    history.push("/pg-client-form/add");
  }
  const updateClient = () => {
    history.push("/pg-client-form/edit");

  }
  const deleteClient = () => {
    history.push("/pg-client-form/delete");
  }

  // On App selection
  const handleClickLogin = () => {
    loginQuery(email, password);
  }

  const loginQuery = (user: any, pass: any) => {
    login({
      variables: {
        identifier: user,
        password: pass,
      },
    })
      .then((userRes) => {
        setUserRes(userRes.data.login);
        setLoginResponse(userRes.data.login);
        getContact({
          variables: {
            id: userRes.data.login.user.id,
          },
        });
      })
      .catch((Error) => {});
  };

  const [getContact, { data: dataCo, loading: loadingCO }] = useLazyQuery(GET_CONTACT, {
    fetchPolicy: "cache-and-network",
    onCompleted (data: any) {
      if (data.contacts.length !== 0) {
        setContactResponse(data.contacts[0]);
        getIndividual({
          variables: {
            where: { contact_id: data.contacts[0].id },
          },
        });
        
      }
    },
    onError (err: any) { }
  });

  const [getIndividual, { data: iData, loading: iLoading }] = useLazyQuery(GET_INDIVIDUAL, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: any) => {
      if (data.individuals.length !== 0) {
        getPartnerSubs({
          variables: {
            where: { contact_id: data.individuals[0].partner_id.id },
          },
        });
      }
    },
    onError (err: any) { }
  });

  const [getPartnerSubs, { data: dataPSubs, loading: loadPSubs }] = useLazyQuery(GET_PARTNER_SUBSCRIPTION, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: any) => {
      if (loginResponse.user.role.name === CompanyUser) {
        if (ccSubscription && data.partnerSubscriptions[0].cc_subscription) {
          navigateTo();
        }
        else if (raSubscription && data.partnerSubscriptions[0].ra_subscription) {
          navigateTo();
        }
        else {
          setInvalidLogin(true);
          Cookies.remove('cc_user', { path: '', domain: process.env.REACT_APP_DOMAIN });
          Cookies.remove('cc_session', { path: '', domain: process.env.REACT_APP_DOMAIN });
          Cookies.remove('cc_contact', { path: '', domain: process.env.REACT_APP_DOMAIN });
          Cookies.remove('cc_app', { path: '', domain: process.env.REACT_APP_DOMAIN });
        }
      }
    },
    onError (err: any) { }
  });

  const navigateTo = () => {
    Cookies.set('cc_user', JSON.stringify(userRes));
    Cookies.set('cc_session', userRes.jwt);
    Cookies.set('cc_contact', JSON.stringify(contactResponse));
    Cookies.set('cc_app', app);
    window.location.replace(routeConstant.CLIENT);
  }

  const handleValueChange = (event: any) => {
    if (event.target.name === "email") {
      setEmail(event.target.value)
    }
    if (event.target.name === "password") {
      setPassword(event.target.value)
    }
  }

  const onSelectSubscription = (val: any) => {
    setInvalidLogin(false);
    setApp(val);
    if (val === "CC360") {
      setCCSubscription(true)
    } else {
      setCCSubscription(false)
    }
    if (val === "RA360") {
      setRASubscription(true)
    } else {
      setRASubscription(false)
    }
  }

  return (
    <React.Fragment>
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h5" variant="h1">
              {"Partner"}
            </Typography>
            <Button
              onClick={PartnerAdd}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
            <Button
              onClick={PartnerUpdate}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
            <Button
              onClick={PartnerDelete}
              variant="contained"
              color="primary"
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h5" variant="h1">
              {"Partner User"}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={AddPartnerUser}
            >
              {"Add"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={updatePartnerUser}
            >
              {"Update"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={deletePartnerUser}
            >
              {"Delete"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h5" variant="h1">
              {"Client"}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={addClient}
            >
              {"Add"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={updateClient}
            >
              {"Update"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={deleteClient}
            >
              {"Delete"}
            </Button>
          </Grid>
        </Grid>
        <Grid container className={styles.loginBorder}>
          <Grid item xs={12} sm={4}>
            <Typography component="h5" variant="h1">
              {"Login"}
            </Typography>
            <Grid xs={12} md={7} lg={5}>
              <FormControl className={styles.TextField} variant="outlined">
                <Input
                  type="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  id="email-id"
                  onChange={handleValueChange}
                  data-testid="email-id"
                  classes={{
                    root: styles.InputField,
                    notchedOutline: styles.InputField,
                    focused: styles.InputField,
                  }}
                >
                  E-mail
                </Input>
              </FormControl>
            </Grid>
            <Grid xs={12} md={7} lg={5}>
              <FormControl className={styles.TextField} variant="outlined">
                <Input
                  type="password"
                  label="Password"
                  name="password"
                  value={password}
                  id="email-id"
                  onChange={handleValueChange}
                  classes={{
                    root: styles.InputField,
                    notchedOutline: styles.InputField,
                    focused: styles.InputField,
                  }}
                >
                  Password
                </Input>
              </FormControl>
            </Grid>
            {invalidLogin ? (
              <span>
                {ccSubscription || raSubscription ?
                  <div className={styles.Errors}>
                    You do not have subscription of {ccSubscription ? "CC360" : (raSubscription ? "RA360" : null)}.
                  </div>
                : null }
                {!ccSubscription && !raSubscription ?
                  <div className={styles.Errors}>
                    Please select any Subscription.
                  </div>
                : null}
              </span>
            ) : null}
            <Grid className={styles.subscriptionGrid}>
              <div className={styles.TabOptions}>
                <Button
                  className={ccSubscription ? styles.TabOptionsActive : styles.TabOptionsInactive}
                  onClick={() => onSelectSubscription('CC360')}
                >
                  <div className={ccSubscription ? styles.para : styles.TabOptionsInactive}>
                    {"CC360"}
                  </div>
                </Button>
                {/* <Button
                  className={raSubscription ? styles.TabOptionsActive : styles.TabOptionsInactive}
                  onClick={() => onSelectSubscription("RA360")}
                >
                  <div className={raSubscription ? styles.para : styles.TabOptionsInactive}>
                    {"RA360"}
                  </div>
                </Button> */}
              </div>
            </Grid>
            <Grid className={styles.FooterActions}>
              <Button
                variant="contained"
                color="primary"
                name="login"
                onClick={handleClickLogin}
              >
                {"Login"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
export default PgAction;
