import { gql } from "@apollo/client";

export const DELETE_COMPLIANCE = gql`
mutation DeleteComplaince($ContactId: ID!, $ComplainceInfoId: ID!, $AddressesId: ID, $OrgainizationId: ID) {
  deleteCustomer(input: {where: {id: $ContactId}}) {
    customer {
      id
    }
  }
  deleteComplianceInfo(input: {where: {id: $ComplainceInfoId}}) {
    complianceInfo {
      id
      contact_id {
        id
      }
    }
  }
  deleteAddress(input: {where: {id: $AddressesId}}) {
    address {
      id
    }
  }
  deleteOrganizationLocation(input: {where: {id: $OrgainizationId}}) {
    organizationLocation {
      id
    }
  }
}
    `;
    
  
  