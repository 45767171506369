// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*.layout {\n  width: 80%;\n}*/\n\n.CompanyForm_paper__1NIvm {\n  margin-bottom: 20px;\n  padding: 28px 25px 17px 25px;\n  background-color: #FFF;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);\n  border-radius: 20px !important;\n  border: 1px solid #0051A8;\n}\n\n.CompanyForm_FormHelperText__3LU9B {\n  color: #e53935 !important;\n  padding: 0px 0px 10px 0px !important\n}\n\n/* .CompanyProfile {\n  width: 300px !important;\n} */\nform.CompanyForm_reviewPageDialogBox__WFpFX {\n  width: 337px;\n}\n.CompanyForm_CompanyProfileForm__zpuxd > div{\n  max-width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"paper": "CompanyForm_paper__1NIvm",
	"FormHelperText": "CompanyForm_FormHelperText__3LU9B",
	"reviewPageDialogBox": "CompanyForm_reviewPageDialogBox__WFpFX",
	"CompanyProfileForm": "CompanyForm_CompanyProfileForm__zpuxd"
};
module.exports = exports;
