import { gql } from '@apollo/client';

export const CREATE_INDIVIDUAL = gql`
  mutation CreateIndividual(
    $first_name: String!
    $last_name: String!
    $contact_id: ID!
    $partner_id: ID
  ) {
    createIndividual(
      input: {
        data: {
          first_name: $first_name
          last_name: $last_name
          contact_id: $contact_id
          partner_id: $partner_id
        }
      }
    ) {
      individual {
        id
        first_name
        last_name
        pg_user_id
        partner_id {
          id
          name
          email
        }
      }
    }
  }
`;

export const UPDATE_INDIVIDUAL = gql`
  mutation UpdateIndividual(
    $id :ID!
    $first_name: String!
    $last_name: String!
    $contact_id: ID!
  ) {
    updateIndividual(
      input: { where: { id: $id }
        data: {
          first_name: $first_name
          last_name: $last_name
          contact_id: $contact_id
        }
      }
    ) {
      individual {
        id
        first_name
        last_name
        partner_id {
          id
          name
          email
        }
      }
    }
  }
`;
export const PG_CREATE_INDIVIDUAL = gql`
  mutation CreateIndividual(
    $first_name: String!
    $last_name: String!
    $contact_id: ID!
    $partner_id: ID
    $pg_user_id : Int!
  ) {
    createIndividual(
      input: {
        data: {
          first_name: $first_name
          last_name: $last_name
          contact_id: $contact_id
          partner_id: $partner_id
          pg_user_id : $pg_user_id
        }
      }
    ) {
      individual {
        id
        first_name
        last_name
        partner_id {
          id
          name
          email
        }
      }
    }
  }
`;

export const PG_DELETE_INDIVIDUAL = gql`
mutation deleteIndividual (
  $id : ID!
) {
  deleteIndividual(input: {where: {id: $id}}) {
    individual {
      id
      contact_id {
        id
      }
    }
  }
}
`;