import { gql } from "@apollo/client";

export const CREATE_ORGANIZATIONTYPE = gql`
  mutation CreateOrganizationType(
    $name: String!
    $sub_type: ID
    $is_active: Boolean!
  ) {
    createOrganizationType(
      input: {
        data: { name: $name, sub_type: $sub_type, is_active: $is_active }
      }
    ) {
      organizationType {
        id
        name
        sub_type {
          id
        }
        is_active
      }
    }
  }
`;

export const UPDATE_ORGANIZATIONTYPE = gql`
  mutation UpdateOrganizationType(
    $id: ID!
    $name: String!
    $sub_type: ID
    $is_active: Boolean!
  ) {
    updateOrganizationType(
      input: {
        where: { id: $id }
        data: { name: $name, sub_type: $sub_type, is_active: $is_active }
      }
    ) {
      organizationType {
        id
        name
        sub_type {
          id
        }
        is_active
      }
    }
  }
`;

export const DELETE_ORGANIZATIONTYPE = gql`
  mutation DeleteOrganizationType($id: ID!) {
    deleteOrganizationType(input: { where: { id: $id } }) {
      organizationType {
        id
        name
        sub_type {
          id
        }
        is_active
      }
    }
  }
`;
