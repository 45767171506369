import { gql } from "@apollo/client";

export const GET_RULE = gql`
  query($where: JSON, $sort: String) {
    rules(where: $where, sort: $sort) {
      id
      name
      policy_description
      workplan_description
      category {
        id
        name
        template_description
      }
      laws {
        id
        name
      }
      policy_content
      workplan_content
      rule_no
      Target
    }
  }
`;
