import { gql } from "@apollo/client";

export const GET_CSET_ASSESSMENT = gql`
query csetAssessments($where: JSON) {
    csetAssessments(where: $where) {
      assessment_name
      assessment_id
      assessment_type
      cset_firm_id {
        id
        name
        sal
        firm_size
        industry {
          name
          industry_id
        }
        relative_effort {
          demographics_id
          description
          size
        }
        sector {
          sector_id
          name
        }
        cset_standards {
          id
          name
          code
        }
      }
      id
      allow_download
      updated_at
      created_at
      assessment_document{
        url
      }
    }
  }
  `;