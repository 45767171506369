import React, { useEffect, useState } from "react";
import styles from "./ClientForm.module.css";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Button } from "../../../components/UI/Form/Button/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
import Input from "../../../components/UI/Form/Input/Input";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Alert from "../../../components/UI/Alert/Alert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  SUCCESS,
  UPDATE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import * as validations from "../../../common/validateRegex";
import { useHistory } from "react-router-dom";
import * as routeConstant from "../../../common/RouteConstants";
import { GET_INDIVIDUAL } from "../../../graphql/queries/Individual";
import { GET_PARTNER_SUBSCRIPTION } from "../../../graphql/queries/PartnerSubscription";
import { GET_ORGANIZATION } from "../../../graphql/queries/Organization";
import { GET_CLIENT_SUBSCRIPTION } from "../../../graphql/queries/ClientSubscription";
import logout from "../../../containers/Auth/Logout/Logout";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_CONTACT,
  UPDATE_CONTACT,
} from "../../../graphql/mutations/Contacts";
import {
  CREATE_ORG,
  UPDATE_ORG,
} from "../../../graphql/mutations/Organization";
import {
  CREATE_CLIENT_SUBSCRIPTION,
  UPDATE_CLIENT_SUBSCRIPTIONS,
} from "../../../graphql/mutations/ClientSubscription";
import { CSET_URL } from "../../../config/index";
import { CreateCSETUser } from "../../../graphql/mutations/CSETUserDetails";

export const ClientForm: React.FC = (props: any) => {
  const history = useHistory();
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [CCsubscription, setCCsubscription] = useState<any>(false);
  const [RAsubscription, setRAsubscription] = useState<any>(false);
  const [subscriptionList, setSubscriptionList] = useState<any>();
  const [isError, setIsError] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [ContactId, setContactId] = useState("");
  const [clientSubsID, setClientSubsID] = useState<any>();
  const [error, setError] = useState<any>(true);

  const contact = JSON.parse(localStorage.getItem("cc_contact") || "{}");
  const headerObj = {
    "Content-Type": "application/json",
    "Accept": "*/*",
  };
  let RegistrationUserData = {
    PrimaryEmail: email,
    ConfirmEmail: email,
    FirstName: firstName,
    LastName: lastName,
    IsSuperUser: "false",
    PasswordResetRequired: "false",
    Password: "Musu30@in",
    Salt: "rGv21R7m7MFLedv4VLkKFuNQ46yJdlZZ",
  };
  let NewCSetUserLoginData = {
    Email: email,
    Password: "Musu30@in",
    TzOffset: new Date().getTimezoneOffset(),
    Scope: "CSET",
  };
  let paramVal = {
    formState : {}
  }
  const [createContact] = useMutation(CREATE_CONTACT);
  const [UpdateContact] = useMutation(UPDATE_CONTACT);
  const [createOrganization] = useMutation(CREATE_ORG);
  const [clientSubscription] = useMutation(CREATE_CLIENT_SUBSCRIPTION);
  const [updateClientSubscription] = useMutation(UPDATE_CLIENT_SUBSCRIPTIONS);
  const [createCSETUser] = useMutation(CreateCSETUser);
  const [param, setParam] = useState<any>();

  useEffect(() => {
    console.log("clients props >", props.location.state);
      getIndividual({
        variables: {
          where: { partner_id_null: false, contact_id: contact.id },
        },
      });
      if (
        props.location.state != undefined &&
        props.location.state.first_name
      ) {
        setParam(props.location.state);
      }
  }, []);

  useEffect(() => {
    if (param != undefined) {
      setFirstName(param.first_name);
      setLastName(param.last_name);
      setEmail(param.email);
    if (param.phone && param.phone != "-") {
      setPhoneNumber(param.phone);
    }
      setCCsubscription(param.cc_subs);
      // setCSETsubscription(param.cset_subs);
      setRAsubscription(param.ra_subs);
      setContactId(param.clientId);
      setClientSubsID(param.clientSubsId)
    }
  }, [param]);

  useEffect(() => {
    console.log("formstate",formState)
    if (formState.isSuccess === true || formState.isUpdate === true && !error) {
         paramVal = {
          formState : formState
        }
        backToList();
    }
  }, [formState]);
  const handleSubscription = (
    event: any,
    value: React.SetStateAction<boolean>
  ) => {
    if (event.target.name === "CCsubscription") {
      setCCsubscription(value);
    }
    if (event.target.name === "RAsubscription") {
      setRAsubscription(value);
    }
  };
  const actionOnSubscription = (id: any) => {
    // If only client has been created without any Subscription
    if (!RAsubscription || !CCsubscription) {
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: " " + firstName + " " + lastName + " ",
      }));
      // backToList();
    }

    if (RAsubscription) {
      addCSetUser(id);
    }

    if (RAsubscription) {
      setError(false);
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: " " + firstName + lastName + " ",
      }));
      // backToList();
    }

    if (CCsubscription) {
      setError(false);
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: " " + firstName + lastName + " ",
      }));
      // backToList();
    }
  };
  const addCSetUser = async (Clientid: any) => {
    try {
      await fetch(CSET_URL + "api/ResetPassword/RegisterUser", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(RegistrationUserData),
      })
        .then(async (data: any) => {
          await fetch(CSET_URL + "api/auth/login", {
            method: "POST",
            headers: headerObj,
            body: JSON.stringify(NewCSetUserLoginData),
          })
            .then((response) => response.json())
            .then((data) => {
              createCSETUser({
                variables: {
                  username: email,
                  password: "Musu30@in",
                  contact_id: Clientid,
                  cset_user_id: data.UserId.toString(),
                },
              })
                .then((CSETUserLoginDetailsResponse: any) => {
                  setError(false);
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: true,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: false,
                    errMessage: " " + firstName + " "+ lastName + " ",
                  }));
                  // backToList();
                })
                .catch((error) => {
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: false,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: true,
                    errMessage: " Registration Failed " ,
                  }));
                  // console.log("Error !!!",error)
                });
              localStorage.setItem("ra_user_data", JSON.stringify(data.Token));
            });
        })
        .catch((error: any) => {
          setError(true);
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: " Registration Failed " ,
          }));
          // console.log("error",error)
        });
    } catch (e) {
      setError(true);
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: " Registration Failed " ,
      }));
      console.error("error", e);
    }
  };
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "FirstName") {
      setFirstName(event.target.value);
      let err =
        event.target.value === "" || null ? "First name is required" : "";
      setIsError((error: any) => ({
        ...error,
        err: true,
        firstName: err,
      }));
    }
    if (event.target.name === "LastName") {
      setLastName(event.target.value);
      let err =
        event.target.value === "" || null ? "Last name is required" : "";
      setIsError((error: any) => ({
        ...error,
        lastName: err,
      }));
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);
      if (event.target.value === "" || event.target.value === null) {
        let err = event.target.value === "" || null ? "Email is required" : "";
        setIsError((error: any) => ({
          ...error,
          email: err,
        }));
      } else if (
        event.target.value &&
        !validations.EMAIL_REGEX.test(event.target.value)
      ) {
        let errors = "Please enter valid email address.";
        setIsError((error: any) => ({
          ...error,
          email: errors,
        }));
      } else {
        setIsError({ error: null });
      }
    }
    if (event.target.name === "phoneNumber") {
      setPhoneNumber(event.target.value);
    }
    setSubmitDisabled(checkValidation);
  };
  const checkValidation = () => {
    let validation = false;
    return validation;
  };
  const handleInputErrors = () => {
    let error = true;
    if (firstName === "") {
      error = false;
      setIsError((error: any) => ({
        ...error,
        firstName: "First name is required",
      }));
    }
    if (lastName === "") {
      error = false;
      setIsError((error: any) => ({
        ...error,
        lastName: "Last name is required",
      }));
    }
    if (email === "") {
      error = false;
      setIsError((error: any) => ({
        ...error,
        email: "Email is required",
      }));
    }
    if (email !== "" && email && !validations.EMAIL_REGEX.test(email)) {
      let errors = "Please enter valid email address.";
      setIsError((error: any) => ({
        ...error,
        email: errors,
      }));
      error = false;
    }
    return error;
  };
  const handleSubmit = () => {
    if (handleInputErrors()) {
      if (props.location.pathname.includes("/client-form/add")) {
        //   if (ContactId === "") {
        createContact({
          variables: {
            name: firstName,
            email: email,
            phone: phoneNumber,
            contact_type: "Organization",
            last_name: lastName,
          },
        })
          .then((contRes: any) => {
            setContactId(contRes.data.createContact.contact.id)
            createOrganization({
              variables: {
                contact_id: contRes.data.createContact.contact.id,
                subtype: "Client",
                partner_id: contact.id,
              },
            })
              .then((orgRes: any) => {
                clientSubscription({
                  variables: {
                    contact_id: contRes.data.createContact.contact.id,
                    CCSubscription: CCsubscription,
                    RAsubscription: RAsubscription,
                  },
                }).then((res: any) => {
                  actionOnSubscription(contRes.data.createContact.contact.id);
                });
              })
              .catch((err) => {
                setError(true);
                console.log("Error> ", err);
                let error = err.message;
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: error,
                }));
              });
          })
          .catch((err) => {
            setError(true);
            console.log("Error> ", err);
            let error = err.message;
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: error,
            }));
          });
      }
        else {
          setSubmitDisabled(true);
          UpdateContact({
            variables: {
              id: ContactId,
              name: firstName,
              last_name: lastName,
              email: email,
              phone: phoneNumber,
              contact_type: "Organization",
            },
          })
            .then((userRes) => {
              setError(false);
              updateClientSubscription({
                variables: {
                  id: clientSubsID,
                  contact_id: ContactId,
                  CCSubscription: CCsubscription,
                  RAsubscription: RAsubscription,
                },
              }).then((res: any) => {
                setError(false);
                actionOnSubscription(ContactId);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: true,
                  isDelete: false,
                  isFailed: false,
                  errMessage: " " + firstName + " " + lastName + " ",
                }));
                // backToList();
              })
              //No Need to Update Organization
            })
            .catch((err) => {
              setError(true);
              console.log("Error> ", err);
              let error = err.message;
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: error,
              }));
            });
        }
    }
  };
  const backToList = () => {
    console.log("formstate111",formState)
    history.push(routeConstant.CLIENT,paramVal);
    setIsError({ error: null });
    setContactId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setCCsubscription(false);
    setRAsubscription(false);
    // setCreateFlag(false);
  };
  const [
    getPartnerSubs,
    { data: dataPSubs, loading: loadPSubs },
  ] = useLazyQuery(GET_PARTNER_SUBSCRIPTION, {
    onCompleted: (data: any) => {
      setSubscriptionList(data.partnerSubscriptions[0]);
    },
    fetchPolicy: "cache-and-network",
    onError:()=>{
      history.push(routeConstant.CLIENT)
    }
  });
  const [
    getIndividual,
    { data: iData, error: iError, loading: iLoading, refetch: refetchOT  },
  ] = useLazyQuery(GET_INDIVIDUAL, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data:any) => {
      console.log("data",data)
      if(data.individuals == null){
        logout()
      }
      getPartnerSubs({
        variables: {
          where: { contact_id: iData.individuals[0].partner_id.id },
        },
      });
    },
    onError:()=>{
      console.log("data")  
      logout()
    }
  });
  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {<div> {param ? param.name : "Add Client"} </div>}
      </Typography>
      <AddEditForm
        handleOk={handleSubmit}
        // disabled={submitDisabled}
        handleCancel={backToList}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {FAILED}
                {formState.errMessage}
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="First Name *"
              name="FirstName"
              value={firstName}
              onChange={handleChange}
              error
              helperText={isError.firstName}
            >
              First Name
            </Input>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="Last Name *"
              name="LastName"
              value={lastName}
              onChange={handleChange}
              error
              helperText={isError.lastName}
            >
              Last Name
            </Input>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="Email *"
              name="email"
              value={email}
              onChange={handleChange}
              error
              helperText={isError.email}
            >
              E-mail
            </Input>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="Phone Number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleChange}
            >
              Phone Number
            </Input>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6} className={styles.SubscriptionBox}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={
                        subscriptionList
                          ? !subscriptionList.cc_subscription
                          : false
                      }
                      checked={CCsubscription}
                      onChange={handleSubscription}
                      name="CCsubscription"
                      color="primary"
                    />
                  }
                  label="CC Subscription"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6} className={styles.SubscriptionBox}>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={
                            subscriptionList
                              ? !subscriptionList.ra_subscription
                              : false
                          }
                          checked={RAsubscription}
                          onChange={handleSubscription}
                          name="RAsubscription"
                          color="primary"
                        />
                      }
                      label="RA Subscription"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AddEditForm>
    </React.Fragment>
  );
};

export default ClientForm;
