import React, { useState, useEffect } from "react";
import styles from "./Customer.module.css";
import { useHistory } from "react-router-dom";
import * as routeConstant from "../../common/RouteConstants";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Grid, FormHelperText, Typography } from "@material-ui/core";
import CustomerForm from "../CustomerForm/CustomerForm";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER
} from "../../graphql/mutations/Customer";
import { GET_COMPLIANCEINFO } from "../../graphql/queries/ComplianceInfo";
import { GET_CUSTOMER } from "../../graphql/queries/Customers";
import { GET_COMPLIANCE } from "../../graphql/queries/Compliance";
import { UPDATE_COMPLIANCE } from "../../graphql/mutations/Compliance";
import {
  getCompliance,
  setCompliance,
  returnArray,
  setOtherOffice,
  setActiveFormStep,
  getOtherOffice
} from "../../services/Data";
import { GET_ADDRESS } from "../../graphql/queries/Address";
import { Button } from "../../components/UI/Form/Button/Button";
import { useApolloClient } from "@apollo/client";
import { setStepper } from "../../common/SetStepper";
import stepper from "../../common/stepperMenu.json";
import logout from "../../containers/Auth/Logout/Logout";
import PiiDataForm from "../PiiDataForm/PiiDataForm";
import TopStepper from "../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import Alert from "../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import { getActiveFormStep } from "../../services/Data";
import { GET_REVIEW_INFO } from "../../graphql/queries/Review";

export const Customer: React.FC = (props: any) => {
  let stepperObject = stepper;
  const client = useApolloClient();
  const history = useHistory();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [orgContactId, setOrgContactId] = useState<any>();
  const [param, setParam] = useState<any>();
  const [doYouCollectPiiData, setDoYouCollectPiiData] = useState(false);
  const [showPiiData, setShowPiiData] = useState(false);
  const [piiArray, setPiiArray] = useState<any>([]);
  const [sameStateError, setSameStateError] = useState(false);
  const [showBackDrop, setShowBackdrop] = useState(true);
  const [disableAddCustomer, setDisableAddCustomer] = useState(true);
  const [stepperObj, setStepperObj] = useState<any>({path: ""});
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: ""
  });
  const [customerForm, setCustomerForm] = useState([
    {
      id: null,
      state: "",
      numOfCustomer: "",
      doYouCollectPiiData: false,
      piiArray: [],
      isError: {
        state: false,
        numOfCustomer: false
      }
    }
  ]);
  const [showLoading, setShowLoading] = useState(false);

  //Query and Mutation
  const [createCustomer] = useMutation(CREATE_CUSTOMER);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER);
  const [updateComplianceData, { data: dataCOM }] = useMutation(
    UPDATE_COMPLIANCE
  );

  const [
    getComplianceData,
    { data: complianceData, loading: complianceLoading }
  ] = useLazyQuery(GET_COMPLIANCE, {
    onCompleted: (data: any) => {
      setCompliance(complianceData.compliances);
    },
    fetchPolicy: "cache-and-network",
    onError:()=>{
      handleCancel()
    }
  });

  const fetchCompliance = (val: any) => {
    getComplianceData({
      variables: {
        where: { contact_id: val }
      }
    });
  };

  const [
    getComplianceInfo,
    { data: compInfo, error: compInfoErr, loading: compInfoLoad }
  ] = useLazyQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setOrgContactId(compInfo.complianceInfos[0].contact_id.id);
      getCustomer({
        variables: {
          where: { contact_id: compInfo.complianceInfos[0].contact_id.id }
        }
      });
      getaddress({
        variables: {
          where: {
            location_type: "Other",
            contact_id: compInfo.complianceInfos[0].contact_id.id
          }
        }
      });
      setSubmitDisabled(false);
    },
    onError:()=>{
      handleCancel()
    }
  });

  const [getCustomer, { data: dataCU, loading: locadingCU }] = useLazyQuery(
    GET_CUSTOMER,
    {
      fetchPolicy: "cache-and-network",
      onError:()=>{
        handleCancel()
      }
    }
  );

  const [getaddress, { data: dataAD, loading: loadingAD }] = useLazyQuery(
    GET_ADDRESS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        // check if there is other office available or not
        let val = dataAD.addresses.filter(
          (item: any) => item.location_type === "Other"
        );
        if (val.length !== 0) {
          setOtherOffice(true);
        } else {
          setOtherOffice(false);
        }
        setShowBackdrop(false)
      },
      onError:()=>{
        handleCancel()
      }
    }
  );

  const [ getReview, { data: reviewData, error: error } ] = useLazyQuery(GET_REVIEW_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: any) => {
      if (data.review.companyprofile.data.length > 0) {
        setActiveFormStep(0);
      }
      if (data.review.mainOffice.data.length > 0) {
        setActiveFormStep(1);
      }
      if (data.review.otherOffice.data.length > 0) {
        setActiveFormStep(2);
      }
      if (data.review.customer.data.length > 0) {
        setActiveFormStep(4);
      }
    },
    onError:()=>{
      handleCancel()
    }
  });

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      if (!sameStateError) {
        handleAlertClose();
      }
    }
  }, [sameStateError]);
  const handleAlertClose = () => {
    setFormState(formState => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: ""
    }));
  };

  const restoredata = () => {
    let customer: any = [];
    if (dataCU) {
      dataCU.customers.forEach((element: any, i: any) => {
        if (dataCU.customers[i] !== undefined) {
          customer.push({
            id: dataCU.customers[i].id,
            state: dataCU.customers[i].state,
            numOfCustomer: dataCU.customers[i].no_of_clients,
            doYouCollectPiiData: dataCU.customers[i].collect_pii_information,
            piiArray: getFields(dataCU.customers[i].pii_data_fields, "id"),
            isError: {
              state: false,
              numOfCustomer: false
            }
          });
        }
      });
      if (dataCU.customers[0] !== undefined) {
        if (dataCU.customers[0].collect_pii_information === true) {
          setDoYouCollectPiiData(true);
          setShowPiiData(true);
        }
        if (customer[0].piiArray.length !== 0) {
          setPiiArray(customer[0].piiArray);
        }
      }
      if (customer.length > 0) {
        setCustomerForm(customer);
        setDisableAddCustomer(false);
      }
    }
  };

  const getFields = (input: any, field: any) => {
    let output = [];
    for (let i = 0; i < input.length; ++i)
      output.push(parseInt(input[i][field]));
    return output;
  };

  useEffect(() => {
    console.log("Customer", props.location.state);
    setParam(props.location.state);
    if (
      props.location.state &&
      props.location.state.hasOwnProperty("complianceInfo")
    ) {
      getReview({
        variables: { ID: parseInt(props.location.state.complianceInfo.contactId) }
      });
      getComplianceInfo({
        variables: {
          where: {
            client_id: props.location.state.clientInfo.clientId,
            contact_id: props.location.state.complianceInfo.contactId,
            id: props.location.state.complianceInfo.id
          }
        }
      });
    }
    setStepper(
      client,
      stepperObject.customer.name,
      stepperObject.customer.value
    );
  }, []);

  
  const onErrorRedirection = ()=>{
    history.push(routeConstant.COMPLIANCE_LIST,param)
  };

  useEffect(() => {
    restoredata();
    if (dataCU !== undefined) {
      if (dataCU.customers.length >= 0) {
        setSubmitDisabled(false);
      }
      if (dataCU.customers.length === 0) {
        setSubmitDisabled(true);
      }
    }
  }, [dataCU]);

  if (compInfoErr) {
    let error = { message: "Error" };
    return <div className="error">Error!{logout()}</div>;
  }

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }

  // if (showBackDrop) return <SimpleBackdrop/>;

  let updatedObj = {};
  const verifyChange = () => {
    let comp = getCompliance();
    updatedObj = {};
    comp.map((compliance: any) => {
      if (compliance.allow_download === true) {
        updatedObj = {
          id: parseInt(compliance.id),
          contact_id: parseInt(compliance.contact_id.id),
          law: returnArray(compliance.laws),
          rule: returnArray(compliance.rules),
          policy: returnArray(compliance.policies),
          workplan: returnArray(compliance.workplans),
          address: parseInt(compliance.address_id.id),
          allowdownload: false,
          compliance_version: compliance.compliance_version
            ? parseInt(compliance.compliance_version.id)
            : compliance.compliance_version
        };
        updateCompliance(updatedObj);
      }
    });
  };

  const updateCompliance = (val: any) => {
    updateComplianceData({
      variables: val
    })
      .then(res => {})
      .catch(error => {
        setShowLoading(false);
        setSubmitDisabled(false);
      });
  };

  const appendACustomerForm = () => {
    setDisableAddCustomer(true);
    let customer = [...customerForm];
    customer.push({
      id: null,
      state: "",
      numOfCustomer: "",
      doYouCollectPiiData: false,
      piiArray: [],
      isError: {
        state: true,
        numOfCustomer: true
      }
    });
    customer.map((val: any, index: any) => {
      customer[index].piiArray = piiArray;
    });
    setCustomerForm(customer);
    setSubmitDisabled(true);
  };

  let foundError = true;
  const customerHandleChange = (event: any) => {
    setCustomerForm(event);
    setFormState(formState => ({
      ...formState,
      isFailed: false,
    }));
    if (isEmptyField(customerForm) || isSameState(customerForm)) {
      {/** Validation for same State */}
      if (isSameState(customerForm)) {
        setFormState(formState => ({
          ...formState,
          isFailed: true,
          errMessage: "State can not be same"
        }));
        setDisableAddCustomer(true);
        setSameStateError(true);
        setSubmitDisabled(true);
      } else {
        setFormState(formState => ({
          ...formState,
          isFailed: false,
        }));
        setSameStateError(false);
        setSubmitDisabled(false);
      }
      setSubmitDisabled(true);
    } else {
      setDisableAddCustomer(false);
      setSubmitDisabled(false);
    }
  };

  const isSameState = (param: any) => {
    let stateArray: any = [];
    param.map((val: any, index: any) => {
      if (val.state !== null) {
        if (val.state !== "") {
          stateArray.push(val.state.id);
        }
      }
    });
    let hasDuplicate = stateArray.some(
      (val: any, i: any) => stateArray.indexOf(val) !== i
    );
    return hasDuplicate;
  };

  const isEmptyField = (param: any) => {
    let hasError = false;
    param.map((element: any) => {
      if (element.numOfCustomer === "" || element.state === "" || element.state === null || (element.doYouCollectPiiData && element.piiArray.length === 0)) {
        hasError = true;
        setDisableAddCustomer(true);
      }
      // if (element.numOfCustomer === "" || element.state === null || (element.doYouCollectPiiData && element.piiArray.length === 0)) {
      //   hasError = true;
      //   setDisableAddCustomer(true);
      // }
    });
    return hasError;
  };

  const handleSubmit = () => {
    postCustomer();
  };

  let piiFieldErrors = false;
  if (doYouCollectPiiData && customerForm[0].piiArray.length === 0) {
    piiFieldErrors = true;
  }
  if (doYouCollectPiiData && customerForm[0].piiArray.length !== 0) {
    piiFieldErrors = false;
  }

  const postCustomer = () => {
    customerForm.map((val: any, i) => {
      if (
        customerForm[i].id === null &&
        customerForm[i].numOfCustomer !== "" &&
        piiFieldErrors !== true
      ) {
        let doYouCollectePiiInfo = false;
        if (val.piiArray.length > 0) {
          doYouCollectePiiInfo = true;
        }
        setShowLoading(true);
        setSubmitDisabled(true);
        createCustomer({
          variables: {
            contactId: orgContactId, // Organization's Contact Id
            noOfCustomer: parseInt(val.numOfCustomer),
            state: val.state.id,
            collectePiiInfo: doYouCollectePiiInfo,
            pii_data_field: val.piiArray
          }
        })
          .then(res => {
            handleNext();
          })
          .catch(err => {
            setShowLoading(false);
            setSubmitDisabled(false);
          });
      } else {
        handleNext();
      }
    });

    customerForm.map((val: any, i) => {
      let doYouCollectePiiInfo = false;
      if (val.piiArray.length > 0) {
        doYouCollectePiiInfo = true;
      }
      if (
        dataCU !== undefined &&
        dataCU.customers[i] !== undefined &&
        customerForm[i].numOfCustomer !== "" &&
        piiFieldErrors !== true
      ) {
        if (customerForm[i].id !== null) {
          setShowLoading(true);
          setSubmitDisabled(true);
          updateCustomer({
            variables: {
              id: dataCU.customers[i].id,
              contactId: orgContactId, // contact id of client
              noOfCustomer: parseInt(val.numOfCustomer),
              state: val.state.id,
              collectePiiInfo: doYouCollectePiiInfo,
              pii_data_field: val.piiArray
            }
          })
            .then(res => {
              verifyChange();
              handleNext();
            })
            .catch(err => {
              setShowLoading(false);
              setSubmitDisabled(false);
            });
        }
      } else {
        handleNext();
      }
    });
  };

  const removeCustomer = (index: number) => {
    let cust = [...customerForm];
    if (cust[index] !== undefined && cust[index].id !== null) {
      setDisableAddCustomer(true);
      setSubmitDisabled(true);
      deleteCustomer({
        variables: {
          id: cust[index].id
        }
      })
        .then(res => {})
        .catch(err => {
          setShowLoading(false);
          setSubmitDisabled(false);
        });
    }
    cust.splice(index, 1);
    setCustomerForm(cust);
    setDisableAddCustomer(false);
    setSubmitDisabled(false);
    setSameStateError(false);
  };

  const handleBack = () => {
    if (getOtherOffice()) {
      setStepper(
        client,
        stepperObject.otherOffice.name,
        stepperObject.otherOffice.value
      );
      history.push(routeConstant.OTHER_OFFICE, param);
    } else {
      setStepper(
        client,
        stepperObject.mainOffice.name,
        stepperObject.mainOffice.value
      );

      history.push(routeConstant.MAIN_OFFICE, param);
    }
  };

  const piiDataHandleChange = (event: any) => {
    if (event.target.name === "doYouCollectPiiData") {
      setDoYouCollectPiiData(event.target.checked);
      showHidePiiDataFields(event.target.checked);
    }
  };

  // Show and Hide pii data fields
  const showHidePiiDataFields = (type: string) => {
    if (type === "true") {
      setShowPiiData(true);
    } else {
      setPiiArray([]);
      customerForm.map((val: any, index: any) => {
        customerForm[index].piiArray = [];
      });
      setShowPiiData(false);
    }
  };

  // Create Pii values array
  let optPiiArray: any = [];
  const handleCheckElement = (event: any) => {
    let val = parseInt(event.target.value);
    optPiiArray = [...piiArray];
    if (optPiiArray.includes(val)) {
      let position = optPiiArray.indexOf(val);
      if (position >= 0) {
        optPiiArray.splice(position, 1);
      }
    } else {
      optPiiArray.push(val);
    }
    setPiiArray(optPiiArray);
    customerForm.map((val: any, index: any) => {
      customerForm[index].piiArray = optPiiArray;
    });
  };

  const handleNext = () => {
    if (!submitDisabled) {
      if (stepperObj.path && stepperObj.path !== "") {
        history.push(stepperObj.path, param);
      } else {
        setStepper(
          client,
          stepperObject.review.name,
          stepperObject.review.value
        );
        history.push(routeConstant.REVIEW, param);
      }
    }
  };

  const handleCancel = () => {
    let val = {
      clientInfo: param.clientInfo
    }
    history.push(routeConstant.COMPLIANCE_LIST, val);
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
    handleSubmit();
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {props.location.state
          ? "CyberCompliance for " + props.location.state.clientInfo.name
          : null}
      </Typography>
      <TopStepper
        navigate={!submitDisabled}
        param={param}
        handleSave={callingFromTopStepper}
        obj={stepperObj}
      />
      <Grid container>
        <Grid item xs={12} sm={8} className={styles.CustomerContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={styles.AlignRight}>
              <Button
                variant="contained"
                color="primary"
                onClick={appendACustomerForm}
                disabled={submitDisabled}
              >
                <AddCircleIcon className={styles.EditIcon}/>Customer
              </Button>
            </Grid>
          </Grid>
          {formState.isFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {/* {FAILED} */}
              {formState.errMessage}
            </Alert>
          ) : null}
          { enableBackdrop() ? <SimpleBackdrop /> : null }
            <Grid container spacing={3}>
              {customerForm.length > 0 &&
                customerForm.map((obj: any, i) => (
                  <Grid item xs={12} md={6} lg={6}>
                    <div key={obj.state ? obj.state.id : i}>
                      <Paper className={styles.paper}>
                        <Grid>
                          {i > 0 ? (
                            <div className={styles.CloseButtonWrap}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => removeCustomer(i)}
                                className={styles.CloseButton}
                              >
                                {"X"}
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid>
                          <CustomerForm
                            key={i}
                            index={i}
                            data={customerForm}
                            handleChange={customerHandleChange}
                          />
                        </Grid>
                      </Paper>
                    </div>
                  </Grid>
                ))}
            </Grid>
          {/* } */}
        </Grid>
        { enableBackdrop() ? null :
          <Grid item xs={12} sm={4} className={styles.PiiDataContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
              <div className={styles.PiiCollectLabel}>
                Collect PII Data
              </div>
                <Paper className={styles.paper}>
                  <PiiDataForm
                    handleChange={piiDataHandleChange}
                    handleCheckElement={handleCheckElement}
                    doYouCollectPiiData={doYouCollectPiiData}
                    showPiiData={showPiiData}
                    PIIdata={piiArray}
                  />
                  {piiFieldErrors ? (
                    <div className={styles.errormsg}>
                      <FormHelperText classes={{ root: styles.FormHelperText }}>
                        Please Select PII Fields.
                      </FormHelperText>
                    </div>
                  ) : null}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
      { enableBackdrop() ? null :
        <Grid container spacing={3}>
          <Grid item xs={12} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button variant="contained" color="primary" onClick={handleBack}>
                {"Previous"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={submitDisabled}
              >
                {"Next"}
              </Button>
              <div className={styles.cancelButton}>
              <Button variant="contained" color="primary" onClick={handleCancel}>
                {"Cancel"}
              </Button>
            </div>
            </div>
          
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
};

export default Customer;
