import { gql } from "@apollo/client";

export const CREATE_POLICY = gql`
  mutation CreatePolicy($name: String!, $description: String!, $category: ID!) {
    createPolicy(
      input: {
        data: { name: $name, description: $description, category: $category }
      }
    ) {
      policy {
        id
        name
        description
        category {
          id
        }
      }
    }
  }
`;

export const UPDATE_POLICY = gql`
  mutation UpdatePolicy(
    $id: ID!
    $name: String!
    $description: String!
    $category: ID!
  ) {
    updatePolicy(
      input: {
        where: { id: $id }
        data: { name: $name, description: $description, category: $category }
      }
    ) {
      policy {
        id
        name
        description
        category {
          id
        }
      }
    }
  }
`;

export const DELETE_POLICY = gql`
  mutation DeletePolicy($id: ID!) {
    deletePolicy(input: { where: { id: $id } }) {
      policy {
        id
        name
        description
        category {
          id
        }
      }
    }
  }
`;
