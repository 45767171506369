import { gql } from "@apollo/client";

export const CREATE_CONTACT = gql`
  mutation CreateContact (
    $name: String!,
    $email: String!,
    $phone: String!,
    $contact_type: String!
    $user_id: ID
    $last_name: String
    ) {
      createContact (input: {
        data: {
          name: $name
          email: $email
          phone: $phone
          contact_type: $contact_type
          user_id: $user_id
          last_name: $last_name
        }
      }) {
        contact {
          id
          name
          email
          phone
          contact_type
          last_name
        }
      }
    }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact (
    $name: String!,
    $email: String!,
    $phone: String!,
    $contact_type: String!
    $user_id: ID
    $id:ID!
    $last_name: String
    ) {
      updateContact (input: { where: { id: $id }
        data: {
          name: $name
          email: $email
          phone: $phone
          contact_type: $contact_type
          user_id: $user_id
          last_name: $last_name
        }
      }) {
        contact {
          id
          name
          email
          phone
          contact_type
          last_name
        }
      }
    }
`;

export const CREATE_ORGCONTACT = gql`
  mutation CreateContact (
    $name: String!,
    $contact_type: String!
    ) {
      createContact (input: {
        data: {
          name: $name
          contact_type: $contact_type
        }
      }) {
        contact {
          id
          name
          contact_type
        }
      }
    }
`;

export const UPDATE_ORGCONTACT = gql`
mutation UpdateContact (
    $id: ID!,
    $name: String!
    ) {
  updateContact(input: { where: { id: $id },
   data: { name:  $name } }) 
   {
    contact {
      id
      name
    }
  }
}`;


export const DELETE_CONTACT = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(input: { where: { id: $id } }) {
      contact {
        id
        name
        contact_type
      }
    }
  }
`;
export const PG_DELETE_CONTACT = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(input: { where: { id: $id } }) {
      contact {
        id
        name
        contact_type
        user_id {
          id
        }
      }
    }
  }
`;