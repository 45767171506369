// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddEditForm_backToListButton__2q1MA {\n  text-align: right;\n}\n\n.AddEditForm_saveButton__RMZku button {\n  margin-top: 12px;\n  margin-right: 10px;\n}\n\n.AddEditForm_saveButton__RMZku {\n  padding: 12px 0px;\n}\n\n.AddEditForm_backToListButton__2q1MA button {\n  background-color: transparent !important;\n  border: 0px !important;\n  box-shadow: none !important;\n  font-size: 12px !important;\n  font-weight: normal !important;\n  color: #0051A8 !important;\n  outline: 0 !important;\n  text-transform: unset !important;\n  padding-right: 0px !important;\n  margin-bottom: 3px;\n}\n\n.AddEditForm_backToListButton__2q1MA button span img {\n  width: 14px !important;\n  margin-right: 3px;\n}\n\n.AddEditForm_saveButton__RMZku button:last-child {\n  padding-left: 0px !important;\n  padding-right: 0px !important;\n  border: 0px !important;\n  box-shadow: none !important;\n  background-color: transparent;\n}", ""]);
// Exports
exports.locals = {
	"backToListButton": "AddEditForm_backToListButton__2q1MA",
	"saveButton": "AddEditForm_saveButton__RMZku"
};
module.exports = exports;
