// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PgAction_clickButton__29Qm2 {\n  margin-left: 10px !important;\n  display: inline-block !important;\n}\n\n.PgAction_TabOptions__2Xji6 button {\n  border-width: 1px 1px 1px 1px !important;\n  border-style: solid !important;\n  border-color: #0051a8 !important;\n  border-top-left-radius: 10px !important;\n  border-top-right-radius: 10px !important;\n  border-bottom-right-radius: 10px !important;\n  border-bottom-left-radius: 10px !important;\n  margin-right: 4px !important;\n  color: #707070 !important;\n  font-weight: 600 !important;\n  background-color: white !important;\n  padding: 10px 10px 10px 10px !important;\n  margin-top: 12px;\n}\n\n.PgAction_TabOptionsActive__2hd5V > span{\n  /* color:#0051a8 !important; */\n  background-color:#0051a8 !important;\n\n}\n\n.PgAction_TabOptionsInactive__1jxJK {\n  background-color: #fcfdfd !important;\n  margin: 1px;\n}\n\n.PgAction_para__37ZSV {\n  color: white;\n  z-index: 1;\n  margin: 1px;\n}\n\n.PgAction_TextField__2WBsX {\n  border-color: #0051a8 !important;\n  border-radius: 12px !important;\n  border-width: 1px !important;\n  width: 100%;\n  height: auto;\n  font-size: 14px;\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n  margin: auto !important;\n  padding: 5px !important;\n}\n\n.PgAction_subscriptionGrid__1q_6O {\n  padding: 5px !important;\n}\n\n.PgAction_loginBorder__1zvJ5 {\n  border-color: #0b0c0e !important;\n  border-radius: 12px !important;\n  border-width: 1px !important;\n  padding: 5px !important;\n  margin-top: 30px;\n}\n\n.PgAction_paper__1Zjwr {\n  margin-bottom: 20px;\n  padding: 28px 25px 17px 25px;\n  background-color: #FFF;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);\n  border-radius: 20px !important;\n  border: 1px solid #0051A8;\n}", ""]);
// Exports
exports.locals = {
	"clickButton": "PgAction_clickButton__29Qm2",
	"TabOptions": "PgAction_TabOptions__2Xji6",
	"TabOptionsActive": "PgAction_TabOptionsActive__2hd5V",
	"TabOptionsInactive": "PgAction_TabOptionsInactive__1jxJK",
	"para": "PgAction_para__37ZSV",
	"TextField": "PgAction_TextField__2WBsX",
	"subscriptionGrid": "PgAction_subscriptionGrid__1q_6O",
	"loginBorder": "PgAction_loginBorder__1zvJ5",
	"paper": "PgAction_paper__1Zjwr"
};
module.exports = exports;
