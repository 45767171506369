// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Loading_CenterItems__3znOR {\n  display: flex;\n  align-items: center;\n  align-content: center;\n  height: 100%;\n  flex-direction: column;\n  min-height: 768px;\n}\n\n.Loading_LoadingPadding__GbkTQ {\n  padding-top: 100px;\n}\n", ""]);
// Exports
exports.locals = {
	"CenterItems": "Loading_CenterItems__3znOR",
	"LoadingPadding": "Loading_LoadingPadding__GbkTQ"
};
module.exports = exports;
