import { gql } from "@apollo/client";

export const GET_STATES = gql`
  query($where: JSON, $sort: String) {
    states(where: $where, sort: $sort) {
      id
      name
      is_active
    }
  }
`;
