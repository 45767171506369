export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const DASHBOARD = "/dashboard";
export const REGISTRATION_URL = "/registration";
export const OTHER_OFFICE = "/other-office";
export const MAIN_OFFICE = "/main-office";
export const CUSTOMER = "/customer";
export const COMPANY_INFO = "/company-info";
export const REVIEW = "/review";
export const COMPLIANCE = "/compliance";
export const CLIENT = "/client";
export const CLIENT_FORM_ADD = "/client-form/add";
export const CLIENT_FORM_EDIT = "/client-form/edit/";
export const COMPLIANCE_LIST = "/compliance-list";

//------------RA ROUTES---------------//
export const TARGET = "/target";
export const TASK_DETAILS = "/task-details";
export const REPORT_STATUS = "/report-status";
export const RA_REPORT_LISTING = "/report-listing";
//------------ADMIN ROUTES---------------//
export const ADMIN_DASHBOARD = "/admin-dashboard";
export const PIIDATA = "/admin-piidata";
export const PIICATEGORY = "/admin-piicategory";
export const WORKPLAN = "/admin-workplan";
export const POLICY = "/admin-policy";
export const RULE = "/admin-rule";
export const ORGANIZATION_TYPE = "/admin-organization-type";
export const STATE = "/admin-state";
export const LAW = "/admin-law";
export const COMPANY = "/admin-company";
export const TEMPLATE = "/admin-template";
export const ADD_PARTNER = "/add-partner";
export const PARTNER_USER = "/partner-user";
export const PARTNER_USER_FORM = "/partner-user-form";
export const PARTNER_USER_FORM_ADD = "/partner-user-form/add";
export const PARTNER_USER_FORM_EDIT = "/partner-user-form/edit/";

//------------CSET ROUTES---------------//
export const ASSESSMENT_LIST = "/assessment-list";
export const ASSESSMENT_INFO = "/assessment-info";
export const SECURITY_ASSURANCE_LEVEL = "/security-assurance-level";
export const STANDARDS = "/standards-selection";
export const REPORTS = "/reports";
export const EXECUTIVE_SUMMARY = "/executive-summary";
export const SITE_SUMMARY_REPORT = "/site-summary-report";
export const SITE_CYBERSECURITY_PLAN = "/site-cybersecurity-plan";
export const SITE_DETAIL_REPORT = "/site-detail-report";
export const OBSERVATIONS_TEAR_OUT_SHEETS = "/observations-tearout-sheets";
export const QUESTIONS = "/question";
export const RESULTS = "/results";
export const STANDARDSUMMARY = "/standard-summary";
// export const CSET_TYPE = "/type";
export const FIRM_TYPE = "/firm-type";



