// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* .layout {\n  width: 60%;\n} */\n\n.OtherOffice_paper__24SzV {\n  margin-bottom: 10px;\n  padding: 28px 25px 17px 25px;\n  background-color: #FFF;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);\n  border-radius: 20px !important;\n  border: 1px solid #0051A8;\n  position: relative;\n}\n\n.OtherOffice_Margin__25arK {\n  margin: 4px;\n}\n\n.OtherOffice_AlignRight__3JtOO {\n  text-align: right;\n  padding-bottom: 15px !important;\n  padding-top: 0px !important;\n}\n\n.OtherOffice_CloseButtonWrap__26l3Y {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n\n.OtherOffice_CloseButton__k476i {\n  width: 30px !important;\n  min-width: 30px !important;\n  height: 30px;\n  font-weight: 600 !important;\n  padding: 0px !important;\n  border-radius: 25px !important;\n}\n\n.OtherOffice_FooterActions__1ltxy button {\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.OtherOffice_prevBtn__1cLDy {\n  display: inline-block !important;\n}\n\n.OtherOffice_cancelButton__kTcW3 {\n  display: inline-block !important;\n}\n\n.OtherOffice_FormHelperText__keF5T {\n  color: #e53935 !important;\n  padding: 0px 0px 10px 0px !important\n}\n\n.OtherOffice_errormsg__mjHQ6 {\n  display: inline-block !important;\n  width: 100% !important;\n}\n\nAlignRight button svg{\n  margin-right: 5px;\n}\n\n.OtherOffice_EditIcon__1BYSa {\n  width: 18px !important;\n  height: 18px !important;\n  margin-right: 5px !important;\n}", ""]);
// Exports
exports.locals = {
	"paper": "OtherOffice_paper__24SzV",
	"Margin": "OtherOffice_Margin__25arK",
	"AlignRight": "OtherOffice_AlignRight__3JtOO",
	"CloseButtonWrap": "OtherOffice_CloseButtonWrap__26l3Y",
	"CloseButton": "OtherOffice_CloseButton__k476i",
	"FooterActions": "OtherOffice_FooterActions__1ltxy",
	"prevBtn": "OtherOffice_prevBtn__1cLDy",
	"cancelButton": "OtherOffice_cancelButton__kTcW3",
	"FormHelperText": "OtherOffice_FormHelperText__keF5T",
	"errormsg": "OtherOffice_errormsg__mjHQ6",
	"EditIcon": "OtherOffice_EditIcon__1BYSa"
};
module.exports = exports;
