import { gql } from "@apollo/client";

// Need to remove it, after adding complianc info query
export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization (
    $contact_id: ID!,
    $contact_person_id: ID!,
    $organization_type: ID!,
    $organization_subtype: ID,
    $best_practices: [ID!]
    ) {
  createOrganization(
    input: { 
      data: { 
        contact_id: $contact_id,
        contact_person_id: $contact_person_id,
        organization_type: $organization_type,
        organization_subtype: $organization_subtype,
        best_practices: $best_practices
      } }
  ) {
    organization {
      id
      contact_person_id{id}
      organization_type{id}
      organization_subtype{id}
      best_practices{id}
    }
  }
}
`;

export const UPDATE_ORGANIZATION = gql`
mutation updateOrganization(
  $id: ID!
  $contact_id: ID!
  $contact_person_id: ID!
  $organization_type: ID!
  $organization_subtype: ID
  $best_practices: [ID!]
) {
  updateOrganization(
    input: {
      where: { id: $id }
      data: {
        contact_id: $contact_id
        contact_person_id: $contact_person_id
        organization_type: $organization_type
        organization_subtype: $organization_subtype
        best_practices: $best_practices
      }
    }
  ) {
    organization {
      id
      contact_person_id {
        id
      }
      organization_type {
        id
      }
      organization_subtype {
        id
      }
      best_practices {
        id
      }
    }
  }
}`;

export const CREATE_ORG = gql`
  mutation CreateOrganization(
    $contact_id: ID!
    $subtype: ENUM_ORGANIZATION_SUBTYPE
    $partner_id: ID
    $pg_id: Int
  ) {
    createOrganization(
      input: {
        data: {
          contact_id: $contact_id
          subtype: $subtype
          partner_id: $partner_id
          pg_id: $pg_id
        }
      }
    ) {
      organization {
        id
      }
    }
  }
`;

export const UPDATE_ORG = gql`
  mutation UpdateOrganization(
    $contact_id: ID!
    $subtype: ENUM_ORGANIZATION_SUBTYPE
    $partner_id: ID
    $id: ID!
  ) {
    updateOrganization(
      input: {
        where: { id: $id }
        data: {
          contact_id: $contact_id
          subtype: $subtype
          partner_id: $partner_id
        }
      }
    ) {
      organization {
        id
      }
    }
  }
`;
export const DELETE_ORGANIZATION = gql`
 mutation DeleteOrganization (
$id : ID!
 ) {
    deleteOrganization(input: {where: {id:  $id}}) {
      organization {
        contact_id {
          id
          email
          last_name
          name
          phone
          contact_type
        }
        id
        partner_id {
          id
          last_name
          name
        }
        updated_at
        subtype
      }
    }
  }
`;