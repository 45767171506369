import { gql } from "@apollo/client";

export const GET_PIICATEGORY = gql`
  query($where: JSON, $sort: String) {
    piiCategories(where: $where, sort: $sort) {
      id
      name
    }
  }
`;
