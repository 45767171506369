import React, { useState, useEffect } from "react";
import styles from "./OtherOffice.module.css";
import Paper from "@material-ui/core/Paper";
import Office from "../Office/Office";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import * as routeConstant from "../../common/RouteConstants";
import { useHistory } from "react-router-dom";
import { GET_ADDRESS } from "../../graphql/queries/Address";
import {
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
} from "../../graphql/mutations/Address";
import {
  CREATE_ORG_LOC,
  UPDATE_ORG_LOC,
  DELETE_ORG_LOC,
} from "../../graphql/mutations/OrganizationLocation";
import { GET_COMPLIANCEINFO } from "../../graphql/queries/ComplianceInfo";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_EMPLOYEE_DATA } from "../../graphql/queries/OrganizationLocation";
import logout from "../../containers/Auth/Logout/Logout";
import { GET_COMPLIANCE } from "../../graphql/queries/Compliance";
import { UPDATE_COMPLIANCE } from "../../graphql/mutations/Compliance";
import {
  getCompliance,
  setCompliance,
  returnArray,
  setOtherOffice,
  setActiveFormStep
} from "../../services/Data";
import { Button } from "../../components/UI/Form/Button/Button";
import { Typography } from "@material-ui/core";
import { useApolloClient } from "@apollo/client";
import { setStepper } from "../../common/SetStepper";
import stepper from "../../common/stepperMenu.json";
import TopStepper from "../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import { GET_REVIEW_INFO } from "../../graphql/queries/Review";

export const OtherOffice: React.FC = (props: any) => {
  const client = useApolloClient();
  let stepperObject = stepper;
  const history = useHistory();
  const [param, setParam] = useState<any>();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [orgContactId, setOrgContactId] = useState<any>();
  const [disableAddForm, setDisableAddForm] = useState(true);
  const [stepperObj, setStepperObj] = useState<any>({path: ""});
  const [showBackDrop, setShowBackdrop] = useState(true);
  const [otherOffice, setOtherOfficeObj] = useState([
    {
      address: {
        id: null,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        isError: {
          address1: true,
          city: true,
          state: true,
          zip: true
        },
      },
      employee: {
        id: null,
        noOfEmployee: "",
        doYouCollectPiiData: false,
        piiArray: [],
        isError: {
          noOfEmployee: true
        },
      },
    },
  ]);

  // Query and Mutation
  const [createAddress] = useMutation(CREATE_ADDRESS);
  const [updateAddress] = useMutation(UPDATE_ADDRESS);
  const [deleteAddress] = useMutation(DELETE_ADDRESS);
  const [getaddress, { data: dataAD, loading: loadingAD }] = useLazyQuery(
    GET_ADDRESS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [createEmployee] = useMutation(CREATE_ORG_LOC);
  const [updateEmployee] = useMutation(UPDATE_ORG_LOC);
  const [deleteEmployee] = useMutation(DELETE_ORG_LOC);
  const [updateComplianceData, { data: dataCOM }] = useMutation(
    UPDATE_COMPLIANCE
  );
  const [
    getComplianceData,
    { data: complianceData, loading: complianceLoading },
  ] = useLazyQuery(GET_COMPLIANCE, {
    onCompleted: (data: any) => {
      setCompliance(complianceData.compliances);
    },
    fetchPolicy: "cache-and-network",
    onError:()=>{
      setShowBackdrop(false)
      handleCancel()
    }
  });

  const fetchCompliance = (val: any) => {
    getComplianceData({
      variables: {
        where: { contact_id: val },
      },
    });
  };

  const [
    getComplianceInfo,
    { data: compInfo, error: compInfoErr, loading: compInfoLoad },
  ] = useLazyQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setOrgContactId(compInfo.complianceInfos[0].contact_id.id);

      getaddress({
        variables: {
          where: {
            location_type: "Other",
            contact_id: compInfo.complianceInfos[0].contact_id.id,
          },
        },
      });
      getOrgLocation({
        variables: {
          where: {
            address_id: { location_type: "Other" },
            contact_id: compInfo.complianceInfos[0].contact_id.id,
          },
        },
      });
      setSubmitDisabled(false);
      setDisableAddForm(false);
    },
    onError:()=>{
      setShowBackdrop(false)
      handleCancel()
    }
  });

  const [getOrgLocation, { data: dataOL, loading: loadingOL }] = useLazyQuery(
    GET_EMPLOYEE_DATA,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data:any) =>{
        setShowBackdrop(false)
      },
      onError:()=>{
          setShowBackdrop(false)
          handleCancel()
      }
    });

  const [ getReview, { data: reviewData, error: error } ] = useLazyQuery(GET_REVIEW_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: any) => {
      if (data.review.companyprofile.data.length > 0) {
        setActiveFormStep(0);
      }
      if (data.review.mainOffice.data.length > 0) {
        setActiveFormStep(1);
      }
      if (data.review.otherOffice.data.length > 0) {
        setActiveFormStep(2);
      }
      if (data.review.customer.data.length > 0) {
        setActiveFormStep(4);
      }
    },
    onError:()=>{
      setShowBackdrop(false)
      handleCancel()
    }
  });

  const handleBack = () => {
    if (props.location.state.val === "Re-Run") {
      props.location.state["back"] = "Back";
    }
    setStepper(
      client,
      stepperObject.mainOffice.name,
      stepperObject.mainOffice.value
    );
    history.push(routeConstant.MAIN_OFFICE, param);
  };

  const handleNext = () => {
    if (!submitDisabled) {
      if (stepperObj.path && stepperObj.path !== "") {
        history.push(stepperObj.path, param);
      } else {
        setStepper(
          client,
          stepperObject.customer.name,
          stepperObject.customer.value
        );
        history.push(routeConstant.CUSTOMER, param);
      }
    }
  };

  useEffect(() => {
    setParam(props.location.state);
    if (
      props.location.state &&
      props.location.state.hasOwnProperty("complianceInfo")
    ) {
      getReview({
        variables: { ID: parseInt(props.location.state.complianceInfo.contactId) }
      });
      getComplianceInfo({
        variables: {
          where: {
            client_id: props.location.state.clientInfo.clientId,
            contact_id: props.location.state.complianceInfo.contactId,
            id: props.location.state.complianceInfo.id,
          },
        },
      });
    }
    setStepper(
      client,
      stepperObject.otherOffice.name,
      stepperObject.otherOffice.value
    );
  }, []);

  const restoredata = () => {
    let office: any = [];
    if (dataAD && dataOL) {
      let piiArray: any = [];
      for (let i in dataOL.organizationLocations) {
        for (let j in dataOL.organizationLocations[i].pii_data_field) {
          piiArray.push(
            parseInt(dataOL.organizationLocations[i].pii_data_field[j].id)
          );
        }
      }

      dataAD.addresses.forEach((element: any, i: any) => {
        if (
          dataAD.addresses[i] !== undefined &&
          dataOL.organizationLocations[i] !== undefined
        ) {
          office.push({
            address: {
              id: dataAD.addresses[i].id,
              address1: dataAD.addresses[i].address_line_1,
              address2: dataAD.addresses[i].address_line_2,
              city: dataAD.addresses[i].city,
              state: dataAD.addresses[i].state,
              zip: dataAD.addresses[i].zip,
              isError: {},
            },
            employee: {
              id: dataOL.organizationLocations[i].id,
              noOfEmployee: dataOL.organizationLocations[i].no_of_employees,
              doYouCollectPiiData:
                dataOL.organizationLocations[i].collect_pii_information,
              piiArray: getFields(
                dataOL.organizationLocations[i].pii_data_field,
                "id"
              ),
              isError: {},
            },
          });
        }
      });
      if (office.length > 0) {
        setOtherOffice(true);
        setOtherOfficeObj(office);
        fetchCompliance(orgContactId);
      }
    }
  };

  const getFields = (input: any, field: any) => {
    let output = [];
    for (let i = 0; i < input.length; ++i)
      output.push(parseInt(input[i][field]));
    return output;
  };

  useEffect(() => {
    restoredata();
    if (dataAD !== undefined && dataOL !== undefined) {
      if (dataAD.addresses.length >= 0) {
        setSubmitDisabled(false);
        setDisableAddForm(false);
      }
      if (dataAD.addresses.length === 0) {
        setSubmitDisabled(true);
        setDisableAddForm(true);
      }
    }
  }, [dataAD, dataOL]);

  if (compInfoErr) {
    let error = compInfoErr ? compInfoErr : { message: "Error" };
    return (
      <div className="error">
        Error!
        {logout()}
      </div>
    );
  }

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }

  let updatedObj = {};
  const verifyChange = () => {
    let comp = getCompliance();
    updatedObj = {};
    comp.map((compliance: any) => {
      if (compliance.allow_download === true) {
        updatedObj = {
          id: parseInt(compliance.id),
          contact_id: parseInt(compliance.contact_id.id),
          law: returnArray(compliance.laws),
          rule: returnArray(compliance.rules),
          policy: returnArray(compliance.policies),
          workplan: returnArray(compliance.workplans),
          address: parseInt(compliance.address_id.id),
          allowdownload: false,
          compliance_version: compliance.compliance_version
            ? parseInt(compliance.compliance_version.id)
            : compliance.compliance_version,
        };
        updateCompliance(updatedObj);
      }
    });
  };

  const updateCompliance = (val: any) => {
    updateComplianceData({
      variables: val,
    })
      .then((res) => {})
      .catch((error) => {});
  };

  const isEmptyField = (param: any) => {
    let hasEmptyField = false;
    param.map((element: any) => {
      let addressIsError = Object.values(element.address.isError).indexOf(true) > -1
      let employeeIsError = Object.values(element.employee.isError).indexOf(true) > -1
      if (addressIsError || employeeIsError || (element.employee.doYouCollectPiiData && element.employee.piiArray.length === 0)) {
        hasEmptyField = true;
      }
    });
    return hasEmptyField;
  };

  const officeHandleChange = (event: any) => {
    setOtherOfficeObj(event);
    if (isEmptyField(event)) {
      setSubmitDisabled(true);
      setDisableAddForm(true);
    } else {
      setSubmitDisabled(false);
      setDisableAddForm(false);
    }
  };

  const handleSubmit = () => {
    postAddress();
  };

  const postAddress = () => {
    otherOffice.map((val: any, i) => {
      if (!submitDisabled && otherOffice[i].address.id === null) {
        setShowBackdrop(true);
        createAddress({
          variables: {
            contact_id: orgContactId, // contact id of client
            address1: val.address.address1,
            address2: val.address.address2,
            locationType: "Other",
            city: val.address.city,
            state: val.address.state.id,
            zip: val.address.zip,
          },
        })
          .then((res) => {
            postEmployee(res, orgContactId, val);
          })
          .catch((err) => {
            setShowBackdrop(false);
            setSubmitDisabled(false);
          });
      } else {
        handleNext();
      }
    });

    otherOffice.map((val: any, i) => {
      if (!submitDisabled && otherOffice[i].address.id !== null) {
        setShowBackdrop(true);
        updateAddress({
          variables: {
            id: otherOffice[i].address.id,
            contact_id: orgContactId, // contact id of client
            address1: val.address.address1,
            address2: val.address.address2,
            locationType: "Other",
            city: val.address.city,
            state: val.address.state.id,
            zip: val.address.zip,
          },
        })
          .then((res) => {
            postEmployee(res, orgContactId, val);
          })
          .catch((Error) => {
            setShowBackdrop(false);
            setSubmitDisabled(false);
          });
      } else {
        handleNext();
      }
    });
  };

  const postEmployee = (res: any, orgId: any, val: any) => {
    let doYouCollectePiiInfo = false;
    if (val.employee.piiArray.length > 0) {
      doYouCollectePiiInfo = true;
    }

    if (!submitDisabled && val.employee.id === null) {
      setShowBackdrop(true);
      createEmployee({
        variables: {
          contactId: orgContactId, // contact id of client
          addressId: res.data.createAddress.address.id,
          noOfEmp: parseInt(val.employee.noOfEmployee),
          collectePiiInfo: doYouCollectePiiInfo,
          pii_data_field: val.employee.piiArray,
        },
      })
        .then((res) => {
          setOtherOffice(true);
          handleNext();
        })
        .catch((err) => {
          setShowBackdrop(false);
          setSubmitDisabled(false);
        });
    }

    if (!submitDisabled && val.employee.id !== null) {
      setShowBackdrop(true);
      updateEmployee({
        variables: {
          id: val.employee.id,
          contactId: orgContactId, // contact id of client
          addressId: res.data.updateAddress.address.id,
          noOfEmp: parseInt(val.employee.noOfEmployee),
          collectePiiInfo: doYouCollectePiiInfo,
          pii_data_field: val.employee.piiArray,
        },
      })
        .then((res) => {
          verifyChange();
          setOtherOffice(true);
          handleNext();
        })
        .catch((err) => {
          setShowBackdrop(false);
          setSubmitDisabled(false);
        });
    }
  };

  const appendAnotherAddressForm = () => {
    let office = [...otherOffice];
    office.push({
      address: {
        id: null,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        isError: {
          address1: true,
          city: true,
          state: true,
          zip: true
        },
      },
      employee: {
        id: null,
        noOfEmployee: "",
        doYouCollectPiiData: false,
        piiArray: [],
        isError: {
          noOfEmployee: true
        },
      },
    });
    setOtherOfficeObj(office);
    setSubmitDisabled(true);
    setDisableAddForm(true);
  };

  const removeOtherOffice = (index: number) => {
    setShowBackdrop(true);
    let office = [...otherOffice];
    if (office[index] !== undefined && office[index].address.id !== null) {
      setSubmitDisabled(true);
      setDisableAddForm(true);
      deleteAddress({
        variables: {
          id: office[index].address.id,
        },
      })
        .then((res) => {})
        .catch((err) => {
          setShowBackdrop(false);
          setSubmitDisabled(false);
          setDisableAddForm(false);
        });
      deleteEmployee({
        variables: {
          id: office[index].employee.id,
        },
      })
        .then((res) => {})
        .catch((err) => {
          setShowBackdrop(false);
          setSubmitDisabled(false);
          setDisableAddForm(false);
        });
    }
    office.splice(index, 1);
    setOtherOfficeObj(office);
    setSubmitDisabled(false);
    setDisableAddForm(false);
  };

  const handleCancel = () => {
    let val = {
      clientInfo: param.clientInfo
    }
    history.push(routeConstant.COMPLIANCE_LIST, val);
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
    handleSubmit()
  }

  let piiErrroosArr: any = {};
  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {props.location.state
          ? "CyberCompliance for " + props.location.state.clientInfo.name
          : null}
      </Typography>
      <TopStepper
        navigate={!submitDisabled}
        param={param}
        handleSave={callingFromTopStepper}
        obj={stepperObj}
      />
      <Grid container spacing={3}>
      { enableBackdrop() ? <SimpleBackdrop/>:  null}
        <Grid item xs={12} className={styles.AlignRight}>
          <Button
            variant="contained"
            color="primary"
            disabled={disableAddForm}
            onClick={appendAnotherAddressForm}
          ><AddCircleIcon className={styles.EditIcon} />
            Office
          </Button>
        </Grid>
      </Grid>
        <Grid container spacing={3}>
          {otherOffice.length > 0 &&
            otherOffice.map((obj: any, i) => (
              <Grid item xs={12} md={6} lg={4}>
                <div key={obj.address.state ? obj.address.state.id : i}>
                  <Paper className={styles.paper}>
                    <Grid>
                      {i > 0 ? (
                        <div className={styles.CloseButtonWrap}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => removeOtherOffice(i)}
                            className={styles.CloseButton}
                          >
                            {"X"}
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid>
                      <Office
                        key={i}
                        index={i}
                        name="Other Office"
                        data={otherOffice}
                        handleChange={officeHandleChange}
                        PIIErrorCheck={(event: Boolean, key: any) => {
                          piiErrroosArr[key] = event;
                        }}
                        showPiiError={true}
                      />
                    </Grid>
                  </Paper>
                </div>
              </Grid>
            ))}
        </Grid>
      
      { enableBackdrop() ? null :
        <Grid container spacing={3}>
          <Grid item xs={12} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button variant="contained" color="primary" onClick={handleBack}>
                {"Previous"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={submitDisabled}
              >
                {"Next"}
              </Button>
            </div>
            <div className={styles.cancelButton}>
              <Button variant="contained" color="primary" onClick={handleCancel}>
                {"Cancel"}
              </Button>
            </div>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
};

export default OtherOffice;
