import { gql } from "@apollo/client";

export const GET_CATEGORY = gql`
  query($where:JSON) {
    categories(where:$where) {
      id
      name
      template_description
    }
  }
`;