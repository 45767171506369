import { gql } from "@apollo/client";

export const ACTIVE_PII_FIELDS = gql`
  query {
    piiData(sort: "name:asc", where: { is_active: true }) {
      id
      name
      category {
        id
        name
      }
      is_active
    }
  }
`;

export const GET_PIIDATA = gql`
query ($where:JSON,$sort:String) {
  piiData(where:$where,sort:$sort) {
    id
    name
    category {
      id
      name
    }
    is_active
  }
}
`;


