// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MainOfficeCompliance_MainOfficeCompliance__1G0Mc {}\n.MainOfficeCompliance_TabContainer__3N_5b{\n  border: 1px solid #0051a8;\n  border-radius: 10px !important;\n  padding: 15px;\n  background-color: #fff;\n  margin-top: -7px;\n  position: relative;\n}\n.MainOfficeCompliance_ComplianceButtons__1gP2L{\n  margin-top:15px;\n}\n.MainOfficeCompliance_ComplianceButtons__1gP2L button{\n  margin-right:10px;\n  margin-bottom:10px;\n}", ""]);
// Exports
exports.locals = {
	"MainOfficeCompliance": "MainOfficeCompliance_MainOfficeCompliance__1G0Mc",
	"TabContainer": "MainOfficeCompliance_TabContainer__3N_5b",
	"ComplianceButtons": "MainOfficeCompliance_ComplianceButtons__1gP2L"
};
module.exports = exports;
