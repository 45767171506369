import React, { useState, useEffect } from "react";
import styles from "./MainOfficeCompliance.module.css";
import ComplianceReport from "../ComplianceReport/ComplianceReport";
import { Button } from "../../../components/UI/Form/Button/Button";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { useLazyQuery } from "@apollo/client";
import { GET_COMPLIANCEINFO } from "../../../graphql/queries/ComplianceInfo";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Paragraph,
  TextRun,
} from "docx";
import { Grid } from "@material-ui/core";
import { setStepper } from "../../../common/SetStepper";
import stepper from "../../../common/stepperMenu.json";
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import * as routeConstant from "../../../common/RouteConstants";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";

export interface ComplianceProps {
  data?: any;
  complianceName?: any;
  param?: any;
  complianceList?: any;
}

export const MainOfficeCompliance: React.SFC<ComplianceProps> = ({
  data,
  param,
  complianceName,
  complianceList,
}: ComplianceProps) => {
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  let stepperObject = stepper;
  const client = useApolloClient();
  const history = useHistory();

  const JSZip = require("jszip");
  const [otherOfficeData, setOtherOfficeData] = useState<any>([]);
  const [folderName, setfolderName] = useState<any>();
  const [otherOfficeState, setOtherOfficeState] = useState<any>([]);
  const [loading, setLoading] = useState<any>();
  const [downloadFromList, setDownloadFromList] = useState<any>();

  const [
    getComplianceInfo,
    { data: dataOrgUser, error: errorOrgUser, loading: loadingOrgUser },
  ] = useLazyQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setfolderName(dataOrgUser.complianceInfos[0].name)
    },
  });
  useEffect(() => {
    otherOfficeObj();
  }, [data]);
  useEffect(() => {
    window.addEventListener("popstate", (event) => {
      if (
        param != undefined &&
        window.location.href === (process.env.REACT_APP_CLIENT_URL + "review")
      ) {
        goToCompliancList()
      }
    });
  }, []);
  useEffect(() => {
    if (param)
      getComplianceInfo({
        variables: {
          where: {
            client_id: param.clientInfo.clientId,
            contact_id: param.complianceInfo.contactId,
            id: param.complianceInfo.id,
          },
        },
      });
  }, []);

  useEffect(() => {
    if (complianceList) {
      setLoading(true);
      getNewData(newData);
      setDownloadFromList("val");
    }
  }, [complianceList]);
  const newData = {
    // "parentLaws": {
    //   "title": "Laws applicable to the company",
    //   "description": [],
    //   "icon": "PermIdentityIcon",
    //   "link": "company-Info"
    // },
    laws: {
      title: "Laws applicable to the company",
      description: [],
      icon: "PermIdentityIcon",
      link: "company-Info",
    },
    rules: {
      title: "Rules applicable to the company",
      description: [],
      icon: "HomeWorkIcon",
      link: "company-address",
    },
    policies: {
      title: "Policies based on the laws",
      description: [],
      icon: "HomeWorkIcon",
      link: "company-address",
    },
    workplans: {
      title: "Workplan",
      description: [],
      icon: "HomeWorkIcon",
      link: "company-address",
    },
  };
  const preData = {
    // "parentLaws": {
    //   "title": "Laws applicable to the company",
    //   "description": [],
    //   "icon": "PermIdentityIcon",
    //   "link": "company-Info"
    // },
    laws: {
      title: "Laws applicable to the company",
      description: [],
      icon: "PermIdentityIcon",
      link: "company-Info",
    },
    rules: {
      title: "Rules applicable to the company",
      description: [],
      icon: "HomeWorkIcon",
      link: "company-address",
    },
    policies: {
      title: "Policies based on the laws",
      description: [],
      icon: "HomeWorkIcon",
      link: "company-address",
    },
    workplans: {
      title: "Workplan",
      description: [],
      icon: "HomeWorkIcon",
      link: "company-address",
    },
  };
  const otherOfficeObj = () => {
    let otherOfficeArray: any[] = [];
    let otherOfficeState: any[] = [];
    if (data.other !== "") {
      data.other.map((office: any, index: any) => {
        let dataObj = {
          // "parentLaws": {
          //   "title": "Laws applicable to the company",
          //   "description": office.parentLaws,
          //   "icon": "PermIdentityIcon",
          //   "link": "company-Info"
          // },
          laws: {
            title: "Laws applicable to the company",
            description: office.laws,
            icon: "PermIdentityIcon",
            link: "company-Info",
          },
          rules: {
            title: "Rules applicable to the company",
            description: office.rules,
            icon: "HomeWorkIcon",
            link: "company-address",
          },
          policies: {
            title: "Policies based on the laws",
            description: office.policies,
            icon: "HomeWorkIcon",
            link: "company-address",
          },
          workplans: {
            title: "Workplan",
            description: office.workplans,
            icon: "HomeWorkIcon",
            link: "company-address",
          },
        };
        otherOfficeArray.push(dataObj);
        otherOfficeState.push(office.state);
      });
    }
    setOtherOfficeData(otherOfficeArray);
    setOtherOfficeState(otherOfficeState);
  };

  const handleLawOpen = (newData: any) => {
    //Discuss about getting the backend url dynamically
    // if (rowData.law_document && rowData.law_document[0]) {
    //   window.open(DOCURL + rowData.law_document[0].url.slice(1))
    // }
  };

  const policyDescription = (rule: any, category: any) => {
    let policyDes: any[] = [];
    rule.map((element: any) => {
      if (category && element.category.id === category.id) {
        policyDes.push(element.policy_content);
      }
    });
    return policyDes;
  };

  const workplanDescription = (rule: any, category: any) => {
    let workplanDes: any[] = [];
    rule.map((element: any) => {
      // if (category && element.category.id === category.id) {
      workplanDes.push(element.workplan_description);
      // }
    });
    return workplanDes;
  };

  const templateDescription = (rule: any, category: any) => {
    let templateDes: any[] = [];
    rule.map((element: any) => {
      if (category && element.category.id === category.id) {
        templateDes.push(element.category.template_description);
      }
    });
    return templateDes;
  };

  const WorkplanDocumentCreator: any = (documentData: any) => {
    const document = new Document();
    if (documentData) {
      document.addSection({
        children: [
          new Paragraph({
            text: "Workplan",
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.CENTER,
            thematicBreak: true,
          }),

          new Paragraph({
            text: documentData.title,
            heading: HeadingLevel.TITLE,
            thematicBreak: true,
          }),

          new Paragraph({
            text: JSON.stringify(documentData.data),
            alignment: AlignmentType.CENTER,
          }),
        ],
      });
    }

    return document;
  };

  const PolicyDocumentCreator: any = (documentData: any) => {
    if (documentData) {
      const createtempContentList = (result: any[]): Paragraph[] => {
        let replaced = result[0].replaceAll("*", "#");
        var result: any[] = replaced.match(/(?<=#\s*).*?(?=\s*#)/gs);
        var filtered = result.filter(function (el) {
          return el != "";
        });
        return filtered.map(
          (result) =>
            new Paragraph({
              text: result.includes("\n") ? result : undefined,
              heading: result.includes("\n") ? undefined : HeadingLevel.HEADING_3,
              spacing: {
                before: 200,
              },
              children: [
                new TextRun({
                  text: "                                                                                                                                                                                                                      ",
                }),
                new TextRun({
                  text: result.includes("\n") ? undefined : result,
                  bold: true,
                }),
              ],
            })
        );
      };
      var overView: any[] = documentData.template_description[0].match(
        /[^*]*$/gs
      );

      const createPolicyContentList = (contents: any[]): Paragraph[] => {
        return contents.map(
          (contents) =>
            new Paragraph({
              spacing: {
                before: 400,
              },
              children: [
                new TextRun({
                  text: contents.includes("\n")
                    ? contents.replaceAll("*", "").split("\n")[0]
                    : undefined,
                  bold: true,
                }),
                new TextRun({
                  text:
                    "                                                                                                                                                                                                                      ",
                }),
                new TextRun({
                  text: contents.includes("\n")
                    ? contents.substr(contents.indexOf("\n") + 1)
                    : undefined,
                }),
              ],
            })
        );
      };

      const createRulesList = (contents: any[]): Paragraph[] => {
        return contents.map(
          (contents) =>
            new Paragraph({
              text: " " + contents.replaceAll("*", "").split("\n")[0],
              bullet: {
                level: 0,
              },
            })
        );
      };

      const document = new Document();
      if (documentData) {
        document.addSection({
          children: [
            new Paragraph({
              text: documentData.title,
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
              thematicBreak: true,
            }),
            ...createtempContentList(documentData.template_description),
            new Paragraph({
              text: overView[0],
              spacing: {
                before: 100,
                after: 100
              },
            }),
            ...createRulesList(documentData.data),
            new Paragraph({
              text: "",
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
              thematicBreak: true,
            }),
            ...createPolicyContentList(documentData.data),
          ],
        });
      }

      return document;
    }
  };

  let zip = new JSZip();
  let workplanData: any = [];
  let Contents: any;
  let workplanDocTitle: any;

  //Not in use currently
  const downloadMainOffice = (newData: any) => {
    setLoading(true);
    zip.remove("Main Office");
    zip.remove("Other Office");
    workplanData = [];
    Object.keys(newData).map((key, index) =>
      newData[key]["description"].map((element: any) => {
        if (key === "policies") {
          Contents = {
            data: policyDescription(
              newData["rules"].description,
              element.category
            ),
            title: element.name,
            template_description: templateDescription(
              newData["rules"].description,
              element.category
            ),
          };
          const policydocumentCreator = PolicyDocumentCreator(Contents);
          Packer.toBlob(policydocumentCreator).then((blob) => {
            var timeStamp = Math.floor(Date.now() / 1000);

            zip
              .folder("Main Office/Policy")
              .file(element.name + "_" + timeStamp + ".docx", blob, { base64: true });
          });
        }
      })
    );
    if (newData.rules.description[0]) {
      for (let i in newData) {
        Contents = {
          data: workplanDescription(
            newData.rules.description,
            newData.rules.description[0].category
          ),
          title: newData.workplans.description[0].name,
          template_description:
            newData["rules"].description[i].category.template_description,
        };
      }
      const workplandocumentCreator = WorkplanDocumentCreator(Contents);
      Packer.toBlob(workplandocumentCreator).then((blob) => {
        var timeStamp = Math.floor(Date.now() / 1000);
        // zip.folder("Main Office/Workplan").file(newData['workplans'].description[0].name + "_" + ".docx", blob, { base64: true })
      });
    }
    //  Adding Law For Main Office in Zip
    if (newData.laws.description[0]) {
      for (let i in newData.laws.description) {
        if (
          newData.laws.description[i].law_document &&
          newData.laws.description[i].law_document[0] &&
          newData.laws.description[i].law_document[0].url
        ) {
          let lawUrl = newData.laws.description[i].law_document[0].url.slice(1);
          let DocUrl = serverUrl + lawUrl;
          fetch(DocUrl, {
            method: "GET",
          })
            .then((response) => response.blob())
            .then((response) => {
              let url = window.URL.createObjectURL(response);
              let a = document.createElement("a");
              var timeStamp = Math.floor(Date.now());
              let fileName = newData.laws.description[i].law_document[0].name;

              let docFileName = fileName + timeStamp
              a.href = url;
              a.download = docFileName;
              zip
                .folder("Main Office/Laws")
                .file(docFileName, response);
            });
        }
      }
    }
    if (Contents) {
      Packer.toBlob(
        WorkplanDocumentCreator(Contents),
        PolicyDocumentCreator(Contents)
      ).then((blob) => {
        zip.generateAsync({ type: "blob" }).then(function (content: any) {
          saveAs(content, "Main_Office_Compliance.zip");
        });
      });
    }
  };

  let otherworkplanData: any = [];
  let otherContents: any;
  let otherworkplanDocTitle: any;

  // const downloadAllOffice = async (newData: any) => {
  //   return new Promise(async (resolve) => {
  //     setLoading(true);
  //     zip.remove(folderName);
  //     zip.remove("Other Office");
  //     let PolicyDocData: any;
  //     // var Main = zip.folder("Main Office");
  //     if (newData.laws) {
  //       if (newData.laws.description[0]) {
  //         let lawDocArray: any = []
  //         new Promise<void>(async function (resolve, reject) {
  //           await newData.laws.description.map(async (val: any) => {
  //             if (val.law_document.length !== 0) {
  //               lawDocArray.push(val.law_document[0])
  //             }
  //           })
  //           resolve();
  //         })
  //         let lawDoc = Array.from(new Set(lawDocArray));
  //         downloadAllLawPdf(lawDoc).then((val: any) => {
  //           resolve(Contents);
  //         })
  //       }
  //     }

  //     otherOfficeData.map((offices: any) => {
  //       Object.keys(offices).map((key, index) =>
  //         offices[key]["description"].map((element: any) => {
  //           if (key === "policies") {
  //             otherworkplanDocTitle = element.name;
  //             otherContents = {
  //               data: policyDescription(
  //                 offices["rules"].description,
  //                 element.category
  //               ),
  //               title: element.name,
  //               template_description: templateDescription(
  //                 newData["rules"].description,
  //                 element.category
  //               ),
  //             };
  //             const policydocumentCreator = PolicyDocumentCreator(
  //               otherContents
  //             );
  //             Packer.toBlob(policydocumentCreator).then((blob) => {
  //               var timeStamp = Math.floor(Date.now());
  //               PolicyDocData = blob;
  //             });
  //           }
  //         })
  //       );
  //     });

  //     workplanData = [];
  //     Object.keys(newData).map((key, index) =>
  //       newData[key]["description"].map((element: any) => {
  //         if (key === "policies") {
  //           Contents = {
  //             data: policyDescription(
  //               newData["rules"].description,
  //               element.category
  //             ),
  //             title: element.name,
  //             template_description: templateDescription(
  //               newData["rules"].description,
  //               element.category
  //             ),
  //           };
  //           const policydocumentCreator = PolicyDocumentCreator(Contents);
  //           Packer.toBlob(policydocumentCreator).then((blob) => {
  //             var timeStamp = Math.floor(Date.now() / 1000);
  //             zip
  //               .folder("Compliance/Policy")
  //               .file(element.name + "_" + timeStamp + ".docx", blob, { base64: true });
  //           });
  //         }
  //       })
  //     );

  //   }).then((contentVal: any) => {
  //     if (Contents) {
  //       Packer.toBlob(PolicyDocumentCreator(Contents)).then((blob) => {
  //         zip.generateAsync({ type: "blob" }).then(function (content: any) {
  //           saveAs(content, folderName ? folderName : complianceName + ".zip");
  //           setLoading(false);
  //         })
  //       });
  //     }
  //   });
  // };

  // const downloadAllLawPdf = async (lawDoc: any) => {
  //   const doc = await lawDoc.map(async (val: any, index: any) => {
  //     const lawUrl = val.url.slice(1);
  //     const DocUrl = serverUrl + lawUrl;
  //     let docFileName = ''
  //     let fileType = ".pdf";
  //     let fetchDoc = await fetch(DocUrl, {
  //       method: "GET",
  //     }).then((res) => res.blob()).then((response) => {
  //       const timeStamp = Math.floor(Date.now() / 1000);
  //       const fileName = val.name;
  //       docFileName = fileName + "_" + timeStamp;
  //       zip.folder("Compliance/Laws").file(docFileName + fileType, response);
  //     })
  //   })
  //   return await Promise.all(doc).then(results => {
  //     return doc;
  //   })
  // }

  const downloadAllOffice = async (Data: any) => {
    setLoading(true)
    const lawUrl = dataOrgUser.complianceInfos[0].compliance_document[0].url.slice(1);
    const DocUrl = serverUrl + lawUrl;
    let fetchDoc = await fetch(DocUrl, {
      method: "GET",
    }).then((res) => res.blob()).then((response) => {
      saveAs(response, folderName ? folderName : complianceName + ".zip");
      setLoading(false)
    });
  };

  async function getNewData(newData: any) {
    return new Promise(async (resolve) => {
      await fetch(newData.laws.description).then((response: any) => {
        if (response.status === 200) {
          // downloadAllOffice(newData);
        }
      });
    });
  }

  // Display in Compliance UI
  let workplan = data;
  if (workplan && workplan.new !== undefined && workplan.new.length !== 0) {
    {
      /** Code for show Law, Rules, Policies and Workplan for Main office only */
    }
    // // newData.parentLaws.description = workplan.new[0].parentLaws;
    // newData.laws.description = workplan.new[0].laws;
    // newData.rules.description = workplan.new[0].rules;
    // newData.policies.description = workplan.new[0].policies;
    // newData.workplans.description = workplan.new[0].workplans;
    {
      /** End of code */
    }

    {
      /** Code for display all law for Main and Other office into On place, Since 'Main' and 'Other' office tab is no longer available in UI */
    }
    let listOlaw: any = [];
    let listORules: any = [];
    let listOPolicy: any = [];
    let listOWorkplan: any = [];
    const assignLaw = Promise.all(
      workplan.new.map((obj: any, index: any) => {
        obj.laws.map((val: any) => {
          listOlaw.push(val);
        });
        obj.rules.map((val: any) => {
          listORules.push(val);
        });
        obj.policies.map((val: any) => {
          listOPolicy.push(val);
        });
      })
    );
    newData.laws.description = Array.from(new Set(listOlaw));
    newData.rules.description = Array.from(new Set(listORules));
    newData.policies.description = Array.from(new Set(listOPolicy));
    // newData.workplans.description =  Array.from(new Set(listOWorkplan));
    {
      /** End of Code */
    }
  }
  if (workplan && workplan.pre !== undefined && workplan.pre.length !== 0) {
    // preData.parentLaws.description = workplan.pre[0].parentLaws;
    preData.laws.description = workplan.pre[0].laws;
    preData.rules.description = workplan.pre[0].rules;
    preData.policies.description = workplan.pre[0].policies;
    preData.workplans.description = workplan.pre[0].workplans;
  }

  // const handleBack = () => {
  //   setStepper(client, stepperObject.review.name, stepperObject.review.value);
  //   history.push(routeConstant.REVIEW, param);
  // };

  const goToCompliancList = () => {
    let val = {
      clientInfo: param.clientInfo
    }
    history.push(routeConstant.COMPLIANCE_LIST, val);
  };

  const enableBackdrop = () => {
    if (loading) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      
      {complianceList ? null : (
        <div>
          <div className={styles.TabContainer}>
            <div>
              {/* <h3>Main Office</h3> */}
              { enableBackdrop() ? <SimpleBackdrop /> : null }
              <ComplianceReport
                reportData={{ new: newData, pre: preData, param: param }}
              ></ComplianceReport>
            </div>
            <div className={styles.ComplianceButtons}>
            {/* <Button
                variant="contained"
                color="primary"
                onClick={() => { downloadMainOffice(newData) }}
                >
                  {'Download for Main office'}
               </Button> */}
            {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { downloadAllOffice(newData) }}
                >
                  {loading && <span>Please Wait</span>}
                  {!loading && 'Download for all offices'}
                </Button> */}
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} className={styles.FooterActions}>
              <div className={styles.ComplianceButtons}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    downloadAllOffice(newData);
                  }}
                >
                  {"Download all Documents"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToCompliancList}
                >
                  {"Done"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default MainOfficeCompliance;
