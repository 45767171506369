import { gql } from '@apollo/client';
import { GET_ORGANIZATIONTYPE } from './OrganizationType';

export const GET_EMPLOYEE_DATA = gql`
  query OrganizationLocations($where: JSON) {
    organizationLocations(where: $where) {
      id
      address_id {
        id
        city
        location_type
        state {
          name
        }
      }
      contact_id {
        id
        name
      }
      no_of_employees
      collect_pii_information
      pii_data_field {
        id
        name
      }
    }
  }

`;
// export const GET_EMPLOYEE_DATA = gql`
//   query OrganizationLocations($orgId: ID!, $location_type: String!) {
//     organizationLocations(
//       where: { address_id: { location_type: $location_type }, contact_id: $orgId }
//     ) {
//       id
//       address_id {
//         id
//         city
//         location_type
//         state {
//           name
//         }
//       }
//       contact_id {
//         id
//         name
//       }
//       no_of_employees
//       collect_pii_information
//       pii_data_field {
//         id
//         name
//       }
//     }
//   }
// `;