import React from "react";
import styles from "./Header.module.css";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Logout from "../../../../containers/Auth/Logout/Logout";
import Cookies from 'js-cookie';

export const Header: React.FC = () => (
  <div className={styles.Header} data-testid="Header">
    <Card className={styles.root}>
      <Grid container>
      <Grid item xs={12} className={styles.RALogoImg}>
          <div>
            <img src={process.env.PUBLIC_URL + "/CyberCompliance360_logo.png"} alt="user icon" />
          </div>
        </Grid>
        <Grid item xs={12} className={styles.userInfo}>
          <span className={styles.userNameLabel}>
            {Cookies.getJSON('cc_contact').name}
            &nbsp;&nbsp;|&nbsp;&nbsp;
          </span>
          <span className={styles.userNameLabel}>
            <a className={styles.logout} onClick={Logout}>
              Logout
            </a>
          </span>
        </Grid>
      </Grid>
    </Card>
  </div>
);

export default Header;
