import React, { useState, useEffect } from "react";
import styles from "./TopStepper.module.css";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import {topStepperMenu} from "../../../../../config/menu";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import { Button } from "../../../Form/Button/Button";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CLIENT_COMPLIANCESTEPPER } from "../../../../../graphql/mutations/ComplienceStepper";
import CCstepper from "../../../../../common/stepperMenu.json";
import { getActiveFormStep } from "../../../../../services/Data";
 
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: "#121412",
    },
  },
  completed: {
    "& $line": {
      backgroundImage: "#121212",
    },
  },
  line: {
    height: 0,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export interface StepperProps {
  obj?: any,
  param?: any,
  handleSave?: any;
  navigate?: boolean;
}

export const TopStepper: React.FC<StepperProps> = ({
  obj,
  param,
  handleSave,
  navigate
}: StepperProps) => {

  let stepperObject = CCstepper;
  const nextValue = useQuery(CLIENT_COMPLIANCESTEPPER);
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<any>();
  const [step, setStep] = useState<any>();
  const allSteps = topStepperMenu;

  if (nextValue.data) {
    if (activeStep !== nextValue.data.activeStep) {
      if (nextValue.data.complianceStep === stepperObject.companyProfile.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if ( nextValue.data.complianceStep === stepperObject.mainOffice.name ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if ( nextValue.data.complianceStep === stepperObject.otherOffice.name ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if ( nextValue.data.complianceStep === stepperObject.customer.name ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === stepperObject.review.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if ( nextValue.data.complianceStep === stepperObject.workplan.name ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
    }
  }

  const handleTab = (e: any, i: number, label: any) => {
    if (navigate) {
      setStep(i);
      e.preventDefault();
      if (nextValue.data) {
        if (i !== parseInt(nextValue.data.activeStep)) {
          obj.path = label.path;
          handleSave(obj);
        }
      }
      if (nextValue.data !== undefined && nextValue.data.activeStep) {
        history.push(label.path, param)
      }
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        className={styles.ReactStepper}
        activeStep={parseInt(activeStep) > getActiveFormStep() ? activeStep : getActiveFormStep()}
        connector={<ColorlibConnector  />
        }
      >
        {allSteps.map((label: any, i: number) => (
          <Step className={styles.ReactStep}>
            <Button
              color={Number(step) === i ? "primary" : "secondary"}
              variant="contained"
              onClick={(e: any) => handleTab(e, i, label)}
            >
              {label.title}
            </Button>
          </Step>
        ))
      }
      </Stepper>
    </div>
  );
}

export default TopStepper;
