import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation CreateCategory(
    $name: String!
    $template_description : String
    ) {
    createCategory(input: { data: { name: $name template_description : $template_description } }) {
      category {
        id
        name
        template_description
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $id: ID!
    $name: String!
    $template_description: String
  ) {
    updateCategory(
      input: {
        where: { id: $id }
        data: { name: $name, template_description: $template_description }
      }
    ) {
      category {
        id
        name
        template_description
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(input: { where: { id: $id } }) {
      category {
        id
        name
        template_description
      }
    }
  }
`;
