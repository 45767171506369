// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DialogBox_DialogboxPaper__1EO-s {\n  border-radius: 24px !important;\n  margin-top: 66px !important;\n  /* WIDTH:50%; */\n  /* width: 500px !important; */\n  border-bottom: 1px solid #0051A8 !important;\n  width: auto !important;\n  max-width: 800px !important;\n  height: auto !important\n}\n\n\n.DialogBox_Dialogbox__15C8g {\n  max-width: 80%;\n  min-width: 50% !important;\n  margin: 0 auto;\n  max-height: 550px;\n  /* height: unset !important; */\n}\n\n.DialogBox_DialogActions__3fFSg {\n  padding: 16px !important;\n  border-top: 1px solid #0051A8;\n}\n\n.DialogBox_DialogTitle__32xFy, .DialogBox_DialogBody__IE_rV {\n  padding: 0px !important;\n}\n\nDialogTitle>* {\n  font-size: 24px !important\n}\n\n.DialogBox_ReactRootDialog__2q-T0 {\n  background-color: rgba(255, 255, 255, 0.85);\n}\n\n.DialogBox_DialogBody__IE_rV form {\n  padding: 16px !important;\n  margin: 10px auto 0 auto;\n  min-width: 300px;\n}", ""]);
// Exports
exports.locals = {
	"DialogboxPaper": "DialogBox_DialogboxPaper__1EO-s",
	"Dialogbox": "DialogBox_Dialogbox__15C8g",
	"DialogActions": "DialogBox_DialogActions__3fFSg",
	"DialogTitle": "DialogBox_DialogTitle__32xFy",
	"DialogBody": "DialogBox_DialogBody__IE_rV",
	"ReactRootDialog": "DialogBox_ReactRootDialog__2q-T0"
};
module.exports = exports;
