export const getJurisdictionList = [
  { label: "State", id: "State" },
  { label: "Federal", id: "Federal" },
];

export const getTargetList = [
  { label: "Management", id: "Management" },
  { label: "Employee", id: "Employee" },
];

export const isActiveFilters = [
  { label: "Yes", id: "true" },
  { label: "No", id: "false" },
];
