import React, { useState, useEffect } from "react";
import styles from "./Partner.module.css";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Button } from "../../../../components/UI/Form/Button/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { AddEditForm } from "../../../../components/UI/AddEditForm/AddEditForm";
import Input from "../../../../components/UI/Form/Input/Input";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Alert from "../../../../components/UI/Alert/Alert";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as routeConstant from "../../../../common/RouteConstants";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "../../../../components/UI/Table/MaterialTable";
import Loading from "../../../../components/UI/Layout/Loading/Loading";
import {
  SUCCESS,
  UPDATE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../../common/MessageConstants";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_CONTACT,
  UPDATE_CONTACT,
} from "../../../../graphql/mutations/Contacts";
import { CREATE_ORG } from "../../../../graphql/mutations/Organization";
import {
  CRREATE_PARTNER_SUBSCRIPTION,
  UPDATE_PARTNER_SUBSCRIPTIONS,
} from "../../../../graphql/mutations/PartnerSubscription";
import { GET_ORGANIZATION } from "../../../../graphql/queries/Organization";
import {
  CREATE_ADDRESS,
  UPDATE_PARTNER_ADDRESS,
} from "../../../../graphql/mutations/Address";
import { GET_PARTNER_SUBSCRIPTION } from "../../../../graphql/queries/PartnerSubscription";
import { GET_ADDRESS } from "../../../../graphql/queries/Address";
import * as validations from "../../../../common/validateRegex";
import logout from "../../../../containers/Auth/Logout/Logout";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";

export const Partner: React.FC = (props: any) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [partnerName, setPartnerName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [newData, setNewData] = useState<any>([]);
  const [rowData, setRowData] = useState<any>();
  const [partnerSubsID, setPartnerSubsID] = useState<any>();

  //table
  const column = [
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "phone" },
  ];

  const [isError, setIsError] = useState<any>({
    partnerName: "",
    address: "",
    email: "",
    phoneNumber: "",
  });
  const history = useHistory();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [CCsubscription, setCCsubscription] = useState(false);
  const [RAsubscription, setRAsubscription] = useState(false);
  const [addPartner, setAddPartner] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [createContact] = useMutation(CREATE_CONTACT,
    {
      onError:()=>{
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [createOrganization] = useMutation(CREATE_ORG,
    {
      onError:()=>{
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [partnerSubscription] = useMutation(CRREATE_PARTNER_SUBSCRIPTION,
    {
      onError:()=>{
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [updatePartnerSubscription] = useMutation(UPDATE_PARTNER_SUBSCRIPTIONS,
    {
      onError:()=>{
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [createAddress] = useMutation(CREATE_ADDRESS,
    {
      onError:()=>{
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [updateAddress] = useMutation(UPDATE_PARTNER_ADDRESS,
    {
      onError:()=>{
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [UpdateContact] = useMutation(UPDATE_CONTACT,
    {
      onError:()=>{
        history.push(routeConstant.ADD_PARTNER)
      }
    });

  const { data: Org, loading: loadOrg, error: errorOrg,  refetch: refetchOrg } = useQuery(
    GET_ORGANIZATION,
    {
      variables: {
        where: { subtype: "Partner" },
        sort: "created_at:desc",
      },
      onCompleted: (data: any) => {
        setShowBackdrop(false)
        createTableDataObject(data.organizations);
        let contact_id_array = data.organizations.map(
          (val: any) => val.contact_id.id
        );
      },
      fetchPolicy: "cache-and-network",
    }
  );

  // Restore Address
  const [getaddress, { data: dataAD, loading: loadingAD }] = useLazyQuery(
    GET_ADDRESS,
    {
      onCompleted: (data: any) => {
        if (data.addresses && data.addresses.length >= 1)
          setAddress(data.addresses[0].address_line_1);
      },
      fetchPolicy: "cache-and-network",
      onError:()=>{
        history.push(routeConstant.ADD_PARTNER)
      }
    }
  );

  useEffect(() => {
    if (dataAD !== undefined) {
      if (dataAD.addresses.length >= 0) {
        setSubmitDisabled(false);
      }
      if (dataAD.addresses.length === 0) {
        setSubmitDisabled(true);
      }
    }
  }, [dataAD]);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  useEffect(() => {
    if (props !== undefined) {

      if (
        props.location &&
        props.location.state &&
        props.location.state &&
        props.location.state.AddPartner
      ) {
        setOpenEdit(true);
        setAddPartner(true);
      }
      if (
        props.location &&
        props.location.state &&
        props.location.state &&
        props.location.state.partner
      ) {
        setPartnerName(props.location.state.partner);
        setOpenEdit(true);
      }
      if (
        props.location &&
        props.location.state &&
        props.location.state &&
        props.location.state.email
      ) {
        setEmail(props.location.state.email);
      }
      if (
        props.location &&
        props.location.state &&
        props.location.state &&
        props.location.state.id
      ) {
        getaddress({
          variables: {
            where: {
              contact_id: props.location.state.id,
            },
          },
        });
        getPartnerSubs({
          variables: {
            where: { contact_id_in: props.location.state.id },
          },
        });
      }
      if (
        props.location &&
        props.location.state &&
        props.location.state &&
        props.location.state.phone
      )
        setPhoneNumber(props.location.state.phone);
    }
  }, [dataAD]);

  const [getPartnerSubs, { data: dataSubs, loading: loadSubs }] = useLazyQuery(
    GET_PARTNER_SUBSCRIPTION,
    {
      onCompleted: (data: any) => {
        setShowBackdrop(false);
        setPartnerSubsID(data.partnerSubscriptions[0].id);
        setCCsubscription(data.partnerSubscriptions[0].cc_subscription);
        setRAsubscription(data.partnerSubscriptions[0].ra_subscription);
      },
      fetchPolicy: "cache-and-network",
      onError:()=>{
        setShowBackdrop(false)
        history.push(routeConstant.ADD_PARTNER,props.location.state)
      }
    }
  );

  const createTableDataObject = (data: any) => {
    let arr: any = [];
    data.map((element: any, index: any) => {
      let obj: any = {};
      obj["id"] = element.contact_id.id;
      obj["name"] = element.contact_id.name;
      obj["email"] = element.contact_id.email;
      obj["phone"] = element.contact_id.phone;
      obj["partnerOrgId"] = element.id;
      arr.push(obj);
    });
    setNewData(arr);
  };


  if (errorOrg) {
    let error = { message: "Error" };
    return (
      <div className="error">
        Error!
        {logout()}
      </div>
    )
  }

  const handleClickOpen = (rowData: any) => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
    setPartnerName("");
    setEmail("");
    setPhoneNumber("");
    setAddress("");
    setCCsubscription(false);
    setRAsubscription(false);
    setOpenEdit(true);
    setAddPartner(true);
  };

  const handleSubscription = (
    event: any,
    value: React.SetStateAction<boolean>
  ) => {
    if (event.target.name === "CCsubscription") {
      setCCsubscription(value);
    }
    if (event.target.name === "RAsubscription") {
      setRAsubscription(value);
    }
  };

 
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "partnerName") {
      setPartnerName(event.target.value);
      let err = event.target.value === "" || null ? "Required" : "";
      setIsError((error: any) => ({
        ...error,
        partnerName: err,
      }));
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);
      let err = event.target.value === "" || null ? "Required" : "";
      setIsError((error: any) => ({
        ...error,
        email: err,
      }));
      if (!err) {
        if (!validations.EMAIL_REGEX.test(event.target.value)) {
          let errors = "Please enter valid email address.";
          setIsError((error: any) => ({
            ...error,
            email: errors,
          }));
        }
      }
    }
    if (event.target.name === "phoneNumber") {
      setPhoneNumber(event.target.value);
    }
    if (event.target.name === "address") {
      setAddress(event.target.value);
    }
    setSubmitDisabled(checkValidation);
  };

  const checkValidation = () => {
    let validation = false;
    if (isError.partnerName !== "" && isError.address !== "") {
      validation = true;
    }
    return validation;
  };

  const handleInputErrors = () => {
    let foundErrors = false;
    if (!partnerName) {
      let err = "Required";
      setIsError((error: any) => ({
        ...error,
        partnerName: err,
      }));
      foundErrors = true;
    }
    if (!email) {
      let errors = "Required";
      setIsError((error: any) => ({
        ...error,
        email: errors,
      }));
      foundErrors = true;
    }
    if (email && !validations.EMAIL_REGEX.test(email)) {
      let errors = "Please enter valid email address.";
      setIsError((error: any) => ({
        ...error,
        email: errors,
      }));
      foundErrors = true;
    }

    return foundErrors;
  };
  const handleSubmit = () => {
    if (!handleInputErrors()) {
      if (!addPartner) {
        if (props.location && props.location.state && props.location.state.id) {
          setShowBackdrop(true)
          UpdateContact({
            variables: {
              id: props.location.state.id,
              name: partnerName,
              email: email,
              phone: phoneNumber,
              contact_type: "Organization",
            },
          })
            .then((userRes) => {
              updateAddress({
                variables: {
                  id: dataAD.addresses[0].id,
                  contact_id: props.location.state.id,
                  address1: address,
                  address2: "",
                  city: "",
                  state: "",
                  zip: "",
                  locationType: "Office",
                },
              })
                .then((res: any) => {
                  updatePartnerSubscription({
                    variables: {
                      id: partnerSubsID,
                      contact_id: props.location.state.id,
                      CCSubscription: CCsubscription,
                      RAsubscription: RAsubscription,
                    },
                  }).then((res: any) => {
                    updateAddress({
                      variables: {
                        id: dataAD.addresses[0].id,
                        contact_id: props.location.state.id,
                        address1: address,
                        address2: "",
                        city: "",
                        state: "",
                        zip: "",
                        locationType: "Office",
                      },
                    })
                      .then((res: any) => {
                        setFormState((formState) => ({
                          ...formState,
                          isSuccess: false,
                          isUpdate: true,
                          isDelete: false,
                          isFailed: false,
                          errMessage: " " + partnerName + " ",
                        }));
                        setShowBackdrop(false)
                        backToList();
                      })
                      .catch((Error) => {
                        setSubmitDisabled(false);
                      });
                  });
                })
                .catch((Error) => {
                  setSubmitDisabled(false);
                });
            })
            .catch((err) => {
              let error = err.message;
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: " " + error,
              }));
              setSubmitDisabled(false);
            });
          setRowData("");
        } else {
          setShowBackdrop(true)
          UpdateContact({
            variables: {
              id: rowData.id,
              name: partnerName,
              email: email,
              phone: phoneNumber,
              contact_type: "Organization",
            },
          })
            .then((userRes) => {
              updateAddress({
                variables: {
                  id: dataAD.addresses[0].id,
                  contact_id: rowData.id,
                  address1: address,
                  address2: "",
                  city: "",
                  state: "",
                  zip: "",
                  locationType: "Office",
                },
              })
                .then((res: any) => {
                  updatePartnerSubscription({
                    variables: {
                      id: partnerSubsID,
                      contact_id: rowData.id,
                      CCSubscription: CCsubscription,
                      RAsubscription: RAsubscription,
                    },
                  }).then((res: any) => {
                    updateAddress({
                      variables: {
                        id: dataAD.addresses[0].id,
                        contact_id: rowData.id,
                        address1: address,
                        address2: "",
                        city: "",
                        state: "",
                        zip: "",
                        locationType: "Office",
                      },
                    })
                      .then((res: any) => {
                        setFormState((formState) => ({
                          ...formState,
                          isSuccess: false,
                          isUpdate: true,
                          isDelete: false,
                          isFailed: false,
                          errMessage: " " + partnerName + " ",
                        }));
                        backToList();
                        setShowBackdrop(false)
                      })
                      .catch((Error) => {
                        setSubmitDisabled(false);
                      });
                  });
                })
                .catch((Error) => {
                  setSubmitDisabled(false);
                });
            })
            .catch((err) => {
              let error = err.message;
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: " " + error,
              }));
              setSubmitDisabled(false);
            });
          setRowData("");
        }
      } else {
        setShowBackdrop(true)
        createContact({
          variables: {
            name: partnerName,
            email: email,
            phone: phoneNumber,
            contact_type: "Organization",
          },
        })
          .then((contRes: any) => {
            createOrganization({
              variables: {
                contact_id: contRes.data.createContact.contact.id,
                subtype: "Partner",
              },
            }).then((orgRes: any) => {
              partnerSubscription({
                variables: {
                  contact_id: contRes.data.createContact.contact.id,
                  CCSubscription: CCsubscription,
                  RAsubscription: RAsubscription,
                },
              })
                .then((res: any) => {
                  createAddress({
                    variables: {
                      contact_id: contRes.data.createContact.contact.id,
                      address1: address,
                      locationType: "Office",
                    },
                  })
                    .then((res: any) => {
                      setFormState((formState) => ({
                        ...formState,
                        isSuccess: true,
                        isUpdate: false,
                        isDelete: false,
                        isFailed: false,
                        errMessage: " " + partnerName + " ",
                      }));
                      setShowBackdrop(false)
                      backToList();
                      refetchOrg();
                    })
                    .catch((err) => {});
                })
                .catch((err) => {});
            });
          })
          .catch((err) => {
            let error = err.message;
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: " " + error,
            }));
          });
      }
    }
  };

  const handleClickAdd = (rowData: any) => {
    let viewdata: any = { propsData: rowData };
    history.push(routeConstant.PARTNER_USER, viewdata);
  };

  const backToList = () => {
    props.location.state = {};
    setIsError({ error: null });
    setOpenEdit(false);
  };

  const handleClickEdit = (rowData: any, event: any) => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
    setAddPartner(false);
    setRowData(rowData);
    setOpenEdit(true);
    getaddress({
      variables: {
        where: {
          contact_id: rowData.id,
        },
      },
    });
    getPartnerSubs({
      variables: {
        where: { contact_id_in: rowData.id },
      },
    });
    if (rowData) {
      if (rowData.name) {
        setPartnerName(rowData.name);
      }
      if (rowData.email) {
        setEmail(rowData.email);
      }
      if (rowData.phone) {
        setPhoneNumber(rowData.phone);
      }
    }
    // }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Partner
      </Typography>
      { showBackdrop ? <SimpleBackdrop /> : null}
      {!openEdit ? (
        <Grid className={styles.TableWrap}>
          <Grid container>
            <Grid item xs={12} sm={12} md={9} className={styles.FilterWrap}>
            </Grid>
            <Grid item xs={12} sm={12} md={3} className={styles.FilterAddWrap}>
              <div className={styles.FilterInput}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  <AddCircleIcon className={styles.EditIcon} />
                  &nbsp; Partner
                </Button>
              </div>
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Partner <strong>{formState.errMessage}</strong> {SUCCESS}
              </Alert>
            ) : null}
            {formState.isUpdate ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Partner <strong>{formState.errMessage}</strong> {UPDATE}
              </Alert>
            ) : null}
            <MaterialTable
              columns={column}
              data={newData}
              actions={[
                {
                  icon: () => <img className={styles.EditIcon}
                  src={
                    process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
                  }
                  alt="edit icon"
                />,
                  tooltip: "Edit",
                  onClick: (event: any, rowData: any) => {
                    handleClickEdit(rowData, event);
                  },
                },
                {
                  icon: () => <AddCircleIcon className={styles.circleIcon}/>,
                  tooltip: "Add Partner User",
                  onClick: (event: any, rowData: any) => {
                    handleClickAdd(rowData);
                  },
                },
              ]}
              options={{
                headerStyle: {
                  backgroundColor: "#EFF6FD",
                  color: "#002F60",
                },
                actionsColumnIndex: -1,
                paging: true,
                sorting: true,
                search: false,
                filter: true,
                draggable: false,
              }}
            />
          </Paper>
        </Grid>
      ) : (
        <AddEditForm
          handleOk={handleSubmit}
          handleCancel={backToList}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {formState.isFailed ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {FAILED}
                  {formState.errMessage}
                </Alert>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                label="Partner Name*"
                name="partnerName"
                value={partnerName}
                onChange={handleChange}
                error={isError.partnerName}
                helperText={isError.partnerName}
              >
                Partner Name
              </Input>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                label="Email*"
                name="email"
                value={email}
                onChange={handleChange}
                error={isError.email}
                helperText={isError.email}
              >
                E-mail
              </Input>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                label="Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleChange}
                error={isError.phoneNumber}
                helperText={isError.phoneNumber}
              >
                Phone Number
              </Input>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                label="Address"
                name="address"
                value={address}
                onChange={handleChange}
                error={isError.address}
                helperText={isError.address}
              >
                Address
              </Input>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}  className={styles.SubscriptionBox}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={CCsubscription}
                        onChange={handleSubscription}
                        name="CCsubscription"
                        color="primary"
                      />
                    }
                    label="CC Subscription"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}  className={styles.SubscriptionBox}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={RAsubscription}
                            onChange={handleSubscription}
                            name="RAsubscription"
                            color="primary"
                          />
                        }
                        label="RA Subscription"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AddEditForm>
      )}
    </React.Fragment>
  );
};

export default Partner;
