import React, { useEffect, useState } from "react";
import styles from "./ClientForm.module.css";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Button } from "../../../components/UI/Form/Button/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Input from "../../../components/UI/Form/Input/Input";
import Alert from "../../../components/UI/Alert/Alert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  SUCCESS,
  UPDATE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import { useHistory } from "react-router-dom";
import { GET_ORGANIZATION } from "../../../graphql/queries/Organization";
import { GET_CLIENT_SUBSCRIPTION } from "../../../graphql/queries/ClientSubscription";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_CONTACT,
  UPDATE_CONTACT,
} from "../../../graphql/mutations/Contacts";
import {
  CREATE_ORG,
} from "../../../graphql/mutations/Organization";
import {
  CREATE_CLIENT_SUBSCRIPTION,
  UPDATE_CLIENT_SUBSCRIPTIONS,
} from "../../../graphql/mutations/ClientSubscription";
import { CSET_URL } from "../../../config/index";
import { CreateCSETUser } from "../../../graphql/mutations/CSETUserDetails";
import { GET_INDIVIDUAL } from "../../../graphql/queries/Individual";

export const ClientForm: React.FC = (props: any) => {
  const history = useHistory();
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState<any>(true);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [CCsubscription, setCCsubscription] = useState<any>(false);
  const [RAsubscription, setRAsubscription] = useState<any>(false);
  const [isError, setIsError] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [ContactId, setContactId] = useState("");
  const headerObj = {
    "Content-Type": "application/json",
    "Accept": "*/*"
  };
  let RegistrationUserData = {
    PrimaryEmail: email,
    ConfirmEmail: email,
    FirstName: firstName,
    LastName: lastName,
    IsSuperUser: "false",
    PasswordResetRequired: "false",
    Password: "Musu30@in",
    Salt: "rGv21R7m7MFLedv4VLkKFuNQ46yJdlZZ",
  };
  let NewCSetUserLoginData = {
    Email: email,
    Password: "Musu30@in",
    TzOffset: new Date().getTimezoneOffset(),
    Scope: "CSET",
  };
  const [createContact] = useMutation(CREATE_CONTACT);
  const [UpdateContact] = useMutation(UPDATE_CONTACT);
  const [createOrganization] = useMutation(CREATE_ORG);
  const [clientSubscription] = useMutation(CREATE_CLIENT_SUBSCRIPTION);
  const [updateClientSubscription] = useMutation(UPDATE_CLIENT_SUBSCRIPTIONS);
  const [createCSETUser] = useMutation(CreateCSETUser);
  const [pgClientId, setPgClientId] = useState("");
  const [pgId, setPgId] = useState("");

  const [getClientSubs, { data: dataSubs, loading: loadSubs }] = useLazyQuery(
    GET_CLIENT_SUBSCRIPTION,
    {
      onCompleted: (data: any) => {
        updateClientSubs(data)
      },
    }
  );

  // const [getIndividual, { data: iData, loading: iLoading }] = useLazyQuery(GET_INDIVIDUAL, {
  //   onCompleted: () => {
  //     if (iData.individuals.length !== 0) {
  //       createContacts(iData.individuals[0]);
  //     }
  //   },
  //   onError: () => { }
  // });

  const [getOrganization, { data: Org }] = useLazyQuery(
    GET_ORGANIZATION,
    {
      onCompleted: (data: any) => {
        if (data.organizations.length !== 0) {
          if (data.organizations[0].subtype === "Partner") {
            createContacts(data.organizations[0]);
          }
          if (data.organizations[0].subtype === "Client") {
            updateContacts(data.organizations[0])
          }
        }
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  const handleSubscription = (
    event: any,
    value: React.SetStateAction<boolean>
  ) => {
    if (event.target.name === "CCsubscription") {
      setCCsubscription(value);
    }
    if (event.target.name === "RAsubscription") {
      setRAsubscription(value);
    }
  };

  const actionOnSubscription = (id: any) => {
    // If only client has been created without any Subscription
    if (!RAsubscription || !CCsubscription) {
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: " " + firstName + " " + lastName + " ",
      }));
    }

    if (RAsubscription) {
      addCSetUser(id);
    }

    if (RAsubscription) {
      setError(false);
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: " " + firstName + lastName + " ",
      }));
    }

    if (CCsubscription) {
      setError(false);
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: " " + firstName + lastName + " ",
      }));
    }
  };

  const addCSetUser = async (Clientid: any) => {
    try {
      await fetch(CSET_URL + "api/ResetPassword/RegisterUser", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(RegistrationUserData),
      })
        .then(async () => {
          await fetch(CSET_URL + "api/auth/login", {
            method: "POST",
            headers: headerObj,
            body: JSON.stringify(NewCSetUserLoginData),
          })
            .then((response) => response.json())
            .then((data) => {
              createCSETUser({
                variables: {
                  username: email,
                  password: "Musu30@in",
                  contact_id: Clientid,
                  cset_user_id: data.UserId.toString()
                }
              })
                .then(() => {
                  setError(false);
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: true,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: false,
                    errMessage: " " + firstName + " " + lastName + " ",
                  }));
                })
                .catch((error) => {
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: false,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: true,
                    errMessage: " Registration Failed ",
                  }));
                });
              localStorage.setItem("ra_user_data", JSON.stringify(data.Token));
            });
        })
        .catch((error: any) => {
          setError(true);
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: " Registration Failed ",
          }));
        });
    } catch (e) {
      setError(true);
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: " Registration Failed ",
      }));
      console.error("error", e);
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "pgClientId") {
      setPgClientId(event.target.value);
    }
    if (event.target.name === "FirstName") {
      setFirstName(event.target.value);
    }
    if (event.target.name === "LastName") {
      setLastName(event.target.value);
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "phoneNumber") {
      setPhoneNumber(event.target.value);
    }
    if (event.target.name === "pgId") {
      setPgId(event.target.value);
    }
  };

  const handleSubmit = () => {
    if (props.location.pathname.includes("/pg-client-form/add")) {
      getOrganization({
        variables: {
          where: {
            subtype: "Partner",
            pg_id: pgId
          }
        },
      });
      // getIndividual({
      //   variables: {
      //     where: {
      //       pg_user_id: pgId
      //     }
      //   }
      // })
    }
    if (props.location.pathname.includes("/pg-client-form/edit")) {
      getOrganization({
        variables: {
          where: {
            subtype: "Client",
            pg_id: pgClientId
          }
        },
      });
    }
  };

  const createContacts = (val: any) => {
    createContact({
      variables: {
        name: firstName,
        email: email,
        phone: phoneNumber,
        contact_type: "Organization",
        last_name: lastName
      }
    })
      .then((contRes: any) => {
        insertIntoOrganization(val, contRes);
      })
      .catch((err) => {
        setError(true);
        let error = err.message;
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: error,
        }));
      });
  }
  const insertIntoOrganization = (val: any, contRes: any) => {
    createOrganization({
      variables: {
        contact_id: contRes.data.createContact.contact.id,
        subtype: "Client",
        partner_id: val.contact_id.id,
        pg_id: JSON.parse(pgClientId)
      }
    })
      .then((orgRes: any) => {
        insertClientSubs(contRes)

      })
      .catch((err) => {
        setError(true);
        let error = err.message;
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: error,
        }));
      });
  }

  const insertClientSubs = (contRes: any) => {
    clientSubscription({
      variables: {
        contact_id: contRes.data.createContact.contact.id,
        CCSubscription: CCsubscription,
        RAsubscription: RAsubscription
      }
    }).then((res: any) => {
      actionOnSubscription(contRes.data.createContact.contact.id);
    });
  }

  const updateContacts = (val: any) => {
    UpdateContact({
      variables: {
        id: val.contact_id.id,
        name: firstName,
        last_name: lastName,
        email: email,
        phone: phoneNumber,
        contact_type: "Organization"
      }
    })
      .then((userRes) => {
        getClientSubscription(val)
      })
      .catch((err) => {
        setError(true);
        let error = err.message;
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: error,
        }));
      });
  }

  const getClientSubscription = (val: any) => {
    getClientSubs({
      variables: {
        where: { contact_id: val.contact_id.id }
      }
    });
  }

  const updateClientSubs = (data: any) => {
    updateClientSubscription({
      variables: {
        id: data.clientSubscriptions[0].id,
        contact_id: data.clientSubscriptions[0].contact_id.id,
        CCSubscription: CCsubscription,
        RAsubscription: RAsubscription
      }
    }).then((res: any) => {
      actionOnSubscription(data.clientSubscriptions[0].contact_id.id);
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: true,
        isDelete: false,
        isFailed: false,
        errMessage: " " + firstName + " " + lastName + " ",
      }));
    })
  }

  const backToList = () => {
    history.push("/pg-action");
    setIsError({ error: null });
    setContactId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setCCsubscription(false);
    setRAsubscription(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {<div> {props.location.pathname.includes("/pg-client-form/edit") ? "Edit Client" : "Add Client"} </div>}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Client<strong>{formState.errMessage}</strong>
              {SUCCESS}
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Client<strong>{formState.errMessage}</strong>
              {UPDATE}
            </Alert>
          ) : null}
        </Grid>
        {props.location.pathname.includes("/pg-client-form/add") ?
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="PG ID"
              name="pgId"
              value={pgId}
              onChange={handleChange}
            >
              PG ID
            </Input>
          </Grid>
          : null}
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="PG Client ID"
            name="pgClientId"
            value={pgClientId}
            onChange={handleChange}
          >
            PG Client ID
            </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="First Name *"
            name="FirstName"
            value={firstName}
            onChange={handleChange}
            error
            helperText={isError.firstName}
          >
            First Name
            </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Last Name *"
            name="LastName"
            value={lastName}
            onChange={handleChange}
            error
            helperText={isError.lastName}
          >
            Last Name
            </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Email *"
            name="email"
            value={email}
            onChange={handleChange}
            error
            helperText={isError.email}
          >
            E-mail
            </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Phone Number"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handleChange}
          >
            Phone Number
            </Input>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6} className={styles.SubscriptionBox}>
              <FormControlLabel
                control={
                  <Switch
                    checked={CCsubscription}
                    onChange={handleSubscription}
                    name="CCsubscription"
                    color="primary"
                  />
                }
                label="CC Subscription"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6} className={styles.SubscriptionBox}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={RAsubscription}
                        onChange={handleSubscription}
                        name="RAsubscription"
                        color="primary"
                      />
                    }
                    label="RA Subscription"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant={"contained"}
                onClick={backToList}
                color="secondary"
                data-testid="cancel-button"
              >
                {"Cancel"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ClientForm;
