import { gql } from "@apollo/client";

export const CREATE_CLIENT_SUBSCRIPTION = gql`
  mutation createClientSubscription(
    $contact_id: ID!,
    $CCSubscription: Boolean!,
    $RAsubscription: Boolean!
  ) {
    createClientSubscription(
      input: {
        data: {
          contact_id: $contact_id
          cc_subscription: $CCSubscription
          ra_subscription: $RAsubscription
        }
      }
    ) {
      clientSubscription {
        id
      }
    }
  }
`;

export const UPDATE_CLIENT_SUBSCRIPTIONS = gql`
  mutation updateClientSubscription (
    $id :ID!,
    $contact_id: ID!,
    $CCSubscription: Boolean!,
    $RAsubscription: Boolean!
  ) {
    updateClientSubscription (
      input: {
        where: { id: $id }
        data: {
          contact_id: $contact_id
          cc_subscription: $CCSubscription
          ra_subscription: $RAsubscription
        }
      }
    ) {
      clientSubscription {
        id
      }
    }
  }
`;

export const DELETE_CLIENT_SUBSCRIPTIONS = gql`
mutation deleteClientSubscription (
  $id: ID!
  ) {
  deleteClientSubscription(input: {where: {  id: $id }}) {
    clientSubscription {
      contact_id {
        id
        contact_type
      }
      cc_subscription
    }
  }
}
`;