import { gql } from "@apollo/client";

export const CREATE_WORKPLAN = gql`
  mutation CreateWorkplan($name: String!, $category: ID!) {
    createWorkplan(input: { data: { name: $name, category: $category } }) {
      workplan {
        id
        name
        category {
          id
        }
      }
    }
  }
`;

export const UPDATE_WORKPLAN = gql`
  mutation UpdateWorkplan($name: String!, $category: ID!, $id: ID!) {
    updateWorkplan(
      input: { where: { id: $id }, data: { name: $name, category: $category } }
    ) {
      workplan {
        id
        name
        category {
          id
        }
      }
    }
  }
`;

export const DELETE_WORKPLAN = gql`
  mutation DeleteWorkplan($id: ID!) {
    deleteWorkplan(input: { where: { id: $id } }) {
      workplan {
        id
        name
        category {
          id
        }
      }
    }
  }
`;
