// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Views_details__2rnHO {\n  align-items: center;\n  display: block !important;\n  width: 100%;\n}\n\n.Views_column__20EV5 {\n  flex-basis: 33.33%;\n}\n.Views_ComplianceAccordion__3LGQf {\n  margin: 0px 0px 6px 0px !important;\n  box-shadow: none !important;\n  border-radius: 12px !important;\n  border-width: 1px 1px 0px 1px !important;\n  border-style: solid !important;\n  border-color: #0051a8 !important;\n}\n.Views_ComplianceAccordionSummary__3wLFo {\n  min-height: auto !important;\n  background-color: #eff6fd !important;\n  border-radius: 12px !important;\n  border-bottom: 1px solid #0051a8 !important;\n}\n.Views_ComplianceAccordionSummary__3wLFo > div {\n  margin: 0px !important;\n  align-items: center;\n}\n.Views_heading__5qaRP {\n  font-weight: 500 !important;\n}\n.Views_ComplianceAccordion__3LGQf:before {\n  background-color: transparent !important;\n}\n.Views_ComplianceAccordionSummary__3wLFo > div:nth-child(2) {\n  padding: 10px 12px;\n}\n\n.Views_ComplianceAccordionDetails__SFaEd {\n  border-width: 0px 0px 1px 0px !important;\n  border-style: solid !important;\n  border-color: #0051a8 !important;\n  color: #707070 !important;\n  font-weight: 500 !important;\n  background-color: white !important;\n  padding: 15px !important;\n  border-radius: 12px !important;\n}\n\n.Views_ComplianceAccordionDetails__SFaEd > div {\n  width: 100% !important;\n}\n\n.Views_expandButtonDiv__3R-wP {\n  text-align: right;\n}\n.Views_collapseButton__Q_q0S {\n  margin-left: 10px!important;\n}\n", ""]);
// Exports
exports.locals = {
	"details": "Views_details__2rnHO",
	"column": "Views_column__20EV5",
	"ComplianceAccordion": "Views_ComplianceAccordion__3LGQf",
	"ComplianceAccordionSummary": "Views_ComplianceAccordionSummary__3wLFo",
	"heading": "Views_heading__5qaRP",
	"ComplianceAccordionDetails": "Views_ComplianceAccordionDetails__SFaEd",
	"expandButtonDiv": "Views_expandButtonDiv__3R-wP",
	"collapseButton": "Views_collapseButton__Q_q0S"
};
module.exports = exports;
