import { gql } from "@apollo/client";

export const GET_WORKPLAN = gql`
  query($where: JSON, $sort: String) {
    workplans(where: $where, sort: $sort) {
      id
      name
      category {
        id
        name
      }
    }
  }
`;
