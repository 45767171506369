// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Input_Input__CKPAM {\n  display: flex;\n  padding: 8px;\n}\n\n.Input_InputBorder__1qyxh {\n  border-radius: 12px !important;\n  border-width: 2px !important;\n}\n\n.Input_Label__1s0i6 {\n  font-size: 16px;\n  font-weight: 500;\n  color: #707070 !important;\n}\n\n.Input_HelperText__3rBIy {\n  margin-left: 16px !important;\n}\n\n/*Change border for textfield*/\n\n.Input_ReactInput__3G3TS:focus, .Input_ReactInput__3G3TS:hover fieldset, .Input_ReactInput__3G3TS input+fieldset {\n  border-color: #0051A8 !important;\n  border-width: 1px !important;\n  border-radius: 10px !important;\n}\n\n.Input_ReactInput__3G3TS > label {\n  transform: translate(14px, 16px) scale(1);\n  color: #707070 !important;\n  font-size: 14px;\n}\n\n.Input_ReactInput__3G3TS input {\n  padding: 5px 7px !important;\n  border-radius: 10px !important;\n  line-height: 1.846 !important;\n  height: 37px !important;\n  color: #707070 !important;\n  font-size: 14px;\n  background-color: #fff;\n}\n\n.Input_ReactInput__3G3TS {\n  margin-bottom: 0px !important;\n  margin-top: 0px !important;\n  max-width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"Input": "Input_Input__CKPAM",
	"InputBorder": "Input_InputBorder__1qyxh",
	"Label": "Input_Label__1s0i6",
	"HelperText": "Input_HelperText__3rBIy",
	"ReactInput": "Input_ReactInput__3G3TS"
};
module.exports = exports;
