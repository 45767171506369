// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Checkbox_Checkbox__1NlKJ {\n  display: flex;\n}\n\n.Checkbox_Label__19mF_ {\n  width: 50%;\n  align-self: center;\n  font-weight: bold;\n}\n.Checkbox_Label__19mF_ + span {\n  padding: 0px 7px 0px 0px;\n}\n.Checkbox_Checkbox__1NlKJ + span{\n  color : #707070;\n}\n\n.Checkbox_Checkbox__1NlKJ svg {\n  color: #0051a8;\n}", ""]);
// Exports
exports.locals = {
	"Checkbox": "Checkbox_Checkbox__1NlKJ",
	"Label": "Checkbox_Label__19mF_"
};
module.exports = exports;
