import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $contactId: ID!
    $noOfCustomer: Int!
    $state: ID!
    $collectePiiInfo: Boolean!
    $pii_data_field: [ID]!
  ) {
    createCustomer(
      input: {
        data: {
          contact_id: $contactId
          no_of_clients: $noOfCustomer
          state: $state
          collect_pii_information: $collectePiiInfo
          pii_data_fields: $pii_data_field
        }
      }
    ) {
      customer {
        id
        contact_id {
          id
          name
          email
          contact_type
          user_id {
            id
            username
          }
        }
        no_of_clients
        collect_pii_information
        pii_data_fields {
          id
          name
          is_active
          category {
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $id: ID!
    $contactId: ID!
    $noOfCustomer: Int!
    $state: ID!
    $collectePiiInfo: Boolean!
    $pii_data_field: [ID]!
  ) {
    updateCustomer(
      input: {where:{id:$id}
        data: {
          contact_id: $contactId
          no_of_clients: $noOfCustomer
          state: $state
          collect_pii_information: $collectePiiInfo
          pii_data_fields: $pii_data_field
        }
      }
    ) {
      customer {
        id
        contact_id {
          id
          name
          email
          contact_type
          user_id {
            id
            username
          }
        }
        no_of_clients
        collect_pii_information
        pii_data_fields {
          id
          name
          is_active
          category {
            name
          }
        }
      }
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(input: { where: { id: $id } }) {
      customer {
        id
        contact_id {
          id
          name
          contact_type
        }
        no_of_clients
        collect_pii_information
        pii_data_fields {
          id
          name
          is_active
          category {
            name
          }
        }
      }
    }
  }
`;