import { gql } from "@apollo/client";

export const CREATE_CSET_ASSESSMENT = gql`
  mutation CreateAssessment(
    $contact_id: ID!
    $assessment_id: Int!
    $assessment_type: ENUM_CSETASSESSMENT_ASSESSMENT_TYPE!
    $cset_firm_id: ID
  ) {
    createCsetAssessment(
      input: {
        data: {
          contact_id: $contact_id
          assessment_id: $assessment_id
          assessment_type: $assessment_type
          cset_firm_id: $cset_firm_id
        }
      }
    ) {
      csetAssessment {
        id
        contact_id {
          id
          email
        }
        assessment_id
        assessment_type
        cset_firm_id {
          id
          name
          firm_size
          sal
          sector {
            name
            sector_id
          }
          industry {
            name
            industry_id
            sector_id
          }
          relative_effort {
            demographics_id
            description
            size
          }
          cset_standards {
            name
            code
          }
        }
      }
    }
  }
`;

export const UPDATE_CSET_ASSESSMENT = gql`
  mutation UpdateAssessment(
    $id: ID!,
    $cset_firm_id: ID
    $assessment_type: ENUM_CSETASSESSMENT_ASSESSMENT_TYPE!
    $allow_download: Boolean
    $assessment_name: String
    $assessment_document:[ID]
  ) {
    updateCsetAssessment(
      input: {
        where: { id: $id },
        data: {
          assessment_type: $assessment_type
          cset_firm_id: $cset_firm_id
          allow_download: $allow_download
          assessment_name : $assessment_name
          assessment_document: $assessment_document
        }
      }
    ) {
      csetAssessment {
        id
        contact_id {
          name
        }
        assessment_document{
          id
          url
        }
      }
    }
  }
`;

export const DELETE_CSET_ASSESSMENT = gql`
  mutation DeleteAssessment($id: ID!) {
    deleteCsetAssessment(input: { where: { id: $id } }) {
      csetAssessment{
        id
        assessment_id
      }
    }
  }
`;