// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Button_Button__264fU {\n  padding: 4px 14px !important;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;\n  line-height: 1.846 !important;\n  border-radius: 20px !important;\n  font-weight: 500!important;\n  font-size: 14px !important;\n  text-transform: uppercase !important;\n  border: 1px solid #0051a8 !important;\n}", ""]);
// Exports
exports.locals = {
	"Button": "Button_Button__264fU"
};
module.exports = exports;
