import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import Input from "../../../components/UI/Form/Input/Input";
import { GET_ORG } from "../../../graphql/queries/Organization";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import SearchIcon from "@material-ui/icons/Search";
// import MaterialTable from "material-table";
import { useQuery, useMutation } from "@apollo/client";

import { Grid } from "@material-ui/core";
import styles from "./Company.module.css";

interface piiCategory {
  id: number;
  name: string;
}
export const Company: React.FC = () => {
  const [newData, setNewData] = useState();
  //table
  const columns = [
    { title: "Username", field: "username" },
    { title: "Company Name", field: "name" },
    { title: "Company Type", field: "organization_type" }
  ];
  //filter dropdown values

  const title = "List of Companies";

  const [filterName, setFilterName] = useState("");
  const [filterCompanyName, setFilterCompanyName] = useState("");
  const [filterCompanyType, setFilterCompanyType] = useState("");
  const [reset, setReset] = useState<boolean>(false);
  const [filters, setFilters] = useState<object>();
  const [orderBy, setOrderBy] = useState<String>();

  useEffect(()=>{
    refetchORG()
  },[])
  //filter query condition declaration
  const {
    data: dataORG,
    error: errorORG,
    loading: loadingORG,
    refetch: refetchORG
  } = useQuery(GET_ORG, {
    variables:
      // !orderBy || orderBy === ""
      //   ? { where: filters, sort: "username:asc" }// foreign key present unable tosprt
      // :
      { where: filters, sort: orderBy }
  });

  useEffect(() => {
    if (dataORG) {
      let temp: any = {};
      temp = convertTableData(dataORG.organizations);
      setNewData(temp);
    }
  }, [dataORG, filters]);

  function convertTableData(data: any) {
    let arr: any = [];
    for (let i in data) {
      let tempArr: any = {};
      tempArr["id"] = data[i].id;
      if (data[i].contact_id) {
        if (data[i].contact_id.contact_type === "Organization") {
          tempArr["username"] = data[i].contact_person_id.name;
        } else if (data[i].contact_id.contact_type === "Individual") {
          if (data[i].contact_id.user_id) {
            tempArr["username"] = data[i].contact_id.user_id.username;
          }
        }
        tempArr["name"] = data[i].contact_id.name;
      }
      if (data[i].organization_type) {
        tempArr["organization_type"] = data[i].organization_type.name;
      }
      if (
        tempArr["username"] ||
        tempArr["name"] ||
        tempArr["organization_type"]
      ) {
        arr.push(tempArr);
      }
    }
    return arr;
  }

  const nameFilter = (event: any) => {
    setFilterName(event.target.value);
  };

  const companyNameFilter = (event: any) => {
    setFilterCompanyName(event.target.value);
  };

  const companyTypeFilter = (event: any) => {
    setFilterCompanyType(event.target.value);
  };

  //for pii data
  if (loadingORG) return <Loading />;
  // if (errorORG) {
  //   return (
  //     <div className="error">
  //       Error!
  //       {/* Error! ${error.message} */}
  //     </div>
  //   );
  // }

  const handleSearch = () => {
    let searchData: any = {};
    if (filterName) {
      searchData[
        "contact_id.user_id.username_contains"
      ] = filterName.toString();
    }
    if (filterCompanyName) {
      searchData["contact_id.name_contains"] = filterCompanyName.toString();
    }
    if (filterCompanyType) {
      searchData[
        "organization_type.name_contains"
      ] = filterCompanyType.toString();
    }
    setFilters(searchData);
  };

  const resetForm = () => {
    setFilters({});
    setReset(true);
    setFilterName("");
    setFilterCompanyName("");
    setFilterCompanyType("");
    setOrderBy("");
  };

  const orderFunc = (orderedColumnId: any, orderDirection: any) => {
    let orderByColumn;
    let orderBy = "";
    if (orderedColumnId >= 0) {
      if (columns[orderedColumnId]["field"] === "username") {
        orderByColumn = "username";
      }
    }
    orderBy = orderByColumn + ":" + orderDirection;
    console.log("orderBy", orderBy);
    setOrderBy(orderBy);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={styles.layout}>
        <Typography component="h1" variant="h1" gutterBottom>
          Company list
        </Typography>
        <Grid className={styles.FilterWrap}>
          <div className={styles.FilterInput} >
            <Input
              label="User Name"
              name="filterName"
              id="combo-box-demo"
              value={filterName}
              onChange={nameFilter}
            />
          </div>
          <div className={styles.FilterInput} >
            <Input
              label="Company Name"
              name="filterCompanyName"
              id="combo-box-demo"
              value={filterCompanyName}
              onChange={companyNameFilter}
            />
          </div>
          <div className={styles.FilterInput} >
            <Input
              label="Company Type"
              name="filterCompanyType"
              id="combo-box-demo"
              value={filterCompanyType}
              onChange={companyTypeFilter}
            />
          </div>
          <div className={styles.FilterSearchButton} >
            <Button
              color="primary"
              variant="contained"
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
          <div className={styles.FilterResetButton} >
            <Button
              color="secondary"
              variant="contained"
              onClick={resetForm}
            >
              reset
            </Button>
          </div>
        </Grid>
        <Paper className={styles.paper}>
          <MaterialTable
            title={title}
            columns={columns}
            data={newData}
            options={{
              actionsColumnIndex: -1,
              paging: true,
              sorting: true,
              search: false,
              filter: true
            }}
            showDelete={true}
            onOrderChange={(orderedColumnId: any, orderDirection: any) => {
              orderFunc(orderedColumnId, orderDirection);
            }}
          />
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default Company;
