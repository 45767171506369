import React, { useState, useEffect } from "react";
import styles from "./CompanyProfile.module.css";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { GET_ORGTYPES } from "../../graphql/queries/OrgTypes";
import { GET_BESTPRACTICE } from "../../graphql/queries/BestPractice";
import * as routeConstant from "../../common/RouteConstants";
import { useHistory } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_COMPLIANCEINFO,
  UPDATE_COMPLIANCEINFO,
} from "../../graphql/mutations/ComplianceInfo";
import {
  CREATE_ORGCONTACT,
  UPDATE_ORGCONTACT,
} from "../../graphql/mutations/Contacts";
import { GET_COMPLIANCEINFO } from "../../graphql/queries/ComplianceInfo";
import { GET_COMPLIANCE } from "../../graphql/queries/Compliance";
import { UPDATE_COMPLIANCE } from "../../graphql/mutations/Compliance";
import { getCompliance, setCompliance, returnArray, setActiveFormStep } from "../../services/Data";
import { Button } from "../../components/UI/Form/Button/Button";
import { useApolloClient } from "@apollo/client";
import { setStepper } from "../../common/SetStepper";
import stepper from "../../common/stepperMenu.json";
import CompanForm from "../CompanyForm/CompanyForm";
import logout from "../../containers/Auth/Logout/Logout";
import { Typography } from "@material-ui/core";
import TopStepper from "../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import { GET_REVIEW_INFO } from "../../graphql/queries/Review";
import Alert from "../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../common/MessageConstants";

export interface OrgType {
  id: Number;
  name: string;
  sub_type: Object;
}

export interface OrgSubType {
  id: Number;
  name: string;
  sub_type: Object;
}

export interface BestPractice {
  id: Number;
  Name: string;
}

export const CompanyProfile: React.FC = (props: any) => {
  const client = useApolloClient();
  let stepperObject = stepper;
  const [userPresent, SetUserPresent] = useState(false);
  const [orgContactID, setOrgContactID] = useState<any | null>(null);
  const history = useHistory();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [param, setParam] = useState<any>();
  const [showBackDrop, setShowBackdrop] = useState(false);
  const [stepperObj, setStepperObj] = useState<any>({path: ""});
  const [dataObj, setDataObj] = useState<any>([
    {
      id: null,
      company_name: props.location.state.clientInfo.name,
      company_type: "",
      company_subtype: "",
      compliance_name : "",
      bestPractice: []
    },
  ]);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const { data: dataOT, error: errorOT, loading: loadingOT } = useQuery(
    GET_ORGTYPES
  );
  const { data: dataBP, error: errorBP, loading: loadingBP } = useQuery(
    GET_BESTPRACTICE
  );
  const [
    getComplianceInfo,
    { data: dataCO, error: errorCO, loading: loadingCO },
  ] = useLazyQuery(GET_COMPLIANCEINFO, {
    onCompleted: () => {setShowBackdrop(false)},
    fetchPolicy: "cache-and-network",
    onError:()=>{
      setShowBackdrop(false)
      handleCancel()
    }
  });

  const [CreateContact, { data: dataCC }] = useMutation(CREATE_ORGCONTACT);
  const [UpdateContact, { data: dataUC }] = useMutation(UPDATE_ORGCONTACT);
  const [CreateComplianceInfo, { data: dataOG }] = useMutation(
    CREATE_COMPLIANCEINFO
  );
  const [updateComplianceInfo, { data: dataORG }] = useMutation(
    UPDATE_COMPLIANCEINFO
  );

  const [updateComplianceData, { data: dataCOM }] = useMutation(
    UPDATE_COMPLIANCE
  );
  const [
    getComplianceData,
    { data: complianceData, loading: complianceLoading },
  ] = useLazyQuery(GET_COMPLIANCE, {
    onCompleted: (data: any) => {
      setCompliance(complianceData.compliances);
    },
    onError:(error:any)=>{
      setShowBackdrop(false)
      handleCancel()
    },
    fetchPolicy: "cache-and-network",
  });

  const fetchCompliance = (val: any) => {
    getComplianceData({
      variables: {
        where: { contact_id: val },
      },
    });
  };

  const [ getReview, { data: reviewData, error: error } ] = useLazyQuery(GET_REVIEW_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: any) => {
      if (data.review.companyprofile.data.length > 0) {
        setActiveFormStep(0);
      }
      if (data.review.mainOffice.data.length > 0) {
        setActiveFormStep(1);
      }
      if (data.review.otherOffice.data.length > 0) {
        setActiveFormStep(2);
      }
      if (data.review.customer.data.length > 0) {
        setActiveFormStep(4);
      }
    }, 
    onError:()=>{
      handleCancel()
    }
  });

  const restoredata = () => {
    SetUserPresent(true);
    let company: any = [];
    if (dataCO) {
      if (dataCO.complianceInfos && dataCO.complianceInfos[0]) {
        company.push({
          id: dataCO.complianceInfos[0].id,
          company_name: props.location.state.clientInfo.name,
          compliance_name :dataCO.complianceInfos[0].name,
          company_type: dataCO.complianceInfos[0].organization_type,
          bestPractice: dataCO.complianceInfos[0].best_practices,
        });
      }
      if (company.length > 0) {
        setDataObj(company);
        fetchCompliance(orgContactID);
      }
    }
  };
  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);
  
  useEffect(() => {
    setParam(props.location.state);
    if (
      props.location.state &&
      props.location.state.hasOwnProperty("complianceInfo")
    ) {      
      setShowBackdrop(true)
      getReview({
        variables: { ID: parseInt(props.location.state.complianceInfo.contactId) }
      });
      getComplianceInfo({
        variables: {
          where: { id: props.location.state.complianceInfo.id },
        },
      });
    }
  }, []);

  useEffect(() => {
    setStepper(
      client,
      stepperObject.companyProfile.name,
      stepperObject.companyProfile.value
    );
    if (dataCO !== undefined && dataCO.complianceInfos !== undefined) {
      restoredata();
      if (dataCO.complianceInfos.length >= 0  && dataCO.complianceInfos[0].name !== null) {
        setSubmitDisabled(false);
      }
      if (dataCO.complianceInfos.length === 0 ||  dataCO.complianceInfos[0].name===null) {
        setSubmitDisabled(true);
      }
      if (dataCO.complianceInfos[0]) {
        fetchCompliance(dataCO.complianceInfos[0].contact_id.id);
      }
    }
    if (dataCO && dataCO.complianceInfos !== undefined) {
      if (dataCO.complianceInfos[0] !== undefined) {
        setOrgContactID(dataCO.complianceInfos[0].contact_id.id);
      }
    }
  }, [dataCO]);

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }

  if (errorOT || errorBP) {
    let error = errorOT ? errorBP : { message: "Error" };
    return (
      <div className="error">
        Error!
        {logout()}
      </div>
    );
  }

  let updatedObj = {};
  const verifyChange = () => {
    let comp = getCompliance();
    updatedObj = {};
    comp.map((compliance: any) => {
      if (compliance.allow_download === true) {
        updatedObj = {
          id: parseInt(compliance.id),
          contact_id: parseInt(compliance.contact_id.id),
          law: returnArray(compliance.laws),
          rule: returnArray(compliance.rules),
          policy: returnArray(compliance.policies),
          workplan: returnArray(compliance.workplans),
          address: parseInt(compliance.address_id.id),
          allowdownload: false,
          compliance_version: compliance.compliance_version
            ? parseInt(compliance.compliance_version.id)
            : compliance.compliance_version,
        };
        updateCompliance(updatedObj);
      }
    });
  };

  const updateCompliance = (val: any) => {
    updateComplianceData({
      variables: val,
    })
      .then((UpdateOrgRes) => {})
      .catch((error) => {});
  };

  let BestPracticeID: any = [];
  const handleCompanyChange = (event: any) => {
    setDataObj(event);
    for (let index in event[0].bestPractice) {
      if (
        event[0].bestPractice.length > 0 &&
        !BestPracticeID.includes(parseInt(event[0].bestPractice[index].id))
      ) {
        BestPracticeID.push(parseInt(event[0].bestPractice[index].id));
      }
    }
    if (event[0].bestPractice.length === 0) {
      BestPracticeID = [];
    }
    if (event[0].company_name === "" || event[0].company_type === null  || event[0].compliance_name == null || event[0].compliance_name == "") {
      setSubmitDisabled(true);
    }
    
    if (event[0].company_name !== "" && event[0].company_type !== null && event[0].compliance_name !== null  && event[0].compliance_name !== "") {
      setSubmitDisabled(false);
    }
  };

  const handleSubmit = () => {
    let contact_id: any;
    if (!submitDisabled && !userPresent) {
      setSubmitDisabled(true);
      setShowLoading(true);
      CreateContact({
        variables: {
          name :dataObj[0].compliance_name,
          contact_type: "ComplianceInfo",
        },
      })
        .then((userRes) => {
          contact_id = userRes.data.createContact.contact.id;
          if (contact_id && dataObj[0].company_type != "") {
            CreateComplianceInfo({
              variables: {
                contact_id: contact_id,
                organization_type: dataObj[0].company_type.id,
                best_practices: BestPracticeID,
                client_id: props.location.state.clientInfo.clientId,
                name: dataObj[0].compliance_name
              }
            })
              .then((res: any) => {
                navigateTo("Save", res.data.createComplianceInfo.complianceInfo);
              })
              .catch((err) => {
                let error = err.message;
                if (
                  error.includes("duplicate key value violates unique constraint")
                ) {
                  error = " Compliance Name already present.";
                } 
                else {
                  error = err.message;
                }
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: error,
                }));
                setShowLoading(false);
                setSubmitDisabled(true);
              });
          }
        })
        .catch((err) => {
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Compliance Name already present.";
          }
          if (
            error.includes(" must not be null")
          ){
            setSubmitDisabled(true);
          }
           else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
          setShowLoading(false);
          setSubmitDisabled(true);
        });
    }
    if (orgContactID && !submitDisabled) {
      setShowLoading(true);
      setSubmitDisabled(true);
      let BPId: any = [];
      for (let index in dataObj[0].bestPractice) {
        BPId.push(parseInt(dataObj[0].bestPractice[index].id));
      }
      UpdateContact({
        variables: {
          id: orgContactID,
          name: dataObj[0].compliance_name,
          contact_type: "Organization",
        },
      })
        .then((userRes) => {
          contact_id = userRes.data.updateContact.contact.id;
          updateComplianceInfo({
            variables: {
              id: dataObj[0].id,
              contact_id: contact_id,
              organization_type: dataObj[0].company_type.id,
              name: dataObj[0].compliance_name,
              best_practices: BPId,
            },
          })
            .then((UpdateOrgRes) => {
              verifyChange();
              navigateTo("Update", "");
            })
            .catch((err) => {
              let error = err.message;
              if (
                error.includes("duplicate key value violates unique constraint")
              ) {
                error = " Compliance Name already present.";
              }else {
                error = err.message;
              }
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: error,
              }));
              setShowLoading(false);
              setSubmitDisabled(true);
            });
        })
        .catch((err) => {
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Compliance Name already present.";
          } 
          else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
          setShowLoading(false);
          setSubmitDisabled(true);
        });
    }
  };

  const navigateTo = (type: any, val: any) => {
    if (!submitDisabled) {
      let path = routeConstant.MAIN_OFFICE
      if (type === "Update") {
        if (stepperObj.path && stepperObj.path !== "" && dataObj[0].compliance_name !== null&& dataObj[0].compliance_name !== "") {
          history.push(stepperObj.path, param);
        } else {
          history.push(path, param);
        }
      }
      if (type === "Save") {
        let obj: any = {};
        obj["id"] = val.id;
        obj["name"] = props.location.state.clientInfo.name;
        obj["created_at"] = val.created_at;
        obj["updated_at"] = val.updated_at;
        obj["contactId"] = val.contact_id.id;
        let paramObj = {
          clientInfo: props.location.state.clientInfo,
          complianceInfo: obj,
        };
        history.push(path, paramObj);
      }
    }
  };

  const handleCancel = () => {
    let val = {
      clientInfo: param.clientInfo,
    }
    history.push(routeConstant.COMPLIANCE_LIST, val);
  };

  const callingFromTopStepper = (event: any) => {
    handleSubmit();
    setStepperObj(event);
  }

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {props.location.state
          ? "CyberCompliance for " + props.location.state.clientInfo.name
          : null}
      </Typography>
      <TopStepper
        navigate={!submitDisabled}
        param={param}
        handleSave={callingFromTopStepper}
        obj={stepperObj}
      />
      <Grid container>
        { enableBackdrop() ? <SimpleBackdrop/>:  null}
        <Grid item xs={12} md={6} lg={4}>
        <Paper className={styles.paper}>
          <React.Fragment>
          {formState.isFailed ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {FAILED}
                  {formState.errMessage}
                </Alert>
              ) : null}
            <CompanForm
              name="Company Info"
              key={0}
              index={0}
              data={dataObj}
              handleChange={handleCompanyChange}
            />
          </React.Fragment>
        </Paper>
        </Grid>
      </Grid>
      { enableBackdrop() ? null : 
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={submitDisabled}
              >
                {"Next"}
              </Button>
              <div className={styles.cancelButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCancel}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      }
      
    </React.Fragment>
  );
};

export default CompanyProfile;
