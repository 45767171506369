import React, { useState } from "react";
import logo from "./logo.svg";
import {
  Switch,
  RouteComponentProps,
  Redirect,
  Route,
  BrowserRouter,
} from "react-router-dom";
import { Layout } from "./components/UI/Layout/Layout";
import { Login } from "./containers/Auth/Login/Login";
import { Registration } from "./containers/Auth/Registration/Registration";
import { SessionContext } from "./context/session";
import { ApolloProvider } from "@apollo/client";
import gqlClient from "./config/apolloclient";
import { ErrorHandler } from "./containers/ErrorHandler/ErrorHandler";
import { AuthRoutes } from "./AuthRoutes";
import { AdminRoutes } from "./AdminRoutes";
import styles from "./App.module.css";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme/";
import logout from "./containers/Auth/Logout/Logout";
import { CompanyUser } from "./common/Roles";
import { PgAction } from "./containers/Auth/PgAction/PgAction";
import PartnerUserForm from "./containers/PG/PartnerUserForm/PartnerUserForm";
import DeletePartnerUser from "./containers/PG/DeletePartnerUser/DeletePartnerUser";
import PartnerForm from "./containers/PG/Partner/Partner";
import ClientForm from "./containers/PG/ClientForm/ClientForm";
import deleteClient from "./containers/PG/DeleteClient/DeleteClient";
import deletePartner from "./containers/PG/DeletePartner/DeletePartner";
import Cookies from 'js-cookie';

function App() {
  const session = Cookies.getJSON('cc_session');
  const user = Cookies.getJSON('cc_user');
  const [authenticated, setAuthenticated] = useState(session ? true : false);
  const values = {
    authenticated: authenticated,
    setAuthenticated: (value: any) => {
      setAuthenticated(value);
    },
  };
  const accessToken = session ? session : null;
  const client = authenticated ? gqlClient(accessToken) : gqlClient(null);
  if (authenticated) {
    setTimeout(function () {
      logout();
      window.location.replace("/pg-action");
    }, 1000 * 60 * 60);
  }
  let routes;

  if (authenticated) {
    if (user.user.role.name === CompanyUser) {
      routes = <div>{AuthRoutes}</div>;
    }
    if (user.user.role.name !== CompanyUser) {
      routes = <div>{AdminRoutes}</div>;
    }
  } else if (!authenticated) {
    routes = (
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/registration" exact component={Registration} />
        <Route path="/pg-action" exact component={PgAction} />

        {/* Routes For PII Tool Starts */}
        <Route path={"/pg-partner-form"} exact component={PartnerForm} />
        <Route path={"/pg-partner-form/add"} exact component={PartnerForm} />
        <Route path={"/pg-partner-form/edit/"}exact component={PartnerForm} />
        <Route path={"/pg-partner-form" + "/:id "} exact component={PartnerForm} />
        <Route path={"/pg-delete-partner"} exact component={deletePartner} />
        <Route path={"/pg-partner-user-form"} exact component={PartnerUserForm} />
        <Route path={"/pg-partner-user-form/add"} exact component={PartnerUserForm} />
        <Route path={"/pg-partner-user-form/edit/" }exact component={PartnerUserForm} />
        <Route path={"/pg-partner-user-form" + "/:id "} exact component={PartnerUserForm} />
        <Route path={"/pg-delete-partner-user"} exact component={DeletePartnerUser} />
        <Route path={"/pg-client-form/add"} exact component={ClientForm} />
        <Route path={"/pg-client-form/edit"} exact component={ClientForm} />
        <Route path={"/pg-client-form/delete"} exact component={deleteClient} />
        {/* Routes For PII Tool Ends*/}

        <Route path="/" render={() => <Redirect to="/pg-action" />} />
      </Switch>
    );
  }

  return (
    <SessionContext.Provider value={values}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ErrorHandler />
          <BrowserRouter>{routes}</BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </SessionContext.Provider>
  );
}

export default App;
