import { gql } from '@apollo/client';

export const GET_ORGTYPES = gql`
  query{
    organizationTypes {
      id,
      name,
      sub_type{
        id,
        name
      }
    }
  }`;