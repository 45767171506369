import React, { useState, useEffect, useCallback, useMemo } from "react";
import styles from "./Rule.module.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
import { TextField } from "@material-ui/core";
import Input from "../../../components/UI/Form/Input/Input";
import { GET_CATEGORY } from "../../../graphql/queries/Category";
import { GET_LAW } from "../../../graphql/queries/Law";
import { GET_RULE } from "../../../graphql/queries/Rule";
import {
  CREATE_RULE,
  UPDATE_RULE,
  DELETE_RULE,
} from "../../../graphql/mutations/Rule";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import Autocomplete from "../../../components/UI/Form/Autocomplete/Autocomplete";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import {
  useQuery,
  useMutation,
  ApolloError,
  useLazyQuery,
} from "@apollo/client";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AutoCompleteDropDown from "../../../components/UI/Form/Autocomplete/Autocomplete";
import logout from "../../../containers/Auth/Logout/Logout";
import * as dropDownConstants from "../../../common/dropDownConstants";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

export const Rule: React.FC = () => {
  //Autocomplete list
  const [getCategoryList, setCategoryList] = useState([]);
  const [getLawList, setLawList] = useState([]);
  const getTargetList = dropDownConstants.getTargetList;
  //Dialog Box
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);

  //add/edit data
  const [name, setName] = useState<String>("");
  const [category, setCategory] = useState({ id: null });
  const [law, setLaw] = useState<any>();
  const [policyDescription, setPolicyDescription] = useState<String>("");
  const [workplanDescription, setWorkplanDescription] = useState<String>("");
  const [ruleNumber, setRuleNumber] = useState<any>();
  const [target, setTarget] = useState<any>();
  const [newData, setNewData] = useState();
  const [editDataId, setEditDataId] = useState<Number | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<any>();

  const title = "Listing of Rules";

  //validation and error handelling
  const [isError, setIsError] = useState<any>({
    name: "",
    category: "",
    // policy_description: "",
    // workplan_description: "",
    laws: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [filterName, setFilterName] = useState("");
  const [filterCategory, setFilterCategory] = useState<any | null>();
  const [filterLaw, setFilterLaw] = useState<any>();
  const [reset, setReset] = useState<boolean>(false);
  const [filters, setFilters] = useState<object>();
  const [orderBy, setOrderBy] = useState<String>();
  const [sortOrder, setSortOrder] = useState<String | null>("asc");

  //queries
  const [createRule] = useMutation(CREATE_RULE);
  const [updateRule] = useMutation(UPDATE_RULE);
  const [deleteRule] = useMutation(DELETE_RULE);

  //table
  const columns = [
    {
      title: "Name",
      field: "name",
      defaultSort: orderBy?.includes("name") ? sortOrder : "asc",
    },
    {
      title: "Rule No.",
      field: "rule_no",
      defaultSort: orderBy?.includes("rule_no") ? sortOrder : "",
    },
    {
      title: "Category",
      field: "category",
      defaultSort: orderBy?.includes("category") ? sortOrder : "",
    },
    {
      title: "Target",
      field: "target",
      defaultSort: orderBy?.includes("target") ? sortOrder : "",
    },
    {
      title: "Laws",
      field: "laws",
      // defaultSort: orderBy?.includes("laws") ? sortOrder : "",
      sorting: false 

    },
    // { title: "Policy Content", field: "policy_content" },
    // { title: "Workplan Description", field: "workplan_content" },
  ];
  //filter query condition declaration
  const {
    data: dataRule,
    error: errorRule,
    loading: loadingRule,
    refetch: refetchRule,
  } = useQuery(GET_RULE, {
    variables:
      !orderBy || orderBy === ""
        ? { where: filters, sort: "name:asc" }
        : { where: filters, sort: orderBy },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    // skip: filterUseQueryCondition(),
  });

  //query for category list
  const {
    data: dataPC,
    error: errorPC,
    loading: loadingPC,
    refetch: refetchPC,
  } = useQuery(GET_CATEGORY);

  //query for law list
  const {
    data: dataLaw,
    error: errorLaw,
    loading: loadingLaw,
    refetch: refetchRL,
  } = useQuery(GET_LAW);

  const checkValidation = () => {
    if (showDialogBox === true) {
      if (
        isError.name !== "" ||
        isError.category !== "" ||
        // isError.policy_description !== "" ||
        // isError.workplan_description !== "" ||
        isError.laws !== "" ||
        !name ||
        // !policyDescription ||
        // !workplanDescription ||
        category.id === null ||
        law.length === 0
      ) {
        return true;
      }
      return false;
    }
    return false;
  };
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (dataRule) {
      let temp: any = {};
      temp = convertTableData(dataRule.rules);
      setNewData(temp);
    }
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [dataRule, filters, formState]);

  useEffect(() => {
    setSubmitDisabled(checkValidation);
  }, [
    name,
    category,
    // policyDescription,
    // workplanDescription,
    law,
    submitDisabled,
  ]);

  if (loadingPC || loadingLaw || loadingRule || loading) return <Loading />;
  if (errorPC || errorLaw || errorRule) {
    return (
      <div className="error">
        Error!
        {logout()}
      </div>
    );
  }
  if (getCategoryList.length === 0) {
    setCategoryList(dataPC.categories);
  }
  if (getLawList.length === 0) {
    setLawList(dataLaw.laws);
  }

  function convertTableData(data: any) {
    let arr: any = [];
    for (let i in data) {
      let tempArr: any = {};
      tempArr["id"] = data[i].id;
      tempArr["name"] = data[i].name;
      tempArr["policy_description"] = data[i].policy_description;
      tempArr["workplan_description"] = data[i].workplan_description;
      tempArr["policy_content"] = data[i].policy_content;
      tempArr["workplan_content"] = data[i].workplan_content;
      tempArr["rule_no"] = data[i].rule_no;
      tempArr["target"] = data[i].Target;
      tempArr["category"] = data[i].category ? data[i].category.name : null;
      tempArr["laws"] = [];
      let lawArr: any = [];
      for (let j in data[i].laws) {
        lawArr.push(data[i].laws[j].name);
      }
      tempArr["laws"] = lawArr.join(", ");
      arr.push(tempArr);
    }
    return arr;
  }
  const getTarget = {
    options: getTargetList,
    getOptionLabel: (option: { label: String }) => option.label,
  };

  const getCategory = {
    options: getCategoryList,
    getOptionLabel: (option: { name: String }) => option.name,
  };
  const getLaw = {
    options: getLawList,
    getOptionLabel: (option: { name: String; parent_law: any }) =>
      option.name
        ? option.parent_law === null
          ? option.name
          : option.name + " - (" + option.parent_law.name + ")"
        : "",
  };

  const nameFilter = (event: any) => {
    setFilterName(event.target.value);
  };

  const categoryFilter = (event: any, newValue: any) => {
    setReset(false);
    setFilterCategory(newValue);
  };
  const lawFilter = (event: any, newValue: any) => {
    setReset(false);
    // let lawArr = [];
    // for (let i in newValue) {
    //   lawArr.push(newValue[i]);
    // }
    // setFilterLaw(lawArr);
    setFilterLaw(newValue);
  };

  const handleClickOpen = (rowData: any) => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
    setIsError((isError: any) => ({
      ...isError,
      name: "",
      category: "",
      // policy_description: "",
      // workplan_description: "",
      laws: "",
    }));
    setName("");
    setEditorState("");
    // setPolicyDescription("");
    // setWorkplanDescription("");
    setCategory({ id: null });
    setLaw([]);
    setRuleNumber(null);
    setTarget([]);
    setShowDialogBox(true);
    if (rowData.id) {
      setEditDataId(rowData.id);
      //for pre-filled category
      let obj = dataPC.categories;
      if (rowData.category) {
        for (let i in obj) {
          if (Object.values(obj[i]).includes(rowData.category)) {
            setCategory(obj[i]);
          }
        }
      }
      if (rowData.policy_description) {
        const markdownString = rowData.policy_description;
        const rawData = markdownToDraft(markdownString);
        const contentState = convertFromRaw(rawData);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
      //for pre-filled law
      let lawObj = dataLaw.laws;
      let rowLaws = rowData.laws.split(", ");
      if (rowLaws) {
        let lawArr = [];
        for (let i in rowLaws) {
          for (let j in lawObj) {
            if (Object.values(lawObj[j]).includes(rowLaws[i])) {
              lawArr.push(lawObj[j]);
            }
          }
        }
        setLaw(lawArr);
      }
      //for pre-filled target
      let targetObj = getTargetList;
      if (rowData.target) {
        for (let i in targetObj) {
          if (Object.values(targetObj[i]).includes(rowData.target)) {
            setTarget(targetObj[i]);
          }
        }
      }
      setName(rowData.name);
      setRuleNumber(rowData.rule_no);
      // setPolicyDescription(rowData.policy_description);
      // setWorkplanDescription(rowData.workplan_description);
    }
  };

  const deleteTableRow = (rowData: any) => {
    setLoading(true);
    deleteRule({
      variables: {
        id: rowData.id,
      },
    })
      .then((userRes) => {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: true,
          isFailed: false,
          errMessage: "",
        }));
        refetchRule();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: err.message,
        }));
      });
  };

  const handleSearch = () => {
    let searchData: any = {};
    if (filterName) {
      searchData["name_contains"] = filterName.toString();
    }
    if (
      filterCategory !== undefined &&
      filterCategory !== null &&
      Object.keys(filterCategory).length !== 0 &&
      filterCategory !== null
    ) {
      searchData["category"] = filterCategory.id;
    }
    // if (filterLaw !== undefined && filterLaw !== null && filterLaw.length > 0) {
    if (filterLaw !== undefined && filterLaw !== null) {
      // let lawArr = [];
      // for (let i in filterLaw) {
      //   lawArr.push(filterLaw[i].id);
      // }
      // searchData["laws_in"] = lawArr;
      searchData["laws_in"] = filterLaw.id;
    }
    setFilters(searchData);
  };

  const resetForm = () => {
    setFilters({});
    setReset(true);
    setFilterName("");
    setFilterCategory({});
    setFilterLaw([]);
    setOrderBy("name");
    refetchRule();
  };

  const handleSubmitDialogBox = () => {
    let lawArr = [];
    for (let i in law) {
      lawArr.push(law[i].id);
    }
    if (editDataId) {
      updateRule({
        variables: {
          id: editDataId,
          name: name,
          policy_description: draftToMarkdown(
            convertToRaw(editorState.getCurrentContent())
          ),
          // workplan_description: workplanDescription,
          category: category ? category.id : null,
          laws: lawArr,
          rule_no: ruleNumber ? ruleNumber : null,
          target: target ? target.label : null,
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: true,
            isDelete: false,
            isFailed: false,
            errMessage: "",
          }));
          setShowDialogBox(false);
          setEditDataId(null);
          refetchRule();
        })
        .catch((err) => {
          console.log("error", err.message);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
          setEditDataId(null);
        });
    } else {
      createRule({
        variables: {
          name: name,
          policy_description: draftToMarkdown(
            convertToRaw(editorState.getCurrentContent())
          ),
          // workplan_description: workplanDescription,
          category: category ? category.id : null,
          laws: lawArr,
          rule_no: ruleNumber ? ruleNumber : null,
          target: target ? target.label : null,
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: "",
          }));
          setShowDialogBox(false);
          refetchRule();
        })
        .catch((err) => {
          console.log("error", err);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Rule number already exists.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
        });
    }
  };

  // const orderFunc = (orderedColumnId: any, orderDirection: any) => {
  //   let orderByColumn;
  //   let orderBy = "";
  //   if (orderedColumnId >= 0) {
  //     if (columns[orderedColumnId]["field"] === "name") {
  //       orderByColumn = "name";
  //     }
  //     // if (columns[orderedColumnId]["field"] === "policy_description") {
  //     //   orderByColumn = "policy_description";
  //     // }
  //     // if (columns[orderedColumnId]["field"] === "workplan_description") {
  //     //   orderByColumn = "workplan_description";
  //     // }
  //     // if (columns[orderedColumnId]["field"] === "laws") {
  //     //   orderByColumn = "laws";
  //     // }
  //     if (columns[orderedColumnId]["field"] === "category") {
  //       orderByColumn = "category";
  //     }
  //      if (columns[orderedColumnId]["field"] === "target") {
  //       orderByColumn = "Target";
  //     }
  //     if (columns[orderedColumnId]["field"] === "rule_no") {
  //       orderByColumn = "rule_no";
  //     }
  //   }
  //   if (orderByColumn != null) {
  //     orderBy = orderByColumn + ":" + orderDirection;
  //     console.log("orderBy", orderBy);
  //     setSortOrder(orderDirection);
  //     setOrderBy(orderBy);
  //   }
  // };

  const closeDialogBox = () => {
    setShowDialogBox(false);
    setEditDataId(null);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    let value = event.target.value;
    let isErrName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      name: isErrName,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handleRuleNumberChange = (event: any) => {
    setRuleNumber(event.target.value);
  };

  const handleLawChange = (event: any, newValue: any) => {
    let lawArr = [];
    for (let i in newValue) {
      lawArr.push(newValue[i]);
    }
    setLaw(lawArr);
    let isErrorLaw = newValue.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      laws: isErrorLaw,
    }));
    setSubmitDisabled(checkValidation);
  };

  // const handlePolicyDescriptionChange = (event: any) => {
  //   setPolicyDescription(event.target.value);
  //   let value = event.target.value;
  //   let isErr = value.length <= 0 ? "Required" : "";
  //   setIsError((isError: any) => ({
  //     ...isError,
  //     policy_description: isErr,
  //   }));
  //   setSubmitDisabled(checkValidation);
  // };

  // const handleWorkplanDescriptionChange = (event: any) => {
  //   setWorkplanDescription(event.target.value);
  //   let value = event.target.value;
  //   let isErr = value.length <= 0 ? "Required" : "";
  //   setIsError((isError: any) => ({
  //     ...isError,
  //     workplan_description: isErr,
  //   }));
  //   setSubmitDisabled(checkValidation);
  // };
  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <main className={styles.layout}> */}
      <Typography component="h5" variant="h1">
        Rule
      </Typography>
      {!showDialogBox ? (
        <Grid className={styles.TableWrap}>
          <div className={styles.FilterAddWrap}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
            >
              <AddCircleIcon className={styles.EditIcon} />
              &nbsp; Rule
            </Button>
          </div>
          <Grid className={styles.FilterWrap}>
            <div className={styles.FilterInput}>
              <Input
                label="Name"
                name="filterName"
                id="combo-box-demo"
                value={filterName}
                onChange={nameFilter}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className={styles.FilterInput}>
              <AutoCompleteDropDown
                {...getCategory}
                id="categoryFilter"
                value={reset ? null : filterCategory ? filterCategory : null}
                onChange={categoryFilter}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    id="categoryFilter"
                    label="Category"
                    onKeyDown={handleKeyDown}
                  />
                )}
              />
            </div>
            <div className={styles.FilterDropInput}>
              <AutoCompleteDropDown
                {...getLaw}
                id="lawFilter"
                value={reset ? [] : filterLaw}
                // value={reset ? null : filterLaw?filterLaw:null }
                // multiple
                onChange={lawFilter}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    className={styles.multiSelectDropdownInput}
                    id="lawFilter"
                    label="Laws"
                    onKeyDown={handleKeyDown}
                  />
                )}
              />
            </div>
            <div className={styles.FilterSearchButton}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
            <div className={styles.FilterResetButton}>
              <Button color="secondary" variant="contained" onClick={resetForm}>
                reset
              </Button>
            </div>
          </Grid>
          <Paper className={styles.paper}>
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Rule {SUCCESS}
              </Alert>
            ) : null}
            {formState.isUpdate ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Rule {UPDATE}
              </Alert>
            ) : null}

            {formState.isDelete ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Rule {DELETE}
              </Alert>
            ) : null}
            <MaterialTable
              title={title}
              columns={columns}
              data={newData}
              actions={[
                {
                  icon: () => (
                    <img
                      className={styles.EditIcon}
                      src={process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"}
                      alt="edit icon"
                    />
                  ),
                  tooltip: "Edit",
                  onClick: (event: any, rowData: any) => {
                    handleClickOpen(rowData);
                  },
                },
                {
                  icon: () => (
                    <img
                      className={styles.EditIcon}
                      src={process.env.PUBLIC_URL + "/icons/svg-icon/delete.svg"}
                      alt="delete icon"
                    />
                  ),
                  tooltip: "Delete",
                  onClick: (event: any, rowData: any) => {
                    deleteTableRow(rowData);
                  },
                },
              ]}
              // editable={{
              //   onRowDelete: (oldData: any) =>
              //     new Promise((resolve: any) => {
              //       resolve();
              //       deleteTableRow(oldData);
              //     }),
              // }}
              options={{
                thirdSortClick: false,
                actionsColumnIndex: -1,
                paging: true,
                sorting: true,
                search: false,
                filter: true,
                pageSize: 25,
                draggable: false,
                pageSizeOptions: [25, 50, 75, 100],
              }}
              // onOrderChange={(orderedColumnId: any, orderDirection: any) => {
              //   orderFunc(orderedColumnId, orderDirection);
              // }}
            />
          </Paper>
        </Grid>
      ) : (
        <AddEditForm
          handleOk={handleSubmitDialogBox}
          disabled={submitDisabled}
          handleCancel={closeDialogBox}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {formState.isFailed ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {FAILED}
                  {formState.errMessage}
                </Alert>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                required
                type="text"
                label="Rule"
                value={name}
                onChange={handleNameChange}
                error={isError.name}
                helperText={isError.name}
              >
                Rule
              </Input>
            </Grid>
            {/* <Grid item xs={6}>
              <Input
                type="text"
                label="Policy Descripton *"
                value={policyDescription}
                onChange={handlePolicyDescriptionChange}
                error={isError.policy_description}
                helperText={isError.policy_description}
              >
                Policy Description
              </Input>
            </Grid>
            <Grid item xs={6}>
              <Input
                type="text"
                label="Workplan Descripton *"
                value={workplanDescription}
                onChange={handleWorkplanDescriptionChange}
                error={isError.workplan_description}
                helperText={isError.workplan_description}
              >
                Workplan Description
              </Input>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <AutoCompleteDropDown
                {...getCategory}
                id="category"
                value={category}
                onChange={(event: any, newValue: any) => {
                  setCategory(newValue);
                  let isErrorCat = newValue === null ? "Required" : "";
                  setIsError((isError: any) => ({
                    ...isError,
                    category: isErrorCat,
                  }));
                  setSubmitDisabled(checkValidation);
                }}
                renderInput={(
                  params:
                    | (JSX.IntrinsicAttributes &
                        import("@material-ui/core").StandardTextFieldProps)
                    | (JSX.IntrinsicAttributes &
                        import("@material-ui/core").FilledTextFieldProps)
                    | (JSX.IntrinsicAttributes &
                        import("@material-ui/core").OutlinedTextFieldProps)
                ) => (
                  <Input
                    {...params}
                    required
                    id="category"
                    label="Category"
                    error={isError.category}
                    helperText={isError.category}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <AutoCompleteDropDown
                {...getLaw}
                limitTags={2}
                id="law"
                name="law"
                value={law}
                multiple
                onChange={handleLawChange}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    required
                    id="law"
                    label="Law"
                    error={isError.laws}
                    helperText={isError.laws}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutoCompleteDropDown
                {...getTarget}
                id="target"
                value={target}
                onChange={(event: any, newValue: any) => {
                  setTarget(newValue);
                }}
                renderInput={(params: any) => (
                  <Input {...params} id="target" label="Target" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                type="number"
                label="Rule Number"
                value={ruleNumber}
                onChange={handleRuleNumberChange}
              >
                Rule Number
              </Input>
            </Grid>
            <Grid item xs={12}>
              <p className="styles.DescriptionLabel">Policy Content </p>
              <div className={styles.Editor}>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={setEditorState}
                  placeholder="  Policy Content"
                  toolbar={{
                    // We can add this functionality later as this was not supported currently in document Generation
                    options: ["inline"],
                    inline: {
                      options: ["bold", "italic"],
                      bold: { className: "bordered-option-classname" },
                      italic: { className: "bordered-option-classname" },
                      // underline: { className: "bordered-option-classname" },
                      // strikethrough: { className: "bordered-option-classname" },
                    },
                    //  blockType: {
                    //   className: "bordered-option-classname",
                    // },
                    fontSize: {
                      className: "bordered-option-classname",
                    },
                    fontFamily: {
                      className: "bordered-option-classname",
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </AddEditForm>
      )}

      {/* </main> */}
    </React.Fragment>
  );
};

export default Rule;
