import { CLIENT_COMPLIANCESTEPPER } from "../graphql/mutations/ComplienceStepper";

export const setStepper = (
  client: any,
  complianceStep: string | null,
  activeStep: string | null = "success"
) => {
  client.writeQuery({
    query: CLIENT_COMPLIANCESTEPPER,
    data: { complianceStep, activeStep },
  });
};