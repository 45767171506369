import React, { useState, useEffect } from 'react';
import styles from './DeleteInfo.module.css';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

// GraphQL Queries and Mutations
import { DELETE_CUSTOMER } from "../../graphql/mutations/Customer";
import { GET_CUSTOMER } from "../../graphql/queries/Customers";
import { DELETE_ADDRESS } from "../../graphql/mutations/Address";
import { GET_EMPLOYEE_DATA } from "../../graphql/queries/OrganizationLocation";
import { GET_ADDRESS } from "../../graphql/queries/Address";
import { DELETE_ORG_LOC } from "../../graphql/mutations/OrganizationLocation";
import { DELETE_COMPLIANCEINFO } from "../../graphql/mutations/ComplianceInfo";
import { GET_COMPLIANCEINFO } from "../../graphql/queries/ComplianceInfo";
import { DELETE_CONTACT } from "../../graphql/mutations/Contacts";
import { DELETE_COMPLIANCE } from "../../graphql/mutations/DeleteCompliance";
import { GET_CSET_ASSESSMENT } from "../../graphql/queries/CSETAssessment";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import { CSET_URL } from '../../config/index';
import {GetCSETUserDetails} from "../../graphql/queries/CSETUserDetails";
import { GET_CLIENT_SUBSCRIPTION } from "../../graphql/queries/ClientSubscription";
import {
  DELETE_CLIENT_SUBSCRIPTIONS,
} from "../../graphql/mutations/ClientSubscription";
import { GET_ORGANIZATION } from "../../graphql/queries/Organization";
import { GET_INDIVIDUAL } from "../../graphql/queries/Individual";
import {
  DELETE_ORGANIZATION,
} from "../../graphql/mutations/Organization";
import { CREATE_CSET_ASSESSMENT, UPDATE_CSET_ASSESSMENT, DELETE_CSET_ASSESSMENT} from "../../graphql/mutations/CSETAssessment";

export interface DeleteInfoProps {
  obj?: any;
  callDeleteMethod?: any;
}

export const DeleteInfo: React.FC<DeleteInfoProps> = ({
    obj,
    callDeleteMethod,
  }: DeleteInfoProps) => {

  const [login, setLogin] = useState<any>(false);
  const [authToken, setAuthToken] = useState<any>(false);

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER);
  const [deleteAddress] = useMutation(DELETE_ADDRESS);
  const [deleteEmployee] = useMutation(DELETE_ORG_LOC);
  const [deleteComplianceInfo] = useMutation(DELETE_COMPLIANCEINFO);
  const [deleteContact] = useMutation(DELETE_CONTACT);
  const [deleteCompliance] = useMutation(DELETE_COMPLIANCE);
  const [deleteClientSubscription] = useMutation(DELETE_CLIENT_SUBSCRIPTIONS);  
  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION);
  const [deteleAssessement] = useMutation(DELETE_CSET_ASSESSMENT);

  const [ getCustomers, { data: custData, loading: custLoading }, ] = useLazyQuery(GET_CUSTOMER, {
    onCompleted: (data: any) => {
      let custIdArray = data.customers.map((val: any) => val.id);
      if (custIdArray.length !== 0) {
        custIdArray.map((val: any) => {
          // setCustomer(val)
          deleteCustomersById(val);
        })
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const [getaddress, { data: dataAD, loading: loadingAddress }] = useLazyQuery(GET_ADDRESS, {
    onCompleted: (data: any) => {
      let addressIdArray = data.addresses.map((val: any) => val.id);
      if (addressIdArray.length !== 0) {
        addressIdArray.map((val: any) => {
          deleteAddressById(val);
        })
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const [getOrgLocation, { data: dataOL, loading: loadingOrgLoc }] = useLazyQuery(GET_EMPLOYEE_DATA, {
    onCompleted: (data: any) => {
      let orgLocArray = data.organizationLocations.map((val: any) => val.id);
      if (orgLocArray.length !== 0) {
        orgLocArray.map((val: any) => {
          deleteOrgLocationById(val);
        })
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const [ getComplianceInfo, { data: compInfo, loading: loadingCompInfo }] = useLazyQuery(GET_COMPLIANCEINFO, {
    onCompleted: (data: any) => {
      let compInfo = data.complianceInfos.map((val: any) => val.id);
      if (compInfo.length !== 0) {
        compInfo.map((val: any) => {
          deleteComplianceInfoById(val);
        })
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const [GetCSETUserLoginDetails, {data: CSETLoginData, loading: CSETLoginLoading}] = useLazyQuery(
    GetCSETUserDetails,
    {
      fetchPolicy: "cache-and-network",
      onCompleted:(LoginRequestData:any) => {
        if(LoginRequestData.csetUserDetails && LoginRequestData.csetUserDetails[0]){
          LoginRegisterdCSETUser(LoginRequestData.csetUserDetails[0].cset_username, LoginRequestData.csetUserDetails[0].cset_password);
        }
      },
    })
    
  const [getAssessment, {called, loading, data: assessmentData}] = useLazyQuery(GET_CSET_ASSESSMENT, {
    fetchPolicy: "cache-and-network",
  });

  const [getOrganization, { data: dataOrg, loading: loadingOrg }] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: "cache-and-network",
    onError: () => {
      // logout();
    },
    onCompleted: (data) => {
      if (dataSubs != undefined) {
        dataOrg.organizations.map((element: any) => {
          if (element.contact_id.id === obj.complianceInfo.clientId) {
            deleteClientSubscriptionsbyId(obj.complianceInfo.clientSubsId)
            deleteOrgById(element.id)
            deleteContactById(element.contact_id.id)
          }
        })
      }
    },
  });
  
  const headerObj = { 
    'Content-Type': 'application/json',
    'Authorization' : authToken ? JSON.parse(authToken) : null
  }
  

  const loginCSET =  () => {
  GetCSETUserLoginDetails({
    variables: {
      where: { contact:{id: obj.complianceInfo.clientId} },
    },
  })
  }
  const LoginRegisterdCSETUser = async (username: any, password: any)=>{
    try {
      let loginUserData = {
        "Email": username,
        "Password": password,
        "TzOffset": new Date().getTimezoneOffset(),
        "Scope": "CSET"
      };
        let CSetLogin = await fetch(CSET_URL + "api/auth/login" , {
          method: 'POST',
          headers: headerObj,
          body: JSON.stringify(loginUserData)
        }).then(response => response.json())
        .then(data => {
          // let param = {clientInfo: clientInfo, csetUserInfo: data}
          // localStorage.setItem("ra_token", JSON.stringify(data.Token))
          setAuthToken(JSON.stringify(data.Token))
          setLogin(true);
          // history.push(routeConstant.ASSESSMENT_LIST, param);
          // setDataLoading(false)
        });
    } catch (error) { 
      // setShowBackdrop(false)
    };
  };

  const deleteAssessmentsFromCSET = async (assessmentInfo: any) => {
    if(assessmentInfo != undefined) {
    let arr: any = [];
    assessmentInfo.csetAssessments.map(async (element: any) => {
      // arr.push(element.assessment_id);
      try {
        await fetch(CSET_URL + "api/contacts/remove" , {
          method: 'POST',
          headers: headerObj,
          body: JSON.stringify({"UserId": 0, "Assessment_ID": element.assessment_id})
        })
        .then(response => response.json())
        .then(data => {
          onDeleteAssessment(assessmentInfo)
          getOrganization({
            variables: {
              where: { subtype: "Client", partner_id: obj.complianceInfo.partnerId },
            },
          });
          // deleteClientSubscriptionsbyId(dataSubs.clientSubscriptions[0].id)          
          // deleteOrgById(dataOrg.organizations[0].id)
          // deleteContactById(dataSubs.clientSubscriptions[0].contact_id.id)
         
        })
      }
      catch (e) {
        // onErrorRedirection()
      }
    });
  } else {
    getOrganization({
      variables: {
        where: { subtype: "Client", partner_id: obj.complianceInfo.partnerId },
      },
    });
  }
  }

  const onDeleteAssessment = async (assessmentInfo : any) => {
    if (assessmentInfo.hasOwnProperty("csetAssessments")) {
      assessmentInfo.csetAssessments.map(async (element: any) => {
      deteleAssessement({
        variables: {id: element.id}
      })
      .then((res) => {
        // setShowBackDrop(false);
        // setOpenDialogBox(false);
        // getAssessmentForUser();
      })
      .catch((err) => {
        // onErrorRedirection()
      });
    })
    } else {
      // setShowBackDrop(false);
      // setOpenDialogBox(false);
      // getAssessmentForUser();
    }
 
  }

  useEffect(() => {
    if (obj) {
      if (obj.from === "ComplianceList") {
        fetchInformations(obj);
      }
      if (obj.from === "Client") {
        fetchClientInformations(obj)
      }
    }
  }, [obj])

  useEffect(() => {
  deleteAssessmentsFromCSET(assessmentData)
}, [authToken])


  const fetchInformations = (obj: any) => {
    getCustomers({
      variables: {
        where: {
          contact_id: {id: obj.complianceInfo.contactId}
        }
      }
    });
    getaddress({
      variables: {
        where: {
          contact_id: {id: obj.complianceInfo.contactId}
        }
      }
    })
    getOrgLocation({
      variables: {
        where: {
          contact_id: {id: obj.complianceInfo.contactId}
        }
      }
    })
    getComplianceInfo({
      variables: {
        where: {
          contact_id: {id: obj.complianceInfo.contactId}
        }
      }
    })
  }
  const [getClientSubs, { data: dataSubs, loading: loadSubs }] = useLazyQuery(
    GET_CLIENT_SUBSCRIPTION,
    {
      onCompleted: (data: any) => {
        
        if(dataSubs.clientSubscriptions != [] &&  dataSubs.clientSubscriptions.length !== 0 && dataSubs.clientSubscriptions[0].ra_subscription) {         
          loginCSET()
          getAssessment ({
            variables: {
              where: {
                "contact_id" :obj.complianceInfo.clientId
              }
            }
          })
      }
      if(dataSubs.clientSubscriptions != [] &&  dataSubs.clientSubscriptions.length !== 0 && dataSubs.clientSubscriptions[0].cc_subscription) {
        fetchInformations(obj);
        getOrganization({
              variables: {
                where: { subtype: "Client", partner_id: obj.complianceInfo.partnerId },
              },
            });
      }
      },
      fetchPolicy: "cache-and-network",
    }
  );
 
  const fetchClientInformations = (obj: any) => {
    getClientSubs({
      variables: {
        where: { contact_id: obj.complianceInfo.clientId },
      },
    });
  }

  const deleteCustomersById = (id: any) => {
      deleteCustomer({
        variables: { id: id }
      }).then(res => {}).catch(err => {});
  }

  const deleteAddressById = (id: any) => {
      deleteAddress({
        variables: {
          id: id,
        },
      }).then((res) => {}).catch((err) => {});
  }
  
  const deleteOrgLocationById = (id: any) => {
      deleteEmployee({
        variables: {
          id: id,
        },
      }).then((res) => {}).catch((err) => {});
  }
  
  const deleteComplianceInfoById = (id: any) => {
    deleteComplianceInfo({
      variables: {
        id: id,
      },
    }).then((res) => {
      deleteContactById( res.data.deleteComplianceInfo.complianceInfo.contact_id.id ?  res.data.deleteComplianceInfo.complianceInfo.contact_id.id : obj.complianceInfo.contactId );
    }).catch((err) => {});
  }

  const deleteContactById = (id: any) => {
    deleteContact({
      variables: {
        id: id,
      },
    }).then((res) => {
      obj.isCompleted = true;
      callDeleteMethod(obj)
    }).catch((err) => {});
  }
  const deleteClientSubscriptionsbyId = (id: any) => {
    deleteClientSubscription({
      variables: {
        id: id
      },
  }).then((res) => {
  })
  .catch(err => {

  });
}
const deleteOrgById = (id: any) => {
  deleteOrganization({
    variables: {
      id:id
    },
  }).then((res: any) => {
  })
  .catch ((err) => {
})
};
  
  const enableBackdrop = () => {
    if (custLoading || loadingAddress || loadingOrgLoc || loadingCompInfo) {
      return true;
    } else {
      return false;
    }
  }

  return (<div></div>)
};

export default DeleteInfo;
