// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OtherOfficeCompliance_OtherOfficeCompliance__SV4iQ {}\n.OtherOfficeCompliance_TabContainer__1w7_F{\n  border: 1px solid #0051a8;\n  border-radius: 10px !important;\n  padding: 15px;\n  background-color: #fff;\n  margin-top: -7px;\n  position: relative;\n}\n.OtherOfficeCompliance_ComplianceButtons__1yi6i{\n  margin-top:15px;\n}\n.OtherOfficeCompliance_OfficeH4__1RQcB{\n  margin-top: 0px !important;\n}", ""]);
// Exports
exports.locals = {
	"OtherOfficeCompliance": "OtherOfficeCompliance_OtherOfficeCompliance__SV4iQ",
	"TabContainer": "OtherOfficeCompliance_TabContainer__1w7_F",
	"ComplianceButtons": "OtherOfficeCompliance_ComplianceButtons__1yi6i",
	"OfficeH4": "OtherOfficeCompliance_OfficeH4__1RQcB"
};
module.exports = exports;
