import React, { useState, useEffect, useCallback, useMemo } from "react";
import styles from "./ComplianceReport.module.css";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useHistory } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import * as routeConstant from "../../../common/RouteConstants";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Tooltip from "@material-ui/core/Tooltip";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import { green } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { DELETE_LAW } from "../../../graphql/mutations/Law";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import Views from "../Views/Views";
import { stat } from "fs";
import { DocumentCreator } from "../../../components/GenerateDocument/GenerateDoc";
import { Packer } from "docx";
import { saveAs } from "file-saver";
export interface ReportProps {
  reportData?: any;
}

export const ComplianceReport: React.FC<ReportProps> = (
  { reportData }: ReportProps,
  props
) => {
  let param = reportData.param;
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const data: any = reportData.new;
  // console.log("compliance report > ", data)
  const preData = reportData.pre;
  const [expanded, setExpanded] = useState<string | false | true>(false);
  const [expandAll, setExpandAll] = useState<boolean | undefined>(false);
  const [updatestatus, setUpdatestatus] = useState<boolean | undefined>(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: ""
  });
  // const history = useHistory();

  // let columns: any;
  const getColumnData = (key: any) => {
    let columns: any = [];
    if (key === "laws") {
      columns = [
        {
          title: "Name",
          field: "Name",
          render: (rowData: any, key: any) => (
            <p>
              {updatestatus ? (
                <Tooltip title="Updated" arrow placement="right">
                  <NewReleasesIcon style={{ color: green[500] }} />
                </Tooltip>
              ) : null}
              {rowData.Name}
            </p>
          )
        }
        // { title: "Description", field: "description" },
        // { title: "Published On", field: "published_on" },
        // { title: "Is Updated", field: "is_updated" },
        // { title: "Updated On", field: "updated_on" },
      ];
    }
    if (key === "policies") {
      columns = [
        {
          title: "Name",
          field: "Name",
          render: (rowData: any, key: any) => (
            <p>
              {updatestatus ? (
                <Tooltip title="Updated" arrow placement="right">
                  <NewReleasesIcon style={{ color: green[500] }} />
                </Tooltip>
              ) : null}
              {rowData.Name}
            </p>
          )
        },
        { title: "Description", field: "description" }
        // { title: "Published On", field: "published_on" },
      ];
    }
    // if (key === 'workplans') {
    //   columns = [
    //     {
    //       title: "Name",
    //       field: "Name",
    //       render: (rowData: any, key: any) => <p>{
    //         updatestatus ? <Tooltip title="Updated" arrow placement="right">
    //           <NewReleasesIcon style={{ color: green[500] }} />
    //         </Tooltip>
    //           : null
    //       }{rowData.Name}</p>
    //     },
    //     { title: "Description", field: "description" },
    //     { title: "Created On", field: "published_on" },
    //   ];
    // }
    return columns;
  };

  const getTableData = (key: any) => {
    if (preData[key]) {
      data[key]["description"].map((k: any, i: any) =>
        // preData[key] !== undefined
        checkNewData(preData[key]["description"], k.id)
      );
    }

    let finalData: any = [];
    for (let i in data[key]["description"]) {
      let dataKeys: any = {};
      dataKeys["id"] = data[key]["description"][i].id;
      if (key === "workplans") {
        dataKeys["Name"] = data[key]["description"][i].name;
      } else {
        dataKeys["Name"] = data[key]["description"][i].name;
      }
      if (key === "policies") {
        dataKeys["description"] =
          data["rules"]["description"][i].policy_content;
      } else {
        dataKeys["description"] = data[key]["description"][i].policy_content;
      }
      dataKeys["url"] = data[key]["description"][i].url;
      let publishDate = new Date(data[key]["description"][i].created_at);
      let published_On =
        publishDate.getDate() +
        "/" +
        publishDate.getMonth() +
        "/" +
        publishDate.getFullYear();
      dataKeys["published_on"] = published_On;
      dataKeys["law_document"] = data[key]["description"][i].law_document;
      dataKeys["is_updated"] = data[key]["description"][i].name;
      let updateDate = new Date(data[key]["description"][i].updated_at);
      let updated_On =
        updateDate.getDate() +
        "/" +
        updateDate.getMonth() +
        "/" +
        updateDate.getFullYear();
      dataKeys["updated_on"] = updated_On;
      finalData.push(dataKeys);
    }

    return finalData;
  };
  const handleClickOpen = (
    policyContent: any,
    title: any,
    key: any,
    template_description: any
  ) => {
    let documentData = {
      data: policyContent,
      key: key,
      title: title,
      template_description: template_description
    };
    const documentCreator = DocumentCreator(documentData);
    Packer.toBlob(documentCreator).then(blob => {
      var timeStamp = Math.floor(Date.now() / 1000);
      saveAs(blob, title + "_" + timeStamp + ".docx");
    });
  };

  const handleLawOpen = (rowData: any, key: any) => {
    //Discuss about getting the backend url dynamically
    if (
      rowData.law_document &&
      rowData.law_document[0] &&
      rowData.law_document[0].url
    ) {
      let lawUrl = rowData.law_document[0].url.slice(1);
      let DocUrl = serverUrl + lawUrl;
      fetch(DocUrl, {
        method: "GET"
      })
        .then(response => response.blob())
        .then(response => {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement("a");
          var timeStamp = Math.floor(Date.now() / 1000);
          let fileName = rowData.Name;
          let fileType = ".pdf";
          let docFileName = fileName + timeStamp + fileType;
          a.href = url;
          a.download = docFileName;
          a.click();
        });
    } else {
      alert("Law Document Not Available");
    }
  };

  // const handleClickView = (oldData: any, title: any, key: any) => {
  //   let viewdata: any = {
  //     oldData: oldData,
  //     key: key,
  //     title: title,
  //     props: param
  //   };
  //   history.push(routeConstant.COMPLIANCE + "/view", viewdata);
  // };

  let status = false;

  const checkNewData = (data: any, Id: any) => {
    let status = false;
    if (data.length > 0) {
      status = true;
      data.map((element: any) => {
        if (element.id === Id) {
          status = false;
        }
      });
    }
    return status;
  };

  const policyDescription = (rule: any, category: any) => {
    let policyDes: any[] = [];
    rule.map((element: any) => {
      if (element.category.id === category.id) {
        policyDes.push(element.policy_content);
      }
    });
    return policyDes;
  };

  const templateDescription = (rule: any, category: any) => {
    let templateDes: any[] = [];
    rule.map((element: any) => {
      if (element.category.id === category.id) {
        templateDes.push(element.category.template_description);
      }
    });
    return templateDes;
  };

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
    setExpandAll(undefined);
  };

  const handleExpanAll = () => {
    setExpandAll(!expandAll);
  };

  return (
    <React.Fragment>
      <div className={styles.expandButtonDiv}>
        <Button
          className={styles.expandButton}
          color="primary"
          variant="contained"
          onClick={(e: any) => handleExpanAll()}
        >
          {expandAll ? "Collapse All" : "Expand all"}
        </Button>
      </div>
      {Object.keys(data).map((key, index) => (
        <div>
          {data[key].title.includes("Workplan") !== true &&
          data[key].title.includes("Rule") !== true ? (
            <Accordion
              key={index}
              className={styles.ComplianceAccordion}
              expanded={expandAll !== undefined ? expandAll : expanded === key}
              onChange={handleChange(key)}
            >
              <AccordionSummary
                className={styles.ComplianceAccordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={styles.column}>
                  <Typography className={styles.heading}>
                    {data[key].title}
                  </Typography>
                </div>
                {/* <div className={styles.details}>
              <Link to={{ pathname: data[key].link }}>
                <Chip label="edit" />
              </Link>
            </div> */}
              </AccordionSummary>
              {/* <Divider /> */}
              <AccordionDetails className={styles.ComplianceAccordionDetails}>
                {key === "laws" ? (
                  <div className={styles.details}>
                    <p>
                      <MaterialTable
                        // title={data[key].title}
                        columns={getColumnData(key)}
                        data={getTableData(key)}
                        actions={[
                          {
                            icon: () => <GetAppIcon />,
                            tooltip: "Download",
                            onClick: (event: any, rowData: any) => {
                              handleLawOpen(rowData, event);
                            }
                          }
                          // {
                          //   icon: () => <VisibilityIcon />,
                          //   tooltip: "View",
                          //   onClick: (event: any, oldData: any) => {
                          //     handleClickView(oldData, key, event);
                          //   },
                          // },
                        ]}
                        options={{
                          headerStyle: {
                            backgroundColor: "#EFF6FD",
                            color: "#002F60"
                          },
                          actionsColumnIndex: -1,
                          paging: false,
                          sorting: false,
                          search: false
                        }}
                      />
                    </p>
                  </div>
                ) : null}
                <Typography className={styles.secondaryHeading}>
                  <Grid container>
                    {/* <Grid item xs={12} sm={12}> */}
                    {/* <Grid container spacing={2}> */}
                    {/* <Grid item xs={12} sm={12}> */}

                    {/* </Grid> */}
                    {/* </Grid> */}
                    {data[key]["description"].map((k: any, i: any) => (
                      <Grid item xs={12} sm={12} className={styles.details}>
                        {key === "policies" ? (
                          <Grid container>
                            <Grid item xs={9} sm={10}>
                              <span className={styles.bottomdetails}>{k.name}</span>
                            </Grid>
                            <Grid item xs={3} sm={2}>
                              {/* {policyDescription(data['rules'].description, k.category).map((policydata: any, i: any) => (
                                  <div>{policydata}<br></br><br></br></div>
                                ))
                                } */}
                              {/* <div>{[policyDescription(data['rules'].description, k.category), (<br></br>)]}<br></br></div> */}
                              {/* <Grid container><Grid item xs={12} sm={12}><label>{k.description}</label></Grid></Grid> */}
                              {/* {console.log(" k.category", data['rules'].description[i].category.id)} */}
                              <span className={styles.bottomdetails}>
                              <span className={styles.DownloadButton}>
                                <Button
                                  onClick={() => {
                                    handleClickOpen(
                                      policyDescription(
                                        data["rules"].description,
                                        k.category
                                      ),
                                      k.name,
                                      key,
                                      templateDescription(
                                        data["rules"].description,
                                        k.category
                                      )
                                    );
                                  }}
                                >
                                  <GetAppIcon />
                                </Button>
                              </span></span>
                              {/* <span className={styles.DownloadButton}>
                                  <Button onClick={() => { handleClickView(policyDescription(data['rules'].description, k.category), k.name, key) }}><VisibilityIcon /></Button>
                                </span> */}
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                    ))}

                    {/* </Grid> */}
                  </Grid>
                </Typography>
                {/* {data[key]['description'].map((k: any, i: any) => ( */}

                {/* {key === 'workplans' && data[key]['description'][0] ? (
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Grid></Grid>
                      <b><span>{data[key]['description'][0].name}</span></b>
                      <Grid >
                        {workplanDescription(data['rules'].description).map((workplandata: any, i: any) => (
                          <div>{workplandata}<br></br><br></br></div>
                        ))}
                        < Grid container><Grid item xs={12} sm={12}>{workplanDescription(data['rules'].description)}</Grid></Grid> 
                        <span className={styles.DownloadButton}>
                          <Button onClick={() => { handleClickView(workplanDescription(data['rules'].description), data[key]['description'][0].name, key) }}><VisibilityIcon /></Button>
                        </span>
                        <span className={styles.DownloadButton}>
                          <Button onClick={() => { handleClickOpen(workplanDescription(data['rules'].description), data[key]['description'][0].name, key); }} ><GetAppIcon /></Button>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>)
                  : null} */}
              </AccordionDetails>
            </Accordion>
          ) : null}
        </div>
      ))}
    </React.Fragment>
  );
};

export default ComplianceReport;
