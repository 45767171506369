import { gql } from "@apollo/client";

export const GET_POLICY = gql`
  query($where: JSON, $sort: String) {
    policies(where: $where, sort: $sort) {
      id
      name
      description
      category {
        id
        name
      }
    }
  }
`;
