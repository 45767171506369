import React from "react";
// import { BrowserRouter as  Router, Route, Link, Switch } from "react-router-dom";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router";
import Dashboard from "./containers/Dashboard/Dashboard";
import CompanyProfile from "./containers/CompanyProfile/CompanyProfile";
import MainOffice from "./containers/MainOffice/MainOffice";
import OtherOffice from "./containers/OtherOffice/OtherOffice";
import Customer from "./containers/Customer/Customer";
import Review from "./containers/Review/Review";
import Compliance from "./containers/Workplan/Compliance/Compliance";
import Logout from "./containers/Auth/Logout/Logout";
import PartnerUser from "./containers/AdminPanel/Partner/PartnerUser/PartnerUser";
import PartnerUserForm from "./containers/AdminPanel/PartnerUserForm/PartnerUserForm";
import Views from "./containers/Workplan/Views/Views";
import * as routeConstants from "./common/RouteConstants";
import Client from "./containers/Client/Client";
// import Assessment from "./containers/CSet/Assessment/Assessment";
import ComplianceList from "./containers/ComplianceList/ComplianceList";
import TopStepperRoute from "./common/TopStepperRoute";
import LayoutRoute from "./common/LayoutRoute";
// import AssessmentInfo from "./containers/CSet/AssessmentInfo/AssessmentInfo";
// import SecurityAssuranceLevel from "./containers/CSet/SecurityAssuranceLevel/SecurityAssuranceLevel";
// import Standards from "./containers/CSet/Standards/Standards";
// import Reports from "./containers/CSet/Reports/Report/Reports";
// import ExecutiveSummary from "./containers/CSet/Reports/ReportGeneration/ExecutiveSummary/ExecutiveSummary";
// import Results from "./containers/CSet/Results/Results"
// import StandardSummary from "./containers/CSet/Results/StandardSummary/StandardSummary"
// import Questions from "./containers/CSet/Questions/Questions"
// import SiteCyberSecurityPlan from "./containers/CSet/Reports/ReportGeneration/SiteCyberSecurityPlan/SiteCyberSecurityPlan";
// import SiteDetailsReport from "./containers/CSet/Reports/ReportGeneration/SiteDetailReport/SiteDetailsReport";
// import SiteSummaryReport from "./containers/CSet/Reports/ReportGeneration/SiteSummaryReport/SiteSummaryReport";
// import ObservationsTearOutSheet from "./containers/CSet/Reports/ReportGeneration/ObservationsTearOutSheet/ObservationsTearOutSheet"
// import FirmType from "./containers/CSet/FirmType/FirmType";
import ClientForm from "./containers/Client/ClientForm/ClientForm";

const defaultRedirect = () => <Redirect to={routeConstants.CLIENT} />;

export const AuthRoutes = (
  <Switch>
    <LayoutRoute path={routeConstants.PARTNER_USER_FORM} exact component={PartnerUserForm} />
    <LayoutRoute path={routeConstants.PARTNER_USER_FORM_ADD} exact component={PartnerUserForm} />
    <LayoutRoute path={routeConstants.PARTNER_USER_FORM_EDIT + ":id" }exact component={PartnerUserForm} />
    <LayoutRoute path={routeConstants.PARTNER_USER_FORM_EDIT+ "/:id "} exact component={PartnerUserForm} />
    <LayoutRoute path={routeConstants.DASHBOARD} exact component={Dashboard} />
    <LayoutRoute path={routeConstants.CLIENT} exact component={Client} />
    <LayoutRoute path={routeConstants.CLIENT_FORM_ADD} exact component={ClientForm} />
    <LayoutRoute path={routeConstants.CLIENT_FORM_EDIT + ":id" }exact component={ClientForm} />
    <LayoutRoute path={routeConstants.COMPLIANCE_LIST} exact component={ComplianceList} />
    <LayoutRoute path={routeConstants.PARTNER_USER} exact component={PartnerUser} />
    
    <TopStepperRoute path={routeConstants.COMPANY_INFO} exact component={CompanyProfile} />
    <TopStepperRoute path={routeConstants.MAIN_OFFICE} exact component={MainOffice} />
    <TopStepperRoute path={routeConstants.OTHER_OFFICE} exact component={OtherOffice} />
    <TopStepperRoute path={routeConstants.CUSTOMER} exact component={Customer} />
    <TopStepperRoute path={routeConstants.REVIEW} exact component={Review} />
    <TopStepperRoute path={routeConstants.COMPLIANCE} exact component={Compliance} />
    
    <LayoutRoute path={routeConstants.LOGOUT_URL} exact component={Logout} />
    <LayoutRoute path={routeConstants.COMPLIANCE + "/view"} exact component={Views} />
    
    {/* -------------Routes for CSET------------ */}
    {/* <LayoutRoute path={routeConstants.ASSESSMENT_LIST} exact component={Assessment} />
    <LayoutRoute path={routeConstants.ASSESSMENT_INFO} exact component={AssessmentInfo} />
    <LayoutRoute path={routeConstants.SECURITY_ASSURANCE_LEVEL} exact component={SecurityAssuranceLevel} />
    <LayoutRoute path={routeConstants.STANDARDS} exact component={Standards} />
    <LayoutRoute path={routeConstants.RESULTS} exact component={Results} />
    <LayoutRoute path={routeConstants.STANDARDSUMMARY} exact component={StandardSummary} />
    <LayoutRoute path={routeConstants.QUESTIONS} exact component={Questions} />
    <LayoutRoute path={routeConstants.REPORTS} exact component={Reports} />
    <LayoutRoute path={routeConstants.EXECUTIVE_SUMMARY} exact component={ExecutiveSummary} />
    <LayoutRoute path={routeConstants.SITE_CYBERSECURITY_PLAN} exact component={SiteCyberSecurityPlan} />
    <LayoutRoute path={routeConstants.SITE_DETAIL_REPORT} exact component={SiteDetailsReport} />
    <LayoutRoute path={routeConstants.SITE_SUMMARY_REPORT} exact component={SiteSummaryReport} />
    <LayoutRoute path={routeConstants.OBSERVATIONS_TEAR_OUT_SHEETS} exact component={ObservationsTearOutSheet} />
    <LayoutRoute path={routeConstants.FIRM_TYPE} exact component={FirmType} /> */}
    <Route path="/" render={defaultRedirect} />
  </Switch>
);
