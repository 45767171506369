// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Company_Company__28hMF {}\n\n/* .layout {\n  width: 100%;\n} */\n\n.Company_paper__20WO8 {\n  margin-bottom: 20px;\n  padding: 28px 25px 17px 25px;\n  background-color: #FFF;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);\n  border-radius: 20px !important;\n  border: 1px solid #0051A8;\n  position: relative;\n}\n\n/* h1 {\n  color: #002f60 !important;\n  padding: 0;\n  text-align: left;\n  font-family: \"Roboto\", sans-serif !important;\n  font-size: 24px !important;\n} */\n\n/* .companyctrl {\n  margin-top: 10px !important;\n  margin-bottom: 10px !important;\n  margin-right: 10px !important;\n} */\n\n#Company_combo-box-demo__3etiD {\n  margin: 0px !important;\n  border: 1px solid #0051a8;\n}\n\n/* .MuiButton-root {\n  font-size: 12px !important;\n  margin-top: 24px;\n  text-align: center;\n} */\n\n.Company_MTableHeader-header__2OCfz {\n  background-color: #ff0000;\n}\n\n/* .resetbutton {\n  color: #0051a8;\n  background-color: transparent;\n  border: none !important;\n  box-shadow: unset !important;\n  font-size: 14px;\n} */\n\n.Company_FilterWrap__3_dRL {\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: 5px;\n  align-items: flex-end;\n}\n\n.Company_FilterInput__1w0hy {\n  width: 250px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.Company_FilterSearchButton__3WiYx {\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.Company_FilterResetButton__3QQit {\n  margin-bottom: 10px;\n}\n\n.Company_FilterResetButton__3QQit button {\n  background-color: transparent !important;\n  border: 0px !important;\n  padding-left: 5px !important;\n  padding-right: 5px !important;\n  box-shadow: none !important;\n}\n\n.Company_FilterResetButton__3QQit button span {\n  font-weight: 600 !important;\n}", ""]);
// Exports
exports.locals = {
	"Company": "Company_Company__28hMF",
	"paper": "Company_paper__20WO8",
	"combo-box-demo": "Company_combo-box-demo__3etiD",
	"MTableHeader-header": "Company_MTableHeader-header__2OCfz",
	"FilterWrap": "Company_FilterWrap__3_dRL",
	"FilterInput": "Company_FilterInput__1w0hy",
	"FilterSearchButton": "Company_FilterSearchButton__3WiYx",
	"FilterResetButton": "Company_FilterResetButton__3QQit"
};
module.exports = exports;
