import { gql } from '@apollo/client';

export const GET_COMPLIANCE_INFO = gql`
  query($input: JSON) {
    getComplianceInfo(data: $input) {
      data {
        mainoffice {
          state {
            id
            name
            is_active
            created_at
            updated_at
          }
          addressId
          allowDownload
          laws {
            id
            name
            state {
              id
            }
            created_at
            updated_at
            jurisdiction
            url
            parent_law {
              id
            }
            law_document {
              id
              name
              url
            }
          }
          rules {
            id
            name
            created_at
            updated_at
            category {
              id
              name
              template_description
              }
            policy_description
            policy_content
            workplan_description
          }
          policies {
            id
            name
            created_at
            updated_at
            category {
              id
            }
            description
          }
          workplans {
            id
            created_at
            updated_at
            name
            category {
              id
            }
          }
        }
        otheroffice {
          state {
            id
            name
            is_active
            created_at
            updated_at
          }
          addressId
          allowDownload
          laws {
            id
            name
            state {
              id
            }
            created_at
            updated_at
            jurisdiction
            url
            parent_law {
              id
            }
            law_document {
              id
              name
              url
            }
          }
          rules {
            id
            name
            created_at
            updated_at
            category {
              id
              name
              template_description
            }
            policy_content
            policy_description
            workplan_description
          }
          policies {
            id
            name
            created_at
            updated_at
            category {
              id
            }
            description
          }
          workplans {
            id
            created_at
            updated_at
            name
            category {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_COMPLIANCE = gql`
  query getCompliance($where: JSON, $sort: String) {
    compliances(where: $where, sort: $sort) {
      id
      created_at
      updated_at
      contact_id {
        id
        name
        contact_type
      }
      address_id {
        id
      }
      allow_download
      compliance_version {
        id
      }
      laws {
        id
      }
      rules {
        id
      }
      policies {
        id
      }
      workplans {
        id
      }
      workplan_document {
        id
      }
    }
  }
`