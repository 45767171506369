// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.Workplan_paper__uzTXV {\n  margin-bottom: 20px;\n  padding: 28px 25px 17px 25px;\n  background-color: #FFF;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);\n  border-radius: 20px !important;\n  border: 1px solid #0051A8;\n  position:relative;\n}\n.Workplan_FilterWrap__Zfvtg {\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: 5px;\n  align-items: flex-end;\n}\n.Workplan_FilterInput__3plP6 {\n  width:250px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n.Workplan_FilterSearchButton__3yCfy{\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n.Workplan_FilterResetButton__2LZsM{\n  margin-bottom: 10px;\n}\n.Workplan_FilterAddWrap__38M64 {\n  float: right;\n  margin-top: 10px;\n}\n.Workplan_FilterResetButton__2LZsM button{\n  background-color: transparent !important;\n  border: 0px !important;\n  padding-left: 5px !important;\n  padding-right: 5px !important;\n  box-shadow: none !important;\n}\n.Workplan_FilterResetButton__2LZsM button span{\n  font-weight:600 !important;\n}\n.Workplan_EditIcon__S-FI7 {\n  height: 18px !important;\n  width: 18px !important;\n}", ""]);
// Exports
exports.locals = {
	"paper": "Workplan_paper__uzTXV",
	"FilterWrap": "Workplan_FilterWrap__Zfvtg",
	"FilterInput": "Workplan_FilterInput__3plP6",
	"FilterSearchButton": "Workplan_FilterSearchButton__3yCfy",
	"FilterResetButton": "Workplan_FilterResetButton__2LZsM",
	"FilterAddWrap": "Workplan_FilterAddWrap__38M64",
	"EditIcon": "Workplan_EditIcon__S-FI7"
};
module.exports = exports;
