import { gql } from "@apollo/client";

export const CREATE_STATE = gql`
  mutation CreateState($name: String!, $is_active: Boolean!) {
    createState(input: { data: { name: $name, is_active: $is_active } }) {
      state {
        id
        name
        is_active
      }
    }
  }
`;

export const UPDATE_STATE = gql`
  mutation UpdateState($id: ID!, $name: String!, $is_active: Boolean!) {
    updateState(
      input: {
        where: { id: $id }
        data: { name: $name, is_active: $is_active }
      }
    ) {
      state {
        id
        name
        is_active
      }
    }
  }
`;

export const DELETE_STATE = gql`
  mutation DeleteState($id: ID!) {
    deleteState(input: { where: { id: $id } }) {
      state {
        id
        name
        is_active
      }
    }
  }
`;
