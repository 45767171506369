import React, { useState, useEffect } from "react";
import styles from "./PiiDataForm.module.css";
import { ACTIVE_PII_FIELDS } from "../../graphql/queries/PiiData";
import { useQuery } from "@apollo/client";
import Loading from "../../components/UI/Layout/Loading/Loading";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from '@material-ui/core/Checkbox';
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import { Typography, FormHelperText } from "@material-ui/core";
// import RadioGroup from "../../components/UI/Form/RadioGroup/RadioGroup"
// import FormControlLabel from "../../components/UI/Form/FormControlLabel/FormControlLabel"
import Switch from "../../components/UI/Form/Switch/Switch";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Grid
} from "@material-ui/core";

export interface PiiDataProps {
  handleChange?: (event: any) => void;
  handleCheckElement?: (event: any) => void;
  doYouCollectPiiData?: Boolean;
  showPiiData?: Boolean;
  PIIdata?: any;
  checked?: any;
  PIIErrorCheck?: (event: any) => void;
  showPiiError?: Boolean;
}

interface Pii {
  id: number;
  name: string;
}

export const PiiDataForm: React.SFC<PiiDataProps> = props => {
  const [piiDataList, setPiiDataList] = useState([]);
  const { data: data, error: error, loading: loading } = useQuery(
    ACTIVE_PII_FIELDS
  );
  const [piichecked, setPiiChecked] = useState(false);
  let showpiidata = props.showPiiData || false;

  if (loading) return <Loading />;

  if (error) {
    let error = { message: "Error" };
    return (
      <div className="error">
        Error!
        {/* Error! ${error.message} */}
      </div>
    );
  }

  if (piiDataList.length === 0) {
    setPiiDataList(data.piiData);
  }
  const DisplayError = () => {
    let piErr: any;
    if (props.doYouCollectPiiData && props.PIIdata.length === 0) {
      if (props.PIIErrorCheck) {
        props.PIIErrorCheck(true);
      }
      piErr = (
        <div className={styles.errormsg}>
          <FormHelperText classes={{ root: styles.FormHelperText }}>
            Please Select PII Fields.
          </FormHelperText>
        </div>
      );
    } else {
      if (props.PIIErrorCheck !== undefined) {
        props.PIIErrorCheck(false);
      }
    }
    return piErr;
  };
  if (piichecked === false && props.doYouCollectPiiData !== false) {
    setPiiChecked(true);
  }
  if (piichecked === true && props.doYouCollectPiiData !== true) {
    setPiiChecked(false);
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className={styles.PiiDataGrid}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Do you collect PII data?</FormLabel>
            <div className={styles.pgswitch}>
              <Switch
                className={
                  piichecked ? styles.PiiDataSwitch : styles.PiiDataSwitchCheck
                }
                id="piiData"
                checked={piichecked}
                onChange={props.handleChange}
                name="doYouCollectPiiData"
                {...props}
              ></Switch>
              <span className={styles.PiiDataSwitchLabels}>
                <span className={styles.PiiDataSwitchNo}>NO</span>
                <span className={styles.PiiDataSwitchYes}>YES</span>
              </span>
            </div>
          </FormControl>

          {showpiidata || piichecked ? (
            <FormGroup row>
              {piiDataList.map((obj: any, i: any) => {
                return (
                  <FormControlLabel
                    className={styles.CheckboxLabel}
                    key={obj.id}
                    control={
                      <Checkbox
                        checked={props.PIIdata.indexOf(parseInt(obj.id)) >= 0}
                        onChange={props.handleCheckElement}
                        name={obj.name}
                        value={obj.id}
                      />
                    }
                    label={obj.name}
                  />
                );
              })}
              {props.showPiiError ? DisplayError() : null}
            </FormGroup>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PiiDataForm;
