import { gql } from "@apollo/client";

export const UPLOAD_FILE = gql`
  mutation uploadFile(
    $refId: ID
    $ref: String
    $field: String
    $source: String
    $file: Upload!
  ) {
    upload(
      refId: $refId
      ref: $ref
      field: $field
      source: $source
      file: $file
    ) {
      id
      name
    }
  }
`;

export const uploadFiles = gql`
mutation uploadFiles($files: Upload!) {
  upload(file: $files) {
    id
    name
    hash
    url
  }
}
`;
// // export const DELETEUPLOADEDFILES = gql`
// // mutation uploadFiles($files: Upload!) {
// //   upload(file: $files) {
// //     id
// //     name
// //     hash
// //     url
// //   }
// // }
// `;