import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { ApolloLink } from "apollo-link";
import { onError } from "@apollo/link-error";
import { RA_URI, URI } from '.';
import { setContext } from '@apollo/link-context';
import * as routeConstants from '../common/RouteConstants';

const gqlClient = (auth_token: string | null) => {

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: auth_token ? "Bearer " + auth_token : null,
      },
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  let httpLink: any;
  let link: any;
  let uploadLink : any
  httpLink = createUploadLink({ uri: URI });
  link = auth_token ? authLink.concat(httpLink) : httpLink;
 

  return new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });
};
export default gqlClient;
