import React, { useEffect, useState } from "react";
import styles from "./Review.module.css";
import { useHistory } from "react-router-dom";
import { GET_REVIEW_INFO } from "../../graphql/queries/Review";
import { SET_COMPLIANCE } from "../../graphql/mutations/Compliance";
import { CREATE_COMPLIANCE_VERSION } from "../../graphql/mutations/ComplianceVersion";
import { useMutation, useLazyQuery } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import {
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER
} from "../../graphql/mutations/Customer";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReviewExpansionPanel from "./ReviewExpansionPanel.json";
import Grid from "@material-ui/core/Grid";
import * as routeConstant from "../../common/RouteConstants";
import * as msgConstant from "../../common/MessageConstants";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AlertBox from "../../components/UI/AlertBox/AlertBox";
import { Button } from "../../components/UI/Form/Button/Button";
import { useApolloClient } from "@apollo/client";
import { setStepper } from "../../common/SetStepper";
import stepper from "../../common/stepperMenu.json";
import MaterialTable from "../../components/UI/Table/MaterialTable";
import { GET_COMPLIANCE } from "../../graphql/queries/Compliance";
import { DialogBox } from "../../components/UI/DialogBox/DialogBox";
import Office from "../Office/Office";
import CompanyForm from "../CompanyForm/CompanyForm";
import CustomerForm from "../CustomerForm/CustomerForm";
import { GET_EMPLOYEE_DATA } from "../../graphql/queries/OrganizationLocation";
import { GET_ADDRESS } from "../../graphql/queries/Address";
import Switch from "../../components/UI/Form/Switch/Switch";
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormControl, FormLabel } from "@material-ui/core";
import {
  UPDATE_ADDRESS,
  DELETE_ADDRESS
} from "../../graphql/mutations/Address";
import {
  UPDATE_ORG_LOC,
  DELETE_ORG_LOC
} from "../../graphql/mutations/OrganizationLocation";
import { GET_CUSTOMER } from "../../graphql/queries/Customers";
import { UPDATE_ORGCONTACT } from "../../graphql/mutations/Contacts";
import {
  UPDATE_COMPLIANCEINFO_NAME,
  UPDATE_COMPLIANCEINFO
} from "../../graphql/mutations/ComplianceInfo";
import { UPDATE_COMPLIANCE } from "../../graphql/mutations/Compliance";
import TopStepper from "../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ACTIVE_PII_FIELDS } from "../../graphql/queries/PiiData";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import Input from "../../components/UI/Form/Input/Input";
import { getActiveFormStep } from "../../services/Data";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { DocumentCreator } from "../../components/GenerateDocument/GenerateDoc";
import { GET_COMPLIANCE_VERSION } from "../../graphql/queries/ComplianceVersion";
import { GET_COMPLIANCE_INFO } from "../../graphql/queries/Compliance";
import { GET_COMPLIANCEINFO } from "../../graphql/queries/ComplianceInfo";
import { uploadFiles } from "../../graphql/mutations/Upload";
// import  save  from 'save-file'
import {
  returnArray,
} from "../../services/Data";
interface Foo {
  key: string;
}

export const Review: React.FC = (props: any) => {
  let stepperObject = stepper;
  const JSZip = require("jszip");
  const envVariables = process.env;
  const client = useApolloClient();
  const data: any = ReviewExpansionPanel;
  const history = useHistory();
  const [haveOtherOffice, setHaveOtherOffice] = useState(true);
  const [orgContactId, setOrgContactId] = useState<any | null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [complianceZip, setComplianceZip] = useState()
  const [fileName, setFileName] = useState<any>();
  const [openPiiDataDialogBox, setOpenPiiDataDialogBox] = useState<boolean>(false);
  const [uploadDocDdata, setUploadDocDdata] = useState<any>();
  const [version, setVersion] = useState<any>([]);
  const [preMainOffice, setPreMainOffice] = useState<any>([]);
  const [preOtherOffice, setPreOtherOffice] = useState<any>([]);
  const [mainOffice, setMainOffice] = useState<any>("");
  const [otherOffice, setOtherOffice] = useState<any>("");
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [folderName, setfolderName] = useState<any>();
  const [otherOfficeData, setOtherOfficeData] = useState<any>([]);
  const [param, setParam] = useState<any>();
  const [doYouCollectPiiData, setDoYouCollectPiiData] = useState<
    boolean | undefined
  >();
  const [currentComplianceId, setCurrentComplianceId] = useState("");
  const [currrentContactId, setCurrrentContactId] = useState("");
  const [currentlaw, setCurrentlaw] = useState<any>([]);
  const [currentRules, setCurrentRules] = useState<any>([]);
  const [currentpolicies, setCurrentpolicies] = useState<any>([]);
  const [currentworkplan, setCurrentworkplan] = useState<any>([]);
  const [currentaddress, setCurrentaddress] = useState<any>();
  const [currentallowdownload, setCurrentallowdownload] = useState();
  const [currentcompliance_version, setCurrentcompliance_version] = useState<any>();
  const [showPiiData, setShowPiiData] = useState(false);
  const [piiArray, setPiiArray] = useState<any>([]);
  const [showBackDrop, setShowBackdrop] = useState(true);
  //expand button
  const [expanded, setExpanded] = useState<string | false | true>(false);
  const [downloadComp, setDownloadComp] = useState(false);
  const [expandAll, setExpandAll] = useState<boolean | undefined>(false);
  const [stepperObj, setStepperObj] = useState<any>({path: ""});
  const [fileChanged, setFileChanged] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<any>(false)
  const [customerForm, setCustomerForm] = useState<any>([
    {
      id: null,
      state: "",
      numOfCustomer: "",
      contact_id: null,
      doYouCollectPiiData: false,
      piiArray: [],
      isError: {
        state: false,
        numOfCustomer: false
      }
    }
  ]);
  const [dataObj, setDataObj] = useState<any>([
    {
      id: null,
      company_name: "",
      company_type: "",
      company_subtype: "",
      bestPractice: [],
      isError: ""
    }
  ]);
  const [offices, setOffices] = useState([
    {
      address: {
        location_type: "",
        id: null,
        address1: "",
        address2: "",
        state: "",
        city: "",
        zip: "",
        isError: {
          address1: false,
          address2: false,
          state: false,
          city: false,
          zip: false
        }
      },
      employee: {
        id: null,
        noOfEmployee: "",
        doYouCollectPiiData: false,
        piiArray: [],
        isError: {
          noOfEmployee: false
        }
      }
    }
  ]);
  const newData = {
    laws: {
      title: "Laws applicable to the company",
      description: [],
      icon: "PermIdentityIcon",
      link: "company-Info",
    },
    rules: {
      title: "Rules applicable to the company",
      description: [],
      icon: "HomeWorkIcon",
      link: "company-address",
    },
    policies: {
      title: "Policies based on the laws",
      description: [],
      icon: "HomeWorkIcon",
      link: "company-address",
    },
    workplans: {
      title: "Workplan",
      description: [],
      icon: "HomeWorkIcon",
      link: "company-address",
    },
  };
 
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [dialogBoxMsg, setDialogBoxMsg] = useState("");
  const [buildWorkplanObj, setBuildWorkplanObj] = useState<any>([]);
  const [element, setElement] = useState<any>();
  //pii data constants
  const [piiDataList, setPiiDataList] = useState<any>([]);
  const [complianceName, setComplianceName] = useState<any>("");
  let showpiidata = props.showPiiData || false;
  const [isError, setIsError] = useState<any>({
    complianceName: ""
  });
  const [uploadFile] = useMutation(uploadFiles);
  const [updateAddress] = useMutation(UPDATE_ADDRESS);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [UpdateContact] = useMutation(UPDATE_ORGCONTACT);
  const [updateComplianceInfo] = useMutation(UPDATE_COMPLIANCEINFO);
  const [updateComplianceData] = useMutation(UPDATE_COMPLIANCE);
  const [setCompliance] = useMutation(SET_COMPLIANCE);
  const [createComplianceVersion] = useMutation(CREATE_COMPLIANCE_VERSION);
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER);
  const [deleteAddress] = useMutation(DELETE_ADDRESS);
  const [deleteEmployee] = useMutation(DELETE_ORG_LOC);
  const [updateEmployee] = useMutation(UPDATE_ORG_LOC);
  const [updateComplianceInfoName] = useMutation(UPDATE_COMPLIANCEINFO_NAME);
  const handleCancel = () => {
    let val = {
      clientInfo: param.clientInfo
    }
    history.push(routeConstant.COMPLIANCE_LIST, val);
  };
  const [
    getComplianceData,
    { data: complianceData, loading: complianceLoading }
  ] = useLazyQuery(GET_COMPLIANCE, {
    onCompleted: (data: any) => {
      setBuildWorkplanObj(data.compliances);
      if(data.compliances.length > 0){
        console.log("data",data.compliances[data.compliances.length - 1].id )
        console.log("data.compliances",data.compliances )
        setCurrentComplianceId(data.compliances[data.compliances.length - 1].id)
        setCurrrentContactId(data.compliances[data.compliances.length - 1].contact_id.id)
        setCurrentlaw(returnArray(data.compliances[data.compliances.length - 1].laws))
        setCurrentRules(returnArray(data.compliances[data.compliances.length - 1].rules))
        setCurrentpolicies(returnArray(data.compliances[data.compliances.length - 1].policies))
        setCurrentworkplan(returnArray(data.compliances[data.compliances.length - 1].workplans))
        setCurrentaddress(parseInt(data.compliances[data.compliances.length - 1].address_id.id))
        setCurrentallowdownload(data.compliances[data.compliances.length - 1].allow_download)
        setCurrentcompliance_version(parseInt(data.compliances[data.compliances.length - 1].compliance_version))
       
      }
    },
    fetchPolicy: "cache-and-network",
    onError:()=>{
      handleCancel()
    }
  });

  // Restore Address
  const [getaddress, { data: dataAD, loading: loadingAD }] = useLazyQuery(
    GET_ADDRESS,
    {
      fetchPolicy: "cache-and-network",
      onError:()=>{
        setShowBackdrop(false)
        handleCancel()
      }
    }
  );

  // Restore Employee
  const [getOrgLocation, { data: dataOL, loading: loadingOL }] = useLazyQuery(
    GET_EMPLOYEE_DATA,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data: any) => {
        setShowBackdrop(false)
      },
      onError:()=>{
        setShowBackdrop(false)
        handleCancel()
      }
    }
  );

  const [getPiiData, { data: dataPD, loading: loadingPD }] = useLazyQuery(
    ACTIVE_PII_FIELDS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data: any) => {
        if (piiDataList.length === 0) {
          setPiiDataList(dataPD.piiData);
        }
      },
      onError:()=>{
        setShowBackdrop(false)
        handleCancel()
      }
    }
  );

  const [
    getComplianceInfo,
    { data: dataOrgUser, error: errorOrgUser, loading: loadingOrgUser }
  ] = useLazyQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setComplianceName(dataOrgUser.complianceInfos[0].name);
      setOrgContactId(dataOrgUser.complianceInfos[0].contact_id.id);
      getComplianceData({
        variables: {
          where: { contact_id: dataOrgUser.complianceInfos[0].contact_id.id }
        }
      });
      getReview({
        variables: {
          ID: parseInt(dataOrgUser.complianceInfos[0].contact_id.id)
        }
      });
      getaddress({
        variables: {
          where: { contact_id: dataOrgUser.complianceInfos[0].contact_id.id }
        }
      });
      getOrgLocation({
        variables: {
          where: {
            address_id: { location_type: "Main" },
            contact_id: dataOrgUser.complianceInfos[0].contact_id.id
          }
        }
      });
      getComplianceVersion({
        variables: {
          where: { contact_id: dataOrgUser.complianceInfos[0].contact_id.id },
          sort: "created_at:DESC",
        },
      });
    },
    onError:()=>{
      setShowBackdrop(false)
      handleCancel()
    }
  });

  const [
    getComplianceVersion,
    { data: compVersionData, loading: compVersionLoading },
  ] = useLazyQuery(GET_COMPLIANCE_VERSION, {
    onCompleted: () => {
      if (compVersionData.complianceVersions.hasOwnProperty(0)) {
        getComplianceInformation(
          compVersionData.complianceVersions[0].compliances
        );
      }
      setVersion(compVersionData.complianceVersions);
      if (compVersionData.complianceVersions.hasOwnProperty(1)) {
        getPreComplianceInfo(compVersionData.complianceVersions[1].compliances);
      }
    },
    onError:()=>{
      setShowBackdrop(false)
    },
    fetchPolicy: "cache-and-network",
  });
  

  const [
    getCompliance,
    { data: compliData, loading: compLoading },
  ] = useLazyQuery(GET_COMPLIANCE_INFO, {
    onCompleted: () => {
      setMainOffice(compliData.getComplianceInfo.data.mainoffice);
      setOtherOffice(compliData.getComplianceInfo.data.otheroffice);
      setShowBackdrop(false)
    },
    onError:()=>{
      setShowBackdrop(false)
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    getPrevCompliance,
    { data: prvComplianceData, loading: prvCompLoading },
  ] = useLazyQuery(GET_COMPLIANCE_INFO, {
    onCompleted: () => {
      setPreMainOffice(prvComplianceData.getComplianceInfo.data.mainoffice);
      setPreOtherOffice(prvComplianceData.getComplianceInfo.data.otheroffice);
      setShowBackdrop(false)
    },
     onError:()=>{
      setShowBackdrop(false)
    },
    fetchPolicy: "cache-and-network",
  });

  const getComplianceInformation = (data: any) => {
    getCompliance({
      variables: {
        input: data,
      },
    })
  };

  const getPreComplianceInfo = (data: any) => {
    getPrevCompliance({
      variables: {
        input: data,
      },
    });
  };

  useEffect(()=>{
    if(newData && downloadComp){
      downloadAllOffice(newData)
    }
  },[mainOffice])

  const [
    getReview,
    { data: reviewData, error: error, loading: loading }
  ] = useLazyQuery(GET_REVIEW_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: any) => {
      setShowLoading(false);
    },
    onError:()=>{
      handleCancel()
    }
  });

  const updateCompliance = (val: any) => {
    updateComplianceData({
      variables: val,
    })
      .then((UpdateOrgRes) => {handleNext()})
      .catch((error) => {});
  };

  const restoredata = () => {
    let office: any = [];
    if (dataAD && dataOL) {
      // check if there is other office available or not
      let val = dataAD.addresses.filter(
        (item: any) => item.location_type === "Other"
      );
      if (val.length !== 0) {
        setOtherOffice(true);
        setHaveOtherOffice(true);
      } else {
        setOtherOffice(false);
        setHaveOtherOffice(false);
      }
      if (
        dataAD.addresses !== undefined &&
        dataOL.organizationLocations[0] &&
        dataAD.addresses[0] !== undefined &&
        dataOL.organizationLocations !== undefined
      ) {
        let piiArray: any = [];
        for (let i in dataOL.organizationLocations[0].pii_data_field) {
          piiArray.push(
            parseInt(dataOL.organizationLocations[0].pii_data_field[i].id)
          );
        }
        office.push({
          address: {
            id: dataAD.addresses[0].id,
            location_type: dataAD.addresses[0].location_type,
            address1: dataAD.addresses[0].address_line_1,
            address2: dataAD.addresses[0].address_line_2,
            city: dataAD.addresses[0].city,
            state: dataAD.addresses[0].state,
            zip: dataAD.addresses[0].zip,
            isError: {}
          },
          employee: {
            id: dataOL.organizationLocations[0].id,
            noOfEmployee: dataOL.organizationLocations[0].no_of_employees,
            doYouCollectPiiData:
              dataOL.organizationLocations[0].collect_pii_information,
            piiArray: piiArray,
            isError: {}
          }
        });
      }
      if (office.length > 0) {
        setOffices(office);
      }
    }
  };

  useEffect(() => {
    restoredata();
    if (dataAD !== undefined && dataOL !== undefined) {
      if (dataAD.addresses.length >= 0) {
        setSubmitDisabled(false);
      }
      if (dataAD.addresses.length === 0) {
        setSubmitDisabled(true);
      }
    }
  }, [dataAD, dataOL]);

  const getFields = (input: any, field: any) => {
    let output = [];
    for (let i = 0; i < input.length; ++i)
      output.push(parseInt(input[i][field]));
    return output;
  };

  // Restore Customer
  const [getCustomer, { data: dataCU, loading: loadingCU }] = useLazyQuery(
    GET_CUSTOMER,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data: any) => {
        let customer: any = [
          {
            id: dataCU.customers[0].id,
            contact_id: dataCU.customers[0].contact_id.id,
            state: dataCU.customers[0].state,
            numOfCustomer: dataCU.customers[0].no_of_clients,
            doYouCollectPiiData: dataCU.customers[0].collect_pii_information,
            piiArray: getFields(dataCU.customers[0].pii_data_fields, "id"),
            isError: {}
          }
        ];
        setDoYouCollectPiiData(dataCU.customers[0].collect_pii_information);
        setPiiArray(getFields(dataCU.customers[0].pii_data_fields, "id"));

        if (customer.length > 0) {
          setCustomerForm(customer);
        }
        if (dataCU.customers.length === 0) {
          setSubmitDisabled(true);
        }
        if (dataCU.customers.length >= 0) {
          setSubmitDisabled(false);
        }
      },
      onError:()=>{
        handleCancel()
      }
    }
  );

  useEffect(() => {
    if (
      props.location.state &&
      props.location.state.hasOwnProperty("complianceInfo")
    ) {
      setParam(props.location.state);
      getComplianceInfo({
        variables: {
          where: {
            client_id: props.location.state.clientInfo.clientId,
            contact_id: props.location.state.complianceInfo.contactId,
            id: props.location.state.complianceInfo.id
          }
        }
      });
    }
  }, []);

  const officeHandleChange = (event: any) => {
    setOffices(event);
  };

  const customerHandleChange = (event: any) => {
    setCustomerForm(event);
    customerForm.forEach((element: any, i: any) => {
      let numOfCustomer = event[i].numOfCustomer;
      let state = event[i].state;
      let Error = event[i].isError;
      if (Error.state == true || Error.numOfCustomer == true) {
        setSubmitDisabled(true);
      } else {
        setSubmitDisabled(false);
      }
      if (state !== null) {
        if (state === "" || numOfCustomer === "") {
          setSubmitDisabled(true);
        }
      }
    });
  };

  useEffect(() => {
    setElement(
      <CustomerForm
        key={0}
        index={0}
        data={customerForm}
        handleChange={customerHandleChange}
      />
    );
  }, [customerForm]);

  let name: any;
  if (offices[0].address.location_type === "Main") {
    name = "Main Office";
  } else {
    name = "Other Office";
  }

  useEffect(() => {
    setElement(
      <Office
        name={name}
        key={0}
        index={0}
        data={offices}
        handleChange={officeHandleChange}
      />
    );
  }, [offices]);

  if (error || errorOrgUser) {
    let error = { message: "Error" };
    return <div className="error">Error!</div>;
  }



  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }

  // Assigning value to iterate and display form info into UI
  let reviewInfo: any = {};
  if (reviewData) {
    reviewInfo = reviewData.review;
  }

  const buildWorkPlan = () => {
    // if (!complianceName) {
    //   let err = "Required";
    //   setIsError((error: any) => ({
    //     ...error,
    //     complianceName: err
    //   }));
    // }
    // if (complianceName) {
      setShowBackdrop(true)
      setDownloadComp(true)
      // setShowLoading(true);
      let data = reviewInfo;
      let workPlanObj = buildWorkplanObj.filter(
        (item: any) => item.allow_download === true
      );
      if (
        data.mainOffice &&
        data.mainOffice.data.length !== 0 &&
        data.companyprofile.data.length !== 0
      ) {
        if (workPlanObj.length === 0) {
          setCompliance({
            variables: { input: data }
          })
            .then(res => {
              setTimeout(function () {
                buildVersion();
              }, 10000);
            })
            .catch(err => {});
          // updateComplianceInfoName({
          //   variables: {
          //     id: dataOrgUser.complianceInfos[0].id,
          //     name: complianceName
          //   }
          // }).then(()=>{
          
         
          // })
          // .catch(err => {});
        } else if(uploadError){
          downloadAllOffice(newData);
        }else{
          handleNext();
        }
      } else {
        setShowDialogBox(true);
        setDialogBoxMsg(msgConstant.BUILD_COMPLIANCE_MESSAGE);
      }
    // }
  };

   {/*Program to Generate Compliance Documents and its folder structure.  */}
  let dataComp = { new: mainOffice, other: otherOffice, pre: preMainOffice };
  // generate Document and Upload It on Server...
    let workplan = dataComp;
    if (workplan && workplan.new !== undefined && workplan.new.length !== 0) {
      { 
        /** Code for Add all law for Main and Other office into One place to generate Compliance Document*/
      }
      let listOlaw: any = [];
      let listORules: any = [];
      let listOPolicy: any = [];
      let listOWorkplan: any = [];
      const assignLaw = Promise.all(
        workplan.new.map((obj: any, index: any) => {
          obj.laws.map((val: any) => {
            listOlaw.push(val);
          });
          obj.rules.map((val: any) => {
            listORules.push(val);
          });
          obj.policies.map((val: any) => {
            listOPolicy.push(val);
          });
        })
      );
      newData.laws.description = Array.from(new Set(listOlaw));
      newData.rules.description = Array.from(new Set(listORules));
      newData.policies.description = Array.from(new Set(listOPolicy));
      // newData.workplans.description =  Array.from(new Set(listOWorkplan));
      {
        /** End of Code */
      }
    }
   
  let BestPracticeID: any = [];
  for (let index in dataObj[0].bestPractice) {
    BestPracticeID.push(parseInt(dataObj[0].bestPractice[index].id));
  }

  let zip = new JSZip();
  let workplanData: any = [];
  let Contents: any;
  let otherContents: any;
  let otherworkplanDocTitle: any;

  const policyDescription = (rule: any, category: any) => {
    let policyDes: any[] = [];
    rule.map((element: any) => {
      if (category && element.category.id === category.id) {
        policyDes.push(element.policy_content);
      }
    });
    return policyDes;
  };

  const templateDescription = (rule: any, category: any) => {
    let templateDes: any[] = [];
    rule.map((element: any) => {
      if (category && element.category.id === category.id) {
        templateDes.push(element.category.template_description);
      }
    });
    return templateDes;
  };

  let ComplianceDocumentId:any ;
  const downloadAllOffice = async (newData: any) => {
    return new Promise(async (resolve) => {
      setShowBackdrop(true)
      zip.remove(folderName);
      zip.remove("Other Office");
      let PolicyDocData: any;
      // var Main = zip.folder("Main Office");
      if (newData.laws) {
        if (newData.laws.description[0]) {
          let lawDocArray: any = []
          new Promise<void>(async function (resolve, reject) {
            await newData.laws.description.map(async (val: any) => {
              if (val.law_document.length !== 0) {
                lawDocArray.push(val.law_document[0])
              }
            })
            resolve();
          })
          let lawDoc = Array.from(new Set(lawDocArray));
          downloadAllLawPdf(lawDoc).then((val: any) => {
            resolve(Contents);
          })
        }
      }

      otherOfficeData.map((offices: any) => {
        Object.keys(offices).map((key, index) =>
          offices[key]["description"].map((element: any) => {
            if (key === "policies") {
              otherworkplanDocTitle = element.name;
              otherContents = {
                data: policyDescription(
                  offices["rules"].description,
                  element.category
                ),
                title: element.name,
                template_description: templateDescription(
                  newData["rules"].description,
                  element.category
                ),
              };
              const policydocumentCreator = DocumentCreator( otherContents );
              Packer.toBlob(policydocumentCreator).then((blob) => {
                var timeStamp = Math.floor(Date.now());
                PolicyDocData = blob;
              });
            }
          })
        );
      });

      workplanData = [];
      Object.keys(newData).map((key, index) =>
        newData[key]["description"].map((element: any) => {
          if (key === "policies") {
            Contents = {
              data: policyDescription(
                newData["rules"].description,
                element.category
              ),
              title: element.name,
              template_description: templateDescription(
                newData["rules"].description,
                element.category
              ),
            };
            const policydocumentCreator = DocumentCreator(Contents);
            Packer.toBlob(policydocumentCreator).then((blob) => {
              var timeStamp = Math.floor(Date.now() / 1000);
              zip
                .folder("Compliance/Policy")
                .file(element.name + "_" + timeStamp + ".docx", blob, { base64: true });
            });
          }
        })
      );

    }).then((contentVal: any) => {
      if (Contents) {
        Packer.toBlob(DocumentCreator(Contents)).then(async(blob) => {
          zip.generateAsync({ type: "blob" }).then(async function (content: any) {
            content["name"] = folderName ? folderName : complianceName + ".zip";
            setUploadDocDdata(content)
            if(content) {
              await uploadDocument(content);
            };
            // saveAs(content, folderName ? folderName : complianceName + ".zip");
          })
        });
      }
    });
  };

  const uploadDocument = async (ComplianceDocumentData:any)=>{
    let handleUploadPromise = new Promise(function(Resolve, Reject) {
        if(ComplianceDocumentData){
          // when successfull
          Resolve(ComplianceDocumentData);
        }
        Reject("error");  // when error
      });

      handleUploadPromise.then(
       async function(value) {
          if(ComplianceDocumentData.size && ComplianceDocumentData.type){
            let BlobDocData:any =  ComplianceDocumentData ;
            if(BlobDocData!==undefined){
             await uploadFile({
                variables: {
                  files: value,
                },
              }).then((uploadRes:any)=>{
                updateComplianceInfoName({
                  variables: {
                    id: dataOrgUser.complianceInfos[0].id,
                    name: complianceName,
                    compliance_document: uploadRes.data.upload.id
                  }
                }).then((updateCompRes:any)=>{
                    setShowBackdrop(false)
                    handleNext()
                  })
              }).catch((error)=>{
                console.log("Error",error)
                  setUploadError(true)
                  setShowBackdrop(false)
              })
            }
          }
           },
        function(error) { console.log("error",error)  }
      );
  }

  const downloadAllLawPdf = async (lawDoc: any) => {
    const doc = await lawDoc.map(async (val: any, index: any) => {
      const lawUrl = val.url.slice(1);
      const DocUrl = serverUrl + lawUrl;
      let docFileName = ''
      let fileType = ".pdf";
      let fetchDoc = await fetch(DocUrl, {
        method: "GET",
      }).then((res) => res.blob()).then((response) => {
        const timeStamp = Math.floor(Date.now() / 1000);
        const fileName = val.name;
        docFileName = fileName + "_" + timeStamp;
        zip.folder("Compliance/Laws").file(docFileName + fileType, response);
      })
    })
    return await Promise.all(doc).then(results => {
      return doc;
    })
  }


  const buildVersion = () => {
    createComplianceVersion({
      variables: {
        id: parseInt(orgContactId)
      }
    })
      .then(res => {
        getComplianceInfo({
          variables: {
            where: {
              client_id: props.location.state.clientInfo.clientId,
              contact_id: props.location.state.complianceInfo.contactId,
              id:  dataOrgUser.complianceInfos[0].id
            },
          },
        });
      })
      .catch(err => {});
  };

  const handleNext = () => {
    if (stepperObj.path && stepperObj.path !== "") {
      history.push(stepperObj.path, param);
    } else {
      setStepper(
        client,
        stepperObject.workplan.name,
        stepperObject.workplan.value
      );
      history.push(routeConstant.COMPLIANCE, param);
    }
  };

  const handleBack = () => {
    if (props.location.state.val === "Re-Run") {
      props.location.state["back"] = "Back";
    }
    setStepper(
      client,
      stepperObject.customer.name,
      stepperObject.customer.value
    );
    history.push(routeConstant.CUSTOMER, param);
  };

  const handleClose = () => {
    setShowDialogBox(false);
  };

  const getTableColumns = (key: any) => {
    var columnArray: any = [];
    reviewInfo[key].data[0].map((mainObj: any, i: any) => {
      if (mainObj.label === "Address Line 1") {
        columnArray.push({ title: "Address", field: "Address" });
      } else if (mainObj.label === "Address Line 2") {
        return false;
      } else if (
        mainObj.label !== "Collect PII data" &&
        mainObj.label !== "PII Fields" &&
        mainObj.label !== "Office Name"
      ) {
        columnArray.push({ title: mainObj.label, field: mainObj.label });
      }
    });
    return columnArray;
  };

  const getTableData = (key: any) => {
    var dataArray: any = [];
    var rowId = new Array<any>();
    reviewInfo[key].filter.map((idObj: any, k: any) => {
      rowId.push(idObj.Id);
    });
    reviewInfo[key].data.map((mainObj: any, i: any) => {
      var dataLines = new Array<any>(i);
      var address: any = "Address";
      let addressLine1 = "";
      let addressLine2 = "";
      dataLines[address] = [];

      let deleteHide: any = 'deleteHide';
      if (key === "otherOffice" && i === 0) {
        dataLines[deleteHide] = true
      }
      mainObj.map((dataObj: any, j: any) => {
        var id: any = "id";
        dataLines[id] = rowId[i];

        var KEY: any = dataObj.label;
        if (KEY === "Address Line 1") {
          addressLine1 = dataObj.value;
        }
        if (KEY === "Address Line 2") {
          addressLine2 = dataObj.value;
          addressLine2 !== ""
            ? (dataLines[address] = addressLine1 + ", " + addressLine2)
            : (dataLines[address] = addressLine1);
        }
        if (KEY === "Collect PII data") {
          dataLines[KEY] = dataObj.value ? "Yes" : "No";
        } else {
          dataLines[KEY] = dataObj.value;
        }
      });
      dataArray.push(dataLines);
    });
    return dataArray;
  };

  //stepper function to set active tab on top menu
  setStepper(client, stepperObject.review.name, stepperObject.review.value);

  // Create an array of selected Bestpractice ids
 
  let compData: any = [];
  var rowdata: any;
  if (
    reviewInfo &&
    reviewInfo.companyprofile &&
    reviewInfo.companyprofile.filter
  ) {
    rowdata = { id: reviewInfo.companyprofile.filter[0].Id };
    reviewInfo.companyprofile.data[0].map((Obj: any, k: any) => {
      compData.push({ filed: Obj.label, data: Obj.value });
    });
  }
  const handleCompanyChange = (event: any) => {
    setDataObj(event);
    let company_name = event[0].company_name;
    let company_type = event[0].company_type;
    if (company_name === "" || company_type === null) {
      setSubmitDisabled(true);
    }
    if (company_name !== "" && company_type !== null) {
      setSubmitDisabled(false);
    }
  };

  const updateCompany = () => {
    setSubmitDisabled(true);
    UpdateContact({
      variables: {
        id: orgContactId,
        name: dataObj[0].company_name,
        contact_type: "Organization"
      }
    })
      .then(userRes => {
        contact_id = userRes.data.updateContact.contact.id;
        updateComplianceInfo({
          variables: {
            id: dataOrgUser.complianceInfos[0].id,
            contact_id: contact_id,
            organization_type: dataObj[0].company_type.id,
            best_practices: BestPracticeID,
            compliance_document: ComplianceDocumentId ? ComplianceDocumentId : null
          }
        })
          .then(UpdateOrgRes => {
            setOpenDialogBox(false);
            getReview({
              variables: { ID: parseInt(param.complianceInfo.contactId) }
            });
          })
          .catch(Error => {
            setShowLoading(false);
            setSubmitDisabled(false);
          });
      })
      .catch(Error => {
        setShowLoading(false);
        setSubmitDisabled(false);
      });
  };

  let address: any;
  let employee: any;

  if (offices !== undefined) {
    address = offices[0].address;
    employee = offices[0].employee;
  }

  const updateAddresses = () => {
    if (
      orgContactId !== undefined &&
      address !== undefined &&
      address.id !== null &&
      address.address1 !== "" &&
      address.city !== "" &&
      address.state.id !== ""
    ) {
      setSubmitDisabled(true);
      updateAddress({
        variables: {
          id: address.id,
          contact_id: orgContactId, // contact id of client
          address1: address.address1,
          address2: address.address2,
          locationType: address.location_type,
          city: address.city,
          state: address.state.id,
          zip: address.zip
        }
      })
        .then(res => {
          updateEmployees(res);
        })
        .catch(Error => {
          setShowLoading(false);
          setSubmitDisabled(false);
        });
    }
  };

  const updateEmployees = (val: any) => {
    let doYouCollectePiiInfo = false;
    if (offices[0].employee.piiArray.length > 0) {
      doYouCollectePiiInfo = true;
    }
    let addressId: any;
    if (val.data.createAddress !== undefined) {
      addressId = val.data.createAddress.address.id;
    } else {
      addressId = val.data.updateAddress.address.id;
    }
    if (orgContactId !== undefined && offices[0].employee.id !== null) {
      setSubmitDisabled(true);
      updateEmployee({
        variables: {
          id: offices[0].employee.id,
          contactId: orgContactId, // contact id of client
          addressId: addressId,
          noOfEmp: parseInt(offices[0].employee.noOfEmployee),
          collectePiiInfo: doYouCollectePiiInfo,
          pii_data_field: offices[0].employee.piiArray
        }
      })
        .then(res => {
          setOpenDialogBox(false);
          getReview({
            variables: { ID: parseInt(param.complianceInfo.contactId) }
          });
        })
        .catch(err => {
          setShowLoading(false);
          setSubmitDisabled(false);
        });
    }
  };

  const updateCustomers = () => {
    updateCustomer({
      variables: {
        id: customerForm[0].id,
        contactId: customerForm[0].contact_id, // contact id of client
        noOfCustomer: parseInt(customerForm[0].numOfCustomer),
        state: customerForm[0].state.id,
        collectePiiInfo: collectePiidata,
        pii_data_field: customerForm[0].piiArray
      }
    })
      .then(res => {
        setOpenDialogBox(false);
        getReview({
          variables: { ID: parseInt(param.complianceInfo.contactId) }
        });
      })
      .catch(err => {
        setSubmitDisabled(false);
      });
  };

  let contact_id: any;
  let collectePiidata: any = false;
  const handleSubmitDialogBox = () => {
    if (orgContactId && dataObj[0].company_type !== "") {
      updateCompany();
    }
    if (customerForm[0].piiArray.length > 0) {
      collectePiidata = true;
    }
    setSubmitDisabled(true);
    if (customerForm[0].state && customerForm[0].contact_id) {
      updateCustomers();
    }
    if (offices[0].address && offices[0].address.id) {
      updateAddresses();
    }
  };

  const handleSubmitPiiData = () => {
    reviewInfo["customer"].data.map((data: any, i: number) => {
      updateCustomer({
        variables: {
          id: reviewInfo["customer"].filter[i].Id,
          contactId: customerForm[0].contact_id, // contact id of client
          noOfCustomer: parseInt(data[1].value),
          state: reviewInfo["customer"].filter[i].stateId,
          collectePiiInfo: doYouCollectPiiData,
          pii_data_field: piiArray
        }
      })
        .then(res => {
          closeDialogBox();
          getReview({
            variables: { ID: parseInt(param.complianceInfo.contactId) }
          });
        })
        .catch(err => {});
    });
  };

  const closeDialogBox = () => {
    setOpenDialogBox(false);
    setOpenPiiDataDialogBox(false);
  };

  const handleClickOpen = (key: any, rowData: any, resolve: any) => {
    if (key === "companyprofile") {
      let companyObj = [
        {
          id: rowData.id,
          company_name: dataOrgUser.complianceInfos[0].contact_id.name,
          company_type: dataOrgUser.complianceInfos[0].organization_type,
          company_subtype: dataOrgUser.complianceInfos[0].organization_subtype,
          bestPractice: dataOrgUser.complianceInfos[0].best_practices,
          isError: ""
        }
      ];
      setElement(
        <CompanyForm
          name="Company Info"
          key={0}
          index={0}
          data={companyObj}
          handleChange={handleCompanyChange}
          classname = {"reviewPageDialogBox"}
        />
      );
      setOpenDialogBox(true);
    }
    if (key === "mainOffice" || key === "otherOffice") {
      getaddress({
        variables: {
          where: {
            id: rowData.id,
            contact_id: dataOrgUser.complianceInfos[0].contact_id.id
          }
        }
      });
      getOrgLocation({
        variables: {
          where: {
            address_id: { id: rowData.id },
            contact_id: dataOrgUser.complianceInfos[0].contact_id.id
          }
        }
      });

      let piiArray: any = [];
      for (let i in dataOL.organizationLocations[0].pii_data_field) {
        piiArray.push(
          parseInt(dataOL.organizationLocations[0].pii_data_field[i].id)
        );
      }

      let offices = [
        {
          address: {
            location_type: "",
            id: null,
            address1: "",
            address2: "",
            state: "",
            city: "",
            zip: "",
            isError: {
              address1: false,
              address2: false,
              state: false,
              city: false,
              zip: false
            }
          },
          employee: {
            id: null,
            noOfEmployee: "",
            doYouCollectPiiData: false,
            piiArray: [],
            isError: {
              noOfEmployee: false
            }
          }
        }
      ];

      let name: any;
      if (offices[0].address.location_type === "Main") {
        name = "Main Office";
      } else {
        name = "Other Office";
      }
      setElement(
        <Office
          name={name}
          key={0}
          index={0}
          data={offices}
          handleChange={officeHandleChange}
        />
      );
      setOpenDialogBox(true);
    }
    if (key === "customer") {
      getCustomer({
        variables: {
          where: {
            id: rowData.id,
            contact_id: dataOrgUser.complianceInfos[0].contact_id.id
          }
        }
      });

      let customerForm = [
        {
          id: null,
          state: "",
          numOfCustomer: "",
          doYouCollectPiiData: false,
          piiArray: [],
          isError: {
            state: false,
            numOfCustomer: false
          }
        }
      ];
      setElement(
        <CustomerForm
          key={0}
          index={0}
          data={customerForm}
          handleChange={customerHandleChange}
        />
      );
      setOpenDialogBox(true);
    }
  };

  const deleteCustomers = (oldData: any) => {
    let cust = [...customerForm];
    if (customerForm[0] !== undefined && oldData.id !== null) {
      setSubmitDisabled(true);
      setShowLoading(true);
      deleteCustomer({
        variables: {
          id: oldData.id
        }
      })
        .then(res => {
          getReview({
            variables: { ID: parseInt(param.complianceInfo.contactId) }
          });
        })
        .catch(err => {
          setShowLoading(false);
          setSubmitDisabled(false);
        });
    }
    setCustomerForm(cust);
    setSubmitDisabled(false);
  };

  const compInfo = (key: any, rowData: any) => {
    if (key === "customer") {
      getPiiData({
        variables: {}
      });
      getCustomer({
        variables: {
          where: {
            id: rowData.filter[0].Id,
            contact_id: dataOrgUser.complianceInfos[0].contact_id.id
          }
        }
      });
      setOpenPiiDataDialogBox(true);
    }
  };

  // Show and Hide pii data fields
  const showHidePiiDataFields = (type: string) => {
    if (type === "true") {
      setShowPiiData(true);
    } else {
      setPiiArray([]);
      customerForm.map((val: any, index: any) => {
        customerForm[index].piiArray = [];
      });
      setShowPiiData(false);
    }
  };

  let optPiiArray: any = [];
  const handleCheckElement = (event: any) => {
    let val = parseInt(event.target.value);
    optPiiArray = [...piiArray];
    if (optPiiArray.includes(val)) {
      let position = optPiiArray.indexOf(val);
      if (position >= 0) {
        optPiiArray.splice(position, 1);
      }
    } else {
      optPiiArray.push(val);
    }
    setPiiArray(optPiiArray);
    customerForm.map((val: any, index: any) => {
      customerForm[index].piiArray = optPiiArray;
    });
  };

  const piiDataHandleChange = (event: any) => {
    if (event.target.name === "doYouCollectPiiData") {
      setDoYouCollectPiiData(event.target.checked);
      showHidePiiDataFields(event.target.checked);
    }
  };

  const getIdofLocation = (val: any) => {
    getOrgLocation({
      variables: {
        where: {
          contact_id: val.address.contact_id.id,
          address_id: val.address.id
        }
      }
    });
  };

  const removeOffice = (oldData: any) => {
    if (offices[0] !== undefined && offices[0].address.id !== null) {
      setSubmitDisabled(true);
      deleteAddress({
        variables: {
          id: oldData.id
        }
      })
        .then(res => {
          getIdofLocation(res.data.deleteAddress);

          getReview({
            variables: { ID: parseInt(param.complianceInfo.contactId) }
          });
        })
        .catch(err => {
          setShowLoading(false);
          setSubmitDisabled(false);
        });
    }
    setSubmitDisabled(false);
  };

  const deleteTableRow = (oldData: any, key: any) => {
    if (
      customerForm[0] !== undefined &&
      oldData.id !== null &&
      key === "customer"
    ) {
      deleteCustomers(oldData);
    }
    if (
      offices[0] !== undefined &&
      oldData.id !== null &&
      key === "otherOffice"
    ) {
      removeOffice(oldData);
    }
  };

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
    setExpandAll(undefined);
  };

  // const handleNameChange = (event: any) => {
  //   setComplianceName(event.target.value);
  //   let value = event.target.value;
  //   let isErrName = value.length <= 0 ? "Required" : "";
  //   setIsError((isError: any) => ({
  //     ...isError,
  //     complianceName: isErrName
  //   }));
  // };

  const handleExpanAll = () => {
    setExpandAll(!expandAll);
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
    handleNext();
  }


  return (
    <div className={styles.Review}>
      <React.Fragment>
        <CssBaseline />
        <Typography component="h5" variant="h1">
          {props.location.state
            ? "CyberCompliance for " + props.location.state.clientInfo.name
            : null}
        </Typography>
        { enableBackdrop() ? <SimpleBackdrop /> : null }
        <TopStepper
          navigate={true}
          param={param}
          handleSave={callingFromTopStepper}
          obj={stepperObj}
        />
        <Paper className={styles.paper}>
          <Grid container>
            {/* <Grid item xs={12} sm={9}>
              <div className={styles.CompNameReview}>
                <Input
                  type="text"
                  label="Compliance Name"
                  name="complianceName"
                  value={complianceName}
                  onChange={handleNameChange}
                  required
                  error={isError.complianceName}
                  helperText={isError.complianceName}
                >
                  Compliance Name
                </Input>
              </div>
            </Grid> */}
            <Grid item xs={12} sm={3}>
              <div className={styles.expandButtonDiv}>
                <Button
                  className={styles.expandButton}
                  color="primary"
                  variant="contained"
                  onClick={(e: any) => handleExpanAll()}
                >
                  {expandAll ? "Collapse All" : "Expand all"}
                </Button>
              </div>
            </Grid>
          </Grid>

          {Object.keys(data).map((key, index) => (
            <Accordion
              className={styles.ComplianceAccordion}
              key={index}
              expanded={expandAll !== undefined ? expandAll : expanded === key}
              onChange={handleChange(key)}
            >
              <AccordionSummary
                className={styles.ComplianceAccordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={styles.column}>
                  <Typography className={styles.heading}>
                    {data[key].title}
                  </Typography>
                </div>
              </AccordionSummary>
              {/* rowData['State'] == reviewInfo['customer'].data[0][0].value ||  */}
              <AccordionDetails className={styles.ComplianceAccordionDetails}>
                <div className={styles.compInfo}>
                  {key !== "companyprofile" ? (
                    <div className={styles.details}>
                      {reviewInfo[key] !== undefined &&
                        reviewInfo[key].data.length !== 0 ? (
                          <MaterialTable
                            columns={getTableColumns(key)}
                            data={getTableData(key)}
                            actions={[
                              {
                                icon: () => <img className={styles.EditIcon}
                                src={
                                  process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
                                }
                                alt="edit icon"
                              />,
                                tooltip: "Edit",
                                onClick: (event: any, rowData: any) => {
                                  new Promise(resolve => {
                                    handleClickOpen(key, rowData, resolve);
                                  });
                                }
                              }
                            ]}
                            editable={{

                              // isDeleteHidden: (rowData: any) =>
                              //   rowData['State'] == reviewInfo['customer'].data[0][0].value ||
                              //   rowData.deleteHide ||
                              //   key === "companyprofile" || key === "mainOffice",

                              onRowDelete: (oldData: any) =>
                                new Promise((resolve : any) => {
                                  
                                  setTimeout(() => {
                                    resolve();
                                    deleteTableRow(oldData, key);
                                  }, 600);
                                }),
                            }}
                            options={{
                              headerStyle: {
                                backgroundColor: "#EFF6FD",
                                color: "#002F60"
                              },
                              actionsColumnIndex: -1,
                              paging: false,
                              sorting: false,
                              search: false
                            }}
                          />
                        ) : (
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <label>You don't have {data[key].title}</label>
                            </Grid>
                          </Grid>
                        )}
                    </div>
                  ) : (
                      <Typography className={styles.secondaryHeading}>
                        <Grid container spacing={2}>
                          <Grid item xs={10} sm={10}>
                            {compData.map((keys: any, indexs: any) => (
                              <div>
                                {keys.filed !== "Organization Type" &&
                                  keys.filed !== "Organization Sub Type" ? (
                                    <Grid container>
                                      <Grid item xs={6} sm={4}>
                                        <label>{keys.filed}:</label>
                                      </Grid>
                                      <Grid item xs={6} sm={4}>
                                        <div className={styles.compInfotabledata}>
                                          {keys.data}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  ) : null}
                                {keys.filed === "Organization Type" ? (
                                  <Grid container>
                                    <Grid item xs={6} sm={4}>
                                      <label>Type: </label>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                      <div className={styles.compInfotabledata}>
                                        {keys.data}
                                      </div>
                                    </Grid>
                                  </Grid>
                                ) : null}
                                {/* { keys.filed === 'Best Practices' ? <p>Best Practices: {keys.data}</p> : null} */}
                              </div>
                            ))}
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <span className={styles.compEditButton}>
                              <Button
                                // className={styles.editButton}
                                onClick={() => {
                                  handleClickOpen(key, rowdata, null);
                                }}
                              >
                                <img className={styles.EditIcon}
                                  src={
                                    process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
                                  }
                                  alt="edit icon"
                                />
                              </Button>
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                    )}
                  <div className={styles.compInfo}>
                    {data[key].title === "Customer" &&
                      reviewInfo &&
                      reviewInfo.customer &&
                      reviewInfo.customer.data.length !== 0 ? (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <Typography className={styles.secondaryHeading}>
                              <Grid container>
                                <Grid item xs={6} sm={12}>
                                  {" "}
                                  <div className={styles.piifieldstitle}>
                                    PII Fields
                                </div>{" "}
                                </Grid>
                                {reviewInfo && reviewInfo.customer ? (
                                  reviewInfo.customer.data.length !== 0 ? (
                                    reviewInfo.customer.data[0].map(
                                      (piidatas: any, index: any) =>
                                        piidatas.label === "PII Fields" ? (
                                          <div
                                            className={styles.piifieldsdatagroup}
                                          >
                                            <Grid item xs={6} sm={12}>
                                              <div
                                                className={styles.piifieldsdata}
                                              >
                                                {piidatas.value}
                                              </div>
                                              <div
                                                className={
                                                  styles.piifieldexpandButtonDiv
                                                }
                                              >
                                                <Button
                                                  className={styles.expandButton}
                                                  onClick={() => {
                                                    compInfo(
                                                      key,
                                                      reviewInfo.customer
                                                    );
                                                  }}
                                                >
                                                  <img className={styles.EditIcon}
                                                    src={
                                                      process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
                                                    }
                                                    alt="edit icon"
                                                  />
                                                </Button>
                                              </div>
                                            </Grid>
                                          </div>
                                        ) : null
                                    )
                                  ) : (
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                          <label>
                                            You don't collect Pii Fields
                                      </label>
                                        </Grid>
                                      </Grid>
                                    )
                                ) : null}
                              </Grid>
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : null}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      </React.Fragment>
      <DialogBox
        title={""}
        open={openDialogBox}
        handleOk={handleSubmitDialogBox}
        handleCancel={closeDialogBox}
        buttonOk={"Confirm"}
        buttonCancel={"Cancel"}
        classes={{
          root: styles.ReviewDialogRoot,
          container: styles.ReviewDialogboxContainer,
          paper: styles.ReviewDialogboxPaper,
          scrollPaper: styles.ReviewScrollPaper,
        }}
      >
        {loadingAD ? <SimpleBackdrop /> : element}
      </DialogBox>
      <DialogBox
        title={""}
        open={openPiiDataDialogBox}
        handleOk={handleSubmitPiiData}
        handleCancel={closeDialogBox}
        buttonOk={"Confirm"}
        buttonCancel={"Cancel"}
        classes={{
          root: styles.PiiDataDialogRoot,
          container: styles.PiiDataDialogboxContainer,
          paper: styles.PiiDataDialogboxPaper,
          scrollPaper: styles.PiiDataScrollPaper,
        }}
      >
        {loadingCU ? (
          <SimpleBackdrop />
        ) : (
            <form className={styles.ReviewWrapPii}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={styles.SpacingGrid}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Do you collect PII data?
                </FormLabel>
                  <div className={styles.pgswitch}>
                    <Switch
                      className={
                        doYouCollectPiiData
                          ? styles.PiiDataSwitch
                          : styles.PiiDataSwitchCheck
                      }
                      id="piiData"
                      checked={doYouCollectPiiData}
                      onChange={piiDataHandleChange}
                      name="doYouCollectPiiData"
                      {...props}
                    ></Switch>
                    <span className={styles.PiiDataSwitchLabels}>
                      <span className={styles.PiiDataSwitchNo}>NO</span>
                      <span className={styles.PiiDataSwitchYes}>YES</span>
                    </span>
                  </div>
                </FormControl>

                {showPiiData || doYouCollectPiiData ? (
                  <FormGroup row>
                    {piiDataList.map((obj: any, i: any) => {
                      return (
                        <FormControlLabel
                          className={styles.CheckboxLabel}
                          key={obj.id}
                          control={
                            <Checkbox
                              checked={piiArray.indexOf(parseInt(obj.id)) >= 0}
                              onChange={handleCheckElement}
                              name={obj.name}
                              value={obj.id}
                            />
                          }
                          label={obj.name}
                        />
                      );
                    })}
                  </FormGroup>
                ) : null}
              </Grid>
            </Grid>
            </form>
          )}
      </DialogBox>
      <AlertBox
        open={showDialogBox}
        dialogBoxMsg={dialogBoxMsg}
        pathName={""}
        closeButtonPath={""}
        buttonName={""}
        handleClose={handleClose}
      ></AlertBox>
      <Grid container spacing={3}>
        <Grid item xs={12} className={styles.FooterActions}>
          <div className={styles.prevBtn}>
            <Button variant="contained" color="primary" onClick={handleBack}>
              {"Previous"}
            </Button>
            <Button variant="contained" color="primary" onClick={buildWorkPlan}>
              {"Build Compliance Report"}
            </Button>
          </div>
          <div className={styles.cancelButton}>
            <Button variant="contained" color="primary" onClick={handleCancel}>
              {"Cancel"}
            </Button>
          </div>
        </Grid>
      </Grid>
      {/* <Typography className={styles.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={buildWorkPlan}
        >
          {"Build Compliance Report"}
        </Button>
      </Typography> */}
    </div>
  );
};

export default Review;
