// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Compliance_Compliance__1uWpz {}\n\n.Compliance_TabOptions__akb6w button {\n  border-width: 1px 1px 0px 1px !important;\n  border-style: solid !important;\n  border-color: #0051a8 !important;\n  border-top-left-radius: 10px !important;\n  border-top-right-radius: 10px !important;\n  border-bottom-right-radius: 0 !important;\n  border-bottom-left-radius: 0px !important;\n  margin-right: 4px !important;\n  color: #707070 !important;\n  font-weight: 600 !important;\n  background-color: white !important;\n  padding: 8px 13px 15px !important;\n  margin-top: 12px;\n}\n.Compliance_ComplianceGrid__36SCc{\n  float:right;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.Compliance_ComplianceVersion__1GMhD fieldset{\n  min-height: auto;\n  padding: 5px;\n  border-color: #0051a8 !important;\n}\n.Compliance_ComplianceVersion__1GMhD {\n  margin-left:5px;\n  margin-left: 5px;\n  background-color: #0051a8;\n  color: #fff !important;\n}\n.Compliance_ComplianceVersion__1GMhD > div {\n  min-height:auto;\n  padding: 10px 37px 10px 15px !important;\n}\n.Compliance_TabOptionsActive__1Y-0c > span{\n  color:#0051a8 !important;\n}\n.Compliance_ComplianceVersion__1GMhD svg{\n  color:#fff !important;\n}\n.Compliance_TabOptionsInactive__PfvLd {\n  background-color: #fcfdfd !important;\n}", ""]);
// Exports
exports.locals = {
	"Compliance": "Compliance_Compliance__1uWpz",
	"TabOptions": "Compliance_TabOptions__akb6w",
	"ComplianceGrid": "Compliance_ComplianceGrid__36SCc",
	"ComplianceVersion": "Compliance_ComplianceVersion__1GMhD",
	"TabOptionsActive": "Compliance_TabOptionsActive__1Y-0c",
	"TabOptionsInactive": "Compliance_TabOptionsInactive__PfvLd"
};
module.exports = exports;
