import { gql } from "@apollo/client";

export const GET_REVIEW_INFO = gql`
  query($ID: Int!) {        # ID of Contact_type = 'ComplianceInfo' from Contact table
    review(id: $ID) {
      companyprofile {
        filter {
          Id
          orgTypeId
          orgType
          orgSubTypeId
          orgSubType
          contactId
          bestPractices {
            id
            Name
          }
        }
        data {
          label
          value
        }
      }
      mainOffice {
        filter {
          Id
          state
          stateId
          stateObj {
            id
            name
          }
        }
        data {
          label
          value
        }
      }
      otherOffice {
        filter {
          Id
          state
          stateId
          stateObj {
            id
            name
          }
        }
        data {
          label
          value
        }
      }
      customer {
        filter {
          Id
          state
          stateId
          stateObj {
            id
            name
          }
        }
        data {
          label
          value
        }
      }
    }
  }

`