import { gql } from "@apollo/client";

export const CREATE_LAW = gql`
  mutation CreateLaw(
    $name: String!
    $jurisdiction: String
    $state: ID
    $url: String
    $parent_law: ID
    $rules: [ID]
    $pii_data: [ID!]
    $applicable_to: [ID!]
    $company_type: [ID!]
    $best_practices: [ID]
    $law_document : [ID]
  ) {
    createLaw(
      input: {
        data: {
          name: $name
          jurisdiction: $jurisdiction
          state: $state
          url: $url
          parent_law: $parent_law
          rules: $rules
          pii_data: $pii_data
          applicable_to: $applicable_to
          company_type: $company_type
          best_practices: $best_practices
          law_document : $law_document
        }
      }
    ) {
      law {
        id
        name
        jurisdiction
        state {
          id
          name
        }
        url
        parent_law {
          id
          name
        }
        rules {
          id
          name
        }
        pii_data {
          id
          name
        }
        applicable_to {
          id
          type
        }
        company_type {
          id
          name
        }
        best_practices {
          id
          Name
        }
      }
    }
  }
`;

export const UPDATE_LAW = gql`
  mutation UpdateLaw(
    $id: ID!
    $name: String!
    $jurisdiction: String
    $state: ID
    $url: String
    $parent_law: ID
    $rules: [ID]
    $pii_data: [ID!]
    $applicable_to: [ID!]
    $company_type: [ID!]
    $best_practices: [ID]
    $law_document : [ID]
  ) {
    updateLaw(
      input: {
        where: { id: $id }
        data: {
          name: $name
          jurisdiction: $jurisdiction
          state: $state
          url: $url
          parent_law: $parent_law
          rules: $rules
          pii_data: $pii_data
          applicable_to: $applicable_to
          company_type: $company_type
          best_practices: $best_practices
          law_document : $law_document
        }
      }
    ) {
      law {
        id
        name
        jurisdiction
        state {
          id
          name
        }
        url
        parent_law {
          id
          name
        }
        rules {
          id
          name
        }
        pii_data {
          id
          name
        }
        applicable_to {
          id
          type
        }
        company_type {
          id
          name
        }
        best_practices {
          id
          Name
        }
      }
    }
  }
`;

export const DELETE_LAW = gql`
  mutation DeleteLaw($id: ID!) {
    deleteLaw(input: { where: { id: $id } }) {
      law {
        id
        name
        jurisdiction
        state {
          id
          name
        }
        url
        parent_law {
          id
          name
        }
        rules {
          id
          name
        }
        pii_data {
          id
          name
        }
        applicable_to {
          id
          type
        }
        company_type {
          id
          name
        }
        best_practices {
          id
          Name
        }
      }
    }
  }
`;
