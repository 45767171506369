import React, { useState, useEffect } from "react";
import styles from "./CompanyForm.module.css";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import { FormHelperText } from "@material-ui/core";
import { GET_ORGTYPES } from "../../graphql/queries/OrgTypes";
import { GET_BESTPRACTICE } from "../../graphql/queries/BestPractice";
import {
  useQuery,
} from "@apollo/client";
import Input from "../../components/UI/Form/Input/Input";
import AutoCompleteDropDown from "../../components/UI/Form/Autocomplete/Autocomplete";
import { useApolloClient } from "@apollo/client";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";

export interface OrgType {
  id: Number;
  name: string;
  sub_type: Object;
}

export interface OrgSubType {
  id: Number;
  name: string;
  sub_type: Object;
}

export interface BestPractice {
  id: Number;
  Name: string;
}

export interface CompanyProps {
  name: String;
  index?: number;
  data?: any;
  handleChange?: any;
  classname? :any;
}

export const CompanyForm: React.SFC<CompanyProps> = ({
  name,
  index = 0,
  data,
  handleChange,
  classname,
}: CompanyProps) => {
  const [orgType, setorgType] = React.useState<any>("");
  const [orgTypeList, setorgTypeList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [complianceName, setComplianceName] = useState("");
  const [orgSubTypeList, setorgSubTypeList] = useState([]);
  const [bestPracticeList, setbestPracticeList] = useState([]);
  const [bestPractice, setbestPractice] = React.useState<BestPractice[] | undefined>();
  const [companyTypeError, setCompanyTypeError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [complianceNameError, setComplianceNameError] = useState(false);
  const orgProps = {
    options: orgTypeList,
    getOptionLabel: (option: OrgType) => option.name,
  };
  const orgSubTypeProps = {
    options: orgSubTypeList,
    getOptionLabel: (option: OrgSubType) => option.name,
  };
  const bestPracticeProps = {
    options: bestPracticeList,
    getOptionLabel: (option: BestPractice) => option.Name,
  };

  const { data: dataOT, error: errorOT, loading: loadingOT } = useQuery(
    GET_ORGTYPES
  );
  const { data: dataBP, error: errorBP, loading: loadingBP } = useQuery(
    GET_BESTPRACTICE
  );

  let bestPracticeArr: any = [];

  useEffect(() => {
    if ( data[index].company_type !== undefined && data[index].company_type !== "") {
      setorgType(data[index].company_type);
    }
    if (companyName === "" && data[index].company_name !== "") {
      setCompanyName(data[index].company_name);
    }
    for (let i in data[index].bestPractice) {
      bestPracticeArr.push(data[index].bestPractice[i]);
    }
    if (bestPractice === undefined || bestPractice.length == 0 && data[index].bestPractice !== undefined) {
      setbestPractice(data[index].bestPractice);
    }
    if (complianceName === "" && data[index].compliance_name !== "") {
      setComplianceName(data[index].compliance_name);
    }
  }, [data]);

  if (loadingOT || loadingBP) return <SimpleBackdrop />;
  if (errorOT || errorBP) {
    return <div className="error">Error!</div>;
  }

  let subType: any = [];
  const handleOrgType = (event: any, selectedValue: any | null) => {
    data[index].company_type = selectedValue;
    data[index].company_subtype = null;
    setorgType(selectedValue);
    if (selectedValue != null) {
      for (let i in subOrgType) {
        if (selectedValue.name === subOrgType[i].sub_type.name)
          subType.push(subOrgType[i]);
      }
      if (selectedValue != null || undefined) {
        setorgSubTypeList(subType);
        setCompanyTypeError(false);
      }
    } else {
      setorgSubTypeList([]);
      setCompanyTypeError(true);
    }
    handleChange(data);
  };

  function uniqueArray(a: any) {
    const result = [];
    const map = new Map();
    for (const item of a) {
      if (!map.has(item.id)) {
        map.set(item.id, true); // set any value to Map
        result.push({
          __typename: item.__typename,
          id: item.id,
          Name: item.Name,
        });
      }
    }
    return result;
  }

  const handleBestPractices = (event: any, newValue: BestPractice[]) => {
    data[index].bestPractice = [];
    data[index].bestPractice = newValue;
    let val: any = uniqueArray(newValue);
    if (val.length > 0 || undefined) {
      setbestPractice(val);
    } else {
      setbestPractice([]);
    }
    handleChange(data);
  };

  const handleCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    data[index].company_name = event.target.value;
    if (event.target.value) {
      setCompanyNameError(false);
    } else {
      setCompanyNameError(true);
    }
    setCompanyName(event.target.value);
    handleChange(data);
  };
  
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    data[index].compliance_name = event.target.value;
    if (event.target.value) {
      setComplianceNameError(false);
    } else {
      setComplianceNameError(true);
    }
    setComplianceName(event.target.value);
    handleChange(data);
  };

  let subOrgType: any = [];
  dataOT.organizationTypes.map((org: any) => {
    if (org.sub_type != null && orgType !== null) {
      if (orgType.name === org.sub_type.name) {
        subOrgType.push(org);
      }
    }
  });

  let ORGType: any = [];
  dataOT.organizationTypes.map((org: any) => {
    if (org.sub_type === null) {
      ORGType.push(org);
    }
  });

  if (orgTypeList.length === 0) {
    setorgTypeList(ORGType);
  }
  if (subOrgType.length !== 0 && orgSubTypeList.length === 0) {
    setorgSubTypeList(subOrgType);
  }
  if (bestPracticeList.length === 0) {
    setbestPracticeList(dataBP.bestPractices);
  }
  const getValue = () => {
    if (bestPractice) {
      return bestPractice;
    } else {
      return [];
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container className={styles.CompanyProfileForm}>
        <React.Fragment>
          <div className={classname != undefined ? classname : styles.CompanyProfile} data-testid="CompanyProfile">
            { }
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    label="Compliance Name"
                    name="complianceName"
                    value={complianceName}
                    onChange={handleNameChange}
                    required
                  >
                    Compliance Name
                  </Input>
                  {complianceNameError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Please enter valid Compliance Name.
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <AutoCompleteDropDown
                    {...orgProps}
                    id="companyType"
                    value={orgType}
                    onChange={handleOrgType}
                    style={{ width: 300 }}
                    renderInput={(params: any) => (
                      <Input
                        {...params}
                        required
                        variant="outlined"
                        id="companyType"
                        error={companyTypeError}
                        label="Company Type"
                        margin="normal"
                        className={styles.companyctrl}
                      />
                    )}
                  />
                  {companyTypeError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Please select a Company Type.
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Input
                    required={true}
                    id="companyName"
                    name="companyName"
                    label="Company Name"
                    error={companyNameError}
                    variant="outlined"
                    value={companyName}
                    disabled
                    style={{ width: 300 }}
                    onChange={handleCompanyName}
                  />
                  {companyNameError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Please enter valid Company Name.
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <AutoCompleteDropDown
                    options={bestPracticeList}
                    getOptionLabel={(option: BestPractice) => option.Name}
                    multiple
                    id="bestPractice"
                    value={getValue()}
                    onChange={handleBestPractices}
                    style={{ width: 300 }}
                    renderInput={(params: any) => (
                      <Input
                        {...params}
                        variant="outlined"
                        id="bestPractice"
                        label="Best Practice"
                        margin="normal"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </React.Fragment>
      </Grid>
    </React.Fragment>
  );
};

export default CompanyForm;
