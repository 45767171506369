import { gql } from "@apollo/client";

export const GET_COMPLIANCEINFO = gql`
  query getcomplianceInfos($where: JSON, $sort: String) {
    complianceInfos(where: $where, sort: $sort) {
      id
      created_at
      updated_at
      name
      contact_id {
        id
        name
        contact_type
        user_id {
          id
          username
        }
      }
      compliance_document{
        id
        name
        url
      }
      organization_type {
        id
        name
      }
      organization_subtype {
        id
        name
      }
      
      best_practices {
        id
        Name
      }
      client_id {
        id
      }
    }
  }
`;

//for admin panel company list page
// export const GET_COMPLIANCEINFOS = gql`
//   query getcomplianceInfos($where: JSON, $sort: String) {
//     complianceInfos(where: $where, sort: $sort) {
//       id
//       contact_id {
//         id
//         name
//         contact_type
//         user_id {
//           id
//           username
//         }
//       }
//       organization_type {
//         id
//         name
//       }
      
//       organization_subtype {
//         id
//         name
//       }
//       best_practices {
//         id
//         Name
//       }
//     }
//   }
// `;
