import React, { useState, useEffect } from "react";
import styles from "./Category.module.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
import Input from "../../../components/UI/Form/Input/Input";
import { GET_CATEGORY } from "../../../graphql/queries/Category";
import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "../../../graphql/mutations/Category";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Grid } from "@material-ui/core";
//import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import logout from "../../../containers/Auth/Logout/Logout";
interface piiCategory {
  id: number;
  name: string;
}

export const Category: React.FC = () => {
  //Dialog Box
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);

  //add/edit data
  const [name, setName] = useState<String>("");
  const [newData, setNewData] = useState();
  const [editCategoryId, setEditCategoryId] = useState<Number | null>();
  const [editorState, setEditorState] = useState<any>();

  //filter dropdown values

  const title = "Listing of PII Category";

  //validation and error handelling
  const [isError, setIsError] = useState<any>({ name: "" });
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [filterName, setFilterName] = useState("");
  const [reset, setReset] = useState<boolean>(false);
  const [filters, setFilters] = useState<object>();
  const [orderBy, setOrderBy] = useState<String>();
  const [sortOrder, setSortOrder] = useState<String | null>("asc");

  //queries
  const [createCategory] = useMutation(CREATE_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);

    //table
    const columns = [{
      title: "Name",
      field: "name",
      defaultSort: orderBy?.includes("name") ? sortOrder : "asc",
     }];
  //filter query condition declaration
  const {
    data: dataC,
    error: errorC,
    loading: loadingC,
    refetch: refetchC,
  } = useQuery(GET_CATEGORY, {
    variables:
      !orderBy || orderBy === ""
        ? { where: filters, sort: "name:asc" }
        : { where: filters, sort: orderBy },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    // skip: filterUseQueryCondition(),
  });
  const checkValidation = () => {
    if (showDialogBox === true) {
      if (isError.name !== "" || !name) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (dataC) {
      let temp: any = {};
      temp = convertTableData(dataC);
      setNewData(temp);
    }
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [dataC, filters, formState]);

  useEffect(() => {
    setSubmitDisabled(checkValidation);
  }, [name, submitDisabled, showDialogBox]);

  useEffect(() => {
    refetchC();
  }, []);

  function convertTableData(data: any) {
    let arr: any = [];
    for (let i in data.categories) {
      let tempArr: any = {};
      if (
        data.categories &&
        data.categories[i] != undefined &&
        data.categories[i].id != null
      ) {
        tempArr["id"] = data.categories[i].id;
      }
      if (
        data.categories &&
        data.categories[i] != undefined &&
        data.categories[i].name != null
      ) {
        tempArr["name"] = data.categories[i].name;
      }
      if (
        data.categories &&
        data.categories[i] != undefined &&
        data.categories[i].template_description != null
      ) {
        tempArr["template_description"] =
          data.categories[i].template_description;
      }
      arr.push(tempArr);
    }
    return arr;
  }

  //for pii data
  if (loadingC) return <Loading />;
  if (errorC) {
    {logout()}
    return <div className="error">PC Error!</div>;

  }

  const nameFilter = (event: any) => {
    setFilterName(event.target.value);
  };

  const handleClickOpen = (rowData: any) => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
    setIsError((isError: any) => ({
      ...isError,
      name: "",
    }));
    setName("");
    setEditorState("");
    setShowDialogBox(true);
    if (rowData.id) {
      setEditCategoryId(rowData.id);
      setName(rowData.name);
      const markdownString = rowData.template_description;
      const rawData = markdownToDraft(markdownString);
      const contentState = convertFromRaw(rawData);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  };

  const deleteTableRow = (rowData: any) => {
    deleteCategory({
      variables: {
        id: rowData.id,
      },
    })
      .then((userRes) => {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: true,
          isFailed: false,
          errMessage: "",
        }));
        refetchC();
      })
      .catch((err) => {
        console.log("error", err);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: err.message,
        }));
      });
  };
  const handleSearch = () => {
    let searchData: any = {};
    if (filterName) {
      searchData["name_contains"] = filterName.toString();
    }
    setFilters(searchData);
  };

  const resetForm = () => {
    setFilters({});
    setReset(true);
    setFilterName("");
    setOrderBy("name");
    refetchC();
  };

  const handleSubmitDialogBox = () => {
    if (editCategoryId) {
      updateCategory({
        variables: {
          id: editCategoryId,
          name: name,
          template_description: draftToMarkdown(
            convertToRaw(editorState.getCurrentContent())
          ),
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: true,
            isDelete: false,
            isFailed: false,
            errMessage: "",
          }));
          setShowDialogBox(false);
          setEditCategoryId(null);
          refetchC();
        })
        .catch((err) => {
          console.log("error", err.message);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
          setEditCategoryId(null);
        });
    } else {
      createCategory({
        variables: {
          name: name,
          template_description: draftToMarkdown(
            convertToRaw(editorState.getCurrentContent())
          ),
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: "",
          }));
          setShowDialogBox(false);
          refetchC();
        })
        .catch((err) => {
          console.log("error", err);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
        });
    }
  };

  // const orderFunc = (orderedColumnId: any, orderDirection: any) => {
  //   let orderByColumn;
  //   let orderBy = "";
  //   if (orderedColumnId >= 0) {
  //     if (columns[orderedColumnId]["field"] === "name") {
  //       orderByColumn = "name";
  //     }
  //   }
  //   orderBy = orderByColumn + ":" + orderDirection;
  //   console.log("orderBy", orderBy);
  //   setSortOrder(orderDirection);
  //   setOrderBy(orderBy);
  // };

  const closeDialogBox = () => {
    setShowDialogBox(false);
    setEditCategoryId(null);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    let value = event.target.value;
    let isErrName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      name: isErrName,
    }));
    setSubmitDisabled(checkValidation);
  };

  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <main className={styles.layout}> */}
      <Typography component="h5" variant="h1">
        Template
      </Typography>
      {!showDialogBox ? (
        <Grid className={styles.TableWrap}>
          <div className={styles.FilterAddWrap}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
            >
              <AddCircleIcon className={styles.EditIcon} />
              &nbsp; Template
            </Button>
          </div>
          <Grid className={styles.FilterWrap}>
            <div className={styles.FilterInput}>
              <Input
                label="Name"
                name="filterName"
                id="combo-box-demo"
                value={filterName}
                onChange={nameFilter}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className={styles.FilterSearchButton}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
            <div className={styles.FilterResetButton}>
              <Button color="secondary" variant="contained" onClick={resetForm}>
                reset
              </Button>
            </div>
          </Grid>
          <Paper className={styles.paper}>
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Category {SUCCESS}
              </Alert>
            ) : null}
            {formState.isUpdate ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Category {UPDATE}
              </Alert>
            ) : null}

            {formState.isDelete ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Category {DELETE}
              </Alert>
            ) : null}
            <MaterialTable
              title={title}
              columns={columns}
              data={newData}
              actions={[
                {
                  icon: () => <img className={styles.EditIcon}
                  src={
                    process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
                  }
                  alt="edit icon"
                />,
                  tooltip: "Edit",
                  onClick: (event: any, rowData: any) => {
                    handleClickOpen(rowData);
                  },
                },
                {
                  icon: () => (
                    <img
                      className={styles.EditIcon}
                      src={process.env.PUBLIC_URL + "/icons/svg-icon/delete.svg"}
                      alt="delete icon"
                    />
                  ),
                  tooltip: "Delete",
                  onClick: (event: any, rowData: any) => {
                    deleteTableRow(rowData);
                  },
                },
              ]}
              // editable={{
              //   onRowDelete: (oldData: any) =>
              //     new Promise((resolve: any) => {
              //       resolve();
              //       deleteTableRow(oldData);
              //     }),
              // }}
              options={{
                thirdSortClick: false,
                actionsColumnIndex: -1,
                paging: true,
                sorting: true,
                search: false,
                filter: true,
                pageSize: 25,
                draggable: false,
                pageSizeOptions: [25, 50, 75, 100],
              }}
              // onOrderChange={(orderedColumnId: any, orderDirection: any) => {
              //   orderFunc(orderedColumnId, orderDirection);
              // }}
            />
          </Paper>
        </Grid>
      ) : (
        <AddEditForm
          handleOk={handleSubmitDialogBox}
          disabled={submitDisabled}
          handleCancel={closeDialogBox}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {formState.isFailed ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {FAILED}
                  {formState.errMessage}
                </Alert>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                label="Template"
                value={name}
                onChange={handleNameChange}
                error={isError.name}
                helperText={isError.name}
              >
                Category
              </Input>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <p className = "styles.DescriptionLabel">Template Description </p>
            <div className={styles.Editor}>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditorState}
                placeholder="  Template Description"
                toolbar={{
                  // We can add this functionality later as this was not supported currently in document Generation
                  options: ["inline"],
                  inline: {
                    options: ["bold", "italic"],
                    bold: { className: "bordered-option-classname" },
                    italic: { className: "bordered-option-classname" },
                    // underline: { className: "bordered-option-classname" },
                    // strikethrough: { className: "bordered-option-classname" },
                  },
                  //  blockType: {
                  //   className: "bordered-option-classname",
                  // },
                  fontSize: {
                    className: "bordered-option-classname",
                  },
                  fontFamily: {
                    className: "bordered-option-classname",
                  },
                }}
              />
            </div>
          </Grid>
        </AddEditForm>
      )}
      {/* </main> */}
    </React.Fragment>
  );
};

export default Category;
