import React, { useState, useEffect } from "react";
import styles from "./Partner.module.css";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Button } from "../../../components/UI/Form/Button/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Input from "../../../components/UI/Form/Input/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Alert from "../../../components/UI/Alert/Alert";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as routeConstant from "../../../common/RouteConstants";
import {
  SUCCESS,
  UPDATE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_CONTACT,
  UPDATE_CONTACT,
} from "../../../graphql/mutations/Contacts";
import { CREATE_ORG } from "../../../graphql/mutations/Organization";
import {
  CRREATE_PARTNER_SUBSCRIPTION,
  UPDATE_PARTNER_SUBSCRIPTIONS,
} from "../../../graphql/mutations/PartnerSubscription";
import { GET_ORGANIZATION } from "../../../graphql/queries/Organization";
import {
  CREATE_ADDRESS,
  UPDATE_PARTNER_ADDRESS,
} from "../../../graphql/mutations/Address";
import { GET_PARTNER_SUBSCRIPTION } from "../../../graphql/queries/PartnerSubscription";
import { GET_ADDRESS } from "../../../graphql/queries/Address";

export const PartnerForm: React.FC = (props: any) => {
  const [partnerName, setPartnerName] = useState("");
  const [pgpartnerId, setPGpartnerId] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const history = useHistory();
  const [CCsubscription, setCCsubscription] = useState(false);
  const [RAsubscription, setRAsubscription] = useState(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [createContact] = useMutation(CREATE_CONTACT,
    {
      onError: () => {
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [createOrganization] = useMutation(CREATE_ORG,
    {
      onError: () => {
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [partnerSubscription] = useMutation(CRREATE_PARTNER_SUBSCRIPTION,
    {
      onError: () => {
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [updatePartnerSubscription] = useMutation(UPDATE_PARTNER_SUBSCRIPTIONS,
    {
      onError: () => {
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [createAddress] = useMutation(CREATE_ADDRESS,
    {
      onError: () => {
        history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [updateAddress] = useMutation(UPDATE_PARTNER_ADDRESS,
    {
      onError: () => {
        // history.push(routeConstant.ADD_PARTNER)
      }
    });
  const [UpdateContact] = useMutation(UPDATE_CONTACT,
    {
      onCompleted: () => { },
      onError: () => {
        history.push(routeConstant.ADD_PARTNER)
      }
    });

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  const handleSubscription = (
    event: any,
    value: React.SetStateAction<boolean>
  ) => {
    if (event.target.name === "CCsubscription") {
      setCCsubscription(value);
    }
    if (event.target.name === "RAsubscription") {
      setRAsubscription(value);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "partnerName") {
      setPartnerName(event.target.value);
    }
    if (event.target.name === "pgpartnerId") {
      setPGpartnerId(event.target.value);
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "phoneNumber") {
      setPhoneNumber(event.target.value);
    }
    if (event.target.name === "address") {
      setAddress(event.target.value);
    }
  };

  const handleSubmit = () => {
    if (props.location.pathname.includes("/pg-partner-form/add")) {
      CreatePartner()
    }
    if (props.location.pathname.includes("/pg-partner-form/edit")) {
      getOrganization({
        variables: {
          where: {
            subtype: "Partner",
            pg_id: pgpartnerId
          }
        },
      });
    }
  };

  const CreatePartner = () => {
    createContact({
      variables: {
        name: partnerName,
        email: email,
        phone: phoneNumber,
        contact_type: "Organization",
      },
    }).then((contRes: any) => {
      CreateIntoOrganization(contRes.data.createContact.contact.id)
    }).catch((err) => {
      let error = err.message;
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: " " + error,
      }));
    });
  };

  const CreateIntoOrganization = (contactId: any) => {
    createOrganization({
      variables: {
        contact_id: contactId,
        subtype: "Partner",
        pg_id: parseInt(pgpartnerId)
      },
    })
    CreateIntoAddress(contactId)
    CreateIntoPartnerSubscription(contactId)
  };

  const CreateIntoAddress = (contactId: any) => {
    createAddress({
      variables: {
        contact_id: contactId,
        address1: address,
        locationType: "Office",
      },
    })
  }

  const CreateIntoPartnerSubscription = (contactId: any) => {
    partnerSubscription({
      variables: {
        contact_id: contactId,
        CCSubscription: CCsubscription,
        RAsubscription: RAsubscription,
      },
    }).then(() => {
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: partnerName + "has been successfull created",
      }));
    })
  }

  const [getOrganization, { data: Org }] = useLazyQuery(GET_ORGANIZATION, {
    onCompleted: (data: any) => {
      if (data.organizations.length === 1) {
        updateContactInfo(data.organizations[0].contact_id.id);
      }
      if (data.organizations.length === 0) {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: " " + "Entry Not Found !",
        }));
      }
    },
    onError: () => { }
  });

  const updateContactInfo = (contactId: any) => {
    UpdateContact({
      variables: {
        id: contactId,
        name: partnerName,
        email: email,
        phone: phoneNumber,
        contact_type: "Organization"
      }
    })
    getaddress({
      variables: {
        where: { contact_id: contactId }
      },
    });
    getPartnerSubs({
      variables: {
        where: { contact_id: contactId }
      },
    });
  };

  const [getaddress, { data: dataAD, loading: loadingAD }] = useLazyQuery(GET_ADDRESS, {
    onCompleted: (data: any) => {
      if (data.addresses.length !== 0) {
        updateIntoAddress(data.addresses[0].id)
      }
    },
    onError: () => {
      history.push(routeConstant.ADD_PARTNER)
    }
  });

  const updateIntoAddress = (addressId: any) => {
    updateAddress({
      variables: {
        id: addressId,
        address1: address,
        locationType: "Office"
      },
    })
  };

  const [getPartnerSubs, { data: dataSubs, loading: loadSubs }] = useLazyQuery(GET_PARTNER_SUBSCRIPTION, {
    onCompleted: (data: any) => {
      if (data.partnerSubscriptions.length === 1) {
        updateIntoPartnerSubscription(data.partnerSubscriptions[0].id)
      }
    },
    onError: () => {
      history.push(routeConstant.ADD_PARTNER, props.location.state)
    }
  });

  const updateIntoPartnerSubscription = (subscriptionId: any) => {
    updatePartnerSubscription({
      variables: {
        id: subscriptionId,
        CCSubscription: CCsubscription,
        RAsubscription: RAsubscription
      }
    }).then((res: any) => {
      setPartnerName("");
      setPhoneNumber("");
      setEmail("");
      setAddress("");
      setCCsubscription(false);
      setRAsubscription(false);
      setPGpartnerId("");
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: true,
        isDelete: false,
        isFailed: false,
        errMessage: " " + email + " ",
      }));
    });
  };

  const backToList = () => {
    history.push("/pg-action");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {props.location.pathname.includes("/pg-partner-form/edit") ? "Edit Partner " : "Add Partner "}
      </Typography>
      {/* { showBackdrop ? <SimpleBackdrop /> : null} */}
      <Grid item xs={12}>
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {FAILED}
            {formState.errMessage}
          </Alert>
        ) : null}
        {formState.isSuccess ? (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            User<strong>{formState.errMessage}</strong>
            {SUCCESS}
          </Alert>
        ) : null}
        {formState.isUpdate ? (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            User<strong>{formState.errMessage}</strong>
            {UPDATE}
          </Alert>
        ) : null}
      </Grid>
      {/* <AddEditForm
          handleOk={handleSubmit}
        > */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="PG Partner ID"
            name="pgpartnerId"
            value={pgpartnerId}
            onChange={handleChange}
          >
            Partner Name
              </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Partner Name"
            name="partnerName"
            value={partnerName}
            onChange={handleChange}
          >
            Partner Name
              </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Email"
            name="email"
            value={email}
            onChange={handleChange}
          >
            E-mail
              </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Phone Number"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handleChange}
          >
            Phone Number
              </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Address"
            name="address"
            value={address}
            onChange={handleChange}
          >
            Address
              </Input>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6} className={styles.SubscriptionBox}>
              <FormControlLabel
                control={
                  <Switch
                    checked={CCsubscription}
                    onChange={handleSubscription}
                    name="CCsubscription"
                    color="primary"
                  />
                }
                label="CC Subscription"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6} className={styles.SubscriptionBox}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={RAsubscription}
                        onChange={handleSubscription}
                        name="RAsubscription"
                        color="primary"
                      />
                    }
                    label="RA Subscription"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            // className={styles.ContinueButton}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          // type="submit"
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant={"contained"}
            onClick={backToList}
            color="secondary"
            data-testid="cancel-button"
          >
            {"Cancel"}
          </Button>
        </Grid>
      </Grid>
      {/* </AddEditForm> */}
    </React.Fragment>
  );
};

export default PartnerForm;
