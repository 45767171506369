import React, { useEffect, useState } from "react";
import styles from "./ComplianceList.module.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "../../components/UI/Table/MaterialTable";
import { Button } from "../../components/UI/Form/Button/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import * as routeConstant from "../../common/RouteConstants";
import { GET_COMPLIANCE } from "../../graphql/queries/Compliance";
import { RERUNCOMPLIANCE } from "../../graphql/mutations/ReRunCompliance";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_COMPLIANCEINFO } from "../../graphql/queries/ComplianceInfo";
import logout from "../../containers/Auth/Logout/Logout";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import { GET_COMPLIANCE_VERSION } from "../../graphql/queries/ComplianceVersion";
import { GET_COMPLIANCE_INFO } from "../../graphql/queries/Compliance";
import MainOfficeCompliance from "../Workplan/MainOfficeCompliance/MainOfficeCompliance";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteInfo from "../DeleteInfo/DeleteInfo";
import { setActiveFormStep } from "../../services/Data";

export const ComplianceList: React.FC = (props: any) => {
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const history = useHistory();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [newData, setNewData] = useState({});
  const [param, setParam] = useState<any>({});
  const [mainOffice, setMainOffice] = useState<any>();
  const [otherOffice, setOtherOffice] = useState<any>();
  const [preMainOffice, setPreMainOffice] = useState<any>([]);
  const [preOtherOffice, setPreOtherOffice] = useState<any>([]);
  const [version, setVersion] = useState<any>([]);
  const [folderName, setfolderName] = useState<any>();
  const [mainComponent, setMainComponent] = useState<any>();
  const [element, setElement] = useState<any>();
  const [processComplete, setProcessComplete] = useState<any>(false);
  const [showBackDrop, setShowBackdrop] = useState(true);
  const [showDownloadBackDrop, setShowDownloadBackDrop] = useState(false);
  
  const [reRunCompliance, { data: ReRundata }] = useMutation(RERUNCOMPLIANCE);
  if (props.location.state) {
    delete props.location.state["val"];
    delete props.location.state["complianceInfo"];
    delete props.location.state["NewComplianceInfo"];
    delete props.location.state["back"];
  }
  //table
  const column = [
    { title: "Compliance Name", field: "complianceName" },
    { title: "Client Name", field: "name" },
    { title: "Created on", field: "created_at" },
    { title: "Submitted on", field: "submitted_on" },
    { title: "Status", field: "status" },
  ];

  useEffect (()=>{
    // console.log("----compliance list >", props.location.state);
    setShowBackdrop(true)
    setParam(props.location.state)
  },[])

  const {
    data: compInfo,
    error: errorCI,
    loading: loadingCI,
    refetch: refetchCompInfo,
  } = useQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        client_id: props.location.state.clientInfo.clientId,
      },
    },
    onCompleted: () => {
      // setShowBackdrop(false)
      if (compInfo.complianceInfos.length !== 0) {
        let contact_id_array = compInfo.complianceInfos.map(
          (val: any) => val.contact_id.id
        );
        getcomplianceforClient({
          variables: {
            where: {
              contact_id_in: contact_id_array,
            },
          },
        });
      }
    },
    onError:()=>{
      setShowBackdrop(false)
      history.push(routeConstant.CLIENT)
    }
  });

  const [
      getcomplianceforClient,
      { data: iData, error: dataError, loading: iLoading },
    ] = useLazyQuery(GET_COMPLIANCE, {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        setShowBackdrop(false)
      }, 
      onError:()=>{
        setShowBackdrop(false)
        history.push(routeConstant.CLIENT)
      }
    });

  const [
    getComplianceInfo,
    { data: dataOrgUser, error: errorOrgUser, loading: loadingOrgUser },
  ] = useLazyQuery(GET_COMPLIANCEINFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setfolderName(dataOrgUser.complianceInfos[0].name)
      getComplianceVersion({
        variables: {
          where: { contact_id: dataOrgUser.complianceInfos[0].contact_id.id },
          sort: "created_at:DESC",
        },
      });
      downloadAllOffice(dataOrgUser.complianceInfos[0].name)
      if(dataOrgUser.complianceInfos[0].compliance_document.length == 0){
        setShowDownloadBackDrop(false)
      }
    }, onError:()=>{
      setShowBackdrop(false)
      history.push(routeConstant.CLIENT)
    }
  });

  const getComplianceInformation = (data: any) => {
    getCompliance({
      variables: {
        input: data,
      },
    });
  };

  const getPreComplianceInfo = (data: any) => {
    getPrevCompliance({
      variables: {
        input: data,
      },
    });
  };

  const [
    getComplianceVersion,
    { data: compVersionData, loading: compVersionLoading },
  ] = useLazyQuery(GET_COMPLIANCE_VERSION, {
    onCompleted: () => {
      if (compVersionData.complianceVersions.hasOwnProperty(0)) {
        getComplianceInformation(
          compVersionData.complianceVersions[0].compliances
        );
      }
      setVersion(compVersionData.complianceVersions);
      if (compVersionData.complianceVersions.hasOwnProperty(1)) {
        getPreComplianceInfo(compVersionData.complianceVersions[1].compliances);
      }
    },
    fetchPolicy: "cache-and-network",
    onError:()=>{
      backToList()
      setShowBackdrop(false)
    }
  });

  const [
    getCompliance,
    { data: compData, loading: compLoading },
  ] = useLazyQuery(GET_COMPLIANCE_INFO, {
    onCompleted: () => {
      setMainOffice(compData.getComplianceInfo.data.mainoffice);
      setOtherOffice(compData.getComplianceInfo.data.otheroffice);
      setShowBackdrop(false)
    },
    fetchPolicy: "cache-and-network", 
    onError:()=>{
      backToList()
      setShowBackdrop(false)
    }
  });

  const [
    getPrevCompliance,
    { data: prvCompData, loading: prvCompLoading },
  ] = useLazyQuery(GET_COMPLIANCE_INFO, {
    onCompleted: () => {
      setPreMainOffice(prvCompData.getComplianceInfo.data.mainoffice);
      setPreOtherOffice(prvCompData.getComplianceInfo.data.otheroffice);
      setShowBackdrop(false)
    },
    fetchPolicy: "cache-and-network", 
    onError:()=>{
      backToList()
      setShowBackdrop(false)
    }
  });

  // const onErrorRedirection = ()=>{
  //   history.push(routeConstant.CLIENT,param)
  // }

  const createTableDataObject = (data: any) => {
    let arr: any = [];
    let contactId: any = [];
    data.map((element: any, i: any) => {
      contactId.push(element.contact_id.id);
      let obj: any = {};
      obj["id"] = element.id;
      obj["name"] = props.location.state.clientInfo.name;
      obj["contactId"] = element.contact_id.id;
      obj["created_at"] = moment(element.created_at).format(
        "MM/DD/YYYY hh:mm a"
      );
      obj["updated_at"] = moment(element.updated_at).format(
        "MM/DD/YYYY hh:mm a"
      );
      obj["complianceName"] = !element.name ? "-" : element.name;
      obj["status"] = "In Progress"
      obj["submitted_on"] = "-"
      if (iData) {
        let tempComp = false;
        for (let j in iData.compliances) {
          if (iData.compliances[j].contact_id) {
            if (element.contact_id.id === iData.compliances[j].contact_id.id) {
              tempComp = true;
              obj["status"] = "Done"
              obj["submitted_on"] = moment(iData.compliances[j].created_at).format('MM/DD/YYYY hh:mm a');
              break;
            } else {
              // obj["status"] = "In Progress"
              // obj["submitted_on"] = "-"
              tempComp = false;
            }
          }
        }
        obj["allowDownload"] = tempComp;
      }
      arr.push(obj);
    });

    setNewData(arr);
    setShowBackdrop(false)
  };

  useEffect(() => {
    if (mainOffice && otherOffice && preMainOffice && dataOrgUser) {
      let param: any = {
        clientInfo: props.location.state.clientInfo,
        complianceInfo: dataOrgUser.complianceInfos[0],
      };
      setMainComponent(
        <MainOfficeCompliance
          // key={mainOffice}
          data={{
            new: mainOffice,
            other: otherOffice,
            pre: preMainOffice,
          }}
          complianceName={dataOrgUser.complianceInfos[0].name}
          complianceList={true}
        ></MainOfficeCompliance>
      );
    }
  }, [mainOffice, otherOffice, preMainOffice, dataOrgUser]);

  useEffect(() => {
    if (compInfo) {
      
      createTableDataObject(compInfo.complianceInfos);
    }
  }, [compInfo, iData]);

  // useEffect(() => {
  //   refetchCompInfo();
  // }, [newData]);

  const enableBackdrop = () => {
    if(showBackDrop||showDownloadBackDrop){
      return true;
    } else {
      return false;
    }
  }


  if (errorCI) {
    let error = errorCI ? errorCI : { message: "Error" };
    return (
      <div className="error">
        {/* Error! */}
        {logout()}
      </div>
    );
  }

  const onRowClick = (event: any, rowData: any, oldData: any, val: any) => {
    if (val === "Edit") {
      let param: any = {
        clientInfo: props.location.state.clientInfo,
        complianceInfo: rowData,
      };
      history.push(routeConstant.COMPANY_INFO, param);
    }

    if (val === "Re-Run") {
      setShowBackdrop(true)
      reRunCompliance({
        variables: {
          input: { id: rowData.contactId },
        },
      }).then((rerunData) => {
        if (rerunData.data.reRunCompliance) {
          let param: any = {
            clientInfo: props.location.state.clientInfo,
            complianceInfo: rerunData.data.reRunCompliance,
          };
          setShowBackdrop(false)
          history.push(routeConstant.COMPANY_INFO, param);
        }
      }).catch(()=>{
        setShowBackdrop(false)
      });
    }

    if (val === "View") {
      let param: any = {
        clientInfo: props.location.state.clientInfo,
        complianceInfo: rowData,
        from: "ComplianceList"
      };
      history.push(routeConstant.COMPLIANCE, param);
    }

    if (val === "Delete") {
      // setShowBackdrop(true)
      deleteInformations(rowData)
    }
  };

  const deleteInformations = (param: any) => {
    let obj = {
      from: "ComplianceList",
      complianceInfo: param,
      isCompleted: false
    }
    setElement(
      <DeleteInfo
        obj={obj}
        callDeleteMethod={updateDeleteFlag}
      />
    );
   
  }

  const updateDeleteFlag = (event: any) => {
    // setProcessComplete(!event.isCompleted);
    if (event.isCompleted) {
      setShowBackdrop(true)
      refetchCompInfo()
    }
  }

  const resetData = () => {
    setMainComponent(undefined);
  };

  const onDownload = (event: any, rowData: any) => {
    setShowDownloadBackDrop(true)
    resetData();
    if (
      props.location.state &&
      props.location.state.hasOwnProperty("clientInfo")
    ) {
      getComplianceInfo({
        variables: {
          where: {
            client_id: props.location.state.clientInfo.clientId,
            contact_id: rowData.contactId,
            id: rowData.id,
          },
        },
      });
    }
  };

  const handleClickOpen = (rowData: any) => {
    let val: any = {
      clientInfo: props.location.state.clientInfo,
    };
    setActiveFormStep(0);
    history.push(routeConstant.COMPANY_INFO, val);
    setOpenEdit(true);
  };

  const downloadAllOffice = async (name:any) => {
    if(dataOrgUser.complianceInfos[0].compliance_document[0]) {
      const lawUrl = dataOrgUser.complianceInfos[0].compliance_document[0].url.slice(1);
      const DocUrl = serverUrl + lawUrl;
      let fetchDoc = await fetch(DocUrl, {
        method: "GET",
      }).then((res) => res.blob()).then((response) => {
        saveAs(response, name + ".zip");
        setShowDownloadBackDrop(false)
      });
    }
  };

  const backToList = () => {
    history.push(routeConstant.CLIENT);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {/* { enableBackdrop() || showDownloadBackDrop  ? <SimpleBackdrop /> : null } */}
      { enableBackdrop() ? <SimpleBackdrop /> : null }
      {!openEdit ? (
        <Grid>
      <Typography component="h5" variant="h1">
        {/* CyberCompliance: {props.location.state ? props.location.state.clientInfo.name : null}  */}
         Compliance List
      </Typography>
          <Grid container>
            <Grid item xs={12} sm={8} className={styles.FilterWrap}>
            </Grid>
            <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
              <div className={styles.ButtonGroup2}>
                <Button
                  className={styles.BackToButton}
                  variant={"contained"}
                  onClick={backToList}
                  color="secondary"
                  data-testid="cancel-button"
                >
                  <img
                  src={
                    process.env.PUBLIC_URL + "/icons/svg-icon/back-list.svg"
                  } alt="user icon"
                  />
                  &nbsp; Back to List
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  <AddCircleIcon className={styles.EditIcon} />
                  &nbsp; New Compliance
                </Button>
              </div>
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            <div className={styles.ScrollTable}>
              { enableBackdrop() ? null : (
                <MaterialTable
                  columns={column}
                  data={newData}
                  actions={[
                    (rowData: any) =>
                      rowData.allowDownload === false
                        ? {
                          icon: () => <img className={styles.EditIcon}
                          src={
                            process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
                          }
                          alt="edit icon"
                        />,
                          tooltip: "Edit",
                          onClick: (event: any, rowData: any, oldData: any) => {
                            onRowClick(event, rowData, oldData, "Edit");
                          },
                        }
                        : null,
                    (rowData: any) =>
                      rowData.allowDownload === true
                        ? {
                          icon: () => <FileCopyIcon />,
                          tooltip: "Make a Copy",
                          onClick: (event: any, rowData: any, oldData: any) => {
                            onRowClick(event, rowData, oldData, "Re-Run");
                          },
                        }
                        : null,
                    (rowData: any) =>
                      rowData.allowDownload === true
                        ? {
                          icon: () => <GetAppIcon />,
                          tooltip: "Download",
                          onClick: (event: any, rowData: any, oldData: any) => {
                            onDownload(event, rowData);
                          },
                        }
                        : null,
                    (rowData: any) =>
                      rowData.allowDownload === true
                        ? {
                          icon: () => <VisibilityIcon />,
                          tooltip: "View",
                          onClick: (event: any, rowData: any, oldData: any) => {
                            onRowClick(event, rowData, oldData, "View");
                          },
                        }
                        : null,
                    (rowData: any) =>
                    rowData.allowDownload === false
                        ? {
                          icon: () => (
                            <img
                              className={styles.EditIcon}
                              src={process.env.PUBLIC_URL + "/icons/svg-icon/delete.svg"}
                              alt="delete icon"
                            />
                          ),
                          tooltip: "Delete",
                          onClick: (event: any, rowData: any, oldData: any) => {
                            onRowClick(event, rowData, oldData, "Delete");
                          },
                        }
                        : null,
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: "#EFF6FD",
                      color: "#002F60",
                    },
                    actionsColumnIndex: -1,
                    paging: true,
                    sorting: true,
                    search: false,
                    filter: true,
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 75, 100],
                  }}
                />
               )} 
            </div>
            {mainComponent ? mainComponent : null}
            {element ? element : null}
          </Paper>
        </Grid>
      ) : (
          <div></div>
        )}
    </React.Fragment>
  );
};

export default ComplianceList;
