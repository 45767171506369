import React, { useState, useEffect, useCallback, useMemo } from "react";
import styles from "./State.module.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
import Input from "../../../components/UI/Form/Input/Input";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { GET_STATES } from "../../../graphql/queries/States";
import {
  CREATE_STATE,
  UPDATE_STATE,
  DELETE_STATE,
} from "../../../graphql/mutations/State";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import {
  useQuery,
  useMutation,
  ApolloError,
  useLazyQuery,
} from "@apollo/client";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Grid } from "@material-ui/core";
import { Dropdown } from "../../../components/UI/Form/Dropdown/Dropdown";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import * as dropDownConstants from "../../../common/dropDownConstants";
import logout from "../../../containers/Auth/Logout/Logout";
import DeleteIcon from '@material-ui/icons/Delete';

export const State: React.FC = () => {
  //Autocomplete list
  const [getSubTypeList, setSubTypeList] = useState([]);

  //Dialog Box
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);

  //add/edit data
  const [name, setName] = useState<String>("");
  const [is_active, setIs_active] = useState(true);
  const [newData, setNewData] = useState();
  const [editDataId, setEditDataId] = useState<Number | null>();
  const [loading, setLoading] = useState<boolean>(false);

  //filter dropdown values
  const isActiveFilters = dropDownConstants.isActiveFilters;
  const title = "Listing of States";

  //validation and error handelling
  const [isError, setIsError] = useState<any>({ name: "" });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [filterName, setFilterName] = useState("");
  const [filterIsActive, setFilterIsActive] = useState<boolean | null>();
  const [reset, setReset] = useState<boolean>(false);
  const [filters, setFilters] = useState<object>();
  const [orderBy, setOrderBy] = useState<String>();
  const [sortOrder, setSortOrder] = useState<String | null>("asc");
  //queries
  const [createOrganizationType] = useMutation(CREATE_STATE);
  const [updateOrganizationType] = useMutation(UPDATE_STATE);
  const [deleteOrganizationType] = useMutation(DELETE_STATE);

  //table
  const columns = [
    {
      title: "Name",
      field: "name",
      defaultSort: orderBy?.includes("name") ? sortOrder : "asc",
    },
    {
      title: "Is Active",
      field: "is_active",
      render: (rowData: any) => (
        <div className={styles.SvgWrap}>
          {rowData.is_active ? <CheckIcon /> : <CloseIcon />}
        </div>
      ),
    },
  ];
  //filter query condition declaration
  const [
    getStates,
    { data: dataST, error: errorST, loading: loadingST },
  ] = useLazyQuery(GET_STATES, {
    fetchPolicy: "cache-and-network",
    variables:
      !orderBy || orderBy === ""
        ? { where: filters, sort: "name:asc" }
        : { where: filters, sort: orderBy },
    notifyOnNetworkStatusChange: true,
  });

  const checkValidation = () => {
    if (showDialogBox === true) {
      if (isError.name !== "" || !name) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (dataST) {
      let temp: any = {};
      temp = convertTableData(dataST.states);
      setNewData(temp);
      console.log("dataST", dataST);
    }
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [dataST, filters, formState]);

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    setSubmitDisabled(checkValidation);
  }, [name, submitDisabled]);

  if (loadingST || loading) return <Loading />;
  if (errorST) {
    {
      logout();
    }

    return <div className="error">Organization Type Error!</div>;
  }

  function convertTableData(data: any) {
    let arr: any = [];
    for (let i in data) {
      let tempArr: any = {};
      tempArr["id"] = data[i].id;
      tempArr["name"] = data[i].name;
      tempArr["is_active"] = data[i].is_active;
      arr.push(tempArr);
    }
    return arr;
  }

  const nameFilter = (event: any) => {
    setFilterName(event.target.value);
  };

  const isActiveFilter = (event: any) => {
    setFilterIsActive(event.target.value);
  };

  const handleClickOpen = (rowData: any) => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
    setIsError((isError: any) => ({
      ...isError,
      name: "",
    }));
    setName("");
    setIs_active(true);
    setShowDialogBox(true);
    if (rowData.id) {
      setEditDataId(rowData.id);
      setName(rowData.name);
      setIs_active(rowData.is_active);
    }
  };

  const deleteTableRow = (rowData: any) => {
    setLoading(true);
    deleteOrganizationType({
      variables: {
        id: rowData.id,
      },
    })
      .then((userRes) => {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: true,
          isFailed: false,
          errMessage: "",
        }));
        getStates();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: err.message,
        }));
      });
  };
  const handleSearch = () => {
    let searchData: any = {};
    if (filterName) {
      searchData["name_contains"] = filterName.toString();
    }
    if (filterIsActive !== null && filterIsActive !== undefined) {
      searchData["is_active"] = filterIsActive;
    }
    setFilters(searchData);
  };

  const resetForm = () => {
    setFilters({});
    setReset(true);
    setFilterName("");
    setFilterIsActive(null);
    setOrderBy("name");
    getStates();
  };

  const handleSubmitDialogBox = () => {
    if (editDataId) {
      updateOrganizationType({
        variables: {
          id: editDataId,
          name: name,
          is_active: is_active,
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: true,
            isDelete: false,
            isFailed: false,
            errMessage: "",
          }));
          setShowDialogBox(false);
          setEditDataId(null);
        })
        .catch((err) => {
          console.log("error", err.message);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
          setEditDataId(null);
        });
    } else {
      createOrganizationType({
        variables: {
          name: name,
          is_active: is_active,
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: "",
          }));
          setShowDialogBox(false);
          getStates();
        })
        .catch((err) => {
          console.log("error", err);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
        });
    }
  };

  // const orderFunc = (orderedColumnId: any, orderDirection: any) => {
  //   let orderByColumn;
  //   let orderBy = "";
  //   if (orderedColumnId >= 0) {
  //     if (columns[orderedColumnId]["field"] === "name") {
  //       orderByColumn = "name";
  //     }
  //     if (columns[orderedColumnId]["field"] === "is_active") {
  //       orderByColumn = "is_active";
  //     }
  //   }
  //   orderBy = orderByColumn + ":" + orderDirection;
  //   setSortOrder(orderDirection);
  //   setOrderBy(orderBy);
  // };

  const closeDialogBox = () => {
    setShowDialogBox(false);
    setEditDataId(null);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    let value = event.target.value;
    let isErrName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      name: isErrName,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handleIsActiveChange = (
    event: any,
    value: React.SetStateAction<boolean>
  ) => {
    setIs_active(value);
  };
  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <main className={styles.layout}> */}
      <Typography component="h5" variant="h1">
        State
      </Typography>
      {!showDialogBox ? (
        <Grid className={styles.TableWrap}>
          <div className={styles.FilterAddWrap}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
            >
              <AddCircleIcon className={styles.EditIcon} />
              &nbsp; State
            </Button>
          </div>
          <Grid className={styles.FilterWrap}>
            <div className={styles.FilterInput}>
              <Input
                label="Name"
                name="filterName"
                id="combo-box-demo"
                value={filterName}
                onChange={nameFilter}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className={styles.FilterInput}>
              <Dropdown
                placeholder="Is Active"
                label="Is Active"
                value={filterIsActive || ""}
                onChange={isActiveFilter}
                options={isActiveFilters}
              ></Dropdown>
            </div>
            <div className={styles.FilterSearchButton}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
            <div className={styles.FilterResetButton}>
              <Button color="secondary" variant="contained" onClick={resetForm}>
                reset
              </Button>
            </div>
          </Grid>
          <Paper className={styles.paper}>
            {formState.isSuccess ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                State {SUCCESS}
              </Alert>
            ) : null}
            {formState.isUpdate ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                State {UPDATE}
              </Alert>
            ) : null}

            {formState.isDelete ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                State {DELETE}
              </Alert>
            ) : null}
            <MaterialTable
              title={title}
              columns={columns}
              data={newData}
              actions={[
                {
                  icon: () => (
                    <img
                      className={styles.EditIcon}
                      src={process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"}
                      alt="edit icon"
                    />
                  ),
                  tooltip: "Edit",
                  onClick: (event: any, rowData: any) => {
                    handleClickOpen(rowData);
                  },
                },
                {
                  icon: () => (
                    <img
                      className={styles.EditIcon}
                      src={process.env.PUBLIC_URL + "/icons/svg-icon/delete.svg"}
                      alt="delete icon"
                    />
                  ),
                  tooltip: "Delete",
                  onClick: (event: any, rowData: any) => {
                    deleteTableRow(rowData);
                  },
                },
              ]}
              // editable={{
              //   onRowDelete: (oldData: any) =>
              //     new Promise((resolve: any) => {
              //       resolve();
              //       deleteTableRow(oldData);
              //     }),
              // }}
              options={{
                thirdSortClick: false,
                actionsColumnIndex: -1,
                paging: true,
                sorting: true,
                search: false,
                filter: true,
                pageSize: 25,
                draggable: false,
                pageSizeOptions: [25, 50, 75, 100],
              }}
              // onOrderChange={(orderedColumnId: any, orderDirection: any) => {
              //   orderFunc(orderedColumnId, orderDirection);
              // }}
            />
          </Paper>
        </Grid>
      ) : (
        <AddEditForm
          handleOk={handleSubmitDialogBox}
          disabled={submitDisabled}
          handleCancel={closeDialogBox}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {formState.isFailed ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {FAILED}
                  {formState.errMessage}
                </Alert>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                label="State"
                value={name}
                onChange={handleNameChange}
                error={isError.name}
                helperText={isError.name}
              >
                State
              </Input>
            </Grid>
            <Grid item xs={12} md={6} className={styles.SubscriptionBox}>
              <FormControlLabel
                control={
                  <Switch
                    checked={is_active}
                    onChange={handleIsActiveChange}
                    name="is_active"
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Grid>
          </Grid>
        </AddEditForm>
      )}

      {/* </main> */}
    </React.Fragment>
  );
};

export default State;
