import { gql } from "@apollo/client";

export const CREATE_COMPLIANCEINFO = gql`
  mutation createComplianceInfo(
    $contact_id: ID!
    $organization_type: ID!
    $organization_subtype: ID
    $best_practices: [ID!]
    $client_id: ID!
    $name: String!
  ) {
    createComplianceInfo(
      input: {
        data: {
          contact_id: $contact_id
          organization_type: $organization_type
          organization_subtype: $organization_subtype
          best_practices: $best_practices
          client_id: $client_id
          name: $name
        }
      }
    ) {
      complianceInfo {
        id
        created_at
        updated_at
        contact_id {
          id
          email
          contact_type
          name
          user_id {
            id
            username
          }
        }
      }
    }
  }
`;

export const UPDATE_COMPLIANCEINFO = gql`
mutation updateComplianceInfo(
  $id: ID!
  $contact_id: ID!
  $organization_type: ID!
  $organization_subtype: ID
  $best_practices: [ID!]
  $name: String!
) {
  updateComplianceInfo(
    input: {
      where: { id: $id }
      data: {
        contact_id: $contact_id
        organization_type: $organization_type
        organization_subtype: $organization_subtype
        best_practices: $best_practices
        name: $name
      }
    }
  ) {
    complianceInfo {
      id
      name
      organization_type {
        id
      }
      organization_subtype {
        id
      }
      best_practices {
        id
      }
    }
  }
}`;

export const UPDATE_COMPLIANCEINFO_NAME = gql`
mutation updateComplianceInfo(
  $id: ID!
  $name: String!
  $compliance_document:[ID]
) {
  updateComplianceInfo(
    input: {
      where: { id: $id }
      data: {
        name: $name
        compliance_document: $compliance_document
      }
    }
  ) {
    complianceInfo {
      id
      organization_type {
        id
      }
      compliance_document{
        id
        name
        url
      }
      organization_subtype {
        id
      }
      best_practices {
        id
      }
    }
  }
}`;

export const DELETE_COMPLIANCEINFO = gql`
  mutation DeleteComplianceInfo($id: ID!) {
    deleteComplianceInfo(input: { where: { id: $id } }) {
      complianceInfo {
        id
        contact_id {
          id
        }
      }
    }
  }
`;