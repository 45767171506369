// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*Change border for textfield*/\n\n.Autocomplete_ReactAutoComplete__3Gt7A:focus, .Autocomplete_ReactAutoComplete__3Gt7A:hover fieldset, .Autocomplete_ReactAutoComplete__3Gt7A fieldset {\n  border-color: #0051A8 !important;\n  border-width: 1px !important;\n  border-radius: 10px !important;\n}\n\n.Autocomplete_ReactAutoComplete__3Gt7A input {\n  color: #707070;\n}\n\n.Autocomplete_ReactAutoComplete__3Gt7A label+div {\n  padding: 0px 36px 0px 0px !important;\n  background-color: #fff;\n  border-radius: 12px !important;\n}\n\n.Autocomplete_ReactAutoComplete__3Gt7A label+div>div {\n  background-color: #fff;\n  border: 1px solid #0051A8;\n  margin: 5px 0px 0px 5px;\n}\n\n.Autocomplete_ReactAutoComplete__3Gt7A label+div>div svg {\n  color: #0051A8 !important;\n}\n\n.Autocomplete_ReactAutoComplete__3Gt7A label+div>div+input+div, .Autocomplete_ReactAutoComplete__3Gt7A label+div>input+div {\n  background-color: #fff;\n  border: 0px;\n  margin: 0px;\n}\n\n.Autocomplete_ReactAutoComplete__3Gt7A {\n  max-width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"ReactAutoComplete": "Autocomplete_ReactAutoComplete__3Gt7A"
};
module.exports = exports;
