import { gql } from "@apollo/client";

export const CREATE_ADDRESS = gql`
  mutation CreateAddress(
    $contact_id: ID!
    $address1: String
    $address2: String
    $locationType: ENUM_ADDRESS_LOCATION_TYPE
    $city: String
    $state: ID
    $zip: String
  ) {
    createAddress(
      input: {
        data: {
          contact_id: $contact_id
          address_line_1: $address1
          address_line_2: $address2
          location_type: $locationType
          city: $city
          state: $state
          zip: $zip
        }
      }
    ) {
      address {
        id
        contact_id {
          id
          name
          email
          contact_type
          user_id {
            id
            username
          }
        }
        address_line_1
        address_line_2
        city
        state {
          id
          name
        }
        zip
      }
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress (
    $id: ID!
    $contact_id: ID!
    $address1: String!
    $address2: String!
    $locationType: ENUM_ADDRESS_LOCATION_TYPE!
    $city: String!
    $state: ID!
    $zip: String!
  ) {
    updateAddress(
      input: {
        where: { id: $id }
        data: {
          contact_id: $contact_id
          address_line_1: $address1
          address_line_2: $address2
          location_type: $locationType
          city: $city
          state: $state
          zip: $zip
        }
      }
    ) {
      address {
        id
        contact_id {
          id
          name
          email
          contact_type
          user_id {
            id
            username
          }
        }
        address_line_1
        address_line_2
        city
        state {
          id
          name
        }
        zip
      }
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: ID!) {
    deleteAddress(input: { where: { id: $id } }) {
      address {
        id
        contact_id {
          id
          name
          email
          contact_type
          user_id {
            id
            username
          }
        }
        address_line_1
        address_line_2
        city
        state {
          id
          name
        }
        zip
      }
    }
  }
`;

export const UPDATE_PARTNER_ADDRESS = gql`
  mutation UpdateAddress (
    $id: ID!
    $contact_id: ID
    $address1: String!
    $address2: String
    $locationType: ENUM_ADDRESS_LOCATION_TYPE!
    $city: String
    $zip: String
  ) {
    updateAddress(
      input: {
        where: { id: $id }
        data: {
          contact_id: $contact_id
          address_line_1: $address1
          address_line_2: $address2
          location_type: $locationType
          city: $city
          zip: $zip
        }
      }
    ) {
      address {
        id
        contact_id {
          id
          name
          email
          contact_type
          user_id {
            id
            username
          }
        }
        address_line_1
        address_line_2
        city
        state {
          id
          name
        }
        zip
      }
    }
  }
  `;
