import { gql } from '@apollo/client';

export const RERUNCOMPLIANCE = gql`
 mutation ReRunCompliance($input: JSON!) {
  reRunCompliance(context: $input) {
    name
    id
    contactId
    allowDownload
  }
}
`;