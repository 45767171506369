// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Backdrop_CenterItems__1pLau {\n  display: flex;\n  align-items: center;\n  align-content: center;\n  height: 100%;\n  flex-direction: column;\n  min-height: 768px;\n  }\n\n.Backdrop_LoadingPadding__YmvT9 {\n  padding-top: 350px;\n  color: #ffffff;\n}\n\n.Backdrop_backdroptext__3fEhU {\n  color: #ffffff;\n  font-size: 24px;\n}", ""]);
// Exports
exports.locals = {
	"CenterItems": "Backdrop_CenterItems__1pLau",
	"LoadingPadding": "Backdrop_LoadingPadding__YmvT9",
	"backdroptext": "Backdrop_backdroptext__3fEhU"
};
module.exports = exports;
