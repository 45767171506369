import React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router";
import Category from "./containers/AdminPanel/Category/Category";
import PiiData from "./containers/AdminPanel/PiiData/PiiData";
import PiiCategory from "./containers/AdminPanel/PiiCategory/PiiCategory";
import Workplan from "./containers/AdminPanel/Workplan/Workplan";
import Policy from "./containers/AdminPanel/Policy/Policy";
import Rule from "./containers/AdminPanel/Rule/Rule";
import OrganizationType from "./containers/AdminPanel/OrganizationType/OrganizationType";
import State from "./containers/AdminPanel/State/State";
import Law from "./containers/AdminPanel/Law/Law";
import Logout from "./containers/Auth/Logout/Logout";
import Company from "./containers/AdminPanel/Company/Company";
import Partner from "./containers/AdminPanel/Partner/Partner/Partner";
import PartnerUser from "./containers/AdminPanel/Partner/PartnerUser/PartnerUser";
import AdminDashboard from "./containers/AdminPanel/Dashboard/Dashboard";
import * as routeConstants from "./common/RouteConstants";
import PartnerUserForm from "./containers/AdminPanel/PartnerUserForm/PartnerUserForm";
import LayoutRoute from "./common/LayoutRoute";

const defaultRedirect = () => <Redirect to="/admin-dashboard" />;
export const AdminRoutes = (
  <Switch>
    <LayoutRoute path={routeConstants.PARTNER_USER_FORM} exact component={PartnerUserForm} />
    <LayoutRoute path={routeConstants.PARTNER_USER_FORM_ADD} exact component={PartnerUserForm} />
    <LayoutRoute path={routeConstants.PARTNER_USER_FORM_EDIT + ":id" }exact component={PartnerUserForm} />
    <LayoutRoute
      path={routeConstants.ADMIN_DASHBOARD}
      exact
      component={AdminDashboard}
    />
    <LayoutRoute path={routeConstants.ADD_PARTNER} exact component={Partner} />
    <LayoutRoute
      path={routeConstants.PARTNER_USER}
      exact
      component={PartnerUser}
    />
    <LayoutRoute path={routeConstants.PIIDATA} exact component={PiiData} />
    <LayoutRoute
      path={routeConstants.PIICATEGORY}
      exact
      component={PiiCategory}
    />
    <LayoutRoute path={routeConstants.WORKPLAN} exact component={Workplan} />
    <LayoutRoute path={routeConstants.POLICY} exact component={Policy} />
    <LayoutRoute path={routeConstants.RULE} exact component={Rule} />
    <LayoutRoute
      path={routeConstants.ORGANIZATION_TYPE}
      exact
      component={OrganizationType}
    />
    <LayoutRoute path={routeConstants.STATE} exact component={State} />
    <LayoutRoute path={routeConstants.LOGOUT_URL} exact component={Logout} />
    <LayoutRoute path={routeConstants.LAW} exact component={Law} />
    <LayoutRoute path={routeConstants.COMPANY} exact component={Company} />
    <LayoutRoute path={routeConstants.TEMPLATE} exact component={Category} />
    <Route path="/" render={defaultRedirect} />
  </Switch>
);
