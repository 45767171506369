import React, { useState, useEffect } from "react";
import styles from "./PartnerUserForm.module.css";
import Grid from "@material-ui/core/Grid";
import { Typography, FormHelperText } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Input from "../../../components/UI/Form/Input/Input";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useMutation, FetchResult, useLazyQuery } from "@apollo/client";
import {
  CREATE_CONTACT,
  UPDATE_CONTACT,
} from "../../../graphql/mutations/Contacts";
import { CREATE_USER, UPDATE_USER } from "../../../graphql/mutations/User";
import {
  PG_CREATE_INDIVIDUAL,
  UPDATE_INDIVIDUAL,
} from "../../../graphql/mutations/Individual";
import { GET_ROLE } from "../../../graphql/queries/User";
import { CompanyUser, Adminstrator } from "../../../common/Roles";
import { GET_INDIVIDUAL } from "../../../graphql/queries/Individual";
import { useHistory } from "react-router-dom";
import * as routeConstant from "../../../common/RouteConstants";
import {
  FAILED,
  SUCCESS,
  ALERT_MESSAGE_TIMER,
  UPDATE,
} from "../../../common/MessageConstants";
import { Button } from "../../../components/UI/Form/Button/Button";
import { GET_USER_DETAILS } from "../../../graphql/queries/PG";
import { GET_ORGANIZATION } from "../../../graphql/queries/Organization";

export const PartnerUserForm: React.FC = (propsData: any) => {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [pgID, setPgID] = useState("");
  const [pgUserID, setPgUserID] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [userRole, setUserRole] = useState();
  let PartnerID: any

  const [isError, setIsError] = useState<any>({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
    phoneNumber: "",
    pgID: "",
    pgUserID: ""
  });

  let partnerUserdata: any;
  if (propsData.location.state !== null) {
    partnerUserdata = propsData.location.state
      ? propsData.location.state.propsData
      : null;
  }

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [createContact] = useMutation(CREATE_CONTACT);
  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [updateContact] = useMutation(UPDATE_CONTACT);
  const [createIndividual] = useMutation(PG_CREATE_INDIVIDUAL);
  const [updateIndividual] = useMutation(UPDATE_INDIVIDUAL);

  const [getRole, { data: userData, loading: userLoading }] = useLazyQuery(GET_ROLE, {
    onCompleted: () => { },
    onError: () => {
      history.push(routeConstant.ADMIN_DASHBOARD);
    },
  });

  const [getPgDetails, { data: pg_UserData, loading: pgUserLoading }] = useLazyQuery(GET_USER_DETAILS, {
    onCompleted: (data: any) => {
      if (data != null && data.individualsConnection.values !== 0 && data.individualsConnection.values[0]) {
        getIndividual({
          variables: {
            where: { partner_id_null: false, contact_id: pg_UserData.individualsConnection.values[0].contact_id.id },
            sort: "created_at:desc",
          },
        });
      } else {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: " Entry Not Found",
        }));
      }
    },
    onError: () => {},
  });

  const [getOrganization, { data: Org }] = useLazyQuery (GET_ORGANIZATION, {
    onCompleted: (data: any) => {
      if (data.organizations[0] !== undefined) {
        insertIntoUser();
        PartnerID = data.organizations[0].contact_id.id
      }
    },
  });

  const [getIndividual, { data: iData, loading: iLoading }] = useLazyQuery (GET_INDIVIDUAL, {
    onCompleted: () => {
      if (
        propsData.location.pathname.includes("/pg-partner-user-form/edit")
      ) {
        updateIntoUser();
      }

    },
    onError: () => {},
  });

  let UserRole: any;
  useEffect(() => {
    if (userData) {
      userData.roles.map((val: any) => {
        if (val.name === CompanyUser) UserRole = parseInt(val.id);
      });
    }
    setUserRole(UserRole);
  }, [userData]);

  useEffect(() => {
    getRole();
  }, []);

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "pgUserID") {
      setPgUserID(event.target.value);
    }
    if (event.target.name === "pgID") {
      setPgID(event.target.value);
    }
    if (event.target.name === "firstName") {
      setFirstName(event.target.value);
    }
    if (event.target.name === "lastName") {
      setLastName(event.target.value);
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "phoneNumber") {
      setPhoneNumber(event.target.value);
    }
  };

  const checkValidation = () => {
    let validation = false;
    return validation;
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const backToList = () => {
    if (
      propsData &&
      propsData.location.state &&
      propsData.location.state.propsData &&
      propsData.location.state.propsData.from &&
      propsData.location.state.propsData.from === "admin-partner-user"
    ) {
      history.push("/pg-action", propsData.location.state);
    } else {
      history.push("/pg-action", propsData.location.state);
    }
  };


  const handleSubmit = () => {
    if (propsData.location.pathname.includes("/pg-partner-user-form/edit")) {
      getPgDetails({
        variables: {
          pg_user_id: JSON.parse(pgUserID)
        },
      });
    } else {
      callorganization();
    }
  };

  const insertIntoUser = () => {
    if (userRole) {
      createUser({
        variables: {
          username: email.toLowerCase(),
          email: email.toLowerCase(),
          password: password,
          role: userRole,
          confirmed: true,
        },
      })
        .then((userRes) => {
          insertIntoContact(userRes);
        })
        .catch((err) => {
          let error = err.message;
          if (
            error.includes(
              "Cannot return null for non-nullable field UsersPermissionsUser.id."
            )
          ) {
            error = " Email already exists.";
          } else {
            error = err.message;
          }
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
          if (
            err ==
            "Error: Cannot return null for non-nullable field UsersPermissionsUser.id."
          ) {
            let errors = "This email already exists";
            setIsError((error: any) => ({
              ...error,
              email: errors,
            }));
          }
        });
    }
  };

  const insertIntoContact = (
    userRes: FetchResult<any, Record<string, any>, Record<string, any>>
  ) => {
    createContact({
      variables: {
        name: firstName + " " + lastName,
        email: email.toLowerCase(),
        phone: phoneNumber,
        contact_type: "Individual",
        user_id: userRes.data.createUser.user.id,
      },
    })
      .then((conRes) => {
        insertIntoIndividual(conRes);
      })
      .catch((err) => { });
  };
  const callorganization = () => {

    getOrganization({
      variables: {
        where: {
          subtype: "Partner",
          pg_id: pgID
        }
      },
    });
  }

  const insertIntoIndividual = (
    conRes: FetchResult<any, Record<string, any>, Record<string, any>>
  ) => {
    if (PartnerID) {
      createIndividual({
        variables: {
          first_name: firstName,
          last_name: lastName,
          contact_id: conRes.data.createContact.contact.id,
          partner_id: PartnerID,
          pg_user_id: JSON.parse(pgUserID),
        },
      })
        .then((res) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: " " + firstName + " " + lastName + " ",
          }));
          setEmail("");
          setFirstName("");
          setLastName("");
          setPassword("");
          setPgID("");
          setPgUserID("")
          setPhoneNumber("")
        })
        .catch((err) => { });
    } else {
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: "Partner not Found",
      }));
    }
  };

  const updateIntoUser = () => {
    if (userRole && iData.individuals[0].partner_id.id) {
      if (password) {
        updateUser({
          variables: {
            id: JSON.parse(iData.individuals[0].id),
            username: email.toLowerCase(),
            email: email.toLowerCase(),
            password: password,
            role: userRole,
            confirmed: true,
          },
        })
          .then((userRes) => {
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: true,
              isDelete: false,
              isFailed: false,
              errMessage: " " + firstName + " " + lastName + " ",
            }));
            updateIntoContact(userRes);
          })
          .catch((err) => {
            let error = err.message;
            if (
              error.includes(
                "Cannot return null for non-nullable field UsersPermissionsUser.id."
              )
            ) {
              error = " Email already exists.";
            } else {
              error = err.message;
            }
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: error,
            }));
          });
      }
      if (!password) {
        updateUser({
          variables: {
            id: JSON.parse(iData.individuals[0].id),
            username: email.toLowerCase(),
            email: email.toLowerCase(),
            role: userRole,
            confirmed: true,
          },
        })
          .then((userRes) => {
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: true,
              isDelete: false,
              isFailed: false,
              errMessage: " " + firstName + " " + lastName + " ",
            }));
            updateIntoContact(userRes);
          })
          .catch((err) => {
            let error = err.message;
            if (
              error.includes(
                "Cannot return null for non-nullable field UsersPermissionsUser.id."
              )
            ) {
              error = " Email already exists.";
            } else {
              error = err.message;
            }
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: error,
            }));
          });
      }
    }
  };

  const updateIntoContact = (
    userRes: FetchResult<any, Record<string, any>, Record<string, any>>
  ) => {
    updateContact({
      variables: {
        name: firstName + " " + lastName,
        email: email.toLowerCase(),
        phone: phoneNumber,
        contact_type: "Individual",
        user_id: userRes.data.updateUser.user.id,
        id: pg_UserData.individualsConnection.values[0].contact_id.id,
      },
    })
      .then((conRes) => {
        updateIntoIndividual(conRes);
      })
      .catch((err) => { });
  };
  const updateIntoIndividual = (
    conRes: FetchResult<any, Record<string, any>, Record<string, any>>
  ) => {
    updateIndividual({
      variables: {
        first_name: firstName,
        last_name: lastName,
        contact_id: conRes.data.updateContact.contact.id,
        id: iData.individuals[0].id,
      },
    })
      .then((res) => {
        // backToList();
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: true,
          isDelete: false,
          isFailed: false,
          errMessage: " " + firstName + " " + lastName + " ",
        }));
      })
      .catch((err) => {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: " " + firstName + " " + lastName + " ",
        }));
      });
    // }
  };


  const handlePasswordChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(event.target.value);
    let err = event.target.value === "" || null ? "Password is Required" : "";
    setIsError((error: any) => ({
      ...error,
      password: err,
    }));
  };


  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        <div>
          {propsData.location.pathname.includes("/pg-partner-user-form/edit") ? "Edit User " : "Add User "}
        </div>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {formState.isFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {FAILED}
              {formState.errMessage}
            </Alert>
          ) : null}
          {formState.isSuccess ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              User<strong>{formState.errMessage}</strong>
              {SUCCESS}
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              User<strong>{formState.errMessage}</strong>
              {UPDATE}
            </Alert>
          ) : null}
        </Grid>
        {!propsData.location.pathname.includes("/pg-partner-user-form/edit/") ?
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="PG Partner ID"
              name="pgID"
              value={pgID}
              onChange={handleChange}
              error={isError.pgID}
              helperText={isError.pgID}
            >
              Pg Id
          </Input>
          </Grid>
          : null}
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="PG User ID"
            name="pgUserID"
            value={pgUserID}
            onChange={handleChange}
            error={isError.pgUserID}
            helperText={isError.pgUserID}
          >
            pgUserID
          </Input>
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Phone Number"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handleChange}
            error={isError.phoneNumber}
            helperText={isError.phoneNumber}
          >
            Phone Number
          </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={handleChange}
            error={isError.firstName}
            helperText={isError.firstName}
          >
            First Name
          </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={handleChange}
            error={isError.lastName}
            helperText={isError.lastName}
          >
            Last Name
          </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Email"
            name="email"
            value={email}
            onChange={handleChange}
            error={isError.email}
            helperText={isError.email}
          >
            E-mail
          </Input>
        </Grid>

        <Grid item xs={12} md={6} className={styles.ConfirmPasswordWrap}>
          <FormControl className={styles.TextField} variant="outlined">
            <InputLabel classes={{ root: styles.FormLabel }}>
              Password
            </InputLabel>
            <OutlinedInput
              classes={{
                root: styles.InputField,
                notchedOutline: styles.InputField,
                focused: styles.InputField,
              }}
              type={"text"}
              label="Password"
              value={password}
              onChange={handlePasswordChange()}
              name="password"
              required
              error={isError.password}
            />
            {isError.password ? (
              <FormHelperText
                error={isError.password}
                classes={{ root: styles.FormHelperText }}
              >
                Password is Required.
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant={"contained"}
            onClick={backToList}
            color="secondary"
            data-testid="cancel-button"
          >
            {"Cancel"}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PartnerUserForm;
