import React, { useState } from "react";
import styles from "./CustomerForm.module.css";
import { GET_STATES } from "../../graphql/queries/States";
import { useQuery } from "@apollo/client";
import Loading from "../../components/UI/Layout/Loading/Loading";
import {
  Grid,
  FormHelperText
} from "@material-ui/core";
import AutoCompleteDropDown from "../../components/UI/Form/Autocomplete/Autocomplete";
import Input from "../../components/UI/Form/Input/Input";

interface stateValues {
  id: number;
  name: string;
}

export interface CustomerProps {
  index?: number;
  data?: any;
  handleChange?: any;
}

export const CustomerForm: React.FC<CustomerProps> = ({
  index = 0,
  data,
  handleChange
}: CustomerProps) => {
  const [stateList, setStateList] = useState("");
  const [state, setState] = useState("");
  const [numOfCustomerError, setNoOfCustomerError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [regexp] = useState(/^[0-9]+$/);
  const [numOfCustomer, setNumOfCustomer] = useState("");

  const { data: dataST, error: error, loading: loading } = useQuery(GET_STATES);

  if (loading) return <Loading />;

  if (error) {
    let error = { message: "Error" };
    return (
      <div className="error">
        Error!
      </div>
    );
  }

  if (stateList.length === 0) {
    setStateList(dataST.states);
  }
  const getStateList = {
    options: stateList,
    getOptionLabel: (option: stateValues) => (option.name ? option.name : "")
  };

  if (data !== undefined) {
    if (state === "" && data[index].state !== "") {
      setState(data[index].state);
    }
    if (numOfCustomer === "" && data[index].numOfCustomer !== "") {
      setNumOfCustomer(data[index].numOfCustomer);
    }
  }

  // Set state value
  const handleStateChange = (event: any, selectedValue: any) => {
    data[index].state = selectedValue;
    if (!selectedValue) {
      setStateError(true);
      data[index].isError.state = true;
    } else {
      setStateError(false);
      data[index].isError.state = false;
    }
    setState(selectedValue);
    handleChange(data);
  };

  // Set Customer values
  const customerHandleChange = (event: any) => {
    data[index][event.target.name] = event.target.value;
    if (event.target.name === "numOfCustomer") {
      if (!event.target.value) {
        setNoOfCustomerError(true);
        data[index].isError.numOfCustomer = true;
      } else {
        setNoOfCustomerError(false);
        data[index].isError.numOfCustomer = false;
      }
      if (event.target.value === "" || regexp.test(event.target.value)) {
        setNumOfCustomer(event.target.value);
      }
    }
    handleChange(data);
  };

  return (
    <React.Fragment>
      <form>
      <Grid container spacing={3}>
        <Grid item xs={8} md={8} xl={8}>
          <AutoCompleteDropDown
            {...getStateList}
            id="state"
            value={state ? state : null}
            name="state"
            onChange={handleStateChange}
            renderInput={(
              params:
                | (JSX.IntrinsicAttributes &
                    import("@material-ui/core").StandardTextFieldProps)
                | (JSX.IntrinsicAttributes &
                    import("@material-ui/core").FilledTextFieldProps)
                | (JSX.IntrinsicAttributes &
                    import("@material-ui/core").OutlinedTextFieldProps)
            ) => (
              <Input
                {...params}
                error={stateError}
                required
                id="state"
                label="State"
              />
            )}
          />
          {stateError ? (
            <FormHelperText classes={{ root: styles.FormHelperText }}>
              State is required.
            </FormHelperText>
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6} xs={6} xl={6}>
          <div className={styles.customerEmpnum}>
            <Input
              required
              id="numOfCustomer"
              name="numOfCustomer"
              label="No of Customer"
              value={numOfCustomer}
              error={numOfCustomerError}
              onChange={customerHandleChange}
            />
          </div>
          {numOfCustomerError ? (
            <FormHelperText classes={{ root: styles.FormHelperText }}>
              No of Customer is required.
            </FormHelperText>
          ) : null}
        </Grid>
      </Grid>
      </form>
    </React.Fragment>
  );
};

export default CustomerForm;
