import React, { useState, useRef } from "react";
import styles from "./Office.module.css";
import Grid from "@material-ui/core/Grid";
// import { TextField } from "@material-ui/core";
// import Autocomplete from "../../components/UI/Form/Autocomplete/Autocomplete";
import { GET_STATES } from "../../graphql/queries/States";
import { useQuery } from "@apollo/client";
import Loading from "../../components/UI/Layout/Loading/Loading";
import Input from "../../components/UI/Form/Input/Input";
import AutoCompleteDropDown from "../../components/UI/Form/Autocomplete/Autocomplete";
import Switch from "../../components/UI/Form/Switch/Switch";
import PiiDataForm from "../PiiDataForm/PiiDataForm";
import { Typography, FormHelperText, Divider, Button } from "@material-ui/core";
import { type } from "os";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";

interface stateValues {
  id: number;
  name: string;
  __typename: string;
}

export interface AddressProps {
  name: String;
  index?: number;
  data?: any;
  handleChange?: any;
  PIIErrorCheck?: any;
  showPiiError?: any;
}

export const Office: React.SFC<AddressProps> = ({
  name,
  index = 0,
  data,
  handleChange,
  PIIErrorCheck,
  showPiiError,
}: AddressProps) => {

  const [stateList, setStateList] = useState("");

  // Address
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [regexp, setRegexp] = useState(/^[0-9]+$/);
  // Employee
  const [noOfEmployee, setNoOfEmployee] = useState("");
  const [doYouCollectPiiData, setDoYouCollectPiiData] = useState(false);
  const [showPiiData, setShowPiiData] = useState(false);
  const [piiArray, setPiiArray] = useState<any>([]);
  // Error
  const [address1Error, setAddress1Error] = useState(false);
  const [address2Error, setAddress2Error] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [zipRegError, setZipRegError] = useState(false);
  const [noOfEmployeeError, setNoOfEmployeeError] = useState(false);
  const [checked, setChecked] = useState(false);
  const { data: dataSt, error: error, loading: loading } = useQuery(GET_STATES);

  if (loading) return <Loading/>;

  if (error) {
    let error = { message: "Error" };
    return <div className="error">Error!</div>;
  }

  if (stateList.length === 0) {
    setStateList(dataSt.states);
  }

  if (name === "Main Office") {
    if (address1 === "" && data[index].address.address1 !== "") {
      setAddress1(data[index].address.address1);
    }
    if (address2 === "" && data[index].address.address2 !== "") {
      setAddress2(data[index].address.address2);
    }
    if (city === "" && data[index].address.city !== "") {
      setCity(data[index].address.city);
    }
    if (zip === "" && data[index].address.zip !== "") {
      setZip(data[index].address.zip);
    }
    if (state === "" && data[index].address.state !== "") {
      setState(data[index].address.state);
    }
    if (noOfEmployee === "" && data[index].employee.noOfEmployee !== "") {
      setNoOfEmployee(data[index].employee.noOfEmployee);
    }
    if (
      doYouCollectPiiData === false &&
      data[index].employee.doYouCollectPiiData === true
    ) {
      setDoYouCollectPiiData(true);
      setShowPiiData(true);
    }
    if (piiArray.length === 0 && data[index].employee.piiArray.length !== 0) {
      setPiiArray(data[index].employee.piiArray);
    }
  }

  if (name === "Other Office") {
    if (address1 === "" && data[index].address.address1 !== "") {
      setAddress1(data[index].address.address1);
    }
    if (address2 === "" && data[index].address.address2 !== "") {
      setAddress2(data[index].address.address2);
    }
    if (city === "" && data[index].address.city !== "") {
      setCity(data[index].address.city);
    }
    if (zip === "" && data[index].address.zip !== "") {
      setZip(data[index].address.zip);
    }
    if (state === "" && data[index].address.state !== "") {
      setState(data[index].address.state);
    }
    if (noOfEmployee === "" && data[index].employee.noOfEmployee !== "") {
      setNoOfEmployee(data[index].employee.noOfEmployee);
    }
    if (
      doYouCollectPiiData === false &&
      data[index].employee.doYouCollectPiiData === true
    ) {
      setDoYouCollectPiiData(true);
      setShowPiiData(true);
    }
    if (piiArray.length === 0 && data[index].employee.piiArray.length !== 0) {
      setPiiArray(data[index].employee.piiArray);
    }
  }

  const getStateList = {
    options: stateList,
    getOptionLabel: (option: stateValues) => (option.name ? option.name : "")
  };

  // Set Address value
  const addressHandleChange = (event: any) => {
    data[index].address[event.target.name] = event.target.value;
    if (event.target.name === "address1") {
      if (!event.target.value) {
        setAddress1Error(true);
        data[index].address.isError.address1 = true;
      } else {
        setAddress1Error(false);
        data[index].address.isError.address1 = false;
      }
      setAddress1(event.target.value);
    }

    if (event.target.name === "address2") {
      setAddress2(event.target.value);
    }

    if (event.target.name === "city") {
      if (!event.target.value) {
        setCityError(true);
        data[index].address.isError.city = true;
      } else {
        setCityError(false);
        data[index].address.isError.city = false;
      }
      setCity(event.target.value);
    }
    if (event.target.name === "zip") {
      setZip(event.target.value);
      if (!event.target.value) {
        setZipError(true);
        data[index].address.isError.zip = true;
      }
      else {
        // var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(event.target.value);
        setZipError(false);
        // setZipRegError(!isValidZip);
        data[index].address.isError.zip = false;
      }
      // if (event.target.value === "" || regexp.test(event.target.value)) {
      //   setZip(event.target.value);
      // }
    }
    handleChange(data);
  };

  // Set state value
  const handleStateChange = (event: any, selectedValue: any) => {
    data[index].address.state = selectedValue;
    if (!selectedValue) {
      setStateError(true);
      data[index].address.isError.state = true;
    } else {
      setStateError(false);
      data[index].address.isError.state = false;
    }
    setState(selectedValue);
    handleChange(data);
  };

  // Set Employee values
  const employeeHandleChange = (event: any) => {
    data[index].employee[event.target.name] = event.target.value;
    if (event.target.name === "noOfEmployee") {
      if (!event.target.value) {
        setNoOfEmployeeError(true);
        data[index].employee.isError.noOfEmployee = true;
      } else {
        setNoOfEmployeeError(false);
        data[index].employee.isError.noOfEmployee = false;
      }
      if (event.target.value === "" || regexp.test(event.target.value)) {
        setNoOfEmployee(event.target.value);
      }
    }
    if (event.target.name === "doYouCollectPiiData") {
      data[index].employee.doYouCollectPiiData = event.target.checked;
      setDoYouCollectPiiData(event.target.checked);
      showHidePiiDataFields(event.target.checked);
    }
    handleChange(data);
  };

  // Create Pii values array
  let optPiiArray: any = [];
  const handleCheckElement = (event: any) => {
    let val = parseInt(event.target.value);
    optPiiArray = [...piiArray];

    if (optPiiArray.includes(val)) {
      let position = optPiiArray.indexOf(val);
      // if (position) optPiiArray.splice(position, 1);
      if (position >= 0) {
        optPiiArray.splice(position, 1);
      }
    } else {
      optPiiArray.push(val);
    }
    setPiiArray(optPiiArray);
    data[index].employee.piiArray = optPiiArray;
    handleChange(data);
  };

  // Show and Hide pii data fields
  const showHidePiiDataFields = (type: string) => {
    if (type === "true") {
      setShowPiiData(true);
    } else {
      setPiiArray([]);
      data[index].employee.piiArray = [];
      setShowPiiData(false);
    }
    handleChange(data);
  };

  return (
    <React.Fragment>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              required
              id="address1"
              name="address1"
              label="Address line 1"
              value={address1}
              autoComplete="address-line1"
              error={address1Error}
              onChange={addressHandleChange}
            />
            {address1Error ? (
              <FormHelperText classes={{ root: styles.FormHelperText }}>
                Address Line 1 is required.
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Input
              id="address2"
              name="address2"
              label="Address line 2"
              value={address2}
              autoComplete="address-line2"
              onChange={addressHandleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              id="city"
              name="city"
              label="City"
              value={city}
              autoComplete="address-level2"
              error={cityError}
              onChange={addressHandleChange}
            />
            {cityError ? (
              <FormHelperText classes={{ root: styles.FormHelperText }}>
                City is required.
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoCompleteDropDown
              {...getStateList}
              id="state"
              value={state}
              name="state"
              onChange={handleStateChange}
              renderInput={(
                params:
                  | (JSX.IntrinsicAttributes &
                    import("@material-ui/core").StandardTextFieldProps)
                  | (JSX.IntrinsicAttributes &
                    import("@material-ui/core").FilledTextFieldProps)
                  | (JSX.IntrinsicAttributes &
                    import("@material-ui/core").OutlinedTextFieldProps)
              ) => (
                  <Input
                    {...params}
                    error={stateError}
                    required
                    id="state"
                    label="State"
                  />
                )}
            />
            {stateError ? (
              <FormHelperText classes={{ root: styles.FormHelperText }}>
                State is required.
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              id="zip"
              type="text"
              name="zip"
              label="Zip"
              value={zip}
              error={zipError}
              // inputProps={{
              //   maxLength: 5
              // }}
              onChange={addressHandleChange}
            />
            {zipError ? (
              <FormHelperText classes={{ root: styles.FormHelperText }}>
                Zip is required.
              </FormHelperText>
            ) : null}
            {/* {zipRegError ? (
              <FormHelperText classes={{ root: styles.FormHelperText }}>
                Zip Should be 5 digit.
              </FormHelperText>
            ) : null} */}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Employee Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.Empnum}>
              <Input
                required
                id="noOfEmployee"
                name="noOfEmployee"
                label="No of employees"
                value={noOfEmployee}
                error={noOfEmployeeError}
                onChange={employeeHandleChange}
              />
              {noOfEmployeeError ? (
                <FormHelperText classes={{ root: styles.FormHelperText }}>
                  No of Employee is required.
                </FormHelperText>
              ) : null}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <PiiDataForm
              handleChange={employeeHandleChange}
              handleCheckElement={handleCheckElement}
              doYouCollectPiiData={doYouCollectPiiData}
              showPiiData={showPiiData}
              PIIdata={piiArray}
              PIIErrorCheck={(event: any) => {
                if (PIIErrorCheck) {
                  PIIErrorCheck(event, index)
                }
              }}
              showPiiError={showPiiError}
            />
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default Office;
