import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "../../components/UI/Table/MaterialTable";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../components/UI/Form/Button/Button";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DescriptionIcon from "@material-ui/icons/Description";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useLazyQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../graphql/queries/Organization";
import { GET_INDIVIDUAL } from "../../graphql/queries/Individual";
import { GET_PARTNER_SUBSCRIPTION } from "../../graphql/queries/PartnerSubscription";
import { useHistory } from "react-router-dom";
import logout from "../../containers/Auth/Logout/Logout";
import * as routeConstant from "../../common/RouteConstants";
import moment from "moment";
import { Link } from 'react-router-dom'
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {GetCSETUserDetails} from "../../graphql/queries/CSETUserDetails";
import { CSET_URL } from '../../config/index';
import { GET_CLIENT_SUBSCRIPTION } from "../../graphql/queries/ClientSubscription";

export const Dashboard: React.FC = () => {
  const [clientCount, setClientCount] = useState();
  const [partnerCount, setPartnerCount] = useState();
  const contact = JSON.parse(localStorage.getItem("cc_contact") || "{}");
  const [newData, setNewData] = useState();
  const [CCsubscription, setCCsubscription] = useState(false);
  const history = useHistory();
  const [RAsubscription, setRAsubscription] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [clientInfo, setClientInfo] = useState("");
  const [clientData, setClientData] = useState("");
  const [subscriptionList, setSubscriptionList] = useState<any>();
  const [showBackDrop, setShowBackdrop] = useState(true);

  const [GetCSETUserLoginDetails, {data: CSETLoginData, loading: CSETLoginLoading}] = useLazyQuery(
    GetCSETUserDetails,
    {
      fetchPolicy: "cache-and-network",
      onCompleted:(LoginRequestData:any) => {
        if(LoginRequestData.csetUserDetails && LoginRequestData.csetUserDetails[0]){
          // setCSETUserPassword(LoginRequestData.csetUserDetails[0].cset_password);
          // setCSETUserEmail(LoginRequestData.csetUserDetails[0].cset_username);

          LoginRegisterdCSETUser(LoginRequestData.csetUserDetails[0].cset_username, LoginRequestData.csetUserDetails[0].cset_password);
        }else{
          setDataLoading(false)
        }
      },
    })
  const [
    getOrganization,
    { data: dataOrg, loading: loadingOrg }
  ] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: "cache-and-network",
    onError: () => {
      logout();
    },
    onCompleted: () => {
      // createTableDataObject(dataOrg.organizations);
      let contact_id_array = dataOrg.organizations.map((val: any) => val.contact_id.id);
      getClientSubs({
        variables: {
          where: { contact_id_in: contact_id_array },
        },
      });
      setClientCount(dataOrg.organizations.length);
    }
  });
  const [getClientSubs, { data: dataClientSubs, loading: loadClientSubs }] = useLazyQuery(
    GET_CLIENT_SUBSCRIPTION,
    {
      onCompleted: (data: any) => {
        // if (data.clientSubscriptions[0] != undefined) {
        //   setCCsubscription(data.clientSubscriptions[0].cc_subscription);
        // }
        // if (data.clientSubscriptions[0] != undefined) {
        //   setRAsubscription(data.clientSubscriptions[0].ra_subscription);
        // }
        setShowBackdrop(false)
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(()=>{
    if (dataOrg) {
      createTableDataObject(dataOrg.organizations)
    }
  }, [dataOrg, dataClientSubs]);
  //table
  const column = [
    { title: "Company Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "phone" },
    { title: "Created on", field: "createdon" },
  ];
  const createTableDataObject = (data: any) => {
    let arr: any = [];
    data.map((element: any, i: any) => {
      let obj: any = {};
      obj["createdon"] = moment(element.contact_id.created_at).format(
        "MM/DD/YYYY hh:mm a"
      );
      obj["email"] = !element.contact_id.email ? "-" : element.contact_id.email;
      obj["name"] = element.contact_id.name + " " + (element.contact_id.last_name ? element.contact_id.last_name : "");
      obj["first_name"] = element.contact_id.name;
      obj["last_name"] = element.contact_id.last_name;
      obj["phone"] = !element.contact_id.phone ? "-" : element.contact_id.phone;
      obj["clientId"] = element.contact_id.id;
      obj["partnerId"] = element.partner_id.id;
      obj["clientOrgId"] = element.id;
      obj["partnerSubscriptions"] = subscriptionList;

      if (dataClientSubs) {
        for (let j in dataClientSubs.clientSubscriptions) {
          if (element.contact_id.id === dataClientSubs.clientSubscriptions[j].contact_id.id) {
            obj["clientSubsId"] = dataClientSubs.clientSubscriptions[j].id;
            obj["cc_subs"] = subscriptionList.cc_subscription ? dataClientSubs.clientSubscriptions[j].cc_subscription : false;
            obj["ra_subs"] = subscriptionList.ra_subscription ? dataClientSubs.clientSubscriptions[j].ra_subscription : false;
          }
        }
      }
      arr.push(obj);
    });
    setNewData(arr.slice(0, 5));
  };

  const handleClickOpen = () => {
    let data: any = { showAddClient: true };
    history.push(routeConstant.CLIENT_FORM_ADD);
  };
  const partnerClickOpen = () => {
    let data: any = { "showPartnerUser": true };
    history.push(routeConstant.PARTNER_USER, data);
  };

  const onRowClick = (event: any, rowData: any, oldData: any, param: any) => {
    let data: any = { clientInfo: rowData };
    if (param === "CC") {
      history.push(routeConstant.COMPLIANCE_LIST, data);
    }
    if (param === "RA") {
      history.push(routeConstant.RA_REPORT_LISTING, data);
    }
    if (param === "CSET") {
      setDataLoading(true);
      GetCSETUserLoginDetails({
        variables: {
          where: { contact:{id:rowData.clientId} },
        },
      })
      setClientInfo(rowData);
      setClientData(data)
      // setDataLoading(true)
      // history.push(routeConstant.ASSESSMENT_LIST, data);
    }
    if (param === "View") {
    }
    if (param === "Edit") {
    }
    if (param === "Delete") {
    }
  };
  const [getIndividual, { data: iData, error: iError ,loading: iLoading }] = useLazyQuery(
    GET_INDIVIDUAL,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (iData.individuals[0] !== undefined) {
          getIndividualpartner({
            variables: {
              where: { partner_id: iData.individuals[0].partner_id.id }
            }
          });
          getPartnerSubs({
            variables: {
              where: { contact_id: iData.individuals[0].partner_id.id }
            }
          });
        }
      },
      onError:()=>{
        logout()
      }
    }
  );
  let contactIdArray: any = [];
  const [
    getIndividualpartner,
    { data: ipData, loading: ipLoading }
  ] = useLazyQuery(GET_INDIVIDUAL, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setPartnerCount(ipData.individuals.length);
      for (let i in data.individuals) {
        contactIdArray.push(data.individuals[i].contact_id.id);
      }
    }
  });
  useEffect(() => {
    getIndividual({
      variables: {
        where: { partner_id_null: false, contact_id: contact.id }
      }
    });
  }, []);
  useEffect(() => {
    if (contactIdArray.length > 0) {
      getOrganization({
        variables: {
          where: { subtype: "Client", partner_id: contactIdArray },
          sort: "created_at:desc"
        }
      });
    }
  }, [contactIdArray]);

  const [getPartnerSubs, { data: dataSubs, loading: loadSubs }] = useLazyQuery(
    GET_PARTNER_SUBSCRIPTION,
    {
      onCompleted: (data: any) => {
        setSubscriptionList(data.partnerSubscriptions[0]);
      },
      fetchPolicy: "cache-and-network"
    }
  );

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }

  if (iError) {
    let error = { message: "Error" };
    return (
      <div className="error">
        Error!
        {logout()}
      </div>
    )
  }
  const headerObj = { 
    'Content-Type': 'application/json',
    'Authorization': '',
    'Accept' : '*/*' 
  }
  const LoginRegisterdCSETUser = async (username: any, password: any)=>{
    try {
      let loginUserData = {
        "Email": username,
        "Password": password,
        "TzOffset": new Date().getTimezoneOffset(),
        "Scope": "CSET"
      };
        let CSetLogin = await fetch(CSET_URL + "api/auth/login" , {
          method: 'POST',
          headers: headerObj,
          body: JSON.stringify(loginUserData)
        }).then(response => response.json())
        .then(data => {
          let param = {clientInfo: clientInfo, csetUserInfo: data}
          localStorage.setItem("ra_token", JSON.stringify(data.Token))
          history.push(routeConstant.ASSESSMENT_LIST, param);
          setDataLoading(false)
        });
    } catch (error) {
      setDataLoading(false)
     };
  };

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        { enableBackdrop() ? <SimpleBackdrop /> : null }
        <Grid container spacing={3} className={styles.GridBox}>
          <Grid item xs={12} md={6} className={styles.FilterAddWrap}>
            <div className={styles.dash_block}>
              <div className={styles.dash_head}>Clients</div>
              <div className={styles.dash_count}>{clientCount}</div>
            </div>
          </Grid>

          <Grid item xs={12} md={6} className={styles.FilterAddWrap}>
            <Grid container>
              <Grid item xs={12} sm={6} md={12}>
                <div className={styles.partnerButton}>
                  <Link to={routeConstant.PARTNER_USER_FORM_ADD}>
                    <Button color="primary" variant="contained">
                      <AddCircleIcon className={styles.EditIcon}/>
                      User
                    </Button>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <div className={styles.partnerButton}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleClickOpen}
                  >
                    <AddCircleIcon className={styles.EditIcon} />
                    Client
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
      <Grid className={styles.recentTypo} item xs={12}>
        <Typography component="h2" variant="h1" gutterBottom>
          Recently added clients
        </Typography>
      </Grid>
      <Grid>
        <Paper className={styles.tableGrid}>
          { enableBackdrop() ? null :
            <MaterialTable
              columns={column}
              data={newData}
              actions={[
                (rowData: any) =>
                rowData ?
                {
                  icon: () =>
                  !rowData.cc_subs ? <DescriptionIcon style={{ fill: "grey" }} /> : <DescriptionIcon/> ,                   
                  tooltip: "Cyber Compliance",
                  disabled: !rowData.cc_subs,
                  onClick: (event: any, rowData: any, oldData: any) => {
                    onRowClick(event, rowData, oldData, "CC");
                  },
                }
                : null,
                (rowData: any) => 
                rowData ?
                  {
                    icon: () => 
                    !rowData.ra_subs ? <AssignmentTurnedInIcon style={{ fill: "grey" }} /> : <AssignmentTurnedInIcon/> ,
                    tooltip: "RA",
                    disabled: !rowData.ra_subs,
                    onClick: (event: any, rowData: any, oldData: any) => {
                      onRowClick(event, rowData, oldData, 'RA');
                    },
                  }
                : null,
              ]}
              options={{
                headerStyle: {
                  backgroundColor: "#EFF6FD",
                  color: "#002F60",
                },
                actionsColumnIndex: -1,
                paging: false,
                sorting: true,
                search: false,
                filter: true,
                draggable: false,
              }}
            />
            }
        </Paper>
      </Grid>
    </div>
  );
};

export default Dashboard;
