import { gql } from "@apollo/client";

export const CREATE_ORG_LOC = gql`
  mutation CreateOrgLocation(
    $contactId: ID!
    $addressId: ID!
    $noOfEmp: Int!
    $collectePiiInfo: Boolean!
    $pii_data_field: [ID]!
  ) {
    createOrganizationLocation(
      input: {
        data: {
          contact_id: $contactId
          address_id: $addressId
          no_of_employees: $noOfEmp
          collect_pii_information: $collectePiiInfo
          pii_data_field: $pii_data_field
        }
      }
    ) {
      organizationLocation {
        id
        contact_id {
          id
          name
          email
          contact_type
          user_id {
            id
            username
          }
        }
        no_of_employees
        collect_pii_information
        pii_data_field {
          id
          name
          is_active
          category {
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_ORG_LOC = gql`
  mutation UpdateOrgLocation(
    $id: ID!
    $contactId: ID!
    $addressId: ID!
    $noOfEmp: Int!
    $collectePiiInfo: Boolean!
    $pii_data_field: [ID]!
  ) {
    updateOrganizationLocation(
      input: {
        where: { id: $id }
        data: {
          contact_id: $contactId
          address_id: $addressId
          no_of_employees: $noOfEmp
          collect_pii_information: $collectePiiInfo
          pii_data_field: $pii_data_field
        }
      }
    ) {
      organizationLocation {
        id
        contact_id {
          id
          name
          email
          contact_type
          user_id {
            id
            username
          }
        }
        no_of_employees
        collect_pii_information
        pii_data_field {
          id
          name
          is_active
          category {
            name
          }
        }
      }
    }
  }
`;

export const DELETE_ORG_LOC = gql`
  mutation DeleteOrgLocation($id: ID!){
    deleteOrganizationLocation(input: { where: { id: $id } }) {
      organizationLocation {
        id
        contact_id {
          id
          name
          email
          contact_type
          user_id {
            id
            username
          }
        }
        no_of_employees
        collect_pii_information
        pii_data_field {
          id
          name
          is_active
          category {
            name
          }
        }
      }
    }
  }
`;