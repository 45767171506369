import { gql } from "@apollo/client";

export const GET_LAW = gql`
  query($where: JSON, $sort: String) {
    laws(where: $where, sort: $sort) {
      id
      name
      jurisdiction
      state {
        id
        name
      }
      law_document {
        id
      }
      url
      parent_law {
        id
        name
      }
      rules {
        id
        name
      }
      pii_data {
        id
        name
      }
      applicable_to {
        id
        type
      }
      company_type {
        id
        name
      }
      best_practices {
        id
        Name
      }
      law_document {
        id
        name
        url
        mime
        hash
        caption
        ext
      }
    }
  }
`;
